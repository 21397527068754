import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  rating: number;
  scale?: number;
  rateable?: boolean;
  className?: string;
  onRatingChange?: (rating: number) => void;
}

/**
 * StarRating component renders a star-based rating system.
 *
 * @component
 * @param {number} rating - The current rating value.
 * @param {number} [scale=5] - The maximum rating value (default is 5).
 * @param {boolean} [rateable=false] - If true, the stars are clickable for rating.
 * @param {string} [className=''] - Additional CSS classes for the component.
 * @param {function} [onRatingChange] - Callback function to handle rating changes.
 *
 * @example
 * <StarRating rating={3} scale={5} rateable={true} onRatingChange={(newRating) => console.log(newRating)} />
 */
const StarRating: React.FC<Props> = ({
  rating,
  scale = 5,
  rateable = false,
  className = '',
  onRatingChange
}: Props) => {
  const handleRatingClick = (rating: number) => rateable && onRatingChange && onRatingChange(rating);

  return (
    <div className={className}>
      {Array(rating)
        .fill(1)
        .map((_, i) => (
          <FontAwesomeIcon
            icon={faStar}
            className={`text-yellow-400 mr-1 ${rateable ? 'cursor-pointer hover:text-yellow-500' : ''}`}
            onClick={() => handleRatingClick(i + 1)}
            key={i}
          />
        ))}
      {Array(scale - rating)
        .fill(1)
        .map((_, i) => (
          <FontAwesomeIcon
            icon={faStar}
            className={`text-gray-300 mr-1 ${rateable ? 'cursor-pointer hover:text-yellow-500' : ''}`}
            onClick={() => handleRatingClick(i + rating + 1)}
            key={i + rating}
          />
        ))}
    </div>
  );
};

export default StarRating;
