import { faBox, faBoxOpen, faMagnifyingGlass, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { StyledDialog, Tooltip } from '.';
import { getErrorMessage, toTitleCase } from '../../common/utils';
import { getPrompts } from '../../services/Prompts';
import { getVersions } from '../../services/PromptVersions';
import { Prompt, PromptVersion } from '../../types';
import { PromptRoles, PromptTypes, PromptVersionTypes } from '../../types/Prompt';
import Selector, { GroupedSelector, SelectorValue } from './Selector';
import { isAdmin as isUserAdmin } from '../../services/User';

/**
 * Represents the selected prompt, and version.
 */
export interface PV {
  prompt?: Prompt;
  version?: PromptVersion;
  adminShowAllPrompts?: boolean;
}

/**
 * Props for the PromptVersionSelector component.
 */
interface Props {
  className?: string;
  defaultLabels?: string[];
  selected?: PV;
  showPrompts?: boolean;
  showVersions?: boolean;
  disabled?: boolean;
  showArchivedButton?: boolean;
  includeArchivedDefault?: boolean;
  showPreviewButton?: boolean;
  promptType?: PromptTypes;
  promptId?: string;
  versionNumber?: number;
  onChange: (ids: PV) => void;
  onPromptsLoad?: (prompts: Prompt[]) => void;
}

const _defaultLabels = ['All Prompts', 'All Versions'];

/**
 * Renders a selector component for selecting a prompt, and version.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {PV} props.selected - The selected prompt, and version.
 * @param {boolean} props.disabled - Indicates whether the selector is disabled.
 * @param {Function} props.onChange - The change event handler for the selector.
 * @param {Function} props.onPromptsLoad - The event handler for when prompts are loaded.
 * @param {string} props.className - The class name for the component.
 * @param {string[]} props.defaultLabels - The default labels for the selectors, must contain two values.
 * @param {boolean} props.showPrompts - Indicates whether to show the prompt selector. Defaults to true.
 * @param {boolean} props.showVersions - Indicates whether to show the version selector. Defaults to true.
 * @param {boolean} props.showArchivedButton - Indicates whether to show the archived selector. Defaults to true.
 * @param {boolean} props.includeArchivedDefault - Indicates whether to include archived prompts by default. Defaults to false.
 * @param {boolean} props.showPreviewButton - Indicates whether to show the preview button. Defaults to true.
 * @param {PromptTypes} props.promptType - The type of prompt. Defaults to PromptTypes.PROMPT.
 * @returns {JSX.Element} The rendered PromptVersionSelector component.
 * @throws {Error} The defaultLabels prop must contain two values.
 * @throws {Error} The promptId prop must be provided when showPrompts is false.
 */
const PromptVersionSelector: React.FC<Props> = ({
  selected,
  disabled,
  onChange,
  onPromptsLoad,
  className,
  defaultLabels = _defaultLabels,
  showPrompts = true,
  showVersions = true,
  showArchivedButton: showArchivedSelector = true,
  includeArchivedDefault = false,
  showPreviewButton = true,
  promptType = PromptTypes.PROMPT
}: Props) => {
  if (defaultLabels.length !== 2) {
    throw new Error('The defaultLabels prop must contain two values.');
  }

  if (!showPrompts && !selected) {
    throw new Error('The selected prop must be provided when showPrompts is false.');
  }

  const allPrompts = { value: 0, label: defaultLabels[0] };
  const allVersions = { value: 0, label: defaultLabels[1] };

  const [isPromptsLoading, setIsPromptsLoading] = useState<boolean>(true);
  const [promptSelectors, setPromptSelectors] = useState<GroupedSelector[]>([
    { label: 'Prompts', options: [allPrompts] }
  ]);
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt | undefined>(selected?.prompt);
  const [isVersionsLoading, setIsVersionsLoading] = useState<boolean>(true);
  const [versionSelectors, setVersionSelectors] = useState<SelectorValue[]>([allVersions]);
  const [versions, setVersions] = useState<PromptVersion[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<PromptVersion | undefined>(selected?.version);
  const [includeArchived, setIncludeArchived] = useState<boolean>(includeArchivedDefault);
  const [versionCache, setVersionCache] = useState<{ [key: string]: PromptVersion[] }>({});
  const [isShowPrompt, setIsShowPrompt] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isShowAllPrompts, setIsShowAllPrompts] = useState<boolean>(false);

  const resetVersionSelector = () => {
    setSelectedVersion(undefined);
    setVersionSelectors([allVersions]);
  };

  const handleOnPromptChange = (value: SelectorValue) => {
    const prompt = prompts.find((prompt) => prompt.id === value.value);
    setSelectedPrompt(prompt);
    onChange({ prompt, adminShowAllPrompts: isShowAllPrompts });
  };

  const handleOnVersionChange = (value: SelectorValue) => {
    const version = versions.find((version) => version.version === value.value);
    setSelectedVersion(version);
    onChange({ prompt: selectedPrompt, version, adminShowAllPrompts: isShowAllPrompts });
  };

  useEffect(() => {
    (async () => {
      try {
        setIsAdmin(isUserAdmin());
        setIsPromptsLoading(true);
        const prompts = await getPrompts(promptType);
        setPrompts(prompts);
        if (onPromptsLoad) onPromptsLoad(prompts);
      } catch (error) {
        return toast.error(getErrorMessage(error));
      } finally {
        setIsPromptsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setIsPromptsLoading(true);
    (async () => {
      try {
        const prompts = await getPrompts(promptType, isShowAllPrompts);
        setPrompts(prompts);
        if (onPromptsLoad) onPromptsLoad(prompts);
      } catch (error) {
        return toast.error(getErrorMessage(error));
      } finally {
        setIsPromptsLoading(false);
      }
    })();
  }, [isShowAllPrompts]);

  useEffect(() => {
    if (!prompts) return;

    const selectors = [
      {
        label: 'Active Prompts',
        options: [
          allPrompts,
          ...prompts
            .filter((p) => !p.archived && p.userRole !== PromptRoles.ADMIN)
            .map((prompt) => ({ value: prompt.id || 0, label: prompt.name }))
        ]
      }
    ];

    if (includeArchived) {
      selectors.push({
        label: 'Archived Prompts',
        options: prompts
          .filter((p) => p.archived && p.userRole !== PromptRoles.ADMIN)
          .map((prompt) => ({ value: prompt.id || 0, label: prompt.name }))
      });
    }

    if (isShowAllPrompts) {
      selectors.push({
        label: 'All User Prompts',
        options: prompts
          .filter((p) => p.userRole === PromptRoles.ADMIN)
          .map((prompt) => ({ value: prompt.id || 0, label: prompt.name }))
      });
    }

    setPromptSelectors(selectors);

    if (!includeArchived && selectedPrompt && selectedPrompt.archived) {
      setSelectedPrompt(prompts[0]);
      onChange({ prompt: prompts[0], adminShowAllPrompts: isShowAllPrompts });
    }
  }, [prompts, includeArchived]);

  useEffect(() => {
    (async () => {
      let versions = [];

      if (!selectedPrompt) {
        resetVersionSelector();
        return;
      } else if (selectedPrompt.id && selectedPrompt.id in versionCache) {
        versions = versionCache[selectedPrompt.id];
      } else {
        try {
          setIsVersionsLoading(true);
          versions = await getVersions(selectedPrompt.id!);
          setVersionCache({ ...versionCache, [selectedPrompt.id!]: versions });
        } catch (error) {
          return toast.error(getErrorMessage(error));
        } finally {
          setIsVersionsLoading(false);
        }
      }

      setVersions(versions);
      setVersionSelectors([
        allVersions,
        ...versions.map((version) => ({
          value: version.version || 0,
          label: version.environments.length
            ? `v${version.version} (${version.environments.map((v) => toTitleCase(v)).join(', ')})`
            : `v${version.version}`
        }))
      ]);
    })();
  }, [selectedPrompt]);

  useEffect(() => {
    if (selected) {
      setSelectedPrompt(selected.prompt);
    }
  }, [selected]);

  return (
    <div className={`flex ${className || ''}`}>
      {showPrompts && (
        <Selector
          onChange={handleOnPromptChange}
          groupValues={promptSelectors}
          classNames="w-48 mr-4"
          disabled={isPromptsLoading || disabled}
          isSearchable={true}
          loading={isPromptsLoading}
          defaultValue={promptSelectors.flatMap((group) => group.options).find((v) => v.value === selectedPrompt?.id)} //.find((v) => v.value === selectedPrompt?.id)}
        />
      )}
      {showVersions && (
        <Selector
          onChange={handleOnVersionChange}
          values={versionSelectors}
          classNames="w-48 mr-4"
          disabled={isVersionsLoading || disabled}
          isSearchable={true}
          loading={selectedPrompt && isVersionsLoading}
          defaultValue={versionSelectors.find((v) => v.value === selectedVersion?.version)}
        />
      )}
      {showArchivedSelector && (
        <div>
          <Tooltip
            className="inline-flex"
            innerClassName="w-40 text-center"
            message={includeArchived ? 'Hide Archived Prompts' : 'Show Archived Prompts'}>
            <FontAwesomeIcon
              icon={includeArchived ? faBoxOpen : faBox}
              className={`mt-2 mr-4 w-5 h-5 inline cursor-pointer hover:text-indigo-700 duration-200 ${includeArchived ? 'text-indigo-500' : 'text-gray-500'}`}
              onClick={() => setIncludeArchived(!includeArchived)}
            />
          </Tooltip>
        </div>
      )}
      {isAdmin && (
        <div>
          <Tooltip
            className="inline-flex"
            innerClassName="w-40 text-center"
            message={includeArchived ? 'Hide All User Prompts' : 'Show All User Prompts'}>
            <FontAwesomeIcon
              icon={faUserSecret}
              className={`mt-2 mr-4 w-5 h-5 inline cursor-pointer hover:text-indigo-700 duration-200 ${isShowAllPrompts ? 'text-indigo-500' : 'text-gray-500'}`}
              onClick={() => setIsShowAllPrompts(!isShowAllPrompts)}
            />
          </Tooltip>
        </div>
      )}
      {showPreviewButton && selectedVersion && (
        <Tooltip className="inline-flex" innerClassName="w-32 text-center" message="Preview Template">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="mt-2 w-5 h-5 inline cursor-pointer hover:text-indigo-700 duration-200 text-gray-500"
            onClick={() => setIsShowPrompt(true)}
          />
        </Tooltip>
      )}

      <StyledDialog
        title="Template Preview"
        isOpen={isShowPrompt}
        icon={faMagnifyingGlass}
        width="w-3/4"
        onClose={() => setIsShowPrompt(false)}>
        <div className="!text-sm">
          <SyntaxHighlighter
            language="hbs"
            style={docco}
            showLineNumbers
            wrapLines
            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}>
            {selectedVersion?.type === PromptVersionTypes.MESSAGING
              ? JSON.stringify(selectedVersion?.messageTemplate, null, 2)
              : selectedVersion?.template || 'No template found.'}
          </SyntaxHighlighter>
        </div>
      </StyledDialog>
    </div>
  );
};

export default PromptVersionSelector;
