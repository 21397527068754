import { BaseApi } from '.';

/**
 * Enum representing the different roles that can be assigned to a webhook.
 *
 * @enum {string}
 * @property {string} OWNER - Full control over the webhook.
 * @property {string} READ_WRITE - Permission to read and modify the webhook.
 * @property {string} READ_ONLY - Permission to only read the webhook.
 * @property {string} ADMIN - Administrative control over the webhook.
 */
export enum WebhookRoles {
  OWNER = 'OWNER',
  READ_WRITE = 'READ_WRITE',
  READ_ONLY = 'READ_ONLY',
  ADMIN = 'ADMIN'
}

/**
 * Enum representing the different authentication methods available for webhooks.
 *
 * @enum {string}
 * @property {string} NONE - No authentication required.
 * @property {string} BEARER - Bearer token authentication.
 * @property {string} URL - URL-based authentication.
 */
export enum WebhookAuthMethods {
  NONE = 'NONE',
  BEARER = 'BEARER',
  HEADER = 'HEADER',
  URL = 'URL'
}

/**
 * Enum representing the HTTP verbs that can be used for webhooks.
 *
 * @enum {string}
 * @property {string} GET - Represents the HTTP GET method.
 * @property {string} POST - Represents the HTTP POST method.
 */
export enum WebhookVerbs {
  GET = 'GET',
  POST = 'POST'
}

/**
 * Represents a user associated with a webhook.
 *
 * @interface WebHookUser
 *
 * @property {string} id - The unique identifier of the user.
 * @property {string} name - The name of the user.
 * @property {string} email - The email address of the user.
 * @property {WebhookRoles} promptRole - The role of the user in the context of the webhook.
 */
export interface WebHookUser {
  id: string;
  name: string;
  email: string;
  promptRole: WebhookRoles;
}

/**
 * Represents a webhook configuration.
 *
 * @extends BaseApi
 *
 * @property {string} name - The name of the webhook.
 * @property {string} description - A brief description of the webhook.
 * @property {string} url - The URL to which the webhook will send requests.
 * @property {WebhookVerbs} httpVerb - The HTTP verb used by the webhook (e.g., GET, POST).
 * @property {WebhookAuthMethods} authMethod - The authentication method used by the webhook.
 * @property {string} authToken - The authentication token used for the webhook.
 * @property {string} authParam - Additional URL parameters for the webhook.
 * @property {WebHookUser[]} users - The users associated with the webhook.
 */
export interface Webhook extends BaseApi {
  name: string;
  description: string;
  url: string;
  httpVerb: WebhookVerbs;
  authMethod: WebhookAuthMethods;
  authToken: string;
  authParam: string;
  users: WebHookUser[];
}
