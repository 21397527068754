import { formatDistanceStrict } from 'date-fns';
import { Pipeline } from '../../types';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faBoxArchive, faFlask } from '@fortawesome/free-solid-svg-icons';

/**
 * Props for the PipelineTile component.
 */
interface Props {
  pipeline?: Pipeline;
  onClick?: (pipeline: Pipeline) => void;
  onManageClick?: (pipeline: Pipeline) => void;
}

/**
 * Renders a pipeline tile component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Pipeline} props.pipeline - The pipeline object to display.
 * @param {Function} props.onClick - The click event handler for the tile.
 * @returns {JSX.Element} The rendered PipelineTile component.
 */
const PipelineTile: React.FC<Props> = ({ pipeline, onClick }: Props) => {
  return (
    <li
      className="group colspan-1 rounded-lg border-gray-300 border p-3 shadow-lg hover:border-indigo-600 hover:cursor-pointer col-span-1"
      onClick={() => onClick && onClick(pipeline!)}>
      <div className="flex items-center w-full">
        <h3 className="text-gray-800 font-semibold">
          <FontAwesomeIcon icon={faFlask} className="inline w-4 h-4 text-indigo-500  mr-2" />
        </h3>
        <span className="overflow-hidden whitespace-nowrap text-ellipsis">
          {pipeline ? pipeline.name : <Skeleton containerClassName="inline" />}
        </span>
      </div>
      <h4 className="text-sm text-gray-700 mt-2 w-full word-break-all line-clamp-3">
        {!pipeline ? <Skeleton count={1} /> : <div>{pipeline.prompt.name}</div>}
      </h4>
      <h3 className="text-sm text-gray-500 mt-2 h-16 w-full word-break-all line-clamp-3">
        {!pipeline ? <Skeleton count={1} /> : <div>{pipeline.description}</div>}
      </h3>
      <div className="flex justify-between mt-4 align-middle items-end">
        <div className="text-xs text-gray-500 pt-3 flex justify-end">
          {pipeline ? (
            <div className="text-right">
              <div>
                <FontAwesomeIcon
                  icon={pipeline.archived ? faBoxArchive : faClock}
                  className="mr-1 w-3 h-3 text-gray-500 inline"
                />
                <span>
                  {pipeline.archived ? 'Archived' : ''} {formatDistanceStrict(pipeline.updated, new Date())} ago
                </span>
              </div>
            </div>
          ) : (
            <span className="w-1/2">
              <Skeleton />
            </span>
          )}
        </div>
      </div>
    </li>
  );
};

export default PipelineTile;
