/**
 * Props for the Tooltip component.
 */
interface Props {
  message: React.ReactElement[] | React.ReactElement | string;
  children: React.ReactElement[] | React.ReactElement | string;
  className?: string;
  innerClassName?: string;
}

/**
 * A tooltip component that displays a message when hovered over.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.message - The message to display in the tooltip.
 * @param {React.ReactNode} props.children - The content to wrap with the tooltip.
 * @param {string} [props.className] - The class name to apply to the tooltip.
 * @param {string} [props.innerClassName] - The class name to apply to the inner tooltip content.
 * @returns {React.ReactNode} The tooltip component.
 */
const Tooltip: React.FC<Props> = ({ message, children, className, innerClassName }: Props) => {
  return (
    <div className={`group relative flex ${className ?? ''}`}>
      {children}
      <span
        className={`absolute top-6 left-2 scale-0 transition-all rounded-lg shadow-lg ring-1 ring-black/5 p-2 text-xs bg-white text-black group-hover:scale-100 ${innerClassName ?? ''}`}>
        {message}
      </span>
    </div>
  );
};

export default Tooltip;
