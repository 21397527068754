import { useEffect, useState } from 'react';
import { GroupBox, ModelParameter, Selector } from '..';
import { SelectorValue } from '../common/Selector';
import { ModelParameterValue, Model } from '../../types';
import Skeleton from 'react-loading-skeleton';
import { PromptVersionTypes } from '../../types/Prompt';
import { translateModelParameters } from '../../common/models';
import toast from 'react-hot-toast';

/**
 * Props for the ModelParameterGroup component.
 */
interface Props {
  disabled: boolean;
  models: Model[];
  model: Model | undefined;
  parameters: ModelParameterValue[];
  type: PromptVersionTypes | undefined;
  // onParameterChange: (parameter: ModelParameterValue) => void;
  onChange: (model: Model, parameters: ModelParameterValue[]) => void;
}

/**
 * Renders a group of model parameters.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.disabled - Indicates whether the model parameters are disabled.
 * @param {Model[]} props.models - The available models.
 * @param {Model} props.model - The selected model.
 * @param {ModelParameterValue[]} props.parameters - The model parameters.
 * @param {Function} props.onParameterChange - The callback function to handle parameter changes.
 * @param {Function} props.onModelChange - The callback function to handle model changes.
 * @returns {JSX.Element} The rendered ModelParameterGroup component.
 */
const ModelParameterGroup: React.FC<Props> = ({
  models,
  model,
  parameters,
  disabled,
  type,
  // onParameterChange,
  onChange
}: Props) => {
  const [modelSelectors, setModelSelectors] = useState<SelectorValue[]>([]);
  const [selectedModel, setSelectedModel] = useState<Model>();
  const [selectedModelSelector, setSelectedModelSelector] = useState<SelectorValue>();
  const [parameterValues, setParameterValues] = useState<ModelParameterValue[]>();
  const [_type, setType] = useState<PromptVersionTypes>();

  useEffect(() => {
    if (!models || !model) return;

    const modelSelectors = [
      ...models.map((model) => ({
        value: model.id,
        label: model.name
      }))
    ];

    setModelSelectors(modelSelectors);
    setSelectedModelSelector(modelSelectors.find((selector) => selector.value === model.id) || modelSelectors[0]);
    setSelectedModel(model);

    // only reset parameters if the model has changed
    if (selectedModel?.mid !== model.mid) {
      setParameterValues(parameters || []);
    }

    if (type && type !== _type) {
      setType(type);

      if (models && model && parameterValues) {
        const newParameters = translateModelParameters(models, model.mid, model.mid, parameterValues, type, _type);
        // console.log(`type set to ${type}, new params: ${newParameters.map((p) => p.name).join(', ')}`);
        setParameterValues(newParameters);
        onChange(model, newParameters);
      }
    }

    if (type && type !== PromptVersionTypes.LEGACY) {
      const filteredModels = models
        .filter((model) => {
          if (type === PromptVersionTypes.MESSAGING && !model.messages) {
            return false;
          } else if (type === PromptVersionTypes.COMPLETION && !model.completions) {
            return false;
          }
          return true;
        })
        .map(({ id, name }) => ({
          value: id,
          label: name
        }));

      const isModelUnavailable = !filteredModels.find((model) => model.value === selectedModel?.id);
      if (isModelUnavailable && parameterValues) {
        const newModel = models.find((model) => model.id === filteredModels[0].value)!;
        const newParameters = translateModelParameters(models, model.mid, newModel.mid, parameterValues, type, _type);

        toast.error(
          `The current model is not available for ${type.toLowerCase()} prompts.\n\nSwitching to ${newModel.name}.`
        );
        setSelectedModel(newModel);
        setSelectedModelSelector(filteredModels[0]);
        setParameterValues(newParameters);
        onChange(newModel, newParameters);
      }

      setModelSelectors(filteredModels);
    }
  }, [models, model, type]);

  const handleParameterChange = (parameter: ModelParameterValue) => {
    const updatedParams = parameterValues!.map((p) => (p.name === parameter.name ? parameter : p));
    if (!updatedParams.find((p) => p.name === parameter.name)) {
      updatedParams.push(parameter);
    }

    setParameterValues(updatedParams);
    onChange(selectedModel!, updatedParams);
  };

  const handleModelChange = (value: SelectorValue) => {
    const newModel = models?.find((model) => model.id === value.value);
    const newParameters = translateModelParameters(models!, model!.mid, newModel!.mid, parameterValues!, _type!);

    setSelectedModel(newModel);
    setParameterValues(newParameters);
    onChange(newModel!, newParameters);
  };

  return (
    <GroupBox title="Model Settings" className="mb-4" collapsable={true}>
      {!selectedModel || !parameterValues || !models ? (
        <div className="p-2">
          <Skeleton count={5} />
        </div>
      ) : (
        <>
          <Selector
            values={modelSelectors}
            defaultValue={selectedModelSelector}
            classNames="my-2 mx-4"
            disabled={disabled}
            onChange={handleModelChange}
          />
          {!selectedModelSelector && (
            <div className="text-center text-gray-500 px-1 py-2">Select a model to view parameters</div>
          )}
          {selectedModel &&
            parameterValues &&
            Object.entries(
              _type === PromptVersionTypes.MESSAGING
                ? selectedModel.parametersMessages
                : selectedModel.parametersCompletion
            ).map(([i, parameter]) => (
              <ModelParameter
                key={i}
                property={parameter}
                value={parameterValues?.find((p) => p.name === parameter.parameterModel)?.value ?? parameter.default}
                disabled={disabled}
                onPropertyChange={handleParameterChange}
              />
            ))}
        </>
      )}
    </GroupBox>
  );
};

export default ModelParameterGroup;
