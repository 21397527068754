import { useState } from 'react';
import StyledDialog from '../common/StyledDialog';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';

interface Props {}

const PipelineHelpRunData: React.FC<Props> = ({}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <button className="standard secondary ml-2" onClick={() => setIsOpen(true)}>
        <FontAwesomeIcon icon={faQuestionCircle} className="mr-1" />
        Help
      </button>
      <StyledDialog isOpen={isOpen} title="Run Data" onClose={() => setIsOpen(false)} width="w-3/5" icon={faPlusCircle}>
        <div>
          <p>
            Runs allow you to test your prompt templates using a supplied dataset. The dataset can be a JSON or CSV
            file, where each row contains the data used in the prompt template. I&apos;s important that the keys/column
            headers match the variables that the template is expecting.
          </p>
          <p className="mt-2">
            After uploading your file, the first entry will be used to generate a preview of the compiled template. You
            can also reuse a dataset from a previous run or use the pipeline&apos;s default dataset.
          </p>
          <div className="mt-4">
            For example, given the following prompt template:
            <blockquote className="mt-1 px-4 my-2 border-s-4 border-gray-300 bg-gray-50 text-sm p-1">
              Tell me a <code className="text-indigo-600">&#123;&#123;length&#125;&#125;</code>{' '}
              <code className="text-indigo-600">&#123;&#123;type&#125;&#125;</code> joke.
            </blockquote>
          </div>

          <div className="mt-4">
            The dataset would be:
            <div className="grid grid-cols-2 mt-2">
              <div>
                <div className="font-semibold">JSON</div>
                <blockquote className="mt-1 px-4 my-2 border-s-4 border-gray-300 bg-gray-50 text-sm p-1 whitespace-pre">
                  <SyntaxHighlighter
                    customStyle={{ marginTop: '0.25em', backgroundColor: 'rgb(249 250 251)' }}
                    language="json"
                    style={docco}
                    lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap', fontSize: '0.7rem' } }}
                    wrapLines>
                    {JSON.stringify(
                      [
                        { length: 'short', type: 'silly' },
                        { length: 'short', type: 'dad' }
                      ],
                      null,
                      2
                    )}
                  </SyntaxHighlighter>
                </blockquote>
              </div>
              <div>
                <div className="font-semibold">CSV</div>
                <blockquote className="mt-1 px-4 my-2 border-s-4 border-gray-300 bg-gray-50 text-sm p-1 whitespace-pre">
                  <SyntaxHighlighter
                    customStyle={{ marginTop: '0.25em', backgroundColor: 'rgb(249 250 251)' }}
                    language="csv"
                    style={docco}
                    lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap', fontSize: '0.7rem' } }}
                    wrapLines>
                    {['length, type', 'short, silly', 'short, dad'].join('\n')}
                  </SyntaxHighlighter>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="mt-4">
            In this case, the template would be compiled using the provided values for &lsquo;length&lsquo; and
            &lsquo;type&lsquo;, resulting in the prompt{' '}
            <span className="text-indigo-600 font-semibold">tell me a short funny joke</span> being generated.
          </div>
        </div>
      </StyledDialog>
    </>
  );
};

export default PipelineHelpRunData;
