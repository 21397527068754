import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { getErrorMessage } from '../../common/utils';
import { CHART_COLORS, DEFAULT_DF_FORMAT } from '../../constants';
import { getPipeline, getPipelineScores } from '../../services/PipelineEvaluations';
import { Pipeline as IPipeline, PipelineScores } from '../../types';

interface Props {
  pipelineId: string;
}

const chartSkeletons = 11;
const labelSizeSmall = { fontSize: '0.85em' };
const barRadius: [number, number, number, number] = [5, 5, 0, 0];
const chartContainerHeight = 300;

const PipelineMetrics: React.FC<Props> = ({ pipelineId }: Props) => {
  const [isLoadingScores, setIsLoadingScores] = useState(true);
  const [pipeline, setPipeline] = useState<IPipeline>();
  const [pipelineScores, setPipelineScores] = useState<PipelineScores>();
  const [evaluatorScores, setEvaluatorScores] = useState<{ [key: string]: any }[]>();
  const [runRecords, setRunRecords] = useState<{ [key: string]: any }[]>();
  const [allEvaluators, setAllEvaluators] = useState<string[]>();
  const [rolledUpScores, setRolledUpScores] = useState<{ [key: string]: any }[]>();
  const [scoresByDate, setScoresByDate] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const [_pipeline, _scores] = await Promise.all([getPipeline(pipelineId), getPipelineScores(pipelineId)]);
        setPipeline(_pipeline);
        setPipelineScores(_scores);
      } catch (error) {
        return toast.error(getErrorMessage(error));
      } finally {
        setIsLoadingScores(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!pipeline || !pipelineScores) return;
    console.clear();
    (async () => {
      const metrics: { [key: string]: any } = {};
      const finalRunRecords: { [key: string]: any }[] = [];
      const groupedEvalRecords: { [key: string]: any }[] = [];
      const _allEvaluators: string[] = [];
      const _scoresByDate: Record<string, Record<string, any>> = {};

      Object.entries(pipelineScores.pipelineScores).forEach(([key, value]) => {
        finalRunRecords.push({ name: value[0].pipelineRunName, runRecords: value[0].totalRuns });
        const group: { [key: string]: any } = { name: value[0].pipelineRunName };

        Object.values(value).forEach((value2) => {
          if (!metrics[value2.evaluatorName]) {
            metrics[value2.evaluatorName] = [];
          }

          group[value2.evaluatorName] = value2.score * 10;
          metrics[value2.evaluatorName].push(value2.score);
          if (!_allEvaluators?.includes(value2.evaluatorName)) {
            _allEvaluators.push(value2.evaluatorName);
          }
        });
        groupedEvalRecords.push(group);

        if (value.length) {
          const key = value[0].created.toUTCString();

          _scoresByDate[key] = value.reduce(
            (acc: Record<string, any>, val) => ({
              ...acc,
              [val.evaluatorName]: val.score,
              date: val.created,
              runName: val.pipelineRunName
            }),
            {}
          );
        }
      });

      const finalEvaluatorMetrics: { [key: string]: any }[] = [];
      Object.entries(metrics).forEach(([key, value]) => {
        const sum = value.reduce((a: number, b: number) => a + b * 10, 0);
        const avg = sum / value.length;
        finalEvaluatorMetrics.push({ evaluatorName: key, score: avg });
      });

      // plug in missing evaluator scores
      const finalScoresByDate = Object.values(_scoresByDate).map((val) => {
        for (const evaluator of _allEvaluators) {
          if (!val[evaluator]) {
            val[evaluator] = 0;
          }
        }
        return val;
      });

      setScoresByDate(finalScoresByDate);
      setAllEvaluators(_allEvaluators);
      setRolledUpScores(groupedEvalRecords);
      setRunRecords(finalRunRecords);
      setEvaluatorScores(finalEvaluatorMetrics);
    })();
  }, [pipelineScores]);

  return (
    <div className="mx-auto">
      <div className="flex w-full">
        <h1 className="w-3/4 text-2xl text-gray-800">{pipeline?.name ?? <Skeleton />}</h1>
      </div>
      <div className="mt-4 grid xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 justify-start gap-x-16 gap-y-8">
        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Pipeline Evaluator Scores</h2>
          <div className="text-xs text-gray-500 mb-4">
            Represents the average score for each evaluator across all runs.
          </div>
          {isLoadingScores ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={evaluatorScores} margin={{ left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="evaluatorName" />
                <YAxis tick={labelSizeSmall} tickFormatter={(v) => `${v}%`} />
                <Tooltip formatter={(value) => `${value.toLocaleString()}%`} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="score" fill={CHART_COLORS[0]} name="Average Score" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Run Evaluator Scores</h2>
          <div className="text-xs text-gray-500 mb-4">
            Represents the average evaluator scores for each run in the pipeline.
          </div>
          {isLoadingScores ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={rolledUpScores} margin={{ left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="name" />
                <YAxis tick={labelSizeSmall} tickFormatter={(v) => `${v.toLocaleString()}%`} />
                <Tooltip formatter={(value) => `${value.toLocaleString()}%`} />
                <Legend wrapperStyle={labelSizeSmall} />
                {allEvaluators?.map((evaluator, index) => (
                  <Bar dataKey={evaluator} fill={CHART_COLORS[index]} name={evaluator} radius={barRadius} key={index} />
                ))}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Average Score by Evaluator</h2>
          <div className="text-xs text-gray-500 mb-4">Represents the average score for each evaluator by run.</div>
          {isLoadingScores ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <LineChart data={scoresByDate} margin={{ left: 10 }} syncId="p">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  tick={labelSizeSmall}
                  dataKey="date"
                  tickFormatter={(tick) => format(tick, DEFAULT_DF_FORMAT)}
                  dy={5}
                />
                <YAxis tick={labelSizeSmall} tickFormatter={(tick) => tick.toLocaleString()} dy={-5} />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend wrapperStyle={labelSizeSmall} />
                {allEvaluators?.map((evaluator, index) => (
                  <Line type="monotone" dataKey={evaluator} stroke={CHART_COLORS[index]} name={evaluator} key={index} />
                ))}
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Dataset Records</h2>
          <div className="text-xs text-gray-500 mb-4">How many records were used in each run&#39;s dataset.</div>
          {isLoadingScores ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={runRecords} margin={{ left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="name" />
                <YAxis tick={labelSizeSmall} />
                <Tooltip />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="runRecords" fill={CHART_COLORS[0]} name="Total" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default PipelineMetrics;
