import { useState } from 'react';
import { Pipeline } from '../../types';
import { getErrorMessage } from '../../common/utils';
import StyledDialog from '../common/StyledDialog';
import { faSpinner, faTable } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { UploadDropzone } from '../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDefaultData } from '../../services/PipelineEvaluations';
import { PipelineMetadata } from '../../types/PipelineEvaluations';
import { PipelineHelpRunData } from '.';

interface Props {
  pipeline: Pipeline;
  isOpen: boolean;
  onClose: () => void;
  onSave: (pipeline: PipelineMetadata) => void;
}

const uploadInstructions = (
  <p className="mb-2 text-sm text-gray-500 ">
    Click to upload your <span className="font-semibold">data file</span> or drag and drop.
  </p>
);

const AddDefaultDataModal: React.FC<Props> = ({ pipeline, isOpen, onClose, onSave }: Props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const reset = () => {
    setFiles([]);
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const handleOnCreate = async () => {
    if (files.length !== 1) {
      return toast.error('Please upload a default data file.');
    }

    setIsSaving(true);

    try {
      onSave(await addDefaultData(pipeline.id, files[0]));
      toast.success('Default Data Added');
      reset();
    } catch (error) {
      const err = getErrorMessage(error);
      console.error(error);
      toast.error(err);
      return;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title="Default Data"
      closeText="Cancel"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : (
          'Save'
        )
      }
      disabled={isSaving}
      onConfirm={handleOnCreate}
      onClose={handleOnClose}
      width="w-1/2"
      icon={faTable}>
      <div>
        <div className="mt-2 flex">
          <div className="flex-1">
            Default data is used for run automated tests of your prompt. This is utilized for testing your prompt after
            saving a new version.
          </div>
          <div className="text-right">
            <PipelineHelpRunData />
          </div>
        </div>
        <UploadDropzone
          disabled={isSaving}
          accept={[
            { name: 'JSON', mimetype: 'application/json' },
            { name: 'CSV', mimetype: 'text/csv' }
          ]}
          instructions={uploadInstructions}
          className="mt-4"
          onFilesChange={setFiles}
        />
      </div>
    </StyledDialog>
  );
};

export default AddDefaultDataModal;
