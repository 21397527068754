import CodeEditor from '@uiw/react-textarea-code-editor';
import { faTableCells, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Prompt } from '../../types/Prompt';
import { StyledDialog } from '../common';

interface Props {
  prompt: Prompt | undefined;
  payload: Record<string, any>;
  isOpen: boolean;
  readOnly?: boolean;
  onSave: (payload: Record<string, any>) => void;
  onClose: () => void;
}

/**
 * SamplePromptPayload component renders a dialog for editing and saving JSON payloads.
 * It includes a code editor for JSON input and handles validation of the input data.
 *
 * @component
 * @param {Props} props - The properties object.
 * @param {object} props.prompt - The prompt object to be displayed.
 * @param {object} props.payload - The initial payload data to be edited.
 * @param {boolean} [props.readOnly=true] - If true, the code editor is read-only.
 * @param {boolean} [props.isOpen=false] - If true, the dialog is open.
 * @param {function} props.onSave - Callback function to handle saving the payload.
 * @param {function} props.onClose - Callback function to handle closing the dialog.
 *
 * @returns {JSX.Element} The rendered SamplePromptPayload component.
 */
const SamplePromptPayload: React.FC<Props> = ({
  prompt,
  payload,
  readOnly = true,
  isOpen = false,
  onSave,
  onClose
}: Props) => {
  const [_payload, setPayload] = useState<string>('');
  const [isShowInvalidDataModal, setIsShowInvalidDataModal] = useState(false);

  useEffect(() => {
    setPayload(JSON.stringify(payload, null, 2));
  }, [payload]);

  const handleOnConfirm = async () => {
    let data;
    try {
      data = JSON.parse(_payload);
    } catch (e) {
      setIsShowInvalidDataModal(true);
      return;
    }

    onSave(data);
  };

  return (
    <>
      <StyledDialog
        key="samplePayloadEditor"
        title="Sample Data"
        confirmText="Update"
        icon={faTableCells}
        onClose={() => !isShowInvalidDataModal && onClose()}
        onConfirm={handleOnConfirm}
        isOpen={isOpen}
        width="w-3/4">
        {prompt && (
          <div>
            <div className="my-2">
              <CodeEditor
                value={_payload}
                language="json"
                placeholder="{}"
                onChange={(e) => setPayload(e.currentTarget.value)}
                padding={10}
                disabled={readOnly}
                className="w-full overflow-auto resize-none border-none p-3 focus:ring-0 .performance-editor"
                style={{
                  backgroundColor: '#fff'
                }}
              />
            </div>
          </div>
        )}
      </StyledDialog>
      <div>
        <StyledDialog
          key="samplePayloadWarning"
          isOpen={isShowInvalidDataModal}
          title="Invalid Payload"
          closeText="Cancel"
          confirmText="Save Anyway"
          confirmClassName="bg-red-500 hover:!bg-red-400"
          icon={faTriangleExclamation}
          onClose={() => setIsShowInvalidDataModal(false)}
          onConfirm={() => onSave(JSON.parse(_payload))}>
          <div>Your payload is invalid. Are you sure you want to save it anyway?</div>
        </StyledDialog>
      </div>
    </>
  );
};

export default SamplePromptPayload;
