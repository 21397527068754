import { useDropzone } from 'react-dropzone';
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faFile } from '@fortawesome/free-solid-svg-icons';

/**
 * Props for the UploadDropZone component.
 */
interface Props {
  accept: AcceptFile[];
  disabled?: boolean;
  maxFiles?: number;
  instructions?: React.ReactElement[] | React.ReactElement | string;
  className?: string;
  onFilesChange: (files: File[]) => void;
}

export interface AcceptFile {
  name: string;
  mimetype: string;
  extensions?: string[];
}

const defaultInstructions = (
  <p className="mb-2 text-sm text-gray-500 ">
    <span className="font-semibold">Click to upload</span> or drag and drop
  </p>
);

/**
 * A component that provides a dropzone for uploading files.
 *
 * @component
 * @param {object} props - The component props.
 * @param {AcceptFile[]} props.accept - The accepted file types.
 * @param {boolean} [props.disabled=false] - Whether the dropzone is disabled.
 * @param {number} [props.maxFiles=1] - The maximum number of files allowed to be uploaded.
 * @param {React.ReactElement[] | React.ReactElement | string} [props.instructions] - The instructions to display in the dropzone.
 * @param {string} [props.className] - The class name to apply to the dropzone.
 * @param {Function} props.onFilesChange - A callback function triggered when files are changed.
 * @returns {JSX.Element} The rendered UploadDropzone component.
 */
const UploadDropzone: React.FC<Props> = ({
  accept,
  disabled = false,
  maxFiles = 1,
  instructions = defaultInstructions,
  className = '',
  onFilesChange,
  ...args
}: Props) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled,
    accept: accept.reduce(
      (acc, { mimetype, extensions }) => {
        acc[mimetype] = extensions || [];
        return acc;
      },
      {} as Record<string, string[]>
    ),
    maxFiles,
    ...args
  });

  useEffect(() => {
    onFilesChange((acceptedFiles || []).map((f) => f as File));
  }, [acceptedFiles]);

  return (
    <>
      <div {...getRootProps({ className: `dropzone disabled flex items-center justify-center w-full ${className}` })}>
        <label
          htmlFor="dropzone-file"
          className="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 ">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <input {...getInputProps()} />
            <FontAwesomeIcon icon={faCloudArrowUp} className="w-16 h-16 text-gray-400 " />
            {instructions}
            <p className="text-xs text-gray-500 mt-2">Supported formats: {accept.map((a) => a.name).join(', ')}</p>
          </div>
        </label>
      </div>
      <ul className="space-y-1 text-gray-600 text-xs mt-4">
        {acceptedFiles.map((file, i) => (
          <li key={i}>
            <FontAwesomeIcon icon={faFile} className="w-4 h-4 mr-2" />
            {file.name}
          </li>
        ))}
      </ul>
    </>
  );
};

export default UploadDropzone;
