import { Tool } from '../../../types/Prompt';

interface Props {
  tools: Tool[];
}

const ToolsTab: React.FC<Props> = ({ tools }: Props) => {
  return (
    <div className="h-full overflow-auto pr-1">
      {tools?.length > 0 ? (
        <div className="grid grid-cols-2 text-sm">
          <div className="text-gray-800 font-semibold border-gray-200 border-b pb-1">Name</div>
          <div className="text-gray-800 font-semibold border-gray-200 border-b pb-1">Inputs</div>
          {tools?.map((t, i) => (
            <div className="grid grid-cols-2 col-span-2" key={i}>
              <div className="mr-1 text-gray-700 mt-2">{t.name}</div>
              <div className="text-gray-700 mt-2">{Object.keys(t.inputSchema.properties).join(', ')}</div>
            </div>
          ))}
        </div>
      ) : (
        <div>No tools available</div>
      )}
    </div>
  );
};

export default ToolsTab;
