import { useState } from 'react';
import { AnalyticsTab, CompareTab, TabGroup } from '../../components';

interface Props {}

/**
 * Prompt Analytics page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const PromptAnalytics: React.FC<Props> = ({}: Props) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <TabGroup
      tabNames={['Prompt Usage', 'Prompt Compare']}
      selectedTabIndex={selectedTab}
      onTabChange={setSelectedTab}
      helpLink={selectedTab === 0 ? '/docs/#/analytics_usage' : 'docs/#/analytics_compare'}>
      <div className="mx-auto">
        {selectedTab === 0 && <AnalyticsTab />}
        {selectedTab === 1 && <CompareTab />}
      </div>
    </TabGroup>
  );
};

export default PromptAnalytics;
