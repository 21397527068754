import { useState } from 'react';
import { Pipeline, PipelineEvaluator } from '../../types';
import { getErrorMessage } from '../../common/utils';
import StyledDialog from '../common/StyledDialog';
import { faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PromptVersionSelector } from '../common';
import { PV } from '../common/PromptVersionSelector';
import { addPromptEvaluator, addWebhookEvaluator } from '../../services/PipelineEvaluations';
import { PromptTypes } from '../../types/Prompt';
import Selector, { SelectorValue } from '../common/Selector';
import { EvaluatorType } from '../../types/PipelineEvaluations';
import { Webhook } from '../../types/Webhooks';
import { getWebhooks } from '../../services/Webhooks';

interface Props {
  pipeline: Pipeline;
  isOpen: boolean;
  onClose: () => void;
  onSave: (pipeline: PipelineEvaluator) => void;
}

const pvLabels = ['Select Prompt', 'Select Version'];
const typeSelectorValues: SelectorValue[] = [
  { value: EvaluatorType.PROMPT, label: 'Prompt' },
  { value: EvaluatorType.WEBHOOK, label: 'Webhook' }
];

const AddPipelineEvaluatorModal: React.FC<Props> = ({ pipeline, isOpen, onClose, onSave }: Props) => {
  const [selectedType, setSelectedType] = useState<SelectorValue>(typeSelectorValues[0]);

  const [name, setName] = useState<string>('');
  const [promptVersion, setPromptVersion] = useState<PV>();
  const [webhooks, setWebhooks] = useState<Webhook[]>([]);
  const [webhooksSelectors, setWebhooksSelectors] = useState<SelectorValue[]>([]);
  const [selectedWebhook, setSelectedWebhook] = useState<SelectorValue>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const reset = () => {
    setName('');
    setPromptVersion(undefined);
    setSelectedType(typeSelectorValues[0]);
    setSelectedWebhook(undefined);
  };

  const handleOnTypeChange = async (value: SelectorValue) => {
    setSelectedType(value);

    if (value.value === EvaluatorType.WEBHOOK && !webhooks.length) {
      setIsLoading(true);
      try {
        const hooks = await getWebhooks();
        setWebhooks(hooks);
        setWebhooksSelectors([
          { label: 'Select Webhook', value: '' },
          ...hooks.map((h) => ({ label: h.name, value: h.id! }))
        ]);
        setPromptVersion(undefined);
      } catch (error) {
        const err = getErrorMessage(error);
        console.error(err);
        return toast.error(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      setSelectedWebhook(undefined);
    }
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const handleOnCreate = async () => {
    if (name.length < 2) {
      toast.error('Please enter a valid name.');
      return;
    } else if (selectedType.value === EvaluatorType.PROMPT) {
      if (!promptVersion?.prompt || promptVersion?.prompt.name === pvLabels[0]) {
        toast.error('Please select a prompt.');
        return;
      } else if (!promptVersion?.version) {
        toast.error('Please select a version.');
        return;
      }
    } else if (
      selectedType.value === EvaluatorType.WEBHOOK &&
      (!selectedWebhook || !(selectedWebhook?.value as string).length)
    ) {
      toast.error('Please select a webhook.');
      return;
    }

    setIsSaving(true);
    let evaluator: PipelineEvaluator;

    try {
      if (selectedType.value === EvaluatorType.PROMPT) {
        evaluator = await addPromptEvaluator(
          pipeline.id,
          name,
          promptVersion!.prompt!.id!,
          promptVersion!.version!.version!
        );
      } else {
        evaluator = await addWebhookEvaluator(pipeline.id, name, selectedWebhook?.value as string);
      }
      toast.success('Evaluator Added');
      onSave(evaluator);
      reset();
    } catch (error) {
      const err = getErrorMessage(error);
      console.error(error);
      toast.error(err);
      return;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title="Add Evaluator"
      closeText="Cancel"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : (
          'Add'
        )
      }
      disabled={isSaving}
      onConfirm={handleOnCreate}
      onClose={handleOnClose}
      icon={faPlusCircle}>
      <div>
        <div className="mt-2">
          <input
            id="evaluationName"
            name="evaluationName"
            type="text"
            autoComplete="off"
            value={name}
            placeholder="Evaluator Name"
            onChange={(e) => setName(e.currentTarget.value)}
            required
            disabled={isSaving}
            className="block w-52 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-3">
          <label htmlFor="httpVerb" className="text-sm text-gray-500 block mt-3">
            Evaluator Type
          </label>
          <Selector
            classNames="w-52 !hover:z-0"
            id="httpVerb"
            values={typeSelectorValues}
            defaultValue={selectedType}
            isSearchable={false}
            onChange={handleOnTypeChange}
          />
        </div>
        <div className="mt-3">
          {selectedType.value === EvaluatorType.PROMPT ? (
            <PromptVersionSelector
              disabled={isSaving}
              showArchivedButton={false}
              onChange={setPromptVersion}
              defaultLabels={pvLabels}
              promptType={PromptTypes.EVALUATOR}
            />
          ) : (
            <Selector
              classNames="w-52 my-2"
              values={webhooksSelectors}
              defaultValue={selectedWebhook}
              disabled={isLoading || isSaving}
              isSearchable={false}
              onChange={(value) => setSelectedWebhook(value)}
            />
          )}
        </div>
      </div>
    </StyledDialog>
  );
};

export default AddPipelineEvaluatorModal;
