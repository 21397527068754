import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

/**
 * Represents a group box component.
 */
interface Props {
  title: string;
  className?: string;
  classNameContent?: string;
  collapsable?: boolean;
  children: React.ReactElement[] | React.ReactElement | string;
}

/**
 * Renders a group box component with a title and content.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the group box.
 * @param {string} props.className - Additional CSS class names for the group box container.
 * @param {string} props.classNameContent - Additional CSS class names for the group box content.
 * @param {boolean} props.collapsable - Indicates whether the group box is collapsable.
 * @param {React.ReactNode} props.children - The content of the group box.
 * @returns {JSX.Element} The rendered group box component.
 */
const GroupBox: React.FC<Props> = ({
  title,
  className: additionalClassNames,
  classNameContent: additionalClassNamesContent,
  collapsable = false,
  children
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className={`rounded-lg border-gray-300 border overflow-hidden ${additionalClassNames || ''}`}>
      <div
        className={`w-full px-3 py-1 h-7 text-sm bg-gray-200 text-gray-800 border-gray-300 flex justify-between items-center ${!isCollapsed ?? 'border-b'}`}>
        <div className="flex-1">{title}</div>
        {collapsable && (
          <div className="text-right -mr-1">
            <FontAwesomeIcon
              className="text-indigo-500 cursor-pointer"
              icon={isCollapsed ? faAnglesUp : faAnglesDown}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          </div>
        )}
      </div>
      {!isCollapsed && <div className={`text-xs text-gray-500 ${additionalClassNamesContent || ''}`}>{children}</div>}
    </div>
  );
};

export default GroupBox;
