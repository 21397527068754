import { StyledDialog } from '../common';
import { faPlus, faTools, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Tool, ToolProperty, ToolPropertyTypes } from '../../types/Prompt';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deepEqual } from '../../common/utils';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import toast from 'react-hot-toast';

interface Props {
  isOpen: boolean;
  existingToolNames?: string[];
  tool?: Tool;
  onClose: () => void;
  onSave: (tool: Tool, previousName: string) => void;
}

const emptyTool: Tool = {
  name: '',
  description: '',
  inputSchema: {
    type: 'object',
    properties: {
      '': {
        type: ToolPropertyTypes.STRING,
        description: '',
        enum: []
      }
    },
    required: []
  }
};

/**
 * ToolEditor component allows users to edit tool properties, including name, description, and input schema.
 * It provides functionalities to add, update, and remove tool properties, as well as handle unsaved changes.
 *
 * @component
 * @param {Props} props - The properties for the ToolEditor component.
 * @param {boolean} props.isOpen - Indicates if the editor dialog is open.
 * @param {Tool} props.tool - The tool object to be edited.
 * @param {string[]} [props.existingToolNames=[]] - List of existing tool names to check for duplicates.
 * @param {function} props.onClose - Callback function to handle closing the editor.
 * @param {function} props.onSave - Callback function to handle saving the tool.
 *
 * @returns {JSX.Element} The rendered ToolEditor component.
 *
 * @example
 * <ToolEditor
 *   isOpen={isEditorOpen}
 *   tool={selectedTool}
 *   existingToolNames={toolNames}
 *   onClose={handleEditorClose}
 *   onSave={handleToolSave}
 * />
 */
const ToolEditor: React.FC<Props> = ({ isOpen, tool, existingToolNames = [], onClose, onSave }: Props) => {
  const [_tool, setTool] = useState<Tool>({ ...emptyTool });
  const [toolOriginalName, setToolOriginalName] = useState<string>('');
  const [isShowChangesModal, setIsShowChangesModal] = useState(false);

  useEffect(() => {
    const newTool = tool || { ...emptyTool };
    setTool(newTool);
    setToolOriginalName(newTool.name);
  }, [tool]);

  const updateToolField = (field: keyof Tool, value: any) => setTool({ ..._tool, [field]: value });

  const updateToolProperty = (property: string, field: keyof ToolProperty, value: any) => {
    const newProperties = {
      ..._tool.inputSchema.properties,
      [property]: { ..._tool.inputSchema.properties[property], [field]: value }
    };

    updateToolField('inputSchema', { ..._tool.inputSchema, properties: newProperties });
  };

  const renameToolProperty = (oldProperty: string, newProperty: string) => {
    const newProperties = { ..._tool.inputSchema.properties, [newProperty]: _tool.inputSchema.properties[oldProperty] };
    delete newProperties[oldProperty];

    updateToolField('inputSchema', { ..._tool.inputSchema, properties: newProperties });
  };

  const addToolProperty = () => {
    const propertyName = 'new_property';

    if (Object.keys(_tool.inputSchema.properties).includes(propertyName)) {
      return toast.error(`Property "${propertyName}" already exists.`);
    }

    updateToolField('inputSchema', {
      ..._tool.inputSchema,
      properties: {
        ..._tool.inputSchema.properties,
        [propertyName]: { type: ToolPropertyTypes.STRING, description: '' }
      }
    });
  };

  const handlePropertyRemove = (property: string) => {
    const newProperties = { ..._tool.inputSchema.properties };
    delete newProperties[property];
    updateToolField('inputSchema', { ..._tool.inputSchema, properties: newProperties });
  };

  const handleOnRequiredChange = (property: string, required: boolean) => {
    const newRequired = required
      ? [..._tool.inputSchema.required, property]
      : _tool.inputSchema.required.filter((r) => r !== property);

    updateToolField('inputSchema', { ..._tool.inputSchema, required: [...new Set(newRequired)] });
  };

  const handleOnClose = () => {
    if (!deepEqual(_tool, tool || emptyTool)) {
      setIsShowChangesModal(true);
    } else {
      onClose();
      setTool({ ...emptyTool });
    }
  };

  const handleOnCloseConfirm = () => {
    setIsShowChangesModal(false);
    setTool({ ...emptyTool });
    onClose();
  };

  const handleOnSave = () => {
    if (!_tool.name || !_tool.description) {
      return toast.error('Tool name and description are required.');
    } else if (existingToolNames.includes(_tool.name) && _tool.name !== toolOriginalName) {
      return toast.error(`Tool "${_tool.name}" already exists.`);
    }

    const regex = /^[a-zA-Z0-9_]+$/;

    let propError;
    Object.entries(_tool.inputSchema.properties).forEach(([key, value], i) => {
      if (!key.length) {
        propError = `Property name is required for parameter ${i + 1}.`;
      } else if (!value.description.length) {
        propError = `Description is required for parameter "${key}".`;
      }

      if (!regex.test(key)) {
        propError = `Property "${key}" can only contain alphanumeric and underscore characters`;
      }
    });

    if (propError) {
      return toast.error(propError);
    }

    // name can only contain alpha, numberic and underscore characters. the regex should be its own varaible
    if (!regex.test(_tool.name)) {
      return toast.error('Tool name can only contain alphanumeric and underscore characters');
    }

    onSave(_tool, toolOriginalName);
    setTool({ ...emptyTool });
  };

  return (
    <div>
      <StyledDialog
        title="Tool Editor"
        isOpen={isOpen}
        closeText="Close"
        icon={faTools}
        width="w-3/5"
        confirmText="Save"
        onConfirm={handleOnSave}
        onClose={handleOnClose}>
        <div>
          <div className="space-y-4">
            <div>
              <input
                id="name"
                name="name"
                type="text"
                placeholder="Tool Name"
                autoComplete="off"
                value={_tool?.name}
                onChange={(e) => updateToolField('name', e.currentTarget.value)}
                onBlur={(e) => updateToolField('name', e.currentTarget.value.trim())}
                required
                className="block md:w-1/3 sm:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <input
                id="desc"
                name="desc"
                type="text"
                placeholder="Description"
                autoComplete="off"
                value={_tool?.description}
                onChange={(e) => updateToolField('description', e.currentTarget.value)}
                onBlur={(e) => updateToolField('description', e.currentTarget.value.trim())}
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="mt-4">
              {_tool?.inputSchema &&
                Object.entries(_tool?.inputSchema.properties).map(([key, value], i) => (
                  <div key={i} className="border relative border-gray-300 rounded-md p-4 space-y-2 mb-6">
                    <label className="absolute -top-2 z-1 left-2 text-xs bg-gray-50 text-gray-600 px-1">{key}</label>
                    <div>
                      <input
                        id={key}
                        name={key}
                        type="text"
                        placeholder="Property Name"
                        autoComplete="off"
                        value={key}
                        onChange={(e) => renameToolProperty(key, e.currentTarget.value)}
                        // onBlur={(e) => renameToolProperty(key, e.currentTarget.value.trim())}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div>
                      <input
                        id={`${key}-desc`}
                        name={`${key}-desc`}
                        type="text"
                        placeholder="Description"
                        autoComplete="off"
                        value={value.description}
                        onChange={(e) => updateToolProperty(key, 'description', e.currentTarget.value)}
                        onBlur={(e) => updateToolProperty(key, 'description', e.currentTarget.value.trim())}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div>
                      <input
                        id={`${key}-enum`}
                        name={`${key}-enum`}
                        type="text"
                        placeholder="Enumerations"
                        autoComplete="off"
                        value={value.enum}
                        onChange={(e) => updateToolProperty(key, 'enum', e.currentTarget.value.split(','))}
                        onBlur={(e) => updateToolProperty(key, 'enum', e.currentTarget.value.trim().split(','))}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div className="flex w-full content-center items-center">
                      <div className="flex-1 flex items-center">
                        <input
                          id={`required-${key}`}
                          type="checkbox"
                          onChange={(e) => {
                            handleOnRequiredChange(key, e.currentTarget.checked);
                          }}
                          checked={_tool.inputSchema.required.includes(key)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor={`required-${key}`}
                          className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                          Required
                        </label>
                      </div>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="text-red-400 cursor-pointer hover:text-red-500"
                        onClick={() => handlePropertyRemove(key)}
                      />
                    </div>
                  </div>
                ))}
              <div className="w-full text-center mt-4">
                <button className="standard secondary" onClick={() => addToolProperty()}>
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add Property
                </button>
              </div>
            </div>
          </div>
        </div>
      </StyledDialog>
      <StyledDialog
        isOpen={isShowChangesModal}
        title="Unsaved Changes"
        closeText="No"
        confirmText="Yes"
        icon={faCircleQuestion}
        onClose={() => setIsShowChangesModal(false)}
        onConfirm={() => handleOnCloseConfirm()}>
        <div>You have unsaved changes for this tool. Are you sure you want to dismiss it?</div>
      </StyledDialog>
    </div>
  );
};

export default ToolEditor;
