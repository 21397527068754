import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { formatCurrency } from '../../common/utils';
import { ModelRunResult } from '../../types/Models';
import { PromptOutputFormatter, StyledDialog } from '../common';
import DataTable, { TableData } from '../common/Datatable';

interface Props {
  results: ModelRunResult[];
}

const ResultsTable: React.FC<Props> = ({ results }: Props) => {
  const [tableData, setTableData] = useState<TableData>();
  const [isShowDetails, setIsShowDetails] = useState<boolean>(false);
  const [responseToShow, setResponseToShow] = useState<string>('');

  useEffect(() => {
    const rows: any = [];
    results.forEach((result) => {
      rows.push({
        cols: [
          { value: result.modelName },
          { value: result.latency },
          { value: formatCurrency(result.tokens.requestCost, 4) },
          { value: formatCurrency(result.tokens.responseCost, 4) },
          { value: formatCurrency(result.tokens.requestCost + result.tokens.responseCost, 4) },
          { value: result.tokens.requestTokens },
          { value: result.tokens.responseTokens }
        ],
        onClick: () => showDetails(result)
      });
      rows.push({
        cols: [
          {
            value: (
              <div className="w-[calc(100dvw-100px)] text-ellipsis overflow-hidden">
                <FontAwesomeIcon icon={faMessage} className="mr-0.5" /> {result.completion}
              </div>
            ),
            colSpan: 7,
            className: 'border-b border-gray-200 !text-gray-400'
          }
        ],
        onClick: () => showDetails(result)
      });
    });

    setTableData({
      headers: ['Model', 'Latency', 'Request Cost', 'Response Cost', 'Total Cost', 'Request Tokens', 'Response Tokens'],
      rows
    });
  }, [results]);

  const showDetails = (result: ModelRunResult) => {
    setResponseToShow(result.completion);
    setIsShowDetails(true);
  };

  return (
    <div className="rounded-lg border-gray-300 border">
      <DataTable data={tableData} divideY={false} />{' '}
      <StyledDialog
        title="Model Response"
        isOpen={isShowDetails}
        closeText="Close"
        icon={faMessage}
        onClose={() => {
          setIsShowDetails(false);
        }}>
        <div className="mt-4 whitespace-pre-wrap">
          <PromptOutputFormatter result={responseToShow} />
        </div>
      </StyledDialog>
    </div>
  );
};

export default ResultsTable;
