import React, { useEffect, useState } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import Handlebars from 'handlebars';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import toast from 'react-hot-toast';

import { GroupBox, PromptPreviewFormatter } from '../common';
import { extractDataStructure } from '../../common/handlebars';
import { deepMergeExcludeMissing, getErrorMessage } from '../../common/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import '../../styles/editor.css';

// TODO: handlebars precompile: https://handlebarsjs.com/api-reference/compilation.html#handlebars-precompile-template-options

/**
 * Props for the PromptComposer component.
 */
interface Props {
  template: string | undefined;
  payload: string;
  disabled: boolean;
  showPreview?: boolean;
  className?: string;

  onPromptGenerate?(prompt?: string): void;
  onUnload?(payload: any): void;
  onPayloadChange?(payload: any): void;
}

/**
 * PromptComposer component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {string} props.template - The template.
 * @param {any} props.payload - The payload.
 * @param {boolean} props.disabled - Indicates if the component is disabled.
 * @param {boolean} props.showPreview - Indicates if the preview should be shown.
 * @param {string} props.className - The additional class names for the component.
 * @param {Function} props.onPromptGenerate - The callback function for prompt generation.
 * @param {Function} props.onUnload - The callback function for unloading of the component.
 * @param {Function} props.onPayloadChange - The callback function for payload change.
 * @returns {JSX.Element} The PromptComposer component.
 */
const PromptComposer: React.FC<Props> = ({
  template = '',
  payload,
  disabled,
  showPreview = false,
  className,
  onUnload,
  onPromptGenerate,
  onPayloadChange
}: Props) => {
  const [isPreview, setIsPreview] = useState<boolean>(showPreview);
  const [_payload, setPayload] = useState<any>(payload);
  const [_template, setTemplate] = useState<string>(template);
  const [isPayloadValid, setIsPayloadValid] = useState<boolean>(true);

  const payloadRef = React.useRef<any | null>(null);
  payloadRef.current = _payload;

  useEffect(() => () => onUnload && onUnload(payloadRef.current), []);

  useEffect(() => {
    if (!payload) return;

    setPayload(payload);
    setIsPayloadValid(true);

    try {
      const hbs = Handlebars.compile(template, { noEscape: true });
      onPromptGenerate && onPromptGenerate(hbs(_payload));
    } catch (e) {}
  }, [payload]);

  useEffect(() => {
    setIsPreview(showPreview);
  }, [showPreview]);

  useEffect(() => {
    if (template === _template) return;

    setTemplate(template);
    setIsPreview(false);

    if (template.length) {
      const hbs = Handlebars.compile(template, { noEscape: true });
      const newPayload = deepMergeExcludeMissing(extractDataStructure(template), _payload);
      setPayload(newPayload);

      try {
        onPromptGenerate && onPromptGenerate(hbs(newPayload));
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
    } else {
      setPayload({});
    }
  }, [template]);

  const handlePreviewError = (error: string) => {
    if (error.includes('Missing helper')) {
      toast.error('Helpers are not yet supported in the preview.');
      return;
    }
    setIsPayloadValid(false);
    toast.error(error);
  };

  const handlePayloadChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const data = e.target.value;

    try {
      const newPayload = JSON.parse(data);
      const hbsFormatter = Handlebars.compile(template, { noEscape: true });

      setPayload(newPayload);
      setIsPayloadValid(true);
      onPromptGenerate && onPromptGenerate(hbsFormatter(newPayload));
      onPayloadChange && onPayloadChange(newPayload);
    } catch (error) {
      toast.error(getErrorMessage(error));
      setIsPayloadValid(false);
    }
  };

  return (
    <div className={`flex gap-x-4 w-full max-h-[42vh] ${className ?? ''}`}>
      <div className="w-1/3 bg-white" data-color-mode="light">
        <GroupBox title="Payload" className="h-full " classNameContent="h-[calc(100%-30px)] overflow-auto">
          <CodeEditor
            value={JSON.stringify(_payload, null, 2)}
            language="json"
            placeholder="Payload"
            onChange={handlePayloadChange}
            padding={10}
            disabled={disabled}
            className="w-full overflow-auto resize-none border-none p-3 focus:ring-0 .performance-editor"
            style={{
              backgroundColor: '#fff'
            }}
          />
        </GroupBox>
        <div className="mt-1">
          {!isPayloadValid && (
            <div>
              <FontAwesomeIcon icon={faCircleExclamation} className="w-5 h-5 text-red-600 inline" />{' '}
              <span className="text-sm text-red-600">Invalid JSON</span>
            </div>
          )}
        </div>
      </div>
      <div className="w-2/3 composer-highlighter">
        <GroupBox title="Template" className="h-full" classNameContent="h-[calc(100%-30px)] overflow-y-auto">
          <>
            {!isPreview && (
              <SyntaxHighlighter
                language="hbs"
                style={docco}
                lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                wrapLines>
                {_template}
              </SyntaxHighlighter>
            )}
            {isPreview && (
              <PromptPreviewFormatter
                template={_template}
                payload={_payload}
                className="text-base p-2 h-[calc(100%-30px)]"
                onError={handlePreviewError}
              />
            )}
          </>
        </GroupBox>
        <div className="flex justify-end items-center mr-2 mb-4">
          <div className="text-sm text-gray-500 mr-2">Preview</div>
          <input
            type="checkbox"
            checked={isPreview}
            className="peer sr-only opacity-0"
            id="toggle"
            onChange={() => setIsPreview(!isPreview)}
          />
          <label htmlFor="toggle" className="selector mt-1" />
        </div>
      </div>
    </div>
  );
};

export default PromptComposer;
