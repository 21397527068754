import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { format } from 'date-fns';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { formatCurrency, formattedDelimDecimal, toFixedSafe, toTitleCase } from '../../common/utils';
import { PromptOutputFormatter, SidePanel, StarRating, StyledDialog } from '../common';
import { PromptMetricExtended } from '../../pages/CallHistory';
import ReplayModal from './ReplayModal';
import { useEffect, useState } from 'react';
import { faCheck, faCircleChevronLeft, faCircleChevronRight, faPencil, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FORMATTER_FS = '0.65rem';

interface Props {
  metric: PromptMetricExtended | undefined;
  isOpen: boolean;
  onClose?: () => void;
  onAnalystScoreChange: (metric: PromptMetricExtended, score: number) => void;
  onAnalystFeedbackChange: (metric: PromptMetricExtended, feedback: string) => void;
  onPage(forward: boolean): void;
}

interface NavvyProps {
  onPrev: () => void;
  onNext: () => void;
}

const Navvy: React.FC<NavvyProps> = ({ onPrev, onNext }: NavvyProps) => {
  return (
    <div className="text-2xl text-indigo-600">
      <FontAwesomeIcon
        className="cursor-pointer hover:text-indigo-500 mr-4"
        icon={faCircleChevronLeft}
        onClick={onPrev}
      />
      <FontAwesomeIcon className="cursor-pointer hover:text-indigo-500" icon={faCircleChevronRight} onClick={onNext} />
    </div>
  );
};

/**
 * Renders the call history details panel.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Metric} props.metric - The metric data.
 * @param {boolean} props.isOpen - Indicates whether the panel is open or not.
 * @param {() => void} props.onClose - The callback function to close the panel.
 * @param {(forward: boolean) => void} props.onPage - The callback function to navigate to the next or previous metric.
 * @param {(metric: PromptMetricExtended, score: number) => void} props.onAnalystScoreChange - The callback function to change the analyst score.
 * @param {(metric: PromptMetricExtended, feedback: string) => void} props.onAnalystFeedbackChange - The callback function to change the analyst feedback.
 * @returns {JSX.Element} The rendered call history details panel.
 */
const CallHistoryDetailsPanel: React.FC<Props> = ({
  metric,
  isOpen,
  onClose,
  onPage,
  onAnalystScoreChange,
  onAnalystFeedbackChange
}: Props) => {
  const [analystFeedback, setAnalystFeedback] = useState<string>('');
  const [isReplayModalOpen, setIsReplayModalOpen] = useState<boolean>(false);
  const [isAnalystFeedbackModalOpen, setIsAnalystFeedbackModalOpen] = useState<boolean>(false);
  useEffect(() => {
    setAnalystFeedback(metric?.analystFeedback || '—');
  }, [metric]);

  const handleAnalystScoreChange = (score: number) => {
    if (metric && score !== metric.analystScore) {
      onAnalystScoreChange(metric, score);
    }
  };

  const handleAnalystFeedbackChange = () => {
    if (metric && analystFeedback !== metric.analystFeedback) {
      onAnalystFeedbackChange(metric, analystFeedback);
    }
    setIsAnalystFeedbackModalOpen(false);
  };

  return (
    <div>
      <SidePanel
        visible={isOpen}
        title="Call Details"
        onClose={() => onClose && onClose()}
        showCloseButton={false}
        topNav={<Navvy onPrev={() => onPage(false)} onNext={() => onPage(true)} />}>
        <div className="mt-4 grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-y-2 gap-x-2 text-xs">
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Time</label>
            <p className="text-gray-700">{metric?.requestAt ? format(metric?.requestAt, 'MMM d hh:mm:ss aaa') : ''}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Model</label>
            <p className="text-gray-700">{metric?.modelObject.name}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Streamed</label>
            <p className="text-gray-700">
              {metric?.streaming ? (
                <FontAwesomeIcon icon={faCheck} className="text-green-600" />
              ) : (
                <FontAwesomeIcon icon={faX} className="text-red-600" />
              )}
            </p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Latency</label>
            <p className="text-gray-700">{formattedDelimDecimal(metric?.latency)} sec</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Prompt</label>
            <p className="text-gray-700">{metric?.promptObject?.name}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Prompt Version</label>
            <p className="text-gray-700">{metric?.promptVersion}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Source</label>
            <p className="text-gray-700">{toTitleCase(metric?.source || 'NA')}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Client</label>
            <p className="text-gray-700">{metric?.client}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Time to First Byte</label>
            <p className="text-gray-700">{metric?.streaming ? `${formattedDelimDecimal(metric?.ttfb, 0)} ms` : 'NA'}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Request Tokens</label>
            <p className="text-gray-700">{metric?.tokensByModel.requestTokens}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Response Tokens</label>
            <p className="text-gray-700">{metric?.tokensByModel.responseTokens}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Request Cost</label>
            <p className="text-gray-700">{formatCurrency(metric?.tokensByModel.requestCost || 0, 4)}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Response Cost</label>
            <p className="text-gray-700">{formatCurrency(metric?.tokensByModel.responseCost || 0, 4)}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Total Cost</label>
            <p className="text-gray-700">{formatCurrency(metric?.totalCost || 0, 4)}</p>
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">User Score</label>
            <StarRating rating={metric?.score || 0} className="text-gray-700" />
          </div>
          <div className="bg-gray-50 p-1">
            <div className="flex">
              <label className="text-gray-500 font-semibold flex-1">Specialist Score</label>
              <FontAwesomeIcon icon={faPencil} />
            </div>
            <StarRating
              rating={metric?.analystScore || 0}
              rateable={true}
              onRatingChange={handleAnalystScoreChange}
              className="text-gray-700"
            />
          </div>
          <div className="bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">Model Archived</label>
            <p className="text-gray-700">
              {metric?.promptArchived ? (
                <FontAwesomeIcon icon={faCheck} className="text-green-600" />
              ) : (
                <FontAwesomeIcon icon={faX} className="text-red-600" />
              )}
            </p>
          </div>
          <div className="xl:col-span-4 lg:col-span-3 sm:col-span-2 bg-gray-50 p-1">
            <label className="text-gray-500 font-semibold">User Feedback</label>
            <p className="text-gray-700">{metric?.feedback?.length ? metric?.feedback : 'NA'}</p>
          </div>
          <div
            className="xl:col-span-4 lg:col-span-3 sm:col-span-2 bg-gray-50 p-1 cursor-pointer"
            onClick={() => setIsAnalystFeedbackModalOpen(true)}>
            <div className="flex">
              <label className="text-gray-500 font-semibold cursor-pointer flex-1">Specialist Feedback</label>
              <FontAwesomeIcon className="ml-1" icon={faPencil} />
            </div>
            <p className="text-gray-700">{analystFeedback}</p>
          </div>
        </div>
        <div className="mt-4 grid grid-cols-2">
          <div>
            <div>
              {metric && metric.fetchTemplateTime > 0 && metric.compileTemplateTime > 0 ? (
                <div className="text-xs bg-gray-50 p-1">
                  <label className="text-gray-500 font-semibold">
                    {`Time: ${toFixedSafe(metric.calculatedTotalTime)} ms`}
                  </label>
                  <div className="text-gray-700 grid grid-cols-2 w-72 ml-3 gap-y-0.5 mt-1">
                    <div className="flex">
                      <div className="border-gray-400 border-l border-b w-2 h-2 mr-1"></div>
                      <div className="flex-1">Fetch Template</div>
                    </div>
                    <div>{toFixedSafe(metric.fetchTemplateTime)} ms</div>
                    <div className="flex">
                      <div className="border-gray-400 border-l border-b w-2 h-2 mr-1"></div>
                      <div className="flex-1">Compile Template</div>
                    </div>
                    <div>{toFixedSafe(metric.compileTemplateTime)} ms</div>
                    <div className="flex">
                      <div className="border-gray-400 border-l border-b w-2 h-2 mr-1"></div>
                      <div className="flex-1">TTFB</div>
                    </div>
                    <div>{toFixedSafe(metric.ttfb)} ms</div>
                    <div className="flex">
                      <div className="border-gray-400 border-l border-b w-2 h-2 mr-1"></div>
                      <div className="flex-1">Completion (Post FB)</div>
                    </div>
                    <div>{toFixedSafe(metric.inferenceTime - metric.ttfb)} ms</div>
                    <div className="flex">
                      <div className="border-gray-400 border-l border-b w-2 h-2 mr-1"></div>
                      <div className="flex-1">Analytics</div>
                    </div>
                    <div>{toFixedSafe(metric.analyticsTime)} ms</div>
                  </div>
                </div>
              ) : undefined}
            </div>
          </div>
          <div className="justify-self-end">
            <button className="standard" onClick={() => setIsReplayModalOpen(true)}>
              Replay
            </button>
          </div>
        </div>
        <div className="mt-4 text-xs">
          <label className="text-gray-500 font-semibold">Payload</label>
          <SyntaxHighlighter
            customStyle={{ marginTop: '0.25em', backgroundColor: 'rgb(249 250 251)' }}
            language="json"
            style={docco}
            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap', fontSize: FORMATTER_FS } }}
            wrapLines>
            {JSON.stringify(metric?.payload, null, 2)}
          </SyntaxHighlighter>
        </div>
        <div className="mt-4 text-xs">
          <label className="text-gray-500 font-semibold">Metadata</label>
          <div className="text-gray-700 whitespace-pre-wrap">
            <SyntaxHighlighter
              customStyle={{ marginTop: '0.25em', backgroundColor: 'rgb(249 250 251)' }}
              language="json"
              style={docco}
              lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap', fontSize: FORMATTER_FS } }}
              wrapLines>
              {metric?.metadatas ? JSON.stringify(metric?.metadatas, null, 2) : '{}'}
            </SyntaxHighlighter>
          </div>
        </div>
        <div className="mt-4 text-xs">
          <label className="text-gray-500 font-semibold">Prompt</label>
          <div className="text-gray-700 whitespace-pre-wrap">
            <div className="text-gray-700">
              <PromptOutputFormatter result={metric?.prompt} fontSize="0.65rem" />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <label className="text-gray-500 font-semibold text-xs">Completion</label>
          <blockquote className="p-4 my-2 border-s-4 border-gray-300 bg-gray-50">
            <div className="text-gray-700">
              <PromptOutputFormatter result={metric?.completion} fontSize="0.65em" />
            </div>
          </blockquote>
        </div>

        <div className="mt-4 text-xs">
          <label className="text-gray-500 font-semibold mr-2">Metric ID:</label>
          <span className="text-gray-700">{metric?.id}</span>
        </div>
      </SidePanel>
      <ReplayModal metric={metric} isOpen={isReplayModalOpen} onClose={() => setIsReplayModalOpen(false)} />
      <StyledDialog
        title="Add Feedback"
        confirmText="Save"
        icon={faPencil}
        onClose={() => setIsAnalystFeedbackModalOpen(false)}
        onConfirm={() => handleAnalystFeedbackChange()}
        isOpen={isAnalystFeedbackModalOpen}
        width="w-3/5">
        <textarea
          id="feedbackDesc"
          name="feedbackDesc"
          placeholder="Feedback"
          rows={3}
          onChange={(e) => setAnalystFeedback(e.target.value)}
          required
          className="disabled:text-gray-400 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={analystFeedback === 'N/A' ? '' : analystFeedback}
        />
      </StyledDialog>
    </div>
  );
};

export default CallHistoryDetailsPanel;
