// import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useEffect, useState } from 'react';
// import { toast } from 'react-hot-toast';
// import { useNavigate } from 'react-router-dom';
// import { getErrorMessage, validateEmailAddress, validateUserPassword } from '../../common/utils';
// import { register } from '../../services/User';

interface Props {}

/**
 * Register user page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const Register: React.FC<Props> = ({}: Props) => {
  // const navigate = useNavigate();

  // const [name, setName] = useState<string>('');
  // const [email, setEmail] = useState<string>('');
  // const [password, setPassword] = useState<string>('');
  // const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  // const [registered, setRegistered] = useState<boolean>(false);
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // useEffect(() => {
  //   if (registered) {
  //     navigate('/login');
  //     toast.success('Account created successfully');
  //   }
  // }, [registered]);

  // const handleRegister = async () => {
  //   if (!validateEmailAddress(email)) {
  //     toast.error('Please enter a valid email address');
  //     return;
  //   } else if (!name || !password || !passwordConfirm) {
  //     toast.error('Please fill in all fields');
  //     return;
  //   } else if (passwordConfirm !== password) {
  //     toast.error('Passwords do not match');
  //     return;
  //   }

  //   const passwordErrorMessage = validateUserPassword(password);
  //   if (passwordErrorMessage) {
  //     toast.error(passwordErrorMessage);
  //     return;
  //   }

  //   try {
  //     setIsSubmitting(true);
  //     await register({ name, email, password });
  //     setRegistered(true);
  //   } catch (e) {
  //     toast.error(getErrorMessage(e));
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  return (
    <div className="mx-auto max-w-lg my-5 bg-gray-50 border-gray-30000 border-2 p-5 rounded-lg">
      <div className="">
        <h1 className="kanit-bold mx-auto text-center text-4xl text-gray-700">
          <span className="text-indigo-500 group-hover:text-black">&#123;</span> Promptly{' '}
          <span className="text-indigo-500 group-hover:text-black">&#125;</span>
        </h1>

        <h2 className="mt-8 text-center text-2xl font-semibold tracking-tight text-gray-900">Signup</h2>
        <p className="my-6">
          Click the button below to be taken to ServiceNow to submit an access request. Be sure to select {'"'}
          Promptly{'"'} in the Application field.
        </p>
        <div className="flex justify-center">
          <a
            rel="noopener noreferrer"
            href="https://coxauto.service-now.com/ess?id=sc_cat_item&sys_id=b9e1b825db965304143b69c3ca9619dc">
            <button className="standard">Request Access</button>
          </a>
        </div>
      </div>

      {/* <div className="mt-10 ">
        <div className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
              Name
            </label>
            <div className="mt-2">
              <input
                id="name"
                name="name"
                type="text"
                autoComplete="off"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                required
                disabled={isSubmitting}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                required
                disabled={isSubmitting}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                required
                disabled={isSubmitting}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="passwordConfirm" className="block text-sm font-medium leading-6 text-gray-900">
                Confirm Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="passwordConfirm"
                name="passwordConfirm"
                type="password"
                autoComplete="off"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.currentTarget.value)}
                required
                disabled={isSubmitting}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              onClick={handleRegister}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              disabled={isSubmitting}>
              {isSubmitting && <FontAwesomeIcon icon={faSpinner} className="animate-spin" />} Signup
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Register;
