import { useState } from 'react';
import { Pipeline } from '../../types';
import { getErrorMessage } from '../../common/utils';
import StyledDialog from '../common/StyledDialog';
import { faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { addPipeline } from '../../services/PipelineEvaluations';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PromptVersionSelector } from '../common';
import { PV } from '../common/PromptVersionSelector';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (pipeline: Pipeline) => void;
}

const pvLabels = ['Select Prompt', 'Select Version'];

const AddPipelineModal: React.FC<Props> = ({ isOpen, onClose, onSave }: Props) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [promptVersion, setPromptVersion] = useState<PV>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const reset = () => {
    setName('');
    setDescription('');
    setPromptVersion(undefined);
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const handleOnCreate = async () => {
    if (!name.length || !description.length) {
      toast.error('Please enter a valid name and description.');
      return;
    }

    if (!promptVersion?.prompt || promptVersion?.prompt.name === pvLabels[0]) {
      toast.error('Please select a prompt.');
      return;
    }

    setIsSaving(true);
    let pipeline: Pipeline;

    try {
      pipeline = await addPipeline(name, description, promptVersion.prompt.id!);
      toast.success('Pipeline added!');
      onSave(pipeline);
      reset();
    } catch (error) {
      const err = getErrorMessage(error);
      console.error(error);
      toast.error(err);
      return;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title="Add Pipeline"
      closeText="Cancel"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : (
          'Add'
        )
      }
      disabled={isSaving}
      onConfirm={handleOnCreate}
      onClose={handleOnClose}
      icon={faPlusCircle}
      width="w-2/5">
      <div className="h-56">
        <div className="mt-2">
          <input
            id="pipelineName"
            name="pipelineName"
            type="text"
            autoComplete="off"
            value={name}
            placeholder="Pipeline Name"
            onChange={(e) => setName(e.currentTarget.value)}
            required
            disabled={isSaving}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-2">
          <PromptVersionSelector
            disabled={isSaving}
            showVersions={false}
            showArchivedButton={false}
            onChange={setPromptVersion}
            defaultLabels={pvLabels}
          />
        </div>
        <div className="mt-2">
          <textarea
            id="pipelineDesc"
            name="pipelineDesc"
            placeholder="Description"
            rows={3}
            onChange={(e) => setDescription(e.currentTarget.value)}
            required
            disabled={isSaving}
            value={description}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
        </div>
      </div>
    </StyledDialog>
  );
};

export default AddPipelineModal;
