export enum EvaluationType {
  COHERENCE = 'coherence',
  FLUENCY = 'fluency'
}

interface Cost {
  requestCost: number;
  responseCost: number;
  totalCost: number;
}

export interface Sentiment extends Cost {
  model: string;
  results: string[];
}

export interface RatedEvaluation extends Cost {
  model: string;
  ratings: number[];
}

export interface Summary extends Cost {
  results: string;
}

export interface Grades extends Cost {
  grades: Record<string, string>;
}
