import { memo, useEffect, useState } from 'react';
import { ModelParameter as Parameter, ModelParameterValue } from '../../types/Models';
/**
 * Props for the ModelParameter component.
 */
interface Props {
  property: Parameter;
  value: number | boolean;
  disabled: boolean;
  onPropertyChange(parameter: ModelParameterValue): void;
}

/**
 * Represents a model parameter component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Parameter} props.property - The model parameter.
 * @param {number | boolean} props.value - The value of the model parameter.
 * @param {boolean} props.disabled - Indicates whether the model parameter is disabled.
 * @param {Function} props.onPropertyChange - The callback function to handle parameter changes.
 * @returns {JSX.Element} The model parameter component.
 */
const ModelParameter: React.FC<Props> = memo(({ property, value, disabled, onPropertyChange }: Props) => {
  const [inputValue, setInputValue] = useState<number | boolean>(value);

  useEffect(() => setInputValue(value), [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const val = Number(e.target.value);
    setInputValue(val);
    onPropertyChange({ name: property.parameterModel, value: val });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onPropertyChange({ name: property.parameterModel, value: e.target.checked });
  };

  let field;
  if (property.type === 'integer' || property.type === 'float') {
    const step = property.type === 'float' ? 0.1 : 1;
    const typedValue: number = Number(inputValue);

    field = (
      <div className="flex items-center">
        <input
          type="range"
          className="transparent h-[4px] flex-grow cursor-pointer appearance-none border-transparent accent-indigo-500 bg-neutral-200 dark:bg-neutral-600"
          min={property.min}
          max={property.max}
          step={step}
          value={typedValue}
          onChange={handleInputChange}
          disabled={disabled}
        />
        <div className="text-xs text-right text-gray-500 pl-2">
          {new Intl.NumberFormat().format(Number(inputValue))}
        </div>
      </div>
    );
  } else {
    field = (
      <div>
        <input
          type="checkbox"
          checked={Boolean(inputValue)}
          className="peer sr-only opacity-0"
          id="toggle"
          onChange={handleCheckboxChange}
          disabled={disabled}
        />
        <label htmlFor="toggle" className="selector mt-1" />
      </div>
    );
  }

  return (
    <div className="py-2 border-b last:border-0 border-gray-300 w-full px-3">
      <div className="text-xs text-gray-600 ">{property.name}</div>
      {field}
    </div>
  );
});

ModelParameter.displayName = 'Model Parameter';

export default ModelParameter;
