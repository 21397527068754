import { Model, ModelParameterValue } from '../types';
import { PromptVersionTypes } from '../types/Prompt';
import { clampNumber } from './utils';

/**
 * Converts an array of model parameters to an object.
 * @param parameters - The array of model parameters.
 * @returns The converted object with parameter names as keys and values as values.
 */
export const modelParameterArrayToObject = (parameters: ModelParameterValue[]): Record<string, number | boolean> =>
  parameters.reduce((acc: any, parameter) => {
    acc[parameter.name] = parameter.value;
    return acc;
  }, {});

/**
 * Translates model parameters from the source model to the destination model.
 * @param models - The array of available models.
 * @param sourceModel - The ID of the source model.
 * @param destModel - The ID of the destination model.
 * @param parameters - The array of model parameters to be translated.
 * @param destType - The prompt version type.
 * @param sourceType - The source prompt version type.
 * @returns The translated array of model parameters.
 */
export const translateModelParameters = (
  models: Model[],
  sourceModel: string,
  destModel: string,
  parameters: ModelParameterValue[],
  destType: PromptVersionTypes,
  sourceType?: PromptVersionTypes
): ModelParameterValue[] => {
  const sourceModelInst = models.find((model) => model.mid === sourceModel);
  const destModelInst = models.find((model) => model.mid === destModel);

  const sourceModelParameters =
    sourceType === PromptVersionTypes.COMPLETION
      ? sourceModelInst?.parametersCompletion
      : sourceModelInst?.parametersMessages;

  const destModelParameters =
    destType === PromptVersionTypes.COMPLETION
      ? destModelInst?.parametersCompletion
      : destModelInst?.parametersMessages;
  const newParameters: ModelParameterValue[] = [];

  destModelParameters?.forEach((destParameter) => {
    const sourceParameter = sourceModelParameters?.find((param) => param.parameter === destParameter.parameter);
    if (sourceParameter) {
      let newValue = parameters.find((p) => p.name === sourceParameter.parameterModel)?.value || destParameter.default;
      if (typeof newValue === 'number') {
        newValue = clampNumber(newValue, destParameter.min!, destParameter.max!);
      }

      newParameters.push({
        name: destParameter.parameterModel,
        value: newValue
      });
    } else {
      newParameters.push({
        name: destParameter.parameterModel,
        value: destParameter.default
      });
    }
  });

  return newParameters;
};
