import { axiosInstance } from '../api';
import { apiToModelRunResultType } from '../common/typeUtils';
import { apiExceptionHandler } from '../common/utils';
import { ModelRunResult } from '../types/Models';

/**
 * Replays a history record by sending a POST request to the server with the metric ID.
 *
 * @param metricId - The ID of the metric to replay.
 * @returns A Promise that resolves to a ModelRunResult object.
 * @throws Throws an error if an error occurs while running the replay.
 */
export const replay = async (metricId: number): Promise<ModelRunResult> => {
  try {
    const { data } = await axiosInstance.post(`history/${metricId}/replay`);
    return apiToModelRunResultType(data);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred running replay.');
  }
};
