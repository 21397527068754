import {
  faBoxArchive,
  faBoxOpen,
  faDownload,
  faClock,
  faPlus,
  faClone,
  faSpinner,
  faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, formatDistance } from 'date-fns';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router';
import { getErrorMessage } from '../../common/utils';
import { SideBar, TabGroup } from '../../components';
import {
  PipelineEvaluatorTile,
  AddPipelineEvaluatorModal,
  AddRunModal,
  ArchivePipelineModal,
  ClonePipelineRun
} from '../../components/pipelines';
import AddDefaultDataModal from '../../components/pipelines/AddDefaultDataModal';
import { DEFAULT_DF_FORMAT } from '../../constants';
import {
  downloadDataset,
  downloadRunData,
  getEvaluators,
  getPipeline,
  getRuns
} from '../../services/PipelineEvaluations';
import { Pipeline as IPipeline, PipelineEvaluator, PipelineRun } from '../../types';
import { PipelineMetadata, PipelineRunPage, RunStatusLabels, RunType } from '../../types/PipelineEvaluations';
import { PromptTypes } from '../../types/Prompt';
import Prompts from '../prompts/Prompts';
import { WebhookList } from '../webhooks';
import PipelineMetrics from './PipelineMetrics';

const enum SideNav {
  PIPELINES = 0,
  OVERVIEW = 1,
  METRICS = 2,
  EVALUATORS = 3,
  WEBHOOKS = 4
}

const enum Tabs {
  EVALUATORS = 1,
  RUNS = 0,
  DATA = 2
}

const PipelinePage: React.FC<Props> = () => {
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const navigate = useNavigate();
  const routerParams = useParams();

  useEffect(() => {
    if (selectedPage === 0) navigate('/pipelines');
  }, [selectedPage]);

  return (
    <div className="-m-5">
      <SideBar
        rows={['Pipelines', 'Overview', 'Metrics', 'Evaluator Prompts', 'Webhooks']}
        rowClasses={['', 'ml-4', 'ml-4', '', '']}
        selectedIndex={selectedPage}
        onClick={(index) => setSelectedPage(index)}>
        <div>
          {selectedPage === SideNav.OVERVIEW && <Pipeline />}
          {selectedPage === SideNav.METRICS && <PipelineMetrics pipelineId={routerParams.pipelineId!} />}
          {selectedPage === SideNav.EVALUATORS && <Prompts type={PromptTypes.EVALUATOR} />}
          {selectedPage === SideNav.WEBHOOKS && <WebhookList />}
        </div>
      </SideBar>
    </div>
  );
};

export default PipelinePage;

interface Props {}

const Pipeline: React.FC<Props> = ({}: Props) => {
  const routerParams = useParams();
  const navigate = useNavigate();
  const [pipeline, setPipeline] = useState<IPipeline>(); //location.state?.pipeline);
  const [evaluators, setEvaluators] = useState<PipelineEvaluator[]>([]);
  const [runsPageData, setRunsPageData] = useState<PipelineRunPage>();
  const [perPage, setPerPage] = useState<number>(10);
  const [pipelineRunToClone, setPipelineRunToClone] = useState<PipelineRun>();
  const [isLoadingEvaluators, setIsLoadingEvaluators] = useState<boolean>(true);
  const [isLoadingRuns, setIsLoadingRuns] = useState<boolean>(true);
  const [isShowAddEvaluatorModal, setIsShowAddEvaluatorModal] = useState<boolean>(false);
  const [isShowAddRunModal, setIsShowAddRunModal] = useState<boolean>(false);
  const [isShowArchiveModal, setIsShowArchiveModal] = useState<boolean>(false);
  const [isShowCloneModal, setIsShowCloneModal] = useState<boolean>(false);
  const [isShowAddDefaultDataModal, setIsShowAddDefaultDataModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    const pipelineId = routerParams.pipelineId;
    if (!pipelineId) return;

    (async () => {
      if (pipeline) {
        return;
      }

      try {
        const [_pipeline, _runs, _evaluators] = await Promise.all([
          getPipeline(pipelineId),
          getRuns(pipelineId, 1, perPage),
          getEvaluators(pipelineId)
        ]);

        setPipeline(_pipeline);
        setIsLoadingEvaluators(false);
        setRunsPageData(_runs);
        setEvaluators(_evaluators);
        setIsLoadingRuns(false);
      } catch (error) {
        toast.error(getErrorMessage(error));
      }
    })();
  }, [routerParams.pipelineId]);

  const loadRuns = async (page: number = 1) => {
    if (!pipeline) return;
    setIsLoadingRuns(true);

    try {
      setRunsPageData(await getRuns(pipeline.id, page, perPage));
    } catch (error) {
      console.error(error);
      return toast.error(getErrorMessage(error));
    } finally {
      setIsLoadingRuns(false);
    }
  };

  const handleAddEvaluator = (evaluator: PipelineEvaluator) => {
    setIsShowAddEvaluatorModal(false);
    setEvaluators([...evaluators, evaluator]);
  };

  const handleAddRun = async (run: PipelineRun) => {
    if (!pipeline) return;

    setIsShowAddRunModal(false);
    navigate(`/pipelines/${pipeline.id}/runs/${run.id}`, { state: { pipeline, run, evaluators: evaluators } });
  };

  const handleRunsPageChange = async (page: number) => await loadRuns(page);

  const handleCallRowClick = (run: PipelineRun, e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    navigate(`/pipelines/${pipeline?.id}/runs/${run.id}`, { state: { pipeline, run, evaluators: evaluators } });
  };

  const handleOnEvaluatorUpdate = (evaluator: PipelineEvaluator) => {
    setEvaluators(evaluators.map((e) => (e.id === evaluator.id ? evaluator : e)));
  };

  const handleArchive = (pipeline: IPipeline) => {
    setIsShowArchiveModal(false);
    setPipeline(pipeline);
  };

  const handleDatasetDownload = async (pipelineId: string, runId: string) => {
    await downloadDataset(pipelineId, runId);
  };

  const handleResultsDownload = async (pipelineId: string, runId: string) => {
    await downloadRunData(pipelineId, runId);
  };

  const handleClonedRun = async (run: PipelineRun) => {
    setIsShowCloneModal(false);
    await loadRuns();
  };

  const handleDefaultDataSave = (metadata: PipelineMetadata) => {
    setPipeline({ ...pipeline!, defaultDataMetadata: metadata });
    setIsShowAddDefaultDataModal(false);
  };

  const generateColumns = (): TableColumn<any>[] => [
    {
      name: 'Created',
      selector: (run: PipelineRun) => format(run.created, DEFAULT_DF_FORMAT),
      sortable: true,
      width: '130px'
    },
    {
      name: 'Name',
      selector: (run: PipelineRun) => run.name,
      sortable: true,
      width: '130px'
    },
    {
      name: 'Version',
      selector: (run: PipelineRun) => run.promptVersionNumber,
      sortable: true,
      width: '90px'
    },
    {
      name: 'Status',
      selector: (run: PipelineRun) => RunStatusLabels[run.status],
      sortable: true,
      width: '100px'
    },
    {
      name: 'Rows',
      selector: (run: PipelineRun) => run.metadata.rows.toLocaleString(),
      width: '80px'
    },
    {
      name: 'Type',
      selector: (run: PipelineRun) => run.metadata.fileType,
      width: '90px'
    },
    {
      name: '',
      cell: (row: PipelineRun) => (
        <div>
          <button
            className="standard secondary small mr-3"
            onClick={() => handleDatasetDownload(row.pipelineId, row.id)}>
            <FontAwesomeIcon icon={faDownload} className="h-3 w-3 mr-1 inline" />
            Dataset
          </button>
          <button className="standard secondary small" onClick={() => handleResultsDownload(row.pipelineId, row.id)}>
            <FontAwesomeIcon icon={faDownload} className="h-3 w-3 mr-1 inline" />
            Results
          </button>
          {row.type !== RunType.HISTORY && (
            <button
              className="standard secondary small ml-3"
              onClick={() => {
                setPipelineRunToClone(row);
                setIsShowCloneModal(true);
              }}>
              <FontAwesomeIcon icon={faClone} className="h-3 w-3 mr-1 inline" />
              Clone
            </button>
          )}
        </div>
      ),
      width: '300px'
    }
  ];

  return (
    <div className="mx-auto">
      <div className="flex w-full">
        <h1 className="w-3/4 text-2xl text-gray-800">
          {!pipeline ? (
            <Skeleton containerClassName="w-52" />
          ) : (
            <div>
              {pipeline?.name} {pipeline?.archived && <FontAwesomeIcon icon={faBoxArchive} className="h-5 w-5 ml-2" />}
            </div>
          )}
        </h1>
        <div className="text-right text-sm text-gray-500 ml-8 w-1/4">
          {pipeline ? (
            <div className="text-right">
              <FontAwesomeIcon icon={faClock} className="mr-2 w-3 h-3 mt-1 inline" />
              <span>Updated {formatDistance(pipeline.updated, new Date()) + ' ago'}</span>
              <button
                className={`standard ml-4 mt-2 small ${!pipeline.archived ? '!bg-red-500 hover:!bg-red-400' : ''} inline`}
                onClick={() => setIsShowArchiveModal(true)}>
                <FontAwesomeIcon icon={pipeline.archived ? faBoxOpen : faBoxArchive} className="h-3.5 w-3.5 mr-0.5" />{' '}
                {pipeline.archived ? 'Restore' : 'Archive'}
              </button>
            </div>
          ) : (
            <Skeleton containerClassName="w-36" />
          )}
        </div>
      </div>
      <div className="text-gray-700 -mt-2 pb-4 ">
        {!pipeline ? (
          <Skeleton count={3} />
        ) : (
          <div>
            {pipeline?.prompt.name}{' '}
            <a
              href={`/prompts/${pipeline?.promptId}`}
              target="_blank"
              rel="noreferrer"
              className="!text-gray-500 leading-none mr-3">
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className="pl-1.5 w-3 h-3 hover:text-indigo-700 duration-200"
              />
            </a>
          </div>
        )}
      </div>
      <div className="text-sm text-gray-500 mb-4 pb-4 ">
        {!pipeline ? <Skeleton count={3} /> : <div>{pipeline?.description}</div>}
      </div>

      <TabGroup
        tabNames={['Runs', 'Evaluators', 'Default Data']}
        selectedTabIndex={selectedTab}
        onTabChange={setSelectedTab}
        helpLink={`/docs/#/pipelines?id=${selectedTab === Tabs.EVALUATORS ? 'pipelines_evaluators' : selectedTab === Tabs.RUNS ? 'pipelines_runs' : 'pipelines_new?id=default-data'}`}>
        <div>
          {selectedTab === Tabs.EVALUATORS && (
            <div>
              <h2 className="flex-1 text-base font-semibold leading-7 text-gray-800 mb-2 mt-4">Evaluators</h2>
              {!isLoadingEvaluators && evaluators.length === 0 ? (
                <div className="mt-2 text-gray-700 mx-auto">
                  It looks like you haven&lsquo;t created any evaluators yet.{' '}
                  <a role="button" onClick={() => setIsShowAddEvaluatorModal(true)} className="standard">
                    Click here to get started!
                  </a>
                </div>
              ) : (
                <ul className="grid xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-4 rounded-lg mt-2">
                  {isLoadingEvaluators && <PipelineEvaluatorTile />}
                  {!isLoadingEvaluators &&
                    evaluators.map((e) => (
                      <PipelineEvaluatorTile key={e.id} evaluator={e} onUpdate={handleOnEvaluatorUpdate} />
                    ))}
                  {!isLoadingEvaluators && evaluators.length && !pipeline?.archived && (
                    <li className="group colspan-1 rounded-lg border-gray-300 border p-3 shadow-lg  hover:cursor-pointer flex justify-center items-center">
                      <div className="text-center">
                        <button
                          className="standard"
                          disabled={!pipeline || pipeline.archived || !evaluators.length}
                          onClick={() => setIsShowAddEvaluatorModal(true)}>
                          <FontAwesomeIcon icon={faPlus} className="h-3.5 w-3.5 mr-0.5" />
                          Evaluator
                        </button>
                      </div>
                    </li>
                  )}
                </ul>
              )}
            </div>
          )}

          {selectedTab === Tabs.RUNS && (
            <div>
              <div className="flex mt-8 w-[900px] mb-2">
                <h2 className="text-base flex-1 font-semibold leading-7 text-gray-800 mb-2">Pipeline Runs</h2>
                <div>
                  {!isLoadingRuns && !pipeline?.archived && !isLoadingEvaluators && evaluators.length > 0 && (
                    <button
                      className="standard small ml-4"
                      disabled={isLoadingRuns}
                      onClick={() => setIsShowAddRunModal(true)}>
                      <FontAwesomeIcon icon={faPlus} className="h-3.5 w-3.5 mr-0.5" />
                      New Run
                    </button>
                  )}
                </div>
              </div>
              {isLoadingRuns && <Skeleton count={3} />}
              {!isLoadingRuns && runsPageData?.total === 0 && (
                <div className="mt-2 text-gray-700 mx-auto">
                  It looks like you haven&lsquo;t created any pipeline runs yet.{' '}
                  <a role="button" onClick={() => setIsShowAddRunModal(true)} className="standard">
                    Click here to get started!
                  </a>
                </div>
              )}
              {!isLoadingRuns && runsPageData && runsPageData?.total > 0 && (
                <div className="max-h-lvh w-[900px] block overflow-y-auto rounded-lg border-gray-300 border">
                  <DataTable
                    pagination
                    paginationServer
                    responsive
                    striped
                    pointerOnHover
                    fixedHeaderScrollHeight="calc(100vh - 500px)"
                    progressPending={isLoadingRuns}
                    progressComponent={<FontAwesomeIcon icon={faSpinner} spin />}
                    noDataComponent="No runs found."
                    data={runsPageData?.items || []}
                    columns={generateColumns()}
                    onChangePage={handleRunsPageChange}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => setPerPage(currentRowsPerPage)}
                    paginationTotalRows={runsPageData?.total}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    className="datatable"
                    onRowClicked={handleCallRowClick}
                  />
                </div>
              )}
            </div>
          )}

          {selectedTab === Tabs.DATA && (
            <div className="mt-2 text-gray-700 mx-auto">
              {!pipeline?.defaultDataMetadata ? (
                <div>
                  It looks like you haven&lsquo;t added a default dataset yet.{' '}
                  <a role="button" onClick={() => setIsShowAddDefaultDataModal(true)} className="standard">
                    Click here to get started!
                  </a>
                </div>
              ) : (
                <div className="flex">
                  <div className="flex-1">
                    Prompt versions can now be tested against the data from{' '}
                    <span className="font-semibold">{pipeline.defaultDataMetadata.fileName}</span> which contains{' '}
                    <span className="font-semibold">{pipeline.defaultDataMetadata.rows.toLocaleString()}</span> rows.
                  </div>
                  <div className="ml-2 text-right">
                    <button className="standard ml-4" onClick={() => setIsShowAddDefaultDataModal(true)}>
                      Update Default Data
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </TabGroup>

      {pipeline && (
        <div>
          <AddPipelineEvaluatorModal
            pipeline={pipeline}
            isOpen={isShowAddEvaluatorModal}
            onClose={() => setIsShowAddEvaluatorModal(false)}
            onSave={handleAddEvaluator}
          />

          <AddRunModal
            pipeline={pipeline}
            isOpen={isShowAddRunModal}
            onClose={() => setIsShowAddRunModal(false)}
            onSave={handleAddRun}
          />

          <ArchivePipelineModal
            pipeline={pipeline}
            isOpen={isShowArchiveModal}
            onClose={() => setIsShowArchiveModal(false)}
            onSave={handleArchive}
          />
        </div>
      )}
      {pipelineRunToClone && (
        <ClonePipelineRun
          pipelineRun={pipelineRunToClone}
          isOpen={isShowCloneModal}
          onSave={handleClonedRun}
          onClose={() => setIsShowCloneModal(false)}
        />
      )}
      {pipeline && (
        <AddDefaultDataModal
          pipeline={pipeline}
          isOpen={isShowAddDefaultDataModal}
          onClose={() => setIsShowAddDefaultDataModal(false)}
          onSave={handleDefaultDataSave}
        />
      )}
    </div>
  );
};
