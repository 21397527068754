import DateTimePicker from 'react-tailwindcss-datetimepicker';
import Select, { MultiValue, SingleValue } from 'react-select';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { PromptVersionSelector, StyledDialog } from '../common';
import { PV } from '../common/PromptVersionSelector';
import { format, subDays } from 'date-fns';
import { Filters, HistoryFilters, MetricsFilters, Model } from '../../types';
import { DateRanges, toTitleCase } from '../../common/utils';

import '../common/DateTimePicker.css';
import { PromptMetricSource } from '../../types/Metrics';

interface Props {
  isOpen: boolean;
  days: number;
  models?: Model[];
  onApply: (filters: MetricsFilters) => void;
  onClose: () => void;
}

const today = new Date();

const CallHistoryFilterModal: React.FC<Props> = ({ isOpen, days, models, onApply, onClose }: Props) => {
  const [dateRange, setDateRange] = useState<Record<string, Date>>({ start: subDays(today, days), end: today });
  const [startDate, setStartDate] = useState<Date>(subDays(today, days));
  const [endDate, setEndDate] = useState<Date>(today);
  const [selectedPV, setSelectedPV] = useState<PV>();
  const [selectedModels, setSelectedModels] = useState<string[]>([]);

  useEffect(() => {
    if (!startDate || !endDate) return;

    setDateRange({ start: startDate, end: endDate });
  }, [startDate, endDate]);

  const [filters, setFilters] = useState<Filters>(HistoryFilters);

  const handleFilterChange = (key: keyof MetricsFilters, value: string | boolean | number) => {
    if (typeof value === 'string') {
      value = value.trim();
    }
    setFilters((prevFilters) => ({ ...prevFilters, [key]: { ...prevFilters[key], value } }));
  };

  const handleModelSelectChange = (selected: MultiValue<{ value: string; label: string }>) => {
    setSelectedModels(selected.map((s) => s.value));
  };

  const handleSourceSelectChange = (selected: SingleValue<{ value: string; label: string }>) => {
    handleFilterChange('source', selected?.value || '');
  };

  const handleDateChange = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnApply = () => {
    const applyFilters: any = {
      startDate: startDate,
      endDate: endDate
    };

    Object.entries(filters).forEach(([key, filter]) => {
      if (filter.default !== filter.value) {
        applyFilters[key] = filter.value;
      }
    });

    if (selectedPV?.prompt?.id) {
      applyFilters.promptId = selectedPV.prompt.id;
    }

    if (selectedPV?.version?.version) {
      applyFilters.promptVersion = selectedPV.version.version;
    }

    if (selectedModels.length > 0) {
      applyFilters.models = selectedModels;
    }

    onApply(applyFilters as MetricsFilters);
  };

  const createSlider = (key: keyof MetricsFilters) => {
    return (
      <div>
        <label htmlFor={`${key}Slider`} className="text-sm text-gray-600 font-semibold block mt-2">
          {filters[key]?.label}
        </label>
        <input
          type="range"
          id={`${key}Slider`}
          className="transparent h-[4px] flex-grow cursor-pointer appearance-none border-transparent accent-indigo-500 bg-neutral-200 w-full dark:bg-neutral-600"
          min={filters[key]?.min}
          max={filters[key]?.max}
          step={filters[key]?.step}
          value={filters[key]?.value as number}
          onChange={(e) => handleFilterChange(key, parseFloat(e.target.value))}
        />
      </div>
    );
  };

  const createSliderInput = (key: keyof MetricsFilters) => {
    return (
      <div>
        <label htmlFor={`${key}Input`} className="text-sm text-gray-600 font-semibold block mb-1 mt-2">
          {filters[key]?.secondaryLabel}
        </label>
        <input
          id={`${key}Input`}
          autoComplete="off"
          type="number"
          value={filters[key]?.value as number}
          onChange={(e) => handleFilterChange(key, parseFloat(e.target.value))}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    );
  };

  const createToggleInput = (key: keyof MetricsFilters) => {
    return (
      <div>
        <label htmlFor={`${key}Toggle`} className="text-sm text-gray-600 font-semibold block mt-2">
          {filters[key]?.label}
        </label>
        <input
          type="checkbox"
          checked={filters[key]?.value as boolean}
          className="peer sr-only opacity-0"
          id={`${key}Toggle`}
          onChange={() => handleFilterChange(key, !filters[key]?.value)}
        />
        <label htmlFor={`${key}Toggle`} className="selector mt-1" />
      </div>
    );
  };

  return (
    <StyledDialog
      title="History Filters"
      confirmText="Apply"
      icon={faFilter}
      onClose={handleOnClose}
      onConfirm={handleOnApply}
      isOpen={isOpen}
      width="w-4/5">
      <div>
        {/* this vvv is a super hacky way of blocking the datepicker from getting autofocused */}
        <input type="text" data-autofocus className="opacity-0 w-0 h-0 absolute top-0" />
        <div className="grid lg:grid-cols-2 grid-cols-1 h-gap-4">
          <div className="">
            <DateTimePicker
              ranges={DateRanges}
              start={dateRange.start}
              end={dateRange.end}
              twelveHoursClock
              locale={{
                format: 'MM/dd/yy hh:mm a',
                close: 'Close',
                cancel: 'Cancel'
              }}
              years={[2024, new Date().getFullYear()]}
              theme="purple"
              applyCallback={handleDateChange}>
              <input
                type="text"
                className="cursor-pointer py-2 pl-4 pr-14 w-5/6 border-gray-300  rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-indigo-500 focus:ring-indigo-500/20"
                placeholder="MM/DD/YY ~ MM/DD/YY"
                role="presentation"
                readOnly
                value={`${format(dateRange.start, 'MM/dd hh:mm a')} to ${format(dateRange.end, 'MM/dd hh:mm a')}`}></input>
            </DateTimePicker>
          </div>
          <PromptVersionSelector onChange={setSelectedPV} selected={selectedPV} className="content-end lg:mt-0 mt-5" />
        </div>
        <div className="w-1/2 mt-5 grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4">
          <div>
            <label htmlFor="model" className="text-sm text-gray-600 font-semibold block mt-2">
              Models
            </label>
            <Select
              isMulti
              id="model"
              options={models?.map((model) => ({ value: model.mid, label: model.name }))}
              value={models
                ?.filter((model) => selectedModels.includes(model.mid))
                .map((model) => ({ value: model.mid, label: model.name }))}
              onChange={handleModelSelectChange}
              className="mt-2"
              classNamePrefix="promptly"
            />
          </div>
          <div>
            <label htmlFor="model" className="text-sm text-gray-600 font-semibold block mt-2">
              Source
            </label>
            <Select<{ value: string; label: string }>
              id="model"
              options={Object.values(PromptMetricSource).map((m) => ({ value: m, label: toTitleCase(m) }))}
              value={{ value: filters.source?.value as string, label: toTitleCase(filters.source?.value as string) }}
              onChange={handleSourceSelectChange}
              className="mt-2"
              classNamePrefix="promptly"
            />
          </div>
        </div>
        <div className="mt-5 grid xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-2 gap-4">
          {Object.entries(filters).map(
            ([key, filter]) =>
              filter.type === 'slider' && (
                <div key={key}>
                  {createSlider(key as keyof MetricsFilters)}
                  {createSliderInput(key as keyof MetricsFilters)}
                </div>
              )
          )}
        </div>
        <div className="mt-5 grid xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-2 gap-4">
          {Object.entries(filters).map(
            ([key, filter]) =>
              filter.type === 'text' && (
                <div key={key}>
                  <label htmlFor={filter.label} className="text-sm text-gray-600 font-semibold block mt-2">
                    {filter.label}
                  </label>

                  <input
                    id={filter.label}
                    type="text"
                    autoComplete="off"
                    value={filter.value as string}
                    onChange={(e) => handleFilterChange(key as keyof MetricsFilters, e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                  />
                </div>
              )
          )}
        </div>
        <div className="mt-5 grid xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-2 gap-4">
          {Object.entries(filters).map(
            ([key, filter]) =>
              filter.type === 'toggle' && <div key={key}>{createToggleInput(key as keyof MetricsFilters)}</div>
          )}
        </div>
      </div>
    </StyledDialog>
  );
};

export default CallHistoryFilterModal;
