import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

interface Props {
  result: string | undefined;
  className?: string;
  fontSize?: string;
}

enum DisplayMode {
  Raw,
  JSON,
  XML
}

const PromptOutputFormatter: React.FC<Props> = ({ result, className, fontSize = '0.7em' }: Props) => {
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.Raw);
  const [formattedResult, setFormattedResult] = useState<string>();

  useEffect(() => {
    if (!result) {
      setDisplayMode(DisplayMode.Raw);
      setFormattedResult('');
      return;
    }

    try {
      var parsedResult = JSON.parse(result); // if not JSON, then parse throws and we continue in catch
      setFormattedResult(JSON.stringify(parsedResult, null, 2));
      setDisplayMode(DisplayMode.JSON);
    } catch {
      setFormattedResult(result);
      setDisplayMode(/<\/?[\w\s="/.':;#-\/\?]+>/gi.test(result) ? DisplayMode.XML : DisplayMode.Raw);
    }
  }, [result]);

  return (
    <div className={className}>
      {displayMode === DisplayMode.JSON && (
        <SyntaxHighlighter
          customStyle={{ marginTop: '0.25em', backgroundColor: 'rgb(249 250 251)' }}
          language="json"
          style={docco}
          lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap', fontSize } }}
          wrapLines>
          {formattedResult ?? '{}'}
        </SyntaxHighlighter>
      )}
      {displayMode === DisplayMode.XML && <span className="whitespace-pre-wrap">{formattedResult}</span>}
      {displayMode === DisplayMode.Raw && (
        <span
          className="whitespace-pre-wrap"
          dangerouslySetInnerHTML={{ __html: formattedResult?.replace(/\\n/g, '\n') || '' }}
        />
      )}
    </div>
  );
};

export default PromptOutputFormatter;
