import { formatDistance } from 'date-fns';
import { Experiment } from '../../types';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFlaskVial } from '@fortawesome/free-solid-svg-icons';

/**
 * Props for the ExperimentTile component.
 */
interface Props {
  experiment?: Experiment;
  onClick?: (experiment: Experiment) => void;
}

/**
 * Renders an individual experiment tile component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Experiment} props.app - The experiment object to display.
 * @param {Function} props.onClick - The click event handler for the tile.
 * @param {Function} props.onManageClick - The click event handler for the manage icon.
 * @returns {JSX.Element} The rendered app tile component.
 */
const ExperimentTile: React.FC<Props> = ({ experiment, onClick }: Props) => {
  return (
    <li
      className="colspan-1 rounded-lg border-gray-300 border p-3 shadow-lg hover:border-indigo-600 hover:cursor-pointer col-span-1"
      onClick={() => onClick && onClick(experiment!)}>
      <div className="flex items-center w-full">
        <FontAwesomeIcon icon={faFlaskVial} className="mr-2 w-4 h-4 text-indigo-500 inline" />
        <span className="w-[calc(100%-24px)]">
          {experiment ? experiment.name : <Skeleton containerClassName="inline" />}
        </span>
      </div>
      <h4 className="text-sm text-gray-600 mt-2 h-16 w-full word-break-all line-clamp-3">
        {experiment ? experiment.description : <Skeleton />}
      </h4>
      <div className="flex justify-between mt-4 items-end">
        <div className="text-sm text-gray-600 w-1/2">
          <div className="mb-1">
            {experiment ? (
              !experiment.variantCount ? (
                'No variants yet.'
              ) : (
                `${experiment.variantCount} variant${experiment.variantCount > 1 ? 's' : ''}`
              )
            ) : (
              <Skeleton className="w-10" />
            )}
          </div>
        </div>
        <div className="text-xs text-gray-500 pt-3 flex w-1/2 justify-end">
          {experiment ? (
            <span>
              <FontAwesomeIcon icon={faClock} className="mr-1 w-3 h-3 text-gray-500 inline" />
              <span className="">{formatDistance(experiment.updated, new Date()) + ' ago'}</span>
            </span>
          ) : (
            <span className="w-1/2">
              <Skeleton />
            </span>
          )}
        </div>
      </div>
    </li>
  );
};

export default ExperimentTile;
