import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeFork, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { PromptVersionSelector, StyledDialog } from '../common';
import { Prompt, PromptTypes } from '../../types/Prompt';
import { forkPrompt } from '../../services/Prompts';
import { getErrorMessage } from '../../common/utils';
import toast from 'react-hot-toast';
import { PV } from '../common/PromptVersionSelector';

interface Props {
  prompt: Prompt | undefined;
  isOpen: boolean;
  onSave: (pv: PV) => void;
  onClose: () => void;
}

const ForkPromptModal: React.FC<Props> = ({ prompt, isOpen = false, onSave, onClose }: Props) => {
  const [newName, setNewName] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [pv, setPv] = useState<PV>();

  useEffect(() => {
    if (!prompt) return;

    setPv({ prompt });
    setNewName(`${prompt.name} (fork)`);
  }, [prompt]);

  const handleVersionChange = (pv: PV) => setPv(pv);

  const handleOnCancel = () => {
    if (isSaving) return;
    onClose();
  };

  const handleOnConfirm = async () => {
    if (!newName.length) {
      toast.error('Prompt name is required.');
      return;
    } else if (!pv || !pv.version) {
      toast.error('Please select a version to fork.');
      return;
    }

    setIsSaving(true);

    let createName = pv.prompt?.name !== newName ? newName : `${newName} (fork)`;

    try {
      const forked = await forkPrompt(pv.prompt?.id!, pv.version.version, createName);
      setPv({});
      onSave({ prompt: forked.prompt, version: forked.version });
    } catch (error) {
      toast.error(getErrorMessage(error));
      setIsSaving(false);
      return;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      title="Fork Prompt"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Forking
          </div>
        ) : (
          'Fork'
        )
      }
      disabled={isSaving}
      icon={faCodeFork}
      onClose={handleOnCancel}
      onConfirm={handleOnConfirm}
      isOpen={isOpen}>
      {prompt && (
        <div>
          <div className="my-2">
            <input
              id="promptName"
              name="promptName"
              type="text"
              autoComplete="off"
              value={newName}
              placeholder="Prompt Name"
              onChange={(e) => setNewName(e.target.value)}
              required
              disabled={isSaving}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          {!prompt ? (
            <Skeleton count={2} />
          ) : (
            <PromptVersionSelector
              selected={pv}
              showArchivedButton={false}
              showPrompts={false}
              promptId={prompt.id}
              defaultLabels={['', 'Select Version']}
              onChange={handleVersionChange}
              promptType={PromptTypes.PROMPT}
            />
          )}
          <div className="mt-4 text-sm text-gray-800">
            Forking a prompt will make a copy of the prompt and the selected version.
            <ul className="list-disc ml-4 mt-4 text-xs text-gray-600 ">
              <li>The fork will be owned by you.</li>
              <li>Other versions will not be copied.</li>
              <li>Metrics will not be copied.</li>
              <li>Existing users will not have access.</li>
            </ul>
          </div>
        </div>
      )}
    </StyledDialog>
  );
};

export default ForkPromptModal;
