import Handlebars from 'handlebars';

/**
 * Represents the details of a helper.
 * @interface HelperDetails
 */
interface HelperDetails {
  [key: string]: {
    contextParam?: number;
    transmogrify?: (path: any[]) => any[];
    optional?: boolean;
  };
}

/**
 * Represents the options for extracting values.
 */
interface ExtractOptions {
  [key: string]: any;
}

/**
 * Callback type for emitting segments.
 *
 * @param segs - The segments to emit.
 * @param optional - Indicates if the segments are optional.
 */
type EmitCallback = (segs: any[], optional: boolean) => void;

/**
 * Extracts information from a Handlebars template.
 *
 * @param template - The Handlebars template string.
 * @param callback - The callback function to receive the extracted information.
 * @param opts - Optional configuration options for the extraction process.
 */
const extract = (template: string, callback: EmitCallback, opts: ExtractOptions = {}): void => {
  const emit = (segs: any[], optional: boolean): void => {
    callback(segs.flat(), optional);
  };

  const helperDetails: HelperDetails = {
    ...{
      each: {
        contextParam: 0,
        transmogrify: (path: any[]): any[] => {
          const clone = path.slice(0);
          clone.push('#');
          return clone;
        }
      },
      with: {
        contextParam: 0
      },
      if: {
        optional: true
      }
    },
    ...opts
  };

  let parsed: any;
  try {
    parsed = Handlebars.parse(template);
  } catch (e) {
    return;
  }

  /**
   * Extends the given path with the provided subpath.
   *
   * @param path - The original path.
   * @param subpath - The subpath to extend with.
   * @returns The extended path.
   */
  const extend = (path: any[], subpath: any): any[] => {
    if (subpath.original && subpath.original.startsWith('@root')) {
      const clone = [...subpath.parts];
      return [clone.slice(1)];
    } else if (subpath.original && subpath.original.startsWith('@')) {
      return [];
    } else if (subpath.original && subpath.original.startsWith('../')) {
      const clone = path[path.length - 1] === '#' ? path.slice(0, -2) : path.slice(0, -1);
      clone.push(subpath.parts);
      return clone;
    } else {
      const clone = [...path];
      clone.push(subpath.parts);
      return clone;
    }
  };

  /**
   * Visits each node in the AST and performs a specific action based on the node's type.
   *
   * @param emit - The callback function to execute for each visited node.
   * @param path - The current path in the AST.
   * @param node - The current node being visited.
   * @param optional - Indicates whether the node is optional or not.
   */
  const visit = (emit: EmitCallback, path: any[], node: any, optional: boolean = false): void => {
    let helper: any;
    try {
      helper = helperDetails[node.path.original];
    } catch (e) {}

    switch (node.type) {
      case 'Program':
        node.body.forEach((child: any) => visit(emit, path, child, optional));
        break;

      case 'BlockStatement':
        let newPath = path;
        node.params.forEach((child: any) => visit(emit, path, child, optional || helper?.optional));
        if (helper?.contextParam !== undefined) {
          const replace = (p: any[]) => {
            newPath = p;
          };
          visit(replace, path, node.params[helper.contextParam]);
          if (helper?.transmogrify) {
            newPath = helper.transmogrify(newPath);
          }
        }
        visit(emit, newPath, node.program, optional || helper?.optional);
        break;

      case 'PathExpression':
        emit(extend(path, node), optional);
        break;

      case 'MustacheStatement':
        if (node.params.length === 0) {
          visit(emit, path, node.path, optional);
        } else {
          node.params.forEach((child: any) => visit(emit, path, child, optional || helper?.optional));
        }
        break;
    }
  };

  visit(emit, [], parsed);
};

/**
 * Extracts the schema from a template.
 *
 * @param template - The template string.
 * @param opts - Optional extraction options.
 * @returns The extracted schema.
 */
export const extractSchema = (template: string, opts: ExtractOptions = {}): any => {
  const obj: any = {};
  const callback = (path: any[], optional: boolean): void => {
    const augment = (obj: any, path: any[]): any => {
      obj._optional = obj.hasOwnProperty('_optional') ? optional && obj._optional : optional;
      if (!(path.length === 0 || (path.length === 1 && path[0] === 'length'))) {
        obj._type = 'object';
        const segment = path[0];
        if (segment === '#') obj._type = 'array';
        obj[segment] = obj[segment] || {};
        augment(obj[segment], path.slice(1));
      } else {
        obj._type = 'any';
      }
      return obj;
    };
    augment(obj, path);
  };

  extract(template, callback, opts);
  delete obj._optional;

  return obj;
};

/**
 * Extracts data structure from a template.
 *
 * @param template - The template string.
 * @param opts - Optional extraction options.
 * @returns The extracted data structure.
 */
export const extractDataStructure = (template: string, opts: ExtractOptions = {}): any => {
  const schema = extractSchema(template, opts);
  const result: any = extractSubDataStructure(schema);
  return result;
};

/**
 * Extracts sub-data structure for an object.
 *
 * @param schema - The current object to extract properties from.
 * @returns The extracted data structure.
 */
export const extractSubDataStructure = (schema: any): any => {
  switch (schema?.hasOwnProperty('_type') ? schema['_type'] : 'any') {
    case 'object':
      const result: any = {};
      for (const [subKey, sv] of Object.entries(schema)) {
        const subValue = sv as any;
        if (!subKey.startsWith('_')) {
          result[subKey] = extractSubDataStructure(subValue);
        }
      }
      return result;
    case 'array':
      const resultArray: any = [];
      for (const [subKey, sv] of Object.entries(schema)) {
        const subValue = sv as any;
        if (!subKey.startsWith('_')) {
          resultArray.push(extractSubDataStructure(subValue));
        }
      }
      return resultArray;
    case 'any':
    default:
      return '';
  }
};

export const exportedForTesting = {
  extract
};
