import React, { useState } from 'react';
import { Prompts } from '../prompts';
import { SideBar } from '../../components';
import { PromptTypes } from '../../types/Prompt';
import PipelineList from './PipelineList';
import { WebhookList } from '../webhooks';

const enum SideNav {
  PIPELINES = 0,
  EVALUATORS = 1,
  WEBHOOKS = 2
}

interface Props {}

/**
 * Pipelines page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const Pipelines: React.FC<Props> = ({}: Props) => {
  const [selectedPage, setSelectedPage] = useState<number>(0);

  return (
    <div className="-m-5">
      <SideBar
        rows={['Pipelines', 'Evaluator Prompts', 'Webhooks']}
        selectedIndex={selectedPage}
        onClick={(index) => setSelectedPage(index)}>
        <div>
          {selectedPage === SideNav.PIPELINES && <PipelineList />}
          {selectedPage === SideNav.EVALUATORS && <Prompts type={PromptTypes.EVALUATOR} />}
          {selectedPage === SideNav.WEBHOOKS && <WebhookList />}
        </div>
      </SideBar>
    </div>
  );
};

export default Pipelines;
