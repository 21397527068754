import { useEffect, useState } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import '../../styles/editor.css';
import { ToolActionTypes, PlaygroundMessage } from '../../types/Prompt';

import './PromptEditor.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faFont, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getMessageContentString } from '../../common/prompts';

/**
 * Props for the PromptEditorTool component.
 */
interface Props {
  message?: PlaygroundMessage;
  toolActionType?: ToolActionTypes;
  disabled: boolean;
  className?: string;
  onMessageChange: (value: PlaygroundMessage) => void;
  onMessageDelete: () => void;
}

/**
 * Represents a component for editing a prompt message.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {PlaygroundMessage} props.message - The tool message.
 * @param {ToolActionTypes} props.toolActionType - The tool action type.
 * @param {boolean} props.disabled - Indicates if the component is disabled.
 * @param {string} props.className - The component class name.
 * @param {Function} props.onMessageChange - The callback function called when the message changes.
 * @param {Function} props.onMessageDelete - The callback function called when the message is deleted.
 * @returns {JSX.Element} The JSX element representing the prompt editor message tool component.
 */
const PromptEditorTool: React.FC<Props> = ({
  message,
  toolActionType,
  disabled,
  className,
  onMessageChange,
  onMessageDelete
}: Props) => {
  const [_message, setMessage] = useState<PlaygroundMessage>();
  const [isShowJson, setIsShowJson] = useState<boolean>(false);

  useEffect(() => {
    if (!message) return;

    setMessage(message);
  }, [message]);

  const handleToolUseChange = (content: string) => {
    const newMessage = JSON.parse(content);
    setMessage(newMessage);
    onMessageChange(newMessage);
  };

  const handleToolResultChange = (content: string) => {
    const newMessage = { ..._message!, content };
    setMessage(newMessage);
    onMessageChange(newMessage);
  };

  const handleToolResultChangeAdvanced = (content: string) => {
    const newMessage = JSON.parse(content);
    setMessage(newMessage);
    onMessageChange(newMessage);
  };

  const handleDelete = () => {
    onMessageDelete();
  };

  return (
    <div className={`mb-4 border border-gray-200 rounded-md promptMessage ${className}`}>
      <div className="border-b border-gray-300 flex text-gray-700 p-3 pr-0.5">
        <div className="text-md">
          {toolActionType === ToolActionTypes.TOOL_USE ? 'Tool Use Request' : 'Tool Response'}
        </div>
        <div className="flex-1 text-right content-center mr-2">
          <FontAwesomeIcon
            icon={!isShowJson ? faCode : faFont}
            className="cursor-pointer mr-3"
            onClick={() => setIsShowJson(!isShowJson)}
          />
          <FontAwesomeIcon
            icon={faTrash}
            className="text-red-400 cursor-pointer hover:text-red-500"
            onClick={handleDelete}
          />
        </div>
      </div>

      <div className="w-full flex-grow" data-color-mode="light">
        {isShowJson && (
          <CodeEditor
            value={getMessageContentString(_message)}
            placeholder=""
            onChange={(e) =>
              toolActionType === ToolActionTypes.TOOL_USE
                ? handleToolUseChange(e.target.value)
                : handleToolResultChangeAdvanced(e.target.value)
            }
            padding={10}
            disabled={disabled}
            language="json"
            className="h-full w-full min-h-[125px] resize-none border-none text-md focus:ring-0 prompt-editor"
            style={{
              backgroundColor: '#fff'
            }}
          />
        )}

        {!isShowJson &&
          (toolActionType === ToolActionTypes.TOOL_USE ? (
            <div className="text-sm flex gap-x-6 p-2">
              <div>
                <div className="font-semibold">Name</div>
                <div className="">{_message?.name}</div>
              </div>
              <div className="flex-1">
                <div className="font-semibold">Inputs</div>
                {_message?.input &&
                  Object.entries(_message?.input).map(([k, v]) => (
                    <div key={k} className="flex">
                      <div className="flex-none text-gray-600 mr-2">{k}:</div>
                      <div>{v}</div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <CodeEditor
              value={_message?.content}
              placeholder="Add tool response here"
              onChange={(e) => handleToolResultChange(e.target.value)}
              padding={10}
              disabled={disabled}
              language="text"
              className="h-full w-full min-h-[125px] resize-none border-none text-md focus:ring-0 prompt-editor"
              style={{
                backgroundColor: '#fff'
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default PromptEditorTool;
