import { useEffect, useState } from 'react';
import { csvToJson, getErrorMessage } from '../../common/utils';
import StyledDialog from '../common/StyledDialog';
import { faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { PromptPreviewFormatter, UploadDropzone } from '../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addRunClone,
  addRunFromDefaultData,
  addRunFromFile,
  getPipelinesData
} from '../../services/PipelineEvaluations';
import Selector, { SelectorValue } from '../common/Selector';
import { PipelineHelpRunData } from '.';
import PromptVersionSelector, { PV } from '../common/PromptVersionSelector';
import { PromptVersionTypes } from '../../types/Prompt';
import { Pipeline, PipelineRun, PipelineRunDatasource } from '../../types/PipelineEvaluations';

interface Props {
  pipeline: Pipeline;
  isOpen: boolean;
  onClose: () => void;
  onSave: (pipeline: PipelineRun) => void;
}

const DATASOURCES_SELECTORS = [
  { label: 'New dataset', value: PipelineRunDatasource.FILE },
  { label: 'Default dataset', value: PipelineRunDatasource.DEFAULT },
  { label: 'Previous run dataset', value: PipelineRunDatasource.PREVIOUS }
];

const uploadInstructions = (
  <p className="mb-2 text-sm text-gray-500 ">
    Click to upload your <span className="font-semibold">data file</span> or drag and drop.
  </p>
);

const AddRunModal: React.FC<Props> = ({ pipeline, isOpen, onClose, onSave }: Props) => {
  const [name, setName] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [selectedPreviousData, setSelectedPreviousData] = useState<SelectorValue>();
  const [previousDataSelectors, setPreviousDataSelectors] = useState<SelectorValue[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [promptVersion, setPromptVersion] = useState<PV | undefined>({ prompt: pipeline.prompt, version: undefined });
  const [sampleData, setSampleData] = useState<any>();
  const [selectedDatasource, setSelectedDatasource] = useState<SelectorValue>(
    DATASOURCES_SELECTORS[PipelineRunDatasource.FILE]
  );

  useEffect(() => {
    if (!isOpen) return;

    (async () => {
      setIsLoading(true);
      try {
        const data = await getPipelinesData(pipeline.id);
        const sels = data.items.map((d) => ({ label: `${d.runName}: ${d.file}`, value: d.runId }));
        setPreviousDataSelectors(sels);
        setSelectedPreviousData(data.items.length ? sels[0] : undefined);
      } catch (error) {
        const err = getErrorMessage(error);
        console.error(error);
        toast.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [isOpen]);

  useEffect(() => {
    (async () => {
      if (!files.length) return;

      try {
        const file = files[0];
        const fileContents = await file.text();
        if (file.type === 'application/json') {
          const content = JSON.parse(fileContents);
          if (!Array.isArray(content)) {
            setFiles([]);
            return toast.error('JSON file must be an array.');
          }

          setSampleData(content[0]);
        } else if (file.type === 'text/csv') {
          const content = csvToJson(fileContents);
          setSampleData(content[0]);
        } else {
          toast.error(`File type "${file.type}" not supported.`);
        }
      } catch (error) {
        setFiles([]);
        return toast.error('File could not be read.');
      }
    })();
  }, [files]);

  const reset = () => {
    setName('');
    setPreviousDataSelectors([]);
    setSelectedPreviousData(undefined);
    setSelectedDatasource(DATASOURCES_SELECTORS[PipelineRunDatasource.FILE]);
    setFiles([]);
    setPromptVersion({ prompt: pipeline.prompt, version: undefined });
    setSampleData(undefined);
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const handleDatasourceChange = ({ value }: SelectorValue) => {
    if (value === PipelineRunDatasource.PREVIOUS && !previousDataSelectors.length) {
      setSelectedDatasource(DATASOURCES_SELECTORS[PipelineRunDatasource.FILE]);
      return toast.error('No previous data available.');
    } else if (value === PipelineRunDatasource.DEFAULT && !pipeline.defaultDataS3Path) {
      setSelectedDatasource(DATASOURCES_SELECTORS[PipelineRunDatasource.FILE]);
      return toast.error('No default data available.');
    }

    setSelectedDatasource(DATASOURCES_SELECTORS[value as PipelineRunDatasource]);
  };

  const handleOnCreate = async () => {
    if (!promptVersion?.version) {
      return toast.error('Please select a version.');
    } else if (!name.length) {
      return toast.error('Please enter a valid name.');
    } else if (selectedDatasource.value === PipelineRunDatasource.FILE && !files.length) {
      return toast.error('Please upload a file.');
    } else if (selectedDatasource.value === PipelineRunDatasource.PREVIOUS && !selectedPreviousData) {
      return toast.error('Please select a previous dataset.');
    }

    setIsSaving(true);
    let pipelineRun: PipelineRun;

    try {
      if (selectedDatasource.value === PipelineRunDatasource.FILE) {
        pipelineRun = await addRunFromFile(
          pipeline.id,
          pipeline.promptId,
          promptVersion.version.version,
          name,
          files?.[0]
        );
      } else if (selectedDatasource.value === PipelineRunDatasource.DEFAULT) {
        pipelineRun = await addRunFromDefaultData(pipeline.id, pipeline.promptId, promptVersion.version.version, name);
      } else {
        pipelineRun = await addRunClone(
          pipeline.id,
          pipeline.promptId,
          promptVersion.version.version,
          name,
          selectedPreviousData?.value as string
        );
      }

      toast.success('Pipeline Run Added');
      onSave(pipelineRun);
      reset();
    } catch (error) {
      const err = getErrorMessage(error);
      console.error(error);
      toast.error(err);
      return;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title="New Run"
      closeText="Cancel"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : (
          'Add'
        )
      }
      disabled={isSaving || isLoading}
      onConfirm={handleOnCreate}
      onClose={handleOnClose}
      width="w-1/2"
      icon={faPlusCircle}>
      <div>
        <div className="text-sm text-gray-600">You can use a dataset from previous runs or upload a new one.</div>
        <div className="mt-2 flex">
          <div className="flex-1">
            <input
              id="runName"
              name="runName"
              type="text"
              autoComplete="off"
              value={name}
              placeholder="Run Name"
              onChange={(e) => setName(e.currentTarget.value)}
              required
              disabled={isSaving}
              className="block w-80 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="text-right">
            <PipelineHelpRunData />
          </div>
        </div>
        <div className="mt-2">
          <PromptVersionSelector
            disabled={isSaving}
            showPrompts={false}
            selected={promptVersion}
            showArchivedButton={false}
            onChange={setPromptVersion}
            showPreviewButton={true}
          />
        </div>
        <div className="mt-2">
          <Selector
            classNames="w-80 my-2"
            values={DATASOURCES_SELECTORS}
            defaultValue={selectedDatasource}
            disabled={isLoading || isSaving}
            isSearchable={false}
            onChange={handleDatasourceChange}
          />
        </div>
        {selectedDatasource.value === PipelineRunDatasource.PREVIOUS && (
          <div className="mt-2">
            <Selector
              values={previousDataSelectors}
              disabled={previousDataSelectors.length < 1 || isSaving}
              classNames="w-80"
              onChange={setSelectedPreviousData}
            />
          </div>
        )}
        {selectedDatasource.value === PipelineRunDatasource.FILE && (
          <div>
            <UploadDropzone
              disabled={isSaving}
              accept={[
                { name: 'JSON', mimetype: 'application/json' },
                { name: 'CSV', mimetype: 'text/csv' }
              ]}
              instructions={uploadInstructions}
              className="mt-4"
              onFilesChange={setFiles}
            />
            {promptVersion && promptVersion.version?.type !== PromptVersionTypes.MESSAGING && sampleData && (
              <div className="mt-4 border-t border-gray-200 pt-4">
                <label className="text-gray-500 font-semibold text-sm">Sample Generated Prompt</label>
                <blockquote className="px-4 my-2 border-s-4 border-gray-300 bg-gray-50 text-sm p-1">
                  <PromptPreviewFormatter template={promptVersion?.version?.template!} payload={sampleData} />
                </blockquote>
              </div>
            )}
          </div>
        )}
      </div>
    </StyledDialog>
  );
};

export default AddRunModal;
