import { faCopy, faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faKey, faPassport, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage, validateUserPassword } from '../common/utils';
import { StyledDialog } from '../components';
import { changePassword, getApiKey, getUserLocal, logout, rotateApiKey } from '../services/User';
import version from '../version.json';

/**
 * Account page component props.
 */
interface Props {}

/**
 * User account page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const Account: React.FC<Props> = ({}: Props) => {
  const navigate = useNavigate();

  const [user] = useState<any>(getUserLocal());
  const [apiKey, setApiKey] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isRotating, setIsRotating] = useState<boolean>(false);
  const [isKeyVisable, setIsKeyVisable] = useState<boolean>(false);
  const [isResetOpen, setIsResetOpen] = useState<boolean>(false);
  const [isRotateOpen, setIsRotateOpen] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');

  useEffect(() => {
    (async () => {
      setApiKey(await getApiKey());
    })();
  });

  const resetPasswordFields = () => {
    setCurrentPassword('');
    setNewPassword('');
    setNewPasswordConfirm('');
  };

  useEffect(resetPasswordFields, [isResetOpen]);

  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword || !newPasswordConfirm) {
      toast.error('Please fill all fields');
      return;
    } else if (newPassword !== newPasswordConfirm) {
      toast.error('Passwords do not match');
      return;
    } else if (newPassword === currentPassword) {
      toast.error('New password cannot be the same as the current password');
      return;
    }

    const passwordErrorMessage = validateUserPassword(newPassword);
    if (passwordErrorMessage) {
      toast.error(passwordErrorMessage);
      return;
    }

    setIsSubmitting(true);

    try {
      await changePassword(currentPassword, newPassword);

      toast.success('Password changed successfully');
      setIsResetOpen(false);
      resetPasswordFields();
      logout();
      navigate('/login');
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLogout = () => {
    toast.success('Logged out successfully');

    logout();
    navigate('/login');
  };

  const handleApiKeyRotation = async () => {
    setIsRotating(true);

    try {
      await rotateApiKey();
      setApiKey(await getApiKey());
      setIsRotateOpen(false);
      toast.success('API key rotated');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsRotating(false);
    }
  };

  return (
    <>
      <div className="mx-auto">
        <div className="flex">
          <div className="flex-1">
            <h1 className="text-2xl text-gray-800">My Account</h1>
            <h2 className="mt-2 mb-6 text-lg text-gray-700">Here you can manage your account.</h2>
          </div>
          <div>
            <a href="#" onClick={handleLogout}>
              <button className="standard">Logout</button>
            </a>
          </div>
        </div>
        <h3 className="mt-2 mb-4 text-lg text-gray-700 font-semibold">My Info</h3>
        <div className="flex flex-col space-y-2 border-b border-gray-300 pb-6 mb-8 text-sm">
          <div className="flex flex-row space-x-2">
            <div className="text-gray-500">Name:</div>
            <div>{user?.name}</div>
          </div>
          <div className="flex flex-row space-x-2">
            <div className="text-gray-500">Email:</div>
            <div>{user?.email}</div>
          </div>
          <div className="flex flex-row mt-8">
            <button className="standard small" onClick={() => setIsResetOpen(true)}>
              Change Password
            </button>
          </div>
        </div>
        <h3 className="mt-2 mb-4 text-lg text-gray-700 font-semibold">My API Key</h3>
        <div className="border-b border-gray-300 pb-6 text-sm">
          {apiKey === '' && <Skeleton className="max-w-32 inline" containerClassName="inline" />}
          {apiKey.length > 0 && (
            <div>
              {!isKeyVisable ? `*****${apiKey?.slice(-8)}` : apiKey}
              <CopyToClipboard text={apiKey} onCopy={() => toast.success('API Key Copied!')}>
                <FontAwesomeIcon icon={faCopy} className="ml-2 w-4 h-4 cursor-pointer hover:text-indigo-500" />
              </CopyToClipboard>
              <FontAwesomeIcon
                icon={isKeyVisable ? faEyeSlash : faEye}
                onClick={() => setIsKeyVisable(!isKeyVisable)}
                className="ml-2 cursor:pointer w-4 h-4 cursor-pointer hover:text-indigo-500"
              />
            </div>
          )}
          <div className="flex flex-row mt-8">
            <button className="standard small" onClick={() => setIsRotateOpen(true)}>
              Rotate API Key
            </button>
          </div>
        </div>

        <div className="mt-8 text-sm text-gray-700 text-right">
          Promptly Version <span className="font-semibold">{version.version}</span>
        </div>
      </div>

      <StyledDialog
        title="Change Password"
        icon={faPassport}
        isOpen={isResetOpen}
        confirmText={isSubmitting ? <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> : 'Save'}
        onClose={() => !isSubmitting && setIsResetOpen(false)}
        onConfirm={handleChangePassword}>
        <div className="w-80 mt-4">
          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
              Current Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.currentTarget.value)}
                required
                disabled={isSubmitting}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-gray-900">
              New Password
            </label>
            <div className="mt-1">
              <input
                id="newPassword"
                name="newPassword"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.currentTarget.value)}
                required
                disabled={isSubmitting}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="newPasswordConfirm" className="block text-sm font-medium leading-6 text-gray-900">
              Confirm New Password
            </label>
            <div className="mt-1">
              <input
                id="newPasswordConfirm"
                name="newPasswordConfirm"
                type="password"
                value={newPasswordConfirm}
                onChange={(e) => setNewPasswordConfirm(e.currentTarget.value)}
                required
                disabled={isSubmitting}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
      </StyledDialog>
      <StyledDialog
        title="Change Password"
        icon={faKey}
        isOpen={isRotateOpen}
        confirmText={isRotating ? <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> : 'Save'}
        onClose={() => !isRotating && setIsRotateOpen(false)}
        onConfirm={handleApiKeyRotation}>
        Are you sure you want to rotate your API key? Your current API key will no longer work.
      </StyledDialog>
    </>
  );
};

export default Account;
