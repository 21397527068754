import { axiosInstance } from '../api';
import {
  apiToForkedPromptVersionType,
  apiToPipelineType,
  apiToPromptType,
  apiToPromptUsers
} from '../common/typeUtils';
import { apiExceptionHandler } from '../common/utils';
import { Pipeline, Prompt } from '../types';
import { PromptAndVersion, PromptRoles, PromptTypes } from '../types/Prompt';

const BASE_PATH = 'prompts';

/**
 * Retrieves a all prompts for a user.
 * @param type - The type of the prompt. Default is 'PROMPT'.
 * @returns A promise that resolves to an array of prompts.
 * @throws Throws an error if an error occurs while loading the prompts.
 */
export const getPrompts = async (type: PromptTypes = PromptTypes.PROMPT, all: boolean = false): Promise<Prompt[]> => {
  try {
    const path = !all ? `${BASE_PATH}/` : 'admin/prompts';
    const response = await axiosInstance.get(path, { params: { type } });

    return response.data.map((apiPrompt: any) => apiToPromptType(apiPrompt));
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while loading your prompts.');
  }
};

/**
 * Retrieves a prompt by its ID.
 * @param id - The ID of the prompt.
 * @returns A promise that resolves to the requested prompt.
 * @throws Throws an error if an error occurs while loading the prompt.
 */
export const getPrompt = async (id: string): Promise<Prompt> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${id}`);
    return apiToPromptType(response.data);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while loading your prompt.');
  }
};

/**
 * Creates a new prompt for a given prompt.
 * @param prompt - The prompt to create.
 * @returns A promise that resolves to the created prompt.
 * @throws Throws an error if an error occurs while creating the prompt.
 */
export const createPrompt = async (prompt: Prompt): Promise<Prompt> => {
  try {
    const response = await axiosInstance.post(`${BASE_PATH}/`, {
      name: prompt.name,
      description: prompt.description,
      tags: prompt.tags,
      type: prompt.type
    });

    return apiToPromptType(response.data);
  } catch (error) {
    let ex = apiExceptionHandler('An error occurred creating your prompt.').message;

    if (ex.includes('already exists')) {
      ex = 'A prompt with this name already exists. Please choose a different name.';
    }

    throw new Error(ex);
  }
};

/**
 * Updates an existing prompt.
 * @param promptId - The ID of the prompt to update.
 * @param name - The new name of the prompt.
 * @param description - The new description of the prompt.
 * @param tags - The new array of tags associated with the prompt.
 * @param archived - Indicates whether the prompt is archived.
 * @param type - The type of the prompt.
 * @returns A promise that resolves when the prompt is successfully updated.
 * @throws Throws an error if an error occurs while updating the prompt.
 */
export const updatePrompt = async (
  promptId: string,
  name: string,
  description: string,
  tags: string[] = [],
  archived: boolean = false,
  type: PromptTypes,
  defaultPipelineId: string | undefined
): Promise<void> => {
  try {
    await axiosInstance.put(`${BASE_PATH}/${promptId}`, {
      name,
      description,
      tags,
      archived,
      type,
      default_pipeline_id: defaultPipelineId
    });
  } catch (error) {
    let ex = apiExceptionHandler('An error occurred updating your prompt.').message;

    if (ex.includes('already exists')) {
      ex = 'A prompt with this name already exists. Please choose a different name.';
    }

    throw new Error(ex);
  }
};

/**
 * Deletes a prompt with the specified ID.
 * @param promptId The ID of the prompt to delete.
 * @throws Throws an error if an error occurs while deleting the prompt.
 */
export const deletePrompt = async (promptId: string): Promise<void> => {
  try {
    await axiosInstance.delete(`${BASE_PATH}/${promptId}`);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while deleting the prompt');
  }
};

/**
 * Manages a user's role in an prompt.
 * @param promptId - The ID of the prompt.
 * @param userId - The ID of the user.
 * @param action - The action to perform ('ADD' or 'SET_ROLE').
 * @param role - The role to assign to the user.
 * @returns A promise that resolves to void.
 */
export const managePromptUser = async (
  promptId: string,
  userId: string,
  action: 'ADD' | 'SET_ROLE',
  role: PromptRoles
): Promise<void> => {
  try {
    await axiosInstance.put(`${BASE_PATH}/${promptId}/users/manage`, {
      prompt_id: promptId,
      user_id: userId,
      action,
      prompt_role: role
    });
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while managing the user.');
  }
};

/**
 * Removes a user from an prompt.
 * @param promptId - The ID of the prompt.
 * @param userId - The ID of the user.
 * @returns A promise that resolves to void.
 */
export const removePromptUser = async (promptId: string, userId: string): Promise<void> => {
  try {
    await axiosInstance.put(`${BASE_PATH}/${promptId}/users/manage`, {
      prompt_id: promptId,
      user_id: userId,
      action: 'REMOVE'
    });
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while removing the user.');
  }
};

/**
 * Retrieves the users associated with a specific prompt.
 * @param promptId - The ID of the prompt.
 * @returns A Promise that resolves to an array of PromptUser objects.
 * @throws Throws an error if there was an issue retrieving the prompt users.
 */
export const getPromptUsers = async (promptId: string): Promise<any[]> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${promptId}/users`);
    return apiToPromptUsers(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while loading the prompt users.');
  }
};

/**
 * Forks a prompt by creating a new version with the specified name.
 * @param promptId - The ID of the prompt to fork.
 * @param versionNumber - The version number of the prompt to fork.
 * @param name - The name of the new forked prompt version.
 * @returns A Promise that resolves to the forked prompt version.
 * @throws Throws an error if an error occurs while forking the prompt.
 */
export const forkPrompt = async (promptId: string, versionNumber: number, name: string): Promise<PromptAndVersion> => {
  try {
    const response = await axiosInstance.post(`${BASE_PATH}/${promptId}/${versionNumber}/fork`, {
      version: versionNumber,
      name
    });

    return apiToForkedPromptVersionType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while forking the prompt.');
  }
};

/**
 * Fetches pipelines associated with a given prompt ID.
 *
 * @param promptId - The ID of the prompt for which to fetch pipelines.
 * @returns A promise that resolves to an array of Pipeline objects.
 * @throws Will throw an error if the request fails.
 */
export const getPromptPipelines = async (promptId: string): Promise<Pipeline[]> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${promptId}/pipelines`);
    return response.data.map(apiToPipelineType);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching pipelines.');
  }
};
