import { axiosInstance } from '../api';
import { modelParameterArrayToObject } from '../common/models';
import { apiToPromptVersionType, transformKeysToApi } from '../common/typeUtils';
import { apiExceptionHandler } from '../common/utils';
import { PromptVersion } from '../types';
import { PromptVersionTypes } from '../types/Prompt';

/**
 * Retrieves the versions of a prompt.
 * @param promptId - The ID of the prompt.
 * @returns A promise that resolves to an array of PromptVersion objects.
 * @throws Throws an error if an error occurs while loading the prompt versions.
 */
export const getVersions = async (promptId: string): Promise<PromptVersion[]> => {
  try {
    const response = await axiosInstance.get(`/prompts/${promptId}/versions`);
    return response.data.map((apiVersion: any) => apiToPromptVersionType(apiVersion));
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while loading your prompt versions.');
  }
};

/**
 * Retrieves a specific version of a prompt.
 * @param promptId - The ID of the prompt.
 * @param version - The version number of the prompt.
 * @returns A Promise that resolves to the requested PromptVersion.
 * @throws Throws an error if an error occurs while loading the prompt version.
 */
export const getVersion = async (promptId: string, version: number): Promise<PromptVersion> => {
  try {
    const response = await axiosInstance.get(`/prompts/${promptId}/v/${version}`);
    return apiToPromptVersionType(response.data);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while loading your prompt version.');
  }
};

/**
 * Converts a PromptVersion object to a payload object for API calls.
 *
 * @param version - The PromptVersion object to convert.
 * @returns The payload object.
 * @throws Error if template or message template is missing.
 */
const promptVersionToPayload = (version: PromptVersion): Record<string, any> => {
  if (!version.template && !version.messageTemplate) {
    throw new Error('Template or messages template is required.');
  }

  const payload: Record<string, any> = {
    version: version.version,
    model: version.model,
    parameters: modelParameterArrayToObject(version.parameters),
    change_log: version.changeLog,
    type: version.type,
    custom_props: version.customProps,
    helpers: version.helpers
  };

  if (version.type === PromptVersionTypes.MESSAGING) {
    payload.message_template = {
      system_prompt: version.messageTemplate?.systemPrompt,
      messages: version.messageTemplate?.messages
    };
    if (version.tools) {
      payload.tools = version.tools.map((t) => transformKeysToApi(t));
    }
  } else {
    payload.template = version.template;
  }

  if (version.samplePayload) {
    payload.sample_payload = version.samplePayload;
  }

  return payload;
};

/**
 * Creates a new version of a prompt.
 * @param version - The PromptVersion object to create.
 * @returns A promise that resolves to the created PromptVersion object.
 * @throws Throws an error if an error occurs while creating the prompt version.
 */
export const createVersion = async (version: PromptVersion): Promise<PromptVersion> => {
  if (!version.template && !version.messageTemplate) {
    throw new Error('Template or messages template is required.');
  }

  try {
    const response = await axiosInstance.post(`prompts/${version.promptId}/versions`, promptVersionToPayload(version));

    return apiToPromptVersionType(response.data);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred creating your prompt version.');
  }
};

/**
 * Updates the environments of a prompt version.
 * @param version - The PromptVersion object to update.
 * @param environment - The new environments.
 * @returns A promise that resolves to the updated PromptVersion object.
 * @throws Throws an error if an error occurs while updating the prompt environments.
 */
export const updateEnvironment = async (version: PromptVersion, environments: string[]): Promise<PromptVersion> => {
  try {
    const payload = promptVersionToPayload(version);
    payload.environments = environments || [];

    await axiosInstance.put(`prompts/${version.promptId}/v/${version.version}`, payload);

    return { ...version, environments };
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred updating prompt environments.');
  }
};
