import { faCircleExclamation, faCircleMinus, faExclamationTriangle, faVials } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { getErrorMessage, toTitleCase } from '../../common/utils';
import { deleteVariant, getVariants } from '../../services/Experimentation';
import { Experiment, Variant } from '../../types';
import { StyledDialog } from '../common';
import DataTable, { TableData } from '../common/Datatable';
import ManageVariantModal from './ManageVariantModal';

interface Props {
  experiment?: Experiment;
  canEdit: boolean;
}

const VariantTab: React.FC<Props> = ({ experiment, canEdit }: Props) => {
  const [variants, setVariants] = useState<Variant[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isManageVariantModalOpen, setIsManageVariantModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [variantTableData, setVariantTableData] = useState<TableData>();
  const [selectedVariant, setSelectedVariant] = useState<Variant>();
  const [totalPercentage, setTotalPercentage] = useState<number>(0);
  const [variantToDelete, setVariantToDelete] = useState<Variant>();

  useEffect(() => {
    (async () => {
      if (!experiment) return;

      try {
        setVariants(await getVariants(experiment.id!));
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [experiment]);

  useEffect(() => {
    const rows: any = [];
    variants.forEach((variant) => {
      rows.push({
        cols: [
          { value: variant.name },
          { value: <div className="w-96 text-ellipsis overflow-hidden">{variant.description}</div> },
          { value: `${variant.percentage}%` },
          { value: variant.promptVersionNumber },
          { value: toTitleCase(variant.type) },
          {
            value: !canEdit ? (
              ''
            ) : (
              <FontAwesomeIcon
                icon={faCircleMinus}
                className="h-4 w-4 text-red-500 hover:text-red-400 hover:cursor-pointer transition ease-in-out hover:scale-125 duration-200"
                onClick={(e) => {
                  e.stopPropagation();
                  setVariantToDelete(variant);
                  setIsDeleteModalOpen(true);
                }}
              />
            )
          }
        ],
        onClick: () => {
          setSelectedVariant(variant);
          setIsManageVariantModalOpen(true);
        }
      });
    });

    setVariantTableData({
      headers: ['Name', 'Description', 'Total Traffic', 'Version', 'Type', ''],
      rows
    });

    setTotalPercentage(variants.reduce((a, v) => a + v.percentage, 0));
  }, [variants, canEdit]);

  const onVariantSave = (variant: Variant) => {
    toast.success('Variant saved');
    setIsManageVariantModalOpen(false);
    setVariants([variant, ...variants.filter((v) => v.id !== variant.id)]);
  };

  const handleVariantDelete = async () => {
    const id = toast.loading('Deleting variant');
    setIsDeleteModalOpen(false);
    try {
      await deleteVariant(variantToDelete!.experimentId, variantToDelete!.id!);
      setVariants(variants.filter((v) => v.id !== variantToDelete!.id));
      toast.success('Variant deleted', { id });
    } catch (error) {
      toast.error(getErrorMessage(error), { id });
    }
  };

  return (
    <>
      {isLoading && <Skeleton count={5} />}
      {!isLoading && variants.length === 0 && (
        <>
          <div className="mt-2 text-indigo-500 mx-auto text-center">
            <FontAwesomeIcon icon={faVials} className="w-40 h-40" />
          </div>
          <div className="mt-5 text-gray-700 mx-auto text-center">
            It looks like you haven&lsquo;t added any variants yet.{' '}
            {canEdit && (
              <a role="button" onClick={() => setIsManageVariantModalOpen(true)}>
                Click here to get started!
              </a>
            )}
          </div>
        </>
      )}
      {!isLoading && variants.length > 0 && (
        <>
          <DataTable data={variantTableData} divideY={false} />
          {totalPercentage !== 100 && (
            <div className="text-gray-500 mt-1 text-xs">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1 text-red-500 text-base" />
              The total traffic allocation for all variants is {totalPercentage}%. Adjust allocation to be 100%.
            </div>
          )}
          {canEdit && (
            <div className="text-center mt-4">
              <button
                onClick={() => {
                  setSelectedVariant(undefined);
                  setIsManageVariantModalOpen(true);
                }}
                className="standard">
                Add Variant
              </button>
            </div>
          )}
        </>
      )}
      {experiment && canEdit && (
        <>
          <ManageVariantModal
            experimentId={experiment.id!}
            promptId={experiment.promptId}
            variant={selectedVariant}
            isOpen={isManageVariantModalOpen}
            onClose={() => setIsManageVariantModalOpen(false)}
            onSave={onVariantSave}
          />
          <StyledDialog
            title="Confirm Delete"
            isOpen={isDeleteModalOpen}
            closeText="Close"
            confirmText="Confirm"
            icon={faCircleExclamation}
            onClose={() => setIsDeleteModalOpen(false)}
            onConfirm={() => handleVariantDelete()}>
            <div>Are you sure you want to remove this variant?</div>
          </StyledDialog>
        </>
      )}
    </>
  );
};

export default VariantTab;
