import { Outlet, Navigate } from 'react-router-dom';
import { Header } from '../components';
import { getUserLocal } from '../services/User';
import { UserRole } from '../types/User';

const AdminLayout = () => {
  const basicUserInfo = getUserLocal();

  if (!basicUserInfo || !basicUserInfo.active) {
    return <Navigate replace to={'/login'} />;
  } else if (basicUserInfo.role !== UserRole.ADMIN) {
    return <Navigate replace to={'/'} />;
  }

  return (
    <>
      <div className="p-2 h-screen overflow-hidden relative">
        <div className="bg-gray-100 flex flex-col pl-0">
          <Header />
          <div className="bg-white p-5 border-gray-300 border rounded-b-sm mb-5 h-[calc(100vh-80px)] overflow-auto ">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
