import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useState } from 'react';

interface Props {
  payload: any;
  disabled?: boolean;
  onPayloadChange?(payload: any): void;
}

const PayloadTab: React.FC<Props> = ({ payload, disabled, onPayloadChange }: Props) => {
  const [_payload, setPayload] = useState<any>(payload);
  const [isPayloadValid, setIsPayloadValid] = useState<boolean>(true);

  const handlePayloadChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const data = e.target.value;
    if (!data) return;

    try {
      const newPayload = JSON.parse(data);

      setPayload(newPayload);
      setIsPayloadValid(true);
      onPayloadChange && onPayloadChange(newPayload);
    } catch (error) {
      setIsPayloadValid(false);
    }
  };

  return (
    <div className="h-full pr-1">
      <div className="h-full overflow-auto bg-white">
        <CodeEditor
          value={JSON.stringify(_payload, null, 2)}
          language="json"
          placeholder="Payload"
          onChange={handlePayloadChange}
          padding={10}
          disabled={disabled}
          className="w-full resize-none border-none p-3 focus:ring-0 .performance-editor"
          style={{
            backgroundColor: '#fff'
          }}
        />
      </div>
      <div className="mt-1">
        {!isPayloadValid && (
          <div className="text-sm text-red-600">
            <FontAwesomeIcon icon={faCircleExclamation} className="inline" /> <span>Invalid JSON</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PayloadTab;
