import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnchor } from '@fortawesome/free-solid-svg-icons';
import { faClock, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { useEffect, useState } from 'react';
import { Webhook } from '../../types/Webhooks';
import { formatDistanceStrict } from 'date-fns';
import ManageWebhookModal from './ManageWebhookModal';

/**
 * Props for the WebhookTile component.
 */
interface Props {
  webhook?: Webhook;
  onUpdate?: (evaluator: Webhook) => void;
}

/**
 *
 * Renders a webhook tile component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Evaluator} props.evaluator - The webhook object to display.
 * @param {Function} props.onUpdate - The update event handler for the tile.
 * @returns {JSX.Element} The rendered WebhookTile component.
 */
const WebhookTile: React.FC<Props> = ({ webhook, onUpdate }: Props) => {
  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
  const [_webhook, setWebhook] = useState<Webhook>();

  useEffect(() => setWebhook(webhook), [webhook]);

  const handleOnWebhookUpdate = (newWebhook: Webhook) => {
    setWebhook(newWebhook);
    setIsShowUpdateModal(false);
    if (newWebhook && onUpdate) onUpdate(newWebhook);
  };

  return (
    <li className="group colspan-1 rounded-lg border-gray-300 border p-3 shadow-lg col-span-1">
      <div className="flex items-center justify-between w-full">
        <h3 className="text-gray-800 font-semibold overflow-hidden">
          <FontAwesomeIcon icon={faAnchor} className="inline w-4 h-4 text-indigo-500 mr-2" />
        </h3>
        <span className="w-full">{_webhook ? _webhook.name : <Skeleton containerClassName="inline" />}</span>
        <div className="ml-2">
          {!prompt ? (
            <Skeleton />
          ) : (
            <FontAwesomeIcon
              icon={faPenToSquare}
              className="w-4 h-4 ml-auto text-gray-500 hover:text-indigo-800 cursor-pointer"
              title="Fork Prompt"
              onClick={() => setIsShowUpdateModal(true)}
            />
          )}
        </div>
      </div>
      <h4 className="text-sm text-gray-600 mt-2  *: w-full word-break-all line-clamp-3">
        {_webhook ? _webhook.description : <Skeleton />}
      </h4>
      <h5 className="text-xs text-gray-500 mt-2 w-full word-break-all line-clamp-1 overflow-hidden">
        {_webhook ? _webhook.url : <Skeleton />}
      </h5>
      <div className="text-xs text-gray-500 pt-3 flex justify-end">
        {_webhook ? (
          <div className="text-right">
            <FontAwesomeIcon icon={faClock} className="mr-1 w-3 h-3 text-gray-500  inline" />
            <span>{formatDistanceStrict(_webhook.updated, new Date())} ago</span>
          </div>
        ) : (
          <span className="w-1/2">
            <Skeleton />
          </span>
        )}
      </div>

      {_webhook && isShowUpdateModal && (
        <ManageWebhookModal
          webhook={_webhook}
          isOpen={isShowUpdateModal}
          onClose={() => setIsShowUpdateModal(false)}
          onSave={handleOnWebhookUpdate}
        />
      )}
    </li>
  );
};

export default WebhookTile;
