import { Disclosure } from '@headlessui/react';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { getUserLocal } from '../../services/User';
import {
  faLock,
  faTerminal,
  faChartColumn,
  faHome,
  faMagnifyingGlassChart,
  faFlask,
  faClockRotateLeft
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserBasicInfo } from '../../types';
import { UserRole } from '../../types/User';

// FIXME: make this dynamic tied to router

/**
 * Represents a navigation item in the header.
 */
interface NavItem {
  name: string;
  href: string;
  matchOn: string[];
  current: boolean;
  icon: ReactElement;
  admin: boolean;
}
[];

const iconClass = 'w-4 h-4 inline mr-2';
const navigation: NavItem[] = [
  {
    name: 'Home',
    href: '/',
    matchOn: ['/'],
    current: false,
    icon: <FontAwesomeIcon icon={faHome} className={iconClass} />,
    admin: false
  },
  {
    name: 'Prompts',
    href: '/prompts/list',
    matchOn: ['/prompts'],
    current: false,
    icon: <FontAwesomeIcon icon={faTerminal} className={iconClass} />,
    admin: false
  },
  {
    name: 'History',
    href: '/history',
    matchOn: ['/history'],
    current: false,
    icon: <FontAwesomeIcon icon={faClockRotateLeft} className={iconClass} />,
    admin: false
  },
  {
    name: 'Analytics',
    href: '/analytics',
    matchOn: ['/analytics'],
    current: false,
    icon: <FontAwesomeIcon icon={faChartColumn} className={iconClass} />,
    admin: false
  },
  {
    name: 'Analysis',
    href: '/analysis',
    matchOn: ['/analysis'],
    current: false,
    icon: <FontAwesomeIcon icon={faMagnifyingGlassChart} className={iconClass} />,
    admin: false
  },
  {
    name: 'Pipelines',
    href: '/pipelines',
    matchOn: ['/pipelines'],
    current: false,
    icon: <FontAwesomeIcon icon={faFlask} className={iconClass} />,
    admin: false
  },
  // {
  //   name: 'Experiments',
  //   href: '/experimentation',
  //   current: false,
  //   icon: <FontAwesomeIcon icon={faFlask} className={iconClass} />,
  //   admin: false
  // },
  {
    name: 'Admin',
    href: '/admin',
    matchOn: ['/admin'],
    current: false,
    icon: <FontAwesomeIcon icon={faLock} className={iconClass} />,
    admin: true
  }
];

/**
 * Props for the Header component.
 */
interface Props {}

/**
 * Header component that displays the navigation bar at the top of the page.
 * @component
 *
 */
const Header: React.FC<Props> = ({}: Props) => {
  const [user, setUser] = useState<UserBasicInfo>();
  const pathName = useLocation().pathname;
  const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

  useEffect(() => {
    const _user = getUserLocal();
    if (!_user) return;

    setUser(_user);
  }, []);

  const nav = navigation.reduce((acc, item) => {
    if (item.admin && user?.role !== UserRole.ADMIN) return acc;

    if (pathName === '/' && item.href === '/') {
      return [...acc, { ...item, current: true }];
    } else if (item.matchOn.filter((i) => pathName.startsWith(i)).length > 0 && item.href !== '/') {
      return [...acc, { ...item, current: true }];
    }

    return [...acc, item];
  }, [] as NavItem[]);

  return (
    <Disclosure as="nav" className="bg-gray-100 rounded-t-lg border-gray-300 border-t border-x w-full h-[58px]">
      {({ open }) => (
        <>
          <div className="mx-auto px-5 flex">
            <div className="flex h-14 items-center justify-between flex-1">
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <h1 className="text-2xl kanit-bold">
                    <a href="/" className="text-gray-700 group">
                      <span className="text-indigo-500 group-hover:text-black">&#123;</span> Promptly{' '}
                      <span className="text-indigo-500 group-hover:text-black">&#125;</span>
                    </a>
                  </h1>
                </div>
                <div className="sm:ml-6 sm:block">
                  <div className="flex space-x-1">
                    {nav.map((item) => (
                      <a key={item.name} href={item.href} className="!text-gray-800 text-base font-medium">
                        <div
                          className={classNames(
                            item.current ? 'bg-gray-300' : 'text-gray-600 hover:bg-gray-200 ',
                            'rounded-md px-3 py-1 flex items-center '
                          )}>
                          {item.icon}
                          <span className="max-lg:hidden max-xl:text-sm">{item.name}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>

              {user && (
                <a href="/account">
                  <img src={user?.profileImage} alt="profile" className="h-11 w-11 rounded-full cursor-pointer" />
                </a>
              )}
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
