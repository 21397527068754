import axios, { AxiosRequestConfig } from 'axios';
import { getUserLocal, logout } from '../services/User';
import { history } from '../lib/history';
import { colorize } from '../common/utils';

export let PENDING_REQUESTS = 0;
export const DEBUG = false;
const BASE_URL = process.env.REACT_APP_API_URL || `${window.location.protocol}//${window.location.host}/api/`;

interface PromptlyAxiosRequestConfig extends AxiosRequestConfig {
  metadata: { startTime: number; endTime: number };
  maxRequestsCount: number;
  intervalMs: number;
}

const config: PromptlyAxiosRequestConfig = {
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  metadata: { startTime: 0, endTime: 0 },
  maxRequestsCount: 5,
  intervalMs: 500
};

export const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use((config) => {
  const conf = config as PromptlyAxiosRequestConfig;

  conf.metadata = { startTime: Date.now(), endTime: 0 };
  const user = getUserLocal();

  const oktaUser = JSON.parse(localStorage.getItem('okta-token-storage') || '{}');
  const token = oktaUser.accessToken?.accessToken || user?.accessToken;

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['X-User'] = oktaUser.idToken?.claims?.name || oktaUser.idToken?.claims?.email || 'Unknown';
    config.headers['X-User-Id'] = oktaUser.idToken?.claims?.email || '';
  }

  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < conf.maxRequestsCount) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        // @ts-ignore
        resolve(conf);
      }
    }, conf.intervalMs);
  });
});

const formatParams = (params: Record<string, any>): string => {
  if (!params) return '';

  let p = Object.entries(params || {})
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return `?${p}`;
};

axiosInstance.interceptors.response.use(
  async (response) => {
    const conf = response.config as PromptlyAxiosRequestConfig;

    if (DEBUG) {
      conf.metadata.endTime = Date.now();
      const duration = conf.metadata.endTime - conf.metadata.startTime;
      console.groupCollapsed(
        `[${conf.method?.toUpperCase()}] ${colorize.yellow(response.config.url + formatParams(response.config.params))} ${colorize.white('-')} ${colorize.green(duration + 'ms')} ${colorize.white('with')} ${colorize.cyan(response.status)} ${colorize.white('status.')}`
      );
      console.log(response.data);
      console.groupEnd();
    }

    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);

    return Promise.resolve(response);
  },
  (error) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);

    const conf = error.config as PromptlyAxiosRequestConfig;
    conf.metadata.endTime = Date.now();
    const duration = conf.metadata.endTime - conf.metadata.startTime;
    console.groupCollapsed(
      colorize.red(
        `[${conf.method?.toUpperCase()}] Request to ${error.config.url + formatParams(error.config.params)} - ${duration}ms with ${error.response?.status} status.`
      )
    );
    // console.log(error.response?.data);
    console.groupEnd();

    if (error.response && error.response.status === 401) {
      logout();
      history.replace('/login');
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
