import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PromptList, TabGroup } from '../../components';
import { PromptTypes } from '../../types/Prompt';

interface Props {
  type?: PromptTypes;
  additonalButton?: React.ReactElement[] | React.ReactElement;
}

const enum Tabs {
  PROMPT = 0,
  PROMPT_LIBRARY = 1,
  EVALUATOR_PROMPTS = 2,
  EVALUATOR_LIBRARY = 3
}

/**
 * Renders the Prompts page.
 *
 * @param {Props} props - The component props.
 * @param {string} props.type - The type of prompt.
 * @param {React.ReactElement[]} props.additonalButton - Additional buttons to render.
 * @returns {JSX.Element} The rendered Prompts page.
 */
const Prompts: React.FC<Props> = ({ type, additonalButton }: Props) => {
  const routerParams = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [promptType, setPromptType] = useState<PromptTypes>(type || PromptTypes.PROMPT);

  useEffect(() => setPromptType(type || PromptTypes.PROMPT), [type]);

  useEffect(() => {
    if (routerParams.type) {
      setPromptType(routerParams.type.toUpperCase() as PromptTypes);
    }
  }, [routerParams]);

  useEffect(() => {
    if (promptType === PromptTypes.PROMPT_LIBRARY) {
      setSelectedTab(Tabs.PROMPT_LIBRARY);
    } else if (promptType === PromptTypes.EVALUATOR) {
      setSelectedTab(Tabs.EVALUATOR_PROMPTS);
    } else if (promptType === PromptTypes.EVALUATOR_LIBRARY) {
      setSelectedTab(Tabs.EVALUATOR_LIBRARY);
    } else {
      setSelectedTab(Tabs.PROMPT);
    }
  }, [promptType]);

  const handleTabChange = (index: number) => {
    const baseUrl = '/prompts/list';
    setSelectedTab(index);
    if (index === Tabs.PROMPT) {
      navigate(baseUrl);
    } else if (index === Tabs.PROMPT_LIBRARY) {
      navigate(`${baseUrl}/${PromptTypes.PROMPT_LIBRARY.toLowerCase()}`);
    } else if (index === Tabs.EVALUATOR_PROMPTS) {
      navigate(`${baseUrl}/${PromptTypes.EVALUATOR.toLowerCase()}`);
    } else if (index === Tabs.EVALUATOR_LIBRARY) {
      navigate(`${baseUrl}/${PromptTypes.EVALUATOR_LIBRARY.toLowerCase()}`);
    }
  };

  return (
    <TabGroup
      tabNames={['My Prompts', 'Prompt Library', 'Evaluator Prompts', 'Evaluator Library']}
      selectedTabIndex={selectedTab}
      onTabChange={handleTabChange}
      helpLink={
        selectedTab === Tabs.PROMPT
          ? '/docs/#/prompts'
          : Tabs.EVALUATOR_PROMPTS
            ? '/docs/#/pipelines_evaluators'
            : undefined
      }>
      <div>
        <div style={selectedTab === Tabs.PROMPT ? {} : { display: 'none' }}>
          <PromptList key={PromptTypes.PROMPT} type={PromptTypes.PROMPT} active={selectedTab === Tabs.PROMPT} />
        </div>
        <div style={selectedTab === Tabs.PROMPT_LIBRARY ? {} : { display: 'none' }}>
          <PromptList
            key={PromptTypes.PROMPT_LIBRARY}
            type={PromptTypes.PROMPT_LIBRARY}
            active={selectedTab === Tabs.PROMPT_LIBRARY}
          />
        </div>
        <div style={selectedTab === Tabs.EVALUATOR_PROMPTS ? {} : { display: 'none' }}>
          <PromptList
            key={PromptTypes.EVALUATOR}
            type={PromptTypes.EVALUATOR}
            active={selectedTab === Tabs.EVALUATOR_PROMPTS}
          />
        </div>
        <div style={selectedTab === Tabs.EVALUATOR_LIBRARY ? {} : { display: 'none' }}>
          <PromptList
            key={PromptTypes.EVALUATOR_LIBRARY}
            type={PromptTypes.EVALUATOR_LIBRARY}
            active={selectedTab === Tabs.EVALUATOR_LIBRARY}
          />
        </div>
      </div>
    </TabGroup>
  );
};

export default Prompts;
