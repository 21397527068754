import { BaseApi } from './BaseApi';

export enum VariantType {
  CONTROL = 'CONTROL',
  VARIANT = 'VARIANT'
}

export interface Experiment extends BaseApi {
  appId: string;
  promptId: string;
  key: string;
  name: string;
  description: string;
  live: boolean;
  begin: Date;
  end: Date;
  variantCount: number;
}

export interface Variant extends BaseApi {
  experimentId: string;
  promptId: string;
  promptVersionNumber: number;
  name: string;
  description: string;
  percentage: number;
  type: VariantType;
}
