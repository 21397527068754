import { faPassport, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { getErrorMessage, validateEmailAddress } from '../../common/utils';
import { StyledDialog } from '../../components';
import { login, sendPasswordResetEmail } from '../../services/User';
import { UserAuth } from '../../types';

interface Props {}

/**
 * Login page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const Login: React.FC<Props> = ({}: Props) => {
  const { oktaAuth } = useOktaAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState<UserAuth>();
  const [resetEmail, setResetEmail] = useState('');
  const [isForgotDisabled, setIsForgotDisabled] = useState(false);
  const [isForgotOpen, setIsForgotOpen] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    if (user.active) {
      navigate('/');
    } else if (user.active !== null && !user.active) {
      toast.error('Your account request is pending approval');
    } else {
      toast.error('Incorrect email or password. Please try again.');
    }
  }, [user]);

  const handleLogin = async () => {
    if (!validateEmailAddress(email)) {
      toast.error('Please enter a valid email address');
      return;
    } else if (!password) {
      toast.error('Please enter your password');
      return;
    }

    setIsLoggingIn(true);
    try {
      setUser(await login({ email, password }));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleLoginOkta = () => {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await handleLogin();
  };

  const sendPasswordReset = async () => {
    if (!validateEmailAddress(resetEmail)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    try {
      setIsForgotDisabled(true);
      await sendPasswordResetEmail(resetEmail);
      setResetEmail('');
      toast.success('Password reset link sent to your email.');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsForgotDisabled(false);
      setIsForgotOpen(false);
    }
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className="mx-auto max-w-lg my-5 bg-gray-50 border-gray-300 border-2 p-5 rounded-lg">
          <div>
            <h1 className="kanit-bold mx-auto text-center text-4xl text-gray-700">
              <span className="text-indigo-500 group-hover:text-black">&#123;</span> Promptly{' '}
              <span className="text-indigo-500 group-hover:text-black">&#125;</span>
            </h1>
          </div>

          <div className="text-center">
            <button type="button" disabled={isLoggingIn} onClick={handleLoginOkta} className="standard mt-12 !px-8">
              {/* <FontAwesomeIcon icon={faCircle} className="pr-4 h-4 w-4" /> */}
              Sign In with Okta
            </button>
          </div>

          <div className="text-center mt-14 border-gray-400 border-t pt-4 text-gray-700 font-semibold">
            Legacy Login
          </div>

          <div className="">
            <div className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    disabled={isLoggingIn}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="text-sm">
                    <a
                      href="#"
                      onClick={() => setIsForgotOpen(true)}
                      className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    disabled={isLoggingIn}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button type="button" disabled={isLoggingIn} onClick={handleLogin} className="flex w-full standard">
                  {isLoggingIn && <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2 mt-1.5" />}
                  Login
                </button>
              </div>
            </div>

            {/* <p className="mt-10 text-center text-sm text-gray-500">
              Not a member? <a href="/register">Signup Now!</a>
            </p> */}
          </div>
        </div>
      </form>

      <StyledDialog
        title="Forgot Password"
        icon={faPassport}
        isOpen={isForgotOpen}
        confirmText={
          isForgotDisabled ? <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> : 'Send Reset Link'
        }
        onClose={() => !isForgotDisabled && setIsForgotOpen(false)}
        onConfirm={sendPasswordReset}>
        <div className="w-80 mt-4">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Email address
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.currentTarget.value)}
              required
              disabled={isForgotDisabled}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </StyledDialog>
    </>
  );
};
export default Login;
