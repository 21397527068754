import { faFlaskVial } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { getErrorMessage } from '../../common/utils';
import AddExperimentModal from '../../components/experiments/AddExperimentModal';
import ExperimentTile from '../../components/experiments/ExperimentTile';
import { getExperiments } from '../../services/Experimentation';
import { Experiment } from '../../types';

interface Props {}

/**
 * Represents the Experimentation page component.
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const Experimentation: React.FC<Props> = ({}: Props) => {
  const navigate = useNavigate();

  const [newShowExperiment, setShowNewExperiment] = useState<boolean>(false);
  const [experiments, setExperiments] = useState<Experiment[]>([]);
  const [isExperimentsLoading, setIsExperimentsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        setExperiments(await getExperiments());
      } catch (error) {
        return toast.error(getErrorMessage(error));
      } finally {
        setIsExperimentsLoading(false);
      }
    })();
  }, []);

  const handleOnNewExperiment = (experiment: Experiment) => {
    setShowNewExperiment(false);
    setExperiments([experiment, ...experiments]);
  };

  const handleOnExperimentClick = (experiment: Experiment) => {
    navigate(`/experimentation/${experiment.id}`, { state: { experiment } });
  };

  return (
    <>
      <div className="mx-auto">
        <div className="flex">
          <div className="flex-1">
            <h1 className="text-2xl text-gray-800">My Experiments</h1>
            <h2 className="mt-2 mb-4 text-lg text-gray-700">
              Here you&apos;ll find a list of your experiments. Experiments allow you to test the performance of various
              prompt versions.
            </h2>
          </div>
          <div>
            <button onClick={() => setShowNewExperiment(true)} className="standard">
              New Experiment
            </button>
          </div>
        </div>
        {isExperimentsLoading ||
          (!experiments.length && (
            <>
              <div className="mt-2 text-indigo-500 mx-auto text-center">
                <FontAwesomeIcon icon={faFlaskVial} className="w-40 h-40" />
              </div>
              <div className="mt-5 text-gray-700 mx-auto text-center">
                It looks like you haven&lsquo;t created any experiments yet.{' '}
                <a role="button" onClick={() => setShowNewExperiment(true)}>
                  Click here to get started!
                </a>
              </div>
            </>
          ))}
        <ul className="grid grid-cols-3 gap-4 rounded-lg mt-4">
          {isExperimentsLoading && <ExperimentTile />}
          {experiments.map((experiment) => (
            <ExperimentTile key={experiment.id} experiment={experiment} onClick={handleOnExperimentClick} />
          ))}
        </ul>
      </div>
      <AddExperimentModal
        isOpen={newShowExperiment}
        onClose={() => setShowNewExperiment(false)}
        onSave={handleOnNewExperiment}
      />
    </>
  );
};

export default Experimentation;
