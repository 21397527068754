import { Pipeline } from '../../types';
import StyledDialog from '../common/StyledDialog';
import { faSpinner, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { updatePipeline } from '../../services/PipelineEvaluations';
import { getErrorMessage } from '../../common/utils';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  isOpen: boolean;
  pipeline: Pipeline;
  onClose: () => void;
  onSave: (pipeline: Pipeline) => void;
}

const ArchivePipelineModal: React.FC<Props> = ({ isOpen, pipeline, onClose, onSave }: Props) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleArchive = async () => {
    setIsSaving(true);

    try {
      await updatePipeline(pipeline.id, pipeline.name, pipeline.description, !pipeline.archived);

      onSave({ ...pipeline, archived: !pipeline.archived });
    } catch (e) {
      console.error(e);
      return toast.error(getErrorMessage(e));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title={pipeline?.archived ? 'Restore Pipeline' : 'Archive Pipeline'}
      closeText="Cancel"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" />{' '}
            {pipeline?.archived ? 'Restoring' : 'Archiving'}
          </div>
        ) : pipeline?.archived ? (
          'Restore'
        ) : (
          'Archive'
        )
      }
      confirmClassName={pipeline?.archived ? '' : `!bg-red-500 hover:!bg-red-400`}
      icon={faTriangleExclamation}
      width="w-1/3"
      onClose={() => !isSaving && onClose()}
      onConfirm={() => handleArchive()}>
      <div className="w-full">
        {pipeline?.archived ? (
          <div>Are you sure you want to restore this pipeline?</div>
        ) : (
          <div>Are you sure you want to archive this pipeline? You will no longer be able to run evaluators.</div>
        )}
      </div>
    </StyledDialog>
  );
};

export default ArchivePipelineModal;
