import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { StyledDialog } from '../common';
import { getErrorMessage } from '../../common/utils';
import toast from 'react-hot-toast';
import { PipelineRun } from '../../types';
import { addRunClone } from '../../services/PipelineEvaluations';

interface Props {
  pipelineRun: PipelineRun;
  isOpen: boolean;
  onSave: (run: PipelineRun) => void;
  onClose: () => void;
}

const ClonePipelineRun: React.FC<Props> = ({ pipelineRun, isOpen = false, onSave, onClose }: Props) => {
  const [newName, setNewName] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!pipelineRun) return;

    setNewName(`${pipelineRun.name} (copy)`);
  }, [prompt]);

  const handleOnCancel = () => {
    if (isSaving) return;
    onClose();
  };

  const handleOnConfirm = async () => {
    if (newName.length < 3) {
      toast.error('Pipeline name is required.');
      return;
    }
    setIsSaving(true);
    let newRun;

    try {
      newRun = await addRunClone(
        pipelineRun.pipelineId,
        pipelineRun.promptId,
        pipelineRun.promptVersionNumber,
        newName,
        pipelineRun.id
      );
      toast.success('Pipeline Run Added');
      onSave(newRun);
    } catch (error) {
      const err = getErrorMessage(error);
      console.error(error);
      toast.error(err);
      return;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      title="Clone Pipeline Run"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Cloning
          </div>
        ) : (
          'Clone'
        )
      }
      disabled={isSaving}
      icon={faClone}
      onClose={handleOnCancel}
      onConfirm={handleOnConfirm}
      isOpen={isOpen}>
      {pipelineRun && (
        <div>
          <div className="my-2">
            <input
              id="pipelineName"
              name="pipelineName"
              type="text"
              autoComplete="off"
              value={newName}
              placeholder="Pipeline Name"
              onChange={(e) => setNewName(e.target.value)}
              required
              disabled={isSaving}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      )}
    </StyledDialog>
  );
};

export default ClonePipelineRun;
