import { faCodeCompare } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { modelParameterArrayToObject } from '../../common/models';
import { deepEqual } from '../../common/utils';

import { PromptVersion } from '../../types';
import { PromptVersionTypes } from '../../types/Prompt';
import { TabGroup } from '../common';
import StyledDialog from '../common/StyledDialog';

const enum Tabs {
  TEMPLATE = 0,
  PROPS = 1,
  TOOLS = 2,
  SAMPLE_DATA = 3,
  HELPERS = 4,
  MODEL_PROPS = 5
}

/**
 * Props for the PromptDiffModal component.
 */
interface Props {
  isOpen: boolean;
  current: PromptVersion | undefined;
  previous: PromptVersion | undefined;
  noModal?: boolean;
  className?: string;
  onClose: () => void;
}

/**
 * Renders a modal that displays the differences between two versions of a prompt.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {boolean} props.isOpen - Indicates whether the modal is open or not.
 * @param {PromptVersion} props.current - The current version of the prompt.
 * @param {PromptVersion} props.previous - The previous version of the prompt.
 * @param {boolean} props.noModal - Indicates whether to render the modal or not.
 * @param {string} props.className - The class name to apply to the modal.
 * @param {() => void} props.onClose - The function to call when the modal is closed.
 * @returns {JSX.Element} The rendered component.
 */
const PromptDiffModal: React.FC<Props> = ({
  isOpen,
  onClose,
  current,
  previous,
  noModal = false,
  className = ''
}: Props) => {
  const [currentParamDict, setCurrentParamDict] = useState<Record<string, number | boolean>>();
  const [previousParamDict, setPreviousParamDict] = useState<Record<string, number | boolean>>();
  const [paramKeys, setParamKeys] = useState<string[]>([]);
  const [currentTemplate, setCurrentTemplate] = useState<string>('');
  const [previousTemplate, setPreviousTemplate] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    if (!current || !previous) return;
    if (!current?.parameters || !previous?.parameters) return;

    const currentParams = modelParameterArrayToObject(current?.parameters);
    const previousParams = modelParameterArrayToObject(previous?.parameters);

    setCurrentParamDict(currentParams);
    setPreviousParamDict(previousParams);
    setCurrentTemplate(
      (current.type === PromptVersionTypes.MESSAGING
        ? JSON.stringify(current.messageTemplate, null, 4)
        : current.template
      ).replace(/\\n/gm, '\n')
    );
    setPreviousTemplate(
      (previous.type === PromptVersionTypes.MESSAGING
        ? JSON.stringify(previous.messageTemplate, null, 4)
        : previous.template
      ).replace(/\\n/gm, '\n')
    );
    setParamKeys([...new Set([...Object.keys(currentParams), ...Object.keys(previousParams)])]);
  }, [current, previous]);

  const diffComponent = (
    <div className={className}>
      <TabGroup
        tabNames={[
          `Template${currentTemplate === previousTemplate ? '' : '  ✅'}`,
          `Custom Properties${deepEqual(current?.customProps, previous?.customProps) ? '' : '  ✅'}`,
          `Tools${deepEqual(current?.tools, previous?.tools) ? '' : '  ✅'}`,
          `Sample Payload${deepEqual(current?.samplePayload, previous?.samplePayload) ? '' : '  ✅'}`,
          `Helpers${deepEqual(current?.helpers, previous?.helpers) ? '' : '  ✅'}`,
          `Model Properties${current?.model === previous?.model ? '' : '  ✅'}`
        ]}
        selectedTabIndex={selectedTab}
        className="diff-viewer h-full"
        onTabChange={setSelectedTab}>
        <div className="text-sm">
          {selectedTab === Tabs.TEMPLATE && (
            <div className="text-xs">
              {currentTemplate === previousTemplate ? (
                <div>No changes.</div>
              ) : (
                <ReactDiffViewer
                  newValue={currentTemplate}
                  oldValue={previousTemplate}
                  splitView={false}
                  hideLineNumbers
                  showDiffOnly
                  disableWordDiff
                />
              )}
            </div>
          )}
          {selectedTab === Tabs.PROPS && (
            <div>
              {deepEqual(current?.customProps, previous?.customProps) ? (
                <div>No changes.</div>
              ) : (
                <ReactDiffViewer
                  newValue={JSON.stringify(current?.customProps, null, 4)}
                  oldValue={JSON.stringify(previous?.customProps, null, 4)}
                  splitView={false}
                  hideLineNumbers
                  showDiffOnly
                  disableWordDiff
                />
              )}
            </div>
          )}
          {selectedTab === Tabs.TOOLS && (
            <div>
              {deepEqual(current?.tools, previous?.tools) ? (
                <div>No changes.</div>
              ) : (
                <ReactDiffViewer
                  newValue={JSON.stringify(current?.tools, null, 4)}
                  oldValue={JSON.stringify(previous?.tools, null, 4)}
                  splitView={false}
                  hideLineNumbers
                  showDiffOnly
                  disableWordDiff
                />
              )}
            </div>
          )}
          {selectedTab === Tabs.SAMPLE_DATA && (
            <div>
              {deepEqual(current?.samplePayload, previous?.samplePayload) ? (
                <div>No changes.</div>
              ) : (
                <ReactDiffViewer
                  newValue={JSON.stringify(current?.samplePayload, null, 4)}
                  oldValue={JSON.stringify(previous?.samplePayload, null, 4)}
                  splitView={false}
                  hideLineNumbers
                  showDiffOnly
                  disableWordDiff
                />
              )}
            </div>
          )}
          {selectedTab === Tabs.HELPERS && (
            <div>
              {deepEqual(current?.helpers, previous?.helpers) ? (
                <div>No changes.</div>
              ) : (
                <ReactDiffViewer
                  newValue={JSON.stringify(current?.helpers, null, 4).replace(/\\n/gm, '\n')}
                  oldValue={JSON.stringify(previous?.helpers, null, 4).replace(/\\n/gm, '\n')}
                  splitView={false}
                  hideLineNumbers
                  showDiffOnly
                  disableWordDiff
                />
              )}
            </div>
          )}
          {selectedTab === Tabs.MODEL_PROPS && (
            <div>
              <div className="ml-4">
                <div className="mb-3">
                  <div className="text-sm text-gray-600 ">Model</div>
                  <div className="text-sm text-gray-500  mt-0.5">
                    {current?.model !== previous?.model ? (
                      <span>
                        {previous?.model} → {current?.model}
                      </span>
                    ) : (
                      <span>No changes</span>
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-600">Parameters</div>
                  {paramKeys.map((key) => {
                    const currentVal = currentParamDict?.[key];
                    const previousVal = previousParamDict?.[key];

                    return (currentVal || previousVal) && currentVal !== previousVal ? (
                      <div key={key} className="mb-3">
                        <div className="text-xs text-gray-500">{key}</div>
                        <div className="text-sm text-gray-600">
                          {currentVal && !previousVal && (
                            <div className="bg-green-200 mt-0.5 p-0.5">+ {currentVal}</div>
                          )}
                          {!currentVal && previousVal && (
                            <div className="bg-red-200   p-0.5 mt-0.5">+ {previousVal}</div>
                          )}
                          {currentVal && previousVal && currentVal !== previousVal ? (
                            <div className="bg-indigo-200 mt-0.5 p-0.5">
                              {previousVal} → {currentVal}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </TabGroup>
    </div>
  );

  return noModal ? (
    diffComponent
  ) : (
    <StyledDialog
      isOpen={isOpen}
      onClose={onClose}
      title={`Differences between current and version ${previous?.version}`}
      closeText="Close"
      width="w-5/6"
      icon={faCodeCompare}>
      {diffComponent}
    </StyledDialog>
  );
};

export default PromptDiffModal;
