import { useEffect, useState } from 'react';
import { camelCaseToTitleCase } from '../../common/utils';
import StyledDialog from '../common/StyledDialog';
import { faTableColumns } from '@fortawesome/free-solid-svg-icons';

interface Props {
  isOpen: boolean;
  columns: Record<string, boolean>;
  onClose: () => void;
  onSave: (columns: Record<string, boolean>) => void;
}

const CustomColumnModal: React.FC<Props> = ({ isOpen = false, columns, onClose, onSave }: Props) => {
  const [selectedColumns, setSelectedColumns] = useState<Record<string, boolean>>(columns);

  useEffect(() => {
    setSelectedColumns(columns);
  }, [columns]);

  return (
    <StyledDialog
      isOpen={isOpen}
      title="Customize Columns"
      closeText="Cancel"
      confirmText="Apply"
      onConfirm={() => onSave(selectedColumns)}
      onClose={onClose}
      icon={faTableColumns}
      width="w-1/2">
      <div>
        <div className="grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-1.5">
          {Object.keys(selectedColumns).map((column) => (
            <div key={column} className="flex items-center justify-between py-2">
              <div className="w-full">
                <label className="block cursor-pointer" htmlFor={column}>
                  {camelCaseToTitleCase(column)}
                </label>
              </div>
              <input
                type="checkbox"
                id={column}
                checked={selectedColumns[column]}
                onChange={(e) => setSelectedColumns({ ...selectedColumns, [column]: e.target.checked })}
              />
            </div>
          ))}
        </div>
      </div>
    </StyledDialog>
  );
};

export default CustomColumnModal;
