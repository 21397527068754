import { useState } from 'react';
import { Experiment } from '../../types';
import { getErrorMessage } from '../../common/utils';
import { PromptVersionSelector, StyledDialog } from '..';
import { createExperiment } from '../../services/Experimentation';
import { faCirclePlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PV } from '../common/PromptVersionSelector';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Props for the AddExperimentModal component.
 */
interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (experiment: Experiment) => void;
}

/**
 * AddExperimentModal component displays a modal for adding a new experiment.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {boolean} props.isOpen - Indicates whether the modal is open or not.
 * @param {Function} props.onClose - Callback function to handle the modal close event.
 * @param {Function} props.onSave - Callback function to handle the save event when a new experiment is created.
 * @returns {JSX.Element} The rendered AddExperimentModal component.
 */
const AddExperimentModal: React.FC<Props> = ({ isOpen, onClose, onSave }: Props) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [apv, setApv] = useState<PV>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const resetForm = () => {
    setName('');
    setDescription('');
    setApv({});
  };

  const handleOnClose = () => {
    if (isSaving) return;

    isOpen = false;
    resetForm();
    onClose();
  };

  const handleOnCreate = async () => {
    if (!apv.prompt) {
      toast.error('Please select a prompt.');
      return;
    } else if (!name.length || !description.length) {
      toast.error('Please provide a name and description.');
      return;
    }

    setIsSaving(true);
    let experiment: Experiment;

    try {
      experiment = await createExperiment(apv.prompt.id!, name, description);
    } catch (error) {
      toast.error(getErrorMessage(error));
      return;
    } finally {
      setIsSaving(false);
    }

    resetForm();
    onSave(experiment);
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title="Add Experiment"
      closeText="Cancel"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : (
          'Save'
        )
      }
      onConfirm={handleOnCreate}
      onClose={handleOnClose}
      disabled={isSaving}
      icon={faCirclePlus}>
      <div className="w-96">
        <div className="mt-2">
          <PromptVersionSelector
            onChange={(ids) => setApv(ids)}
            showVersions={false}
            defaultLabels={['Select Prompt', '']}
          />
        </div>
        <div className="mt-2">
          <input
            id="experimentName"
            name="experimentName"
            type="text"
            autoComplete="off"
            value={name}
            placeholder="Experiment Name"
            onChange={(e) => setName(e.currentTarget.value)}
            required
            disabled={isSaving}
            className="disabled:text-gray-400 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-2">
          <textarea
            id="experimentDesc"
            name="experimentDesc"
            placeholder="Experiment description"
            rows={3}
            onChange={(e) => setDescription(e.currentTarget.value)}
            required
            disabled={isSaving}
            className="disabled:text-gray-400 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={description}
          />
        </div>
      </div>
    </StyledDialog>
  );
};

export default AddExperimentModal;
