import { useEffect, useState } from 'react';
import { CompletionEvaluated } from '../../pages/pipelines/PipelineRun';
import { StarRating } from '../common';

/**
 * Props for the Pipeline Scorecard component.
 */
interface Props {
  evaulations: CompletionEvaluated[];
}

interface Score {
  evaluatorName: string;
  completions: number;
  averageScore: number;
}

const PipelineScoreCard: React.FC<Props> = ({ evaulations }: Props) => {
  const [groupedByEvaluatorName, setGroupedByEvaluatorName] = useState<Record<string, Score>>({});
  const [totalScore, setTotalScore] = useState<number>(0);
  const [stars, setStars] = useState<number>(0);

  useEffect(() => {
    if (!evaulations) return;

    const update: Record<string, Score> = { ...groupedByEvaluatorName };
    evaulations.forEach((evaluation) => {
      if (!update[evaluation.evaluatorName]) {
        update[evaluation.evaluatorName] = {
          evaluatorName: evaluation.evaluatorName,
          completions: 0,
          averageScore: 0
        };
      }

      if (evaluation.score === -1) return;

      const updateScore = { ...update[evaluation.evaluatorName] };
      updateScore.completions += 1;
      updateScore.averageScore =
        (updateScore.averageScore * updateScore.completions + evaluation.score) / (updateScore.completions + 1);
      update[evaluation.evaluatorName] = updateScore;
    });

    const score =
      Object.values(update).reduce((acc, curr) => acc + curr.averageScore, 0) / Object.entries(update).length;

    let starCount = Math.round(Math.floor(score * 10) / 10) * 10;
    starCount = starCount / 20;

    setStars(starCount);
    setTotalScore(score);
    setGroupedByEvaluatorName(update);
  }, [evaulations]);
  2;
  return (
    <div>
      <div className="flex items-center">
        <div>
          <StarRating rating={stars} />
        </div>
        <div className="text-sm font-medium text-gray-500 ml-2">{totalScore.toFixed(2)} out of 10</div>
      </div>
      <p className="text-xs font-medium text-gray-400 mt-2">{evaulations.length} evaluations</p>
      <div className="mt-4">
        {Object.values(groupedByEvaluatorName).map((score) => (
          <div key={score.evaluatorName} className="flex items-center mt-4">
            <span className="text-sm font-medium text-gray-600 w-1/5">{score.evaluatorName}</span>
            <div className="w-2/4 h-5 mx-4 bg-gray-200 rounded">
              <div
                className="h-5 bg-indigo-500 rounded"
                style={{ width: `${(score.averageScore * 10).toFixed(0)}%` }}></div>
            </div>
            <span className="text-sm font-medium text-gray-500">{(score.averageScore * 10).toFixed(2)}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PipelineScoreCard;
