export const oktaConfig = {
  oidc: {
    clientId: process.env.CLIENT_ID || '0oa1y82zr13qLWq8F0h8',
    issuer: process.env.ISSUER || 'https://coxauto.okta.com/oauth2/default',
    // issuer: process.env.ISSUER || 'https://atg.oktapreview.com',
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false
  }
};
