import makeAnimated from 'react-select/animated';
import { useEffect, useState } from 'react';
import { faPenToSquare, faSave, faSeedling, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { StyledDialog } from '../common';
import { Environments, PromptVersion } from '../../types/Prompt';
import { SelectorValue } from '../common/Selector';
import Select, { MultiValue } from 'react-select';
import { arrayContentsMatch, arrayToSentence, getErrorMessage, toTitleCase } from '../../common/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import { updateEnvironment } from '../../services/PromptVersions';

/**
 * Props for the PromptEnvironmentsModal component.
 */
interface Props {
  version: PromptVersion | undefined;
  owner: boolean;
  dirty: boolean;
  disabled: boolean;
  onSave: (environments: string[]) => void;
}

const animatedComponents = makeAnimated();
const envSelectorsAll: SelectorValue[] = Object.entries(Environments)
  .map(([id, label]) => ({
    value: id,
    label: label
  }))
  .filter((e) => e.label !== Environments.none);

const envSelectors: SelectorValue[] = envSelectorsAll.filter(
  (env) => env.label !== Environments.PREPROD && env.label !== Environments.PROD
);

/**
 * Component for managing and updating the environments associated with a prompt version.
 *
 * @component
 * @param {Props} props - The properties passed to the component.
 * @param {Version} props.version - The current version of the prompt.
 * @param {string} props.owner - The owner of the prompt.
 * @param {boolean} props.dirty - Indicates if there are unsaved changes.
 * @param {boolean} props.disabled - Indicates if the component is disabled.
 * @param {function} props.onSave - Callback function to be called when environments are saved.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <PromptEnvironmentsModal
 *   version={version}
 *   owner={owner}
 *   dirty={dirty}
 *   disabled={disabled}
 *   onSave={handleSave}
 * />
 */
const PromptEnvironmentsModal: React.FC<Props> = ({ version, owner, dirty, disabled, onSave }: Props) => {
  const [selectedEnvironments, setSelectedEnvironments] = useState<SelectorValue[]>([]);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isShowSaveConfirm, setIsShowSaveConfirm] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);

  useEffect(() => {
    if (!version) return;

    setSelectedEnvironments(
      version.environments.map((env) => {
        return {
          value: env,
          label: toTitleCase(env)
        };
      })
    );

    setCanSave(false);
  }, [version, isOpened]);

  const handleEnvironmentOptionChange = (selected: MultiValue<unknown>) => {
    setCanSave(checkIfEnvironmentsChanged(selected as SelectorValue[]));
    setSelectedEnvironments(selected as SelectorValue[]);
  };

  const checkIfEnvironmentsChanged = (selected: SelectorValue[]) => {
    return !arrayContentsMatch(version?.environments || [], selected.map((e) => e.value) as string[]);
  };

  /**
   * Updates the environments for a prompt. This will remove the environments from any other version using them.
   * @param envs - The new environments to update to.
   */
  const updateEnvs = async () => {
    if (!version) return;

    setIsShowSaveConfirm(false);
    const id = 'toasty-id';
    toast.loading('Saving environments', { id });
    setIsSaving(true);

    const envIds = selectedEnvironments.map((env) => env.value) as string[];

    try {
      await updateEnvironment(version, envIds);
    } catch (error) {
      return toast.error(getErrorMessage(error), { id });
    } finally {
      setIsSaving(false);
    }

    toast.success(
      `Version ${version?.version} is now configured for ${arrayToSentence(selectedEnvironments.map((e) => e.label.toLowerCase()))} environment.`,
      {
        id
      }
    );

    setCanSave(false);
    setIsOpened(false);
    onSave(envIds);
  };

  return (
    <>
      {!isOpened && (
        <div className="mb-4 flex w-full text-gray-800 text-xs">
          {version && version?.environments.length > 0 && (
            <div className="flex-1 grid grid-cols-3 justify-start  ">
              {version.environments.map((e, i) => (
                <span key={i} className="px-1 bg-gray-200 rounded-md p-0.5 text-center mr-1 mb-1">
                  {toTitleCase(e)}
                </span>
              ))}
            </div>
          )}
          {version?.environments.length === 0 && <div className="flex-1">No active environments.</div>}
          {owner && (
            <div className="w-4 ml-1 text-right">
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="text-sm cursor-pointer text-gray-500 hover:text-indigo-800"
                onClick={() => setIsOpened(true)}
              />
            </div>
          )}
        </div>
      )}
      <StyledDialog
        title="Environments"
        isOpen={isOpened}
        closeText="Close"
        icon={faSeedling}
        width="w-2/5"
        disabled={disabled}
        confirmDisabled={!canSave}
        confirmText={
          isSaving ? (
            <div>
              <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
            </div>
          ) : (
            <div>
              <FontAwesomeIcon icon={faSave} className="mr-1.5" /> Save
            </div>
          )
        }
        onConfirm={() => setIsShowSaveConfirm(true)}
        onClose={() => setIsOpened(false)}>
        <div className="!text-sm">
          Environments are used to separate different stages of development. You can specify as many environments as you
          like, this allows you to access prompt versions without the need to specify a version number.
          <div className="my-4 flex">
            <div className="font-semibold pr-1">
              Current Environment{`${version?.environments.length !== 1 ? 's' : ''}`}:
            </div>
            {version?.environments.map((env, index) => (
              <div key={index} className="px-1 bg-gray-200 rounded-md p-0.5 mr-1">
                {toTitleCase(env)}
              </div>
            ))}
            {version?.environments.length === 0 && 'None'}
          </div>
          <Select
            isMulti
            name="environments"
            defaultValue={selectedEnvironments}
            options={owner ? envSelectorsAll : envSelectors}
            isDisabled={disabled}
            isSearchable={false}
            classNamePrefix="promptly"
            components={animatedComponents}
            placeholder="Environments"
            menuPortalTarget={document.body}
            onChange={handleEnvironmentOptionChange}
          />
        </div>
      </StyledDialog>
      <div>
        <StyledDialog
          title="Save Environments"
          isOpen={isShowSaveConfirm}
          closeText="Close"
          icon={faSeedling}
          width="w-2/5"
          onConfirm={() => updateEnvs()}
          onClose={() => setIsShowSaveConfirm(false)}>
          <div>
            <div className="mb-2">
              {selectedEnvironments.length > 0 ? (
                <div>
                  <div>
                    Version {version?.version} will be configured for the following environment
                    {`${selectedEnvironments.length !== 1 ? 's' : ''}`}:
                    {selectedEnvironments.map((env, i) => (
                      <span key={i} className="bg-indigo-400 rounded-md text-white ml-1 py-0.5 px-1">
                        {env.label}
                      </span>
                    ))}
                  </div>
                  <div className="mt-4">
                    This will remove {selectedEnvironments.length === 1 ? 'this environment' : 'these environments'}{' '}
                    from any versions currently using {selectedEnvironments.length === 1 ? 'it' : 'them'}.
                  </div>
                </div>
              ) : (
                <div>Version {version?.version} will be removed from all environments.</div>
              )}
            </div>

            {dirty && (
              <div className="text-sm text-red-600 mt-4">
                You have unsaved changes. Updating the environment will not refelect the changes made.
              </div>
            )}
          </div>
        </StyledDialog>
      </div>
    </>
  );
};

export default PromptEnvironmentsModal;
