import Select from 'react-select';
import { useEffect, useState } from 'react';

import './Selector.css';

/**
 * Represents a value in the selector.
 */
export interface SelectorValue {
  value: number | string;
  label: string;
}

export interface GroupedSelector {
  label: string;
  options: SelectorValue[];
}

/**
 * Represents the props for the Selector component.
 */
interface Props {
  values?: SelectorValue[];
  groupValues?: GroupedSelector[];
  defaultValue?: SelectorValue;
  classNames?: string;
  disabled?: boolean;
  onChange: (value: SelectorValue) => void;
  // isClearable?: boolean;
  loading?: boolean;
  isSearchable?: boolean;
  id?: string;
}

/**
 * Selector Component
 *
 * @component
 * @param {Props} props - The component props.
 * @param {SelectorValue[]} props.values - The array of values for the selector.
 * @param {GroupedSelector} props.groupValues - The grouped values for the selector.
 * @param {SelectorValue} props.defaultValue - The default value for the selector.
 * @param {string} props.classNames - The additional CSS classes for the selector.
 * @param {boolean} props.disabled - Whether the selector is disabled or not.
 * @param {boolean} props.isSearchable - Whether the selector is searchable or not.
 * @param {boolean} props.loading - Whether the selector is in a loading state or not.
 * @param {(value: SelectorValue) => void} props.onChange - The callback function to handle value changes.
 * @returns {JSX.Element} The rendered Selector component.
 */
const Selector: React.FC<Props> = ({
  values,
  groupValues,
  defaultValue,
  classNames,
  disabled = false,
  isSearchable = true,
  loading = false,
  id,
  onChange
}: Props) => {
  const [selectValues, setSelectValues] = useState<SelectorValue[] | undefined>(values);
  const [selectGroups, setSelectGroups] = useState<GroupedSelector[] | undefined>(groupValues);
  const [selectedValue, setSelectedValue] = useState<SelectorValue>();

  useEffect(() => {
    if (!values && !groupValues) {
      throw new Error('You must provide either values or groupValues to the Selector component.');
    }
  }, []);

  useEffect(() => {
    if (!values) return;

    setSelectValues(values);
    setSelectedValue(values.find((v) => v.value === defaultValue?.value) || values[0]);
  }, [values, defaultValue]);

  useEffect(() => {
    if (!groupValues) return;

    setSelectGroups(groupValues);
    setSelectedValue(
      groupValues.flatMap((group) => group.options).find((v) => v.value === defaultValue?.value) ||
        groupValues[0].options[0]
    );
  }, [groupValues, defaultValue]);

  const handleSelectorChange = (value: SelectorValue): void => {
    setSelectedValue(value);
    onChange(value);
  };

  const formatGroupLabel = (data: GroupedSelector) => (
    <div className="flex align-middle justify-between text-xs">
      <span>{data.label}</span>
      <span className="rounded border-slate-500 p-0.5 w-8 text-center bg-gray-400 text-white inline">
        {data.options.length}
      </span>
    </div>
  );

  return (
    <Select
      options={selectValues || selectGroups}
      formatGroupLabel={formatGroupLabel}
      isDisabled={disabled || loading}
      value={selectedValue}
      isSearchable={isSearchable}
      isClearable={false}
      isLoading={loading}
      id={id}
      className={classNames || ''}
      onChange={(value) => handleSelectorChange(value as SelectorValue)}
      classNamePrefix="promptly"
      menuPortalTarget={document.body}
    />
  );
};

export default Selector;
