import Handlebars from 'handlebars';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBoxArchive, faPencil, faSave, faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router';
import {
  generatePromptPayload,
  generatePromptPayloadFromVersion,
  userCanEditPrompt,
  userIsOwner
} from '../../common/prompts';
import { deepCopy, deepEqual, deepMergeExcludeMissing, getErrorMessage } from '../../common/utils';
import {
  StyledDialog,
  ModelParameterGroup,
  PromptDiffModal,
  PromptEditor,
  PromptVersions,
  DialogProps,
  MetricOverviewGroup,
  PromptEnvironmentsModal,
  PromptActionToolbar,
  PromptPipelineRunner,
  PromptVersionSaveModal
} from '../../components';
import Selector, { SelectorValue } from '../../components/common/Selector';
import { DEFAULT_DF_FORMAT } from '../../constants';
import { getModels } from '../../services/Models';
import { getPrompt, getPromptPipelines, updatePrompt } from '../../services/Prompts';
import { createVersion, getVersions } from '../../services/PromptVersions';
import { getUserLocal } from '../../services/User';
import { ModelParameterValue, Prompt as IPrompt, PromptVersion, Model, Pipeline } from '../../types';
import { history } from '../../lib/history';
import { Transition } from 'history';
import { faCopy, faNoteSticky } from '@fortawesome/free-regular-svg-icons';
import { PromptTemplate, PromptTypes, PromptVersionTypes, Tool } from '../../types/Prompt';
import { format } from 'date-fns';
import { TagsInput } from 'react-tag-input-component';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useValidateEvaluator, useValidateNewVersion, useVersionTypeSelectors } from '../../hooks/versionHooks';

interface Props {}

const emptyDialogProps: DialogProps = {
  title: '',
  isOpen: false,
  onClose: () => {}
};

/**
 * Prompt page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const Prompt: React.FC<Props> = ({}: Props) => {
  const routerParams = useParams();
  const navigateDestination = useRef<Transition>();
  const unblockNavigationRef = useRef<Function>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isShowSaveDialog, setIsShowSaveDialog] = useState<boolean>(false);
  const [isShowDiff, setIsShowDiff] = useState<boolean>(false);
  const [isShowPipelineRunner, setIsShowPipelineRunner] = useState<boolean>(false);
  const [runPipelineOnSave, setRunPipelineOnSave] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<IPrompt>(); //location.state?.prompt);
  const [selectedVersion, setSelectedVersion] = useState<PromptVersion | undefined>(undefined);
  const [promptMutable, setPromptMutable] = useState<IPrompt>();
  const [versionMutable, setVersionMutable] = useState<PromptVersion>();
  const [selectedModel, setSelectedModel] = useState<Model>();
  const [versions, setVersions] = useState<PromptVersion[]>();
  const [pipelines, setPipelines] = useState<Pipeline[]>();
  const [models, setModels] = useState<Model[]>([]);
  const [versionTypeVal, setPromptVersionTypeSelector] = useState<SelectorValue>();
  const [diffVersion, setDiffVersion] = useState<PromptVersion>();
  const [dialogProps, setDialogProps] = useState<DialogProps>({ ...emptyDialogProps });
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [canRelease, setCanRelease] = useState<boolean>(false);
  const versionTypeSelectors = useVersionTypeSelectors(promptMutable);

  // Load necessary data
  useEffect(() => {
    (async () => {
      try {
        const [_models, _prompt, _versions, _pipelines] = await Promise.all([
          getModels(),
          getPrompt(routerParams.promptId!),
          getVersions(routerParams.promptId!),
          getPromptPipelines(routerParams.promptId!)
        ]);

        setModels(_models);
        setPrompt(_prompt);
        setPromptMutable(_prompt);
        setVersions(_versions);
        syncVersionNumber(_versions);
        setPipelines(_pipelines);
      } catch (error) {
        return toast.error(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // When selected version is loaded, set the model, version type and create a mutable copy of the version
  useEffect(() => {
    if (!selectedVersion) return;

    setSelectedModel(models.find((m) => m.mid === selectedVersion.model));
    setPromptVersionTypeSelector(versionTypeSelectors.find((t) => t.value === selectedVersion.type));
    setVersionMutable(deepCopy(selectedVersion));
    ensureVersionUrl();
  }, [selectedVersion]);

  useEffect(
    () => setPromptVersionTypeSelector(versionTypeSelectors.find((t) => t.value === selectedVersion?.type)),
    [versionTypeSelectors]
  );

  // When prompt is loaded, set the mutable prompt and set user permissions
  useEffect(() => {
    if (!prompt) return;

    setCanEdit(userCanEditPrompt(prompt, getUserLocal()!) && !prompt.archived);
    setCanRelease(userIsOwner(prompt, getUserLocal()!) && !prompt.archived);
    setPromptMutable(deepCopy(prompt));
  }, [prompt]);

  // Check if navigation should be blocked
  useEffect(() => {
    if (!versionMutable) return;

    if (isDirty()) {
      unblockNavigationRef.current?.();
      unblockNavigationRef.current = history.block((tx) => {
        navigateDestination.current = tx;
        return false;
      });
    } else {
      unblockNavigationRef.current?.();
      // sometimes the navigation is blocked after saving
      ensureVersionUrl();
    }
  }, [history, versionMutable?.template, versionMutable?.messageTemplate, selectedModel, versionMutable?.parameters]);

  /**
   * Synchronizes the version number of the prompt based on the provided versions and router parameters.
   * Default is latest, or the one request in URL.
   * @param {PromptVersion[]} versions - An array of prompt versions.
   */
  const syncVersionNumber = (versions: PromptVersion[]) => {
    if (!versions) {
      return;
    } else if (!versions.length) {
      toast.error('No versions found. Was this created via the API? If so, please create a version.');
    } else {
      const requestedVersion = Number(routerParams.version);
      if (!isNaN(requestedVersion)) {
        const selected = versions.find((v) => v.version === requestedVersion);
        if (selected) {
          setSelectedVersion(deepCopy(selected));
        } else {
          toast.error(`Could not find v${requestedVersion}.`);
        }
      } else {
        setSelectedVersion(deepCopy(versions[0]));
      }
    }
  };

  /**
   * Checks if the prompt is dirty, i.e., if any of its mutable properties have changed.
   * @returns {boolean} True if the prompt is dirty, false otherwise.
   */
  const isDirty = () => {
    if (!prompt || !promptMutable || !versionMutable || !selectedVersion || !selectedModel) return false;

    return (
      promptMutable.description !== prompt.description ||
      !deepEqual(promptMutable.tags, prompt.tags) ||
      promptMutable.name !== prompt.name ||
      (versionMutable.type === PromptVersionTypes.MESSAGING
        ? !deepEqual(versionMutable.messageTemplate, selectedVersion.messageTemplate)
        : versionMutable.template !== selectedVersion?.template) ||
      !deepEqual(versionMutable.parameters, selectedVersion.parameters) ||
      versionMutable.type !== selectedVersion.type ||
      selectedModel.mid !== selectedVersion.model ||
      !deepEqual(versionMutable.samplePayload, selectedVersion.samplePayload) ||
      !deepEqual(versionMutable.tools, selectedVersion.tools) ||
      !deepEqual(versionMutable.customProps, selectedVersion.customProps) ||
      !deepEqual(versionMutable.helpers, selectedVersion.helpers)
    );
  };

  /**
   * Ensures that the URL reflects the selected version of the prompt.
   * @returns {void} This function does not return a value.
   */
  const ensureVersionUrl = () => {
    if (!selectedVersion) return;

    const newPath = `/prompts/${prompt?.id}/${selectedVersion.version}`;
    if (history.location.pathname !== newPath) {
      history.replace({ pathname: `/prompts/${prompt?.id}/${selectedVersion.version}` });
    }
  };

  /**
   * Updates a mutable field of the prompt version.
   * @param field - The field to update.
   * @param value - The new value for the field.
   */
  const updateVersionMutableField = (field: keyof PromptVersion, value: any) =>
    setVersionMutable({ ...deepCopy(versionMutable!), [field]: value });

  /**
   * Updates a mutable field of the prompt object.
   *
   * @param field - The field to update.
   * @param value - The new value for the field.
   */
  const updatePromptMutableField = (field: keyof IPrompt, value: any) =>
    setPromptMutable({ ...deepCopy(promptMutable!), [field]: value });

  /**
   * Handles the change of the model selection. This will update the selected model and the parameters of the selected version.
   * @param model - The selected model.
   * @param parameters - The parameters of the selected model.
   */
  const handleModelChange = (model: Model, parameters: ModelParameterValue[]) => {
    setSelectedModel(model);
    setVersionMutable({ ...versionMutable!, parameters, model: model.mid });
  };
  /**
   * Handles the change event when a version is selected. Will prompt the user if there are unsaved changes.
   * @param version - The selected version number.
   */
  const handleOnVersionChange = (version: number) => {
    const selected = versions?.find((v) => v.version === version);
    if (selected) {
      if (
        versionMutable?.template !== selectedVersion?.template ||
        selectedModel?.mid !== selectedVersion?.model ||
        !deepEqual(versionMutable?.parameters, selectedVersion?.parameters)
      ) {
        showDialog(
          'Unsaved Changes',
          'You have unsaved changes. Are you sure you want to continue?',
          undefined,
          'Cancel',
          'Continue',
          () => {},
          () => {
            changeVersion(selected);
            resetDialog();
          },
          faPencil
        );
      } else {
        changeVersion(selected);
      }
    } else {
      toast.error(`Could not find v${version}.`);
    }
  };

  /**
   * Changes the version of the prompt.
   *
   * @param version - The new version of the prompt.
   */
  const changeVersion = (version: PromptVersion) => {
    setSelectedModel(models.find((m) => m.mid === version.model));
    setSelectedVersion(version);
  };

  /**
   * Shows a version diff between the current version and the selected version.
   * @param version - The version number to diff.
   */
  const handleDiffClick = (version: number) => {
    const selected = versions?.find((v) => v.version === version);
    if (selected) {
      setIsShowDiff(true);
      setDiffVersion(selected);
    } else {
      toast.error(`Could not find selected v${version} to diff.`);
    }
  };

  /**
   * Shows the changelog for the selected version.
   * @param version - The version number of the changelog.
   */
  const handleOnChangelogClick = (version: number) => {
    const selected = versions?.find((v) => v.version === version);
    if (selected) {
      showDialog(
        `Changelog for v${version}`,
        undefined,
        <div>
          <div>{selected.changeLog}</div>
          <div className="text-sm mt-2">
            {selected.author?.name} on {format(selected.updated, DEFAULT_DF_FORMAT)}
          </div>
        </div>,
        'Close',
        undefined,
        undefined,
        undefined,
        faNoteSticky
      );
    } else {
      toast.error(`Could not find selected v${version} for change log.`);
    }
  };

  /**
   * Runs validations and prompts the user to confirm saving the prompt.
   */
  const handleOnSave = async () => {
    if (!promptMutable || !versionMutable) return;

    updateVersionMutableField('changeLog', '');
    const id = 'toasty-id';

    if (!isDirty()) {
      return toast.error('No changes detected.', { id });
    }

    const validatorProps = { prompt: promptMutable, version: versionMutable };
    const validationErrors = useValidateNewVersion(validatorProps);
    const validationEvaluatorErrors = useValidateEvaluator(validatorProps);

    if (validationErrors) {
      return toast.error(validationErrors, { id });
    } else if (validationEvaluatorErrors) {
      showDialog(
        `Missing Parameters`,
        `Your template must include the following parameters: ${validationEvaluatorErrors.join(', ')}.`,
        undefined,
        'Close',
        undefined,
        undefined,
        undefined,
        faWarning
      );

      return;
    }

    // TODO: add template validation for messages?
    if (versionMutable.type !== PromptVersionTypes.MESSAGING) {
      try {
        Handlebars.compile(versionMutable.template)({});
      } catch (error) {
        console.error(error);
        const err = getErrorMessage(error).replace('Expecting', '\nExpecting');
        showDialog(
          'Template Error',
          undefined,
          <>
            <div className="text-md text-red-700">There was an error detected in your template.</div>
            <div className="text-sm text-gray-600 mt-4">{err}</div>
          </>,
          'Cancel',
          'Save Anyway',
          undefined,
          () => {
            resetDialog();
            setIsShowSaveDialog(true);
          },
          faWarning,
          'w-1/2'
        );

        return;
      }
    }

    setIsShowSaveDialog(true);
  };

  /**
   * Saves the prompt and creates a new version.
   * @returns {Promise<void>} A promise that resolves when the save operation is complete.
   */
  const save = async (changeLog: string, runPipeline: boolean) => {
    const id = 'toasty-id';

    if (!promptMutable || !versionMutable) return;

    if (!changeLog.trim().length) {
      return toast.error('Changelog is required.', { id });
    }

    updateVersionMutableField('changeLog', changeLog);
    setRunPipelineOnSave(runPipeline);
    setIsShowSaveDialog(false);
    setIsSaving(true);

    toast.loading('Updating Prompt', { id });

    try {
      await updatePrompt(
        promptMutable.id!,
        promptMutable.name,
        promptMutable.description,
        promptMutable.tags,
        false,
        promptMutable.type,
        promptMutable.defaultPipeline?.id
      );
      toast.success('Prompt Updated', { id });
      toast.loading('Creating New Version', { id });
    } catch (error) {
      return toast.error(`Error updating prompt: ${getErrorMessage(error)}`, { id });
    } finally {
      setIsSaving(false);
    }

    const hasSamplePayload = !deepEqual(versionMutable.samplePayload, generatePromptPayloadFromVersion(versionMutable));

    let version: PromptVersion = {
      ...versionMutable,
      version: versions!.length + 1,
      samplePayload: hasSamplePayload ? versionMutable.samplePayload : undefined,
      changeLog
    };

    try {
      version = await createVersion(version);
    } catch (error) {
      return toast.error(`Error creating new version: ${getErrorMessage(error)}`, { id });
    }

    toast.success(`Created Version ${version.version}`, { id });
    setVersions([version, ...versions!]);
    setSelectedVersion(version);

    if (runPipelineOnSave) {
      setIsShowPipelineRunner(true);
    }

    return;
  };

  const handleEnvironmentSave = async (envs: string[]) => {
    updateVersionMutableField('environments', envs);

    setVersions(
      versions?.map((v) => {
        if (v.version === selectedVersion?.version) {
          return { ...v, environments: envs };
        }

        return { ...v, environments: v.environments.filter((e) => !envs.includes(e)) };
      })
    );
  };

  /**
   * Called when the user changes the prompt template or message template. It will also generate a sample payload.
   * @param template - The new template for the prompt.
   */
  const handleTemplateChange = (template: string | PromptTemplate, tools: Tool[]) => {
    if (versionTypeVal?.value === PromptVersionTypes.MESSAGING) {
      const updatedSample = deepMergeExcludeMissing(generatePromptPayload(template), versionMutable?.samplePayload);

      setVersionMutable({
        ...deepCopy(versionMutable!),
        template: '',
        messageTemplate: template as PromptTemplate,
        samplePayload: deepEqual(updatedSample, {}) ? versionMutable?.samplePayload : updatedSample,
        tools
      });
    } else {
      setVersionMutable({
        ...deepCopy(versionMutable!),
        template: template as string,
        messageTemplate: undefined,
        samplePayload: deepMergeExcludeMissing(generatePromptPayload(template), versionMutable?.samplePayload),
        tools: undefined
      });
    }
  };

  /**
   * Handles the change of the version type.
   *
   * @param type - The selected version type.
   */
  const handleVersionTypeChange = (type: SelectorValue) => {
    setPromptVersionTypeSelector(type);
    updateVersionMutableField('type', type.value as PromptVersionTypes);
  };

  /**
   * Resets the shared dialog properties.
   */
  const resetDialog = () => {
    setDialogProps({ ...emptyDialogProps });
  };

  const showDialog = (
    title: string,
    message?: string,
    children?: React.ReactElement[] | React.ReactElement | string,
    closeText: string = 'Close',
    confirmText?: string,
    onClose?: () => void,
    onConfirm?: () => void,
    icon?: IconProp,
    width?: string
  ) => {
    setDialogProps({
      isOpen: true,
      title,
      message,
      children,
      closeText,
      confirmText,
      width,
      onClose: () => {
        if (onClose) onClose();
        resetDialog();
      },
      onConfirm,
      icon
    });
  };

  return (
    <>
      <div className="mx-auto">
        <div className="flex mt-4 space-x-4">
          <div className="w-full">
            <div className="sticky z-10 -top-5 bg-white">
              <PromptActionToolbar
                prompt={promptMutable}
                version={versionMutable}
                pipelines={pipelines}
                canEdit={canEdit}
                onUpdateField={updateVersionMutableField}
              />
            </div>
            <div className="w-full mr-4 rounded-lg border-gray-300 border overflow-hidden flex flex-col">
              <div className="border-b border-gray-300 px-1 flex justify-between">
                <div className="flex-1">
                  {!isLoading ? (
                    <input
                      value={promptMutable?.name}
                      placeholder="Prompt Name"
                      onChange={(e) => updatePromptMutableField('name', e.target.value)}
                      onBlur={(e) => updatePromptMutableField('name', e.target.value.trim())}
                      disabled={isSaving || isLoading || !canEdit}
                      required
                      className="block w-full border-0 pt-2.5 text-lg font-medium placeholder-gray-500 focus:ring-0"
                    />
                  ) : (
                    <Skeleton className="!leading-7 !w-48 mt-1.5 ml-2 mb-[12px]" />
                  )}
                </div>
                <div className="rounded-full bg-indigo-600 text-white pt-0.5 mt-3 text-sm font-semibold h-6 px-3 mr-3 text-center">
                  v{selectedVersion?.version || 1}
                </div>
                {prompt?.archived && (
                  <div className="pt-0.5 mt-3 text-sm font-semibold h-6 pr-3 ">
                    <FontAwesomeIcon icon={faBoxArchive} className="mr-1 w-4 h-4 inline text-gray-500" />
                    <span className="text-gray-400">Archived</span>
                  </div>
                )}
              </div>

              <PromptEditor
                promptVersion={versionMutable}
                disabled={isSaving || isLoading || !canEdit}
                loading={isLoading}
                readOnly={!canEdit}
                onChange={handleTemplateChange}
              />
              <div className="w-full py-1 px-1 border-t border-gray-300">
                <div className="text-sm px-3 pt-1 text-gray-600">Notes</div>
                {!isLoading ? (
                  <textarea
                    placeholder="Add any notes you'd like to keep about this prompt."
                    rows={2}
                    onChange={(e) => updatePromptMutableField('description', e.target.value)}
                    onBlur={(e) => updatePromptMutableField('description', e.target.value.trim())}
                    disabled={isSaving || isLoading || !canEdit}
                    value={promptMutable?.description}
                    className="w-full resize-none border-none focus:ring-0"
                  />
                ) : (
                  <Skeleton containerClassName="h-16 w-[calc(100%-10px)] block" className="ml-2" count={2} />
                )}
              </div>
              <div className="w-full items-center flex justify-end px-4 py-1 border-t border-gray-300">
                <div className="flex-1 text-gray-600">
                  <div className="flex items-center">
                    <div className="text-sm inline-block">Tags</div>
                    {!isLoading ? (
                      <TagsInput
                        value={promptMutable?.tags}
                        onChange={(tags: string[]) => updatePromptMutableField('tags', tags)}
                        name="tags"
                        placeHolder="Add Tag"
                        disabled={isSaving || isLoading || !canEdit}
                        classNames={{
                          input: 'focus:ring-0 !w-auto !text-xs !pl-0 placeholder-indigo-700 text-indigo-700',
                          tag: 'mr-2 !pl-2 !py-1 text-xs !bg-gray-200'
                        }}
                      />
                    ) : (
                      <Skeleton containerClassName="ml-2 w-1/5" />
                    )}
                  </div>
                </div>
                <div>
                  {!canEdit ? (
                    ''
                  ) : (
                    <button
                      className={'standard ' + (isLoading || isSaving ? 'disabled:opacity-25' : '')}
                      onClick={handleOnSave}
                      disabled={isLoading || isSaving}>
                      {isSaving ? (
                        <div>
                          <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
                        </div>
                      ) : (
                        <div>
                          <FontAwesomeIcon icon={faSave} className="mr-1.5" /> Save
                        </div>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4 text-sm">
              Prompt ID: <span className="text-gray-600">{prompt?.id}</span>
              <span className="ml-2 cursor-pointer">
                <CopyToClipboard
                  text={prompt?.id || ''}
                  onCopy={() => {
                    toast.success('Prompt ID copied to clipboard!');
                  }}>
                  <FontAwesomeIcon icon={faCopy} className="h-4 w-4 mr-1 inline-block" />
                </CopyToClipboard>
              </span>
            </div>
          </div>
          <div className="flex-intial w-64">
            {isLoading ? (
              <Skeleton className="mb-4" />
            ) : (
              prompt?.type === PromptTypes.PROMPT && (
                <PromptEnvironmentsModal
                  version={versionMutable}
                  owner={canRelease}
                  dirty={isDirty()}
                  disabled={isSaving || isLoading || !canEdit}
                  onSave={handleEnvironmentSave}
                />
              )
            )}
            <Selector
              values={versionTypeSelectors}
              defaultValue={versionTypeVal}
              onChange={handleVersionTypeChange}
              disabled={isSaving || isLoading || !canEdit}
              isSearchable={false}
              classNames="w-full mb-4"
            />
            <ModelParameterGroup
              models={models}
              model={selectedModel}
              parameters={versionMutable?.parameters || []}
              disabled={isSaving || isLoading || !canEdit}
              type={versionMutable?.type}
              onChange={handleModelChange}
            />
            <PromptVersions
              prompt={promptMutable}
              versions={versions || []}
              selectedVersion={selectedVersion?.version}
              onVersionChange={handleOnVersionChange}
              onDiffClick={handleDiffClick}
              onChangeLogClick={handleOnChangelogClick}
            />
          </div>
        </div>
        {prompt?.type === PromptTypes.PROMPT && (
          <div>
            <h2 className="text-lg text-gray-800 mt-10 pt-8 pb-2 border-t border-gray-900/10">
              Version {selectedVersion?.version} Metrics
            </h2>
            {prompt && selectedVersion ? (
              <MetricOverviewGroup promptId={prompt.id} version={selectedVersion?.version} />
            ) : (
              <Skeleton count={3} />
            )}
          </div>
        )}
      </div>
      <PromptDiffModal
        current={versionMutable}
        previous={diffVersion}
        isOpen={isShowDiff}
        onClose={() => {
          setIsShowDiff(false);
        }}
      />
      <PromptVersionSaveModal
        version={versionMutable}
        previousVersion={selectedVersion}
        hasPipelines={!!pipelines?.length}
        isOpen={isShowSaveDialog}
        isDisabled={isSaving}
        onSave={save}
        onClose={() => setIsShowSaveDialog(false)}
      />
      <PromptPipelineRunner
        prompt={promptMutable}
        version={versionMutable}
        pipelines={pipelines}
        isOpen={isShowPipelineRunner}
        onClose={() => setIsShowPipelineRunner(false)}
      />
      <StyledDialog {...dialogProps} />
    </>
  );
};

export default Prompt;
