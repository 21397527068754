import { PipelineEvaluator } from '../../types';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faFlaskVial, faPencil } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import ModifyEvaluator from './ModifyEvaluator';
import { EvaluatorType } from '../../types/PipelineEvaluations';
import { toTitleCase } from '../../common/utils';

/**
 * Props for the EvaluatorTile component.
 */
interface Props {
  evaluator?: PipelineEvaluator;
  onUpdate?: (evaluator: PipelineEvaluator) => void;
}

/**
 * Renders a evaluator tile component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Evaluator} props.evaluator - The evaluator object to display.
 * @param {Function} props.onUpdate - The update event handler for the tile.
 * @returns {JSX.Element} The rendered EvaluatorTile component.
 */
const PipelineEvaluatorTile: React.FC<Props> = (props: Props) => {
  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
  const [evaluator, setEvaluator] = useState<PipelineEvaluator>();
  // const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useEffect(() => {
    if (props.evaluator) setEvaluator(props.evaluator);
  }, [props.evaluator]);

  const handleOnEvaluatorUpdate = (newEvaluator: PipelineEvaluator) => {
    setEvaluator(newEvaluator);
    setIsShowUpdateModal(false);
    if (newEvaluator && props.onUpdate) props.onUpdate(newEvaluator);
  };
  //   setIsDeleting(true);

  //   try {
  //     await deleteEvaluator(evaluator.pipelineId, evaluator.id);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDeleting(false);
  //     setIsShowDeleteModal(false);
  //   }
  // };

  return (
    <li className="group colspan-1 rounded-lg border-gray-300 border p-3 shadow-lg col-span-1">
      <div className="flex items-center w-full">
        <h3 className="text-gray-800 font-semibold overflow-hidden">
          <FontAwesomeIcon icon={faFlaskVial} className="inline w-4 h-4 text-indigo-500  mr-2" />
        </h3>
        <span className="w-full">{evaluator ? evaluator.name : <Skeleton containerClassName="inline" />}</span>
      </div>
      <h4 className="text-sm text-gray-600 mt-2 h-16 w-full word-break-all line-clamp-3">
        <div>{evaluator?.name ? evaluator.name : <Skeleton />}</div>
        {evaluator?.type === EvaluatorType.PROMPT && (
          <div>{evaluator?.promptVersionNumber ? `Version  ${evaluator.promptVersionNumber}` : <Skeleton />}</div>
        )}
      </h4>
      <div className="flex justify-between mt-4 items-end">
        <div className="flex text-sm text-gray-600">{evaluator ? toTitleCase(evaluator.type) : <Skeleton />}</div>
        <div className="text-sm align-middle justify-end text-gray-500 flex">
          <a
            href={
              evaluator?.type === EvaluatorType.PROMPT
                ? `/prompts/${evaluator?.promptId}/${evaluator?.promptVersionNumber}`
                : `/webhooks`
            }
            target="_blank"
            rel="noreferrer"
            className="!text-gray-500 leading-none mr-3">
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="w-4 h-4 hover:text-indigo-700 duration-200" />
          </a>
          <FontAwesomeIcon
            icon={faPencil}
            className="w-4 h-4 cursor-pointer hover:text-indigo-700 duration-200"
            onClick={() => setIsShowUpdateModal(true)}
          />
        </div>
      </div>
      {evaluator && isShowUpdateModal && (
        <ModifyEvaluator
          evaluator={evaluator}
          onClose={() => setIsShowUpdateModal(false)}
          onSave={handleOnEvaluatorUpdate}
        />
      )}
    </li>
  );
};

export default PipelineEvaluatorTile;
