import { formatISO, parse, set, subDays } from 'date-fns';
import { axiosInstance } from '../api';
import {
  apiToMetricsCompare,
  apiToPromptBucketedMetric,
  apiToPromptBucketedModelMetric,
  apiToPromptMetricOverviewType,
  apiToPromptMetricPage
} from '../common/typeUtils';
import { apiExceptionHandler, downloadFile } from '../common/utils';
import {
  MetricsFilters,
  MetricsSort,
  MetricsSortPair,
  PromptBucketedMetric,
  PromptMetricOverview,
  PromptMetricPage
} from '../types';
import { FilterApiMappings } from '../types/CallHistory';
import { MetricsCompare, MetricsCompareSummary, ModelBucketedMetric, PromptMetric } from '../types/Metrics';

const generateFilters = (filters: MetricsFilters): Record<string, any> => {
  const { startDate, endDate } = handleDates(filters.startDate, filters.endDate);

  const payload: FilterApiMappings = {
    prompt_id: filters.promptId,
    prompt_version_number: filters.promptVersion,
    created__gte: startDate,
    created__lte: endDate,
    model__in: filters.models?.join(','),
    latency__gte: filters.latencyMin,
    latency__lte: filters.latencyMax,
    request_price__gte: filters.requestPriceMin,
    request_price__lte: filters.requestPriceMax,
    response_price__gte: filters.responsePriceMin,
    response_price__lte: filters.responsePriceMax,
    ttfb__gte: filters.ttfbMin,
    ttfb__lte: filters.ttfbMax,
    score__gte: filters.scoreMin,
    score__lte: filters.scoreMax,
    analyst_score__gte: filters.analystScoreMin,
    analyst_score__lte: filters.analystScoreMax,
    streaming: filters.streaming,
    prompt__archived: filters.archived,
    search: filters.search,
    meta_filter_key: filters.metafilterKey,
    meta_filter_value: filters.metafilterValue,
    source: filters.source,
    prompt__like: filters.promptLike, // this can be a string or an array ,
    completion__like: filters.completionLike, // this can be a string or an array
    feedback__neq: filters.hasFeedback === true ? '' : undefined,
    analyst_feedback__neq: filters.hasSpecialistFeedback === true ? '' : undefined
  };

  Object.keys(payload).forEach((key: string) => (payload as any)[key] === undefined && delete (payload as any)[key]);
  return payload;
};

interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface ISearchDefaults {
  pageSize: number;
  startPage: number;
  sort: MetricsSortPair;
  days: number;
}

export const SearchDefaults: ISearchDefaults = {
  pageSize: 50,
  startPage: 1,
  sort: { sortBy: MetricsSort.CREATED, sortDesc: true },
  days: 30
};

/**
 * Calculates the date range based on the provided start and end dates.
 * If start date or end date is not provided, it uses the current date and the default number of days.
 * @param startDate - The start date of the date range. If not provided, the default number of days will be subtracted from the current date.
 * @param endDate - The end date of the date range. If not provided, the current date will be used.
 * @param defaultDays - The default number of days to subtract from the current date if start date is not provided. Defaults to {SearchDefaults.days} days.
 * @returns The calculated date range object with start and end dates.
 * @throws Error if only one date is provided.
 */
const handleDates = (
  startDate: Date | undefined = undefined,
  endDate: Date | undefined = undefined,
  defaultDays: number = SearchDefaults.days
): DateRange => {
  if ((!startDate && endDate) || (startDate && !endDate)) {
    throw new Error('Start and end dates are exclusive.');
  }

  if (typeof endDate === 'string') {
    endDate = parse(endDate, 'yyyy-MM-dd', new Date()); // for some reason date picker sometimes returns strings
  }

  if (typeof startDate === 'string') {
    startDate = parse(startDate, 'yyyy-MM-dd', new Date());
  }

  if (!startDate || !endDate) {
    endDate = new Date();
    startDate = subDays(endDate, defaultDays);
  }

  return {
    startDate: set(startDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
    endDate: set(endDate, { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
  };
};

/**
 * Retrieves the overview metrics. If start date and end date are not provided, the default is {SearchDefaults.days} days.
 * @param endpoint The endpoint to retrieve the metrics from.
 * @param startDate The start date to retrieve metrics for.
 * @param endDate The end date to retrieve metrics for.
 * @param includeArchived Whether to include archived prompts. Defaults to false.
 * @param adminShowAll Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the overview metrics  .
 */
const getOverviewMetrics = async (
  endpoint: string,
  start: Date | undefined = undefined,
  end: Date | undefined = undefined,
  includeArchived: boolean = false,
  adminShowAll: boolean = false
): Promise<PromptMetricOverview> => {
  try {
    const { startDate, endDate } = handleDates(start, end);
    const params: any = { start_date: formatISO(startDate), end_date: formatISO(endDate), su: adminShowAll };
    if (includeArchived) {
      params.include_archived = includeArchived;
    }

    const response = await axiosInstance.get(endpoint, { params });
    return apiToPromptMetricOverviewType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while loading metrics summary.');
  }
};

/**
 * Retrieves the overview metrics for all prompts. If start date and end date are not provided, the default is {SearchDefaults.days} days.
 * @param startDate The start date to retrieve metrics for.
 * @param endDate The end date to retrieve metrics for.
 * @param includeArchived Whether to include archived prompts. Defaults to false.
 * @param adminShowAll Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the overview metrics for all prompts.
 */
export const getPromptsOverviewMetrics = async (
  startDate: Date | undefined = undefined,
  endDate: Date | undefined = undefined,
  includeArchived: boolean = false,
  adminShowAll: boolean = false
): Promise<PromptMetricOverview> => {
  return await getOverviewMetrics('metrics/prompts/overview', startDate, endDate, includeArchived, adminShowAll);
};

/**
 * Compares metrics between two prompts/versions.
 *
 * @param promptId - The ID of the first prompt.
 * @param promptVersion - The version of the first prompt.
 * @param prompt2Id - The ID of the second prompt.
 * @param prompt2Version - The version of the second prompt.
 * @param days - The number of days to compare metrics for (default: 30).
 * @returns The compared metrics.
 * @throws Throws an error if an error occurs while loading metrics summary.
 */
export const compareMetrics = async (
  promptId: string,
  promptVersion: number,
  prompt2Id: string,
  prompt2Version: number,
  days: number = 30
): Promise<MetricsCompare> => {
  try {
    const response = await axiosInstance.get(
      `metrics/compare/${promptId}/${promptVersion}/${prompt2Id}/${prompt2Version}`,
      {
        params: { days }
      }
    );
    return apiToMetricsCompare(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while loading metrics summary.');
  }
};

/**
 * Summarizes the results using the provided data.
 * @param data - The data to be used for summarization.
 * @returns A Promise that resolves to a string representing the summarized results.
 * @throws Throws an error if an error occurs while summarizing the results.
 */
export const summarizeComparedMetrics = async (data: MetricsCompare): Promise<MetricsCompareSummary> => {
  const compare: any = { version1: { ...data.version1 }, version2: { ...data.version2 } };

  compare.version1 = {
    ...compare.version1,
    prompt: data.version1.version.template,
    model: data.version1.version.model,
    parameters: data.version1.version.parameters
  };
  compare.version2 = {
    ...compare.version2,
    prompt: data.version2.version.template,
    model: data.version2.version.model,
    parameters: data.version2.version.parameters
  };
  delete compare.version1.version;
  delete compare.version2.version;

  // Round all numbers to 4 decimal places to keep the model from returning scientific notation
  Object.keys(compare.version1).forEach((key: string) => {
    if (typeof compare.version1[key] === 'number') {
      compare.version1[key] = Number(compare.version1[key]).toFixed(4);
      compare.version2[key] = Number(compare.version1[key]).toFixed(4);
    }
  });

  try {
    const response = await axiosInstance.post(`metrics/compare/summarize`, compare);
    return response.data;
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while summarizing the results.');
  }
};

/**
 * Retrieves the overview metrics for a specific prompt. If start date and end date are not provided, the default is {SearchDefaults.days} days.
 * @param promptId The ID or name of the prompt.
 * @param startDate The start date to retrieve metrics for.
 * @param endDate The end date to retrieve metrics for.
 * @param adminShowAll Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the overview metrics for the specified prompt.
 */
export const getPromptOverviewMetrics = async (
  promptId: string,
  startDate: Date | undefined = undefined,
  endDate: Date | undefined = undefined,
  adminShowAll: boolean = false
): Promise<PromptMetricOverview> => {
  return await getOverviewMetrics(`metrics/prompts/${promptId}/overview`, startDate, endDate, false, adminShowAll);
};

/**
 * Retrieves the overview metrics for a specific version. If start date and end date are not provided, the default is {SearchDefaults.days} days.
 * @param promptId The ID or name of the prompt.
 * @param version The name of the version.
 * @param startDate The start date to retrieve metrics for.
 * @param endDate The end date to retrieve metrics for.
 * @param adminShowAll Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the overview metrics for the specified version.
 */
export const getVersionOverviewMetrics = async (
  promptId: string,
  version: number,
  startDate: Date | undefined = undefined,
  endDate: Date | undefined = undefined,
  adminShowAll: boolean = false
): Promise<PromptMetricOverview> => {
  return await getOverviewMetrics(
    `metrics/prompts/${promptId}/v/${version}/overview`,
    startDate,
    endDate,
    false,
    adminShowAll
  );
};

/**
 * Retrieves the detailed metrics for all prompts. If start date and end date are not provided, the default is {SearchDefaults.days} days.
 * @param filters The filters to apply to the metrics.
 * @param sort How to sort results.
 * @param page The page number to retrieve.
 * @param perPage The number of items per page.
 * @param adminShowAll Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the detailed metrics for all prompts.
 */
export const getMetrics = async (
  filters: MetricsFilters,
  sort: MetricsSortPair,
  page: number,
  perPage: number,
  adminShowAll: boolean = false
): Promise<PromptMetricPage> => {
  try {
    const params: any = {
      page,
      size: perPage,
      sort_by: sort.sortBy,
      sort_desc: sort.sortDesc,
      su: adminShowAll,
      ...generateFilters(filters)
    };

    const response = await axiosInstance.get('/metrics/prompts', { params });

    return apiToPromptMetricPage(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while loading your prompt metrics.');
  }
};

/**
 * Retrieves the detailed metrics for all prompts as a CSV. If start date and end date are not provided, the default is {SearchDefaults.days} days.
 * @param filters The filters to apply to the metrics.
 * @param sort How to sort results.
 *
 * @returns A Promise that resolves to the detailed metrics for all prompts.
 */
export const getMetricsCsv = async (filters: MetricsFilters, sort: MetricsSortPair): Promise<void> => {
  const params: any = {
    sort_by: sort.sortBy,
    sort_desc: sort.sortDesc,
    ...generateFilters(filters)
  };

  await downloadFile('/metrics/prompts/csv', params);
};

/**
 * Retrieves bucketed metrics from the specified endpoint.
 *
 * @param endpoint - The API endpoint to retrieve the metrics from.
 * @param start - The start date for the metrics (optional).
 * @param end - The end date for the metrics (optional).
 * @param includeArchived - Whether to include archived prompts. Defaults to false.
 * @param adminShowAll - Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the bucketed metrics.
 * @throws Throws an error if an error occurs while loading the metrics.
 */
const getBucketedMetrics = async (
  endpoint: string,
  start: Date | undefined = undefined,
  end: Date | undefined = undefined,
  includeArchived: boolean = false,
  adminShowAll: boolean = false
): Promise<PromptBucketedMetric[]> => {
  try {
    const { startDate, endDate } = handleDates(start, end);
    const params: any = { start_date: formatISO(startDate), end_date: formatISO(endDate), su: adminShowAll };
    if (includeArchived) {
      params.include_archived = includeArchived;
    }
    const response = await axiosInstance.get(endpoint, {
      params
    });
    return response.data.map((d: any) => apiToPromptBucketedMetric(d));
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while loading your prompt metrics.');
  }
};

/**
 * Retrieves the bucketed metrics for prompts within a specified date range.
 *
 * @param startDate - The start date of the date range. Defaults to undefined.
 * @param endDate - The end date of the date range. Defaults to undefined.
 * @param includeArchived - Whether to include archived prompts. Defaults to false.
 * @param adminShowAll - Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the bucketed metrics for prompts.
 */
export const getPromptsBucketedMetrics = async (
  startDate: Date | undefined = undefined,
  endDate: Date | undefined = undefined,
  includeArchived: boolean = false,
  adminShowAll: boolean = false
): Promise<PromptBucketedMetric[]> =>
  await getBucketedMetrics('metrics/prompts/by_date', startDate, endDate, includeArchived, adminShowAll);

/**
 * Retrieves the bucketed metrics for a prompt within a specified date range.
 *
 * @param promptId - The ID of the prompt.
 * @param startDate - The start date of the date range. Defaults to undefined.
 * @param endDate - The end date of the date range. Defaults to undefined.
 * @param adminShowAll - Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the bucketed metrics for prompts.
 */
export const getPromptBucketedMetrics = async (
  promptId: string,
  startDate: Date | undefined = undefined,
  endDate: Date | undefined = undefined,
  adminShowAll: boolean = false
): Promise<PromptBucketedMetric[]> =>
  await getBucketedMetrics(`metrics/prompts/by_date/${promptId}`, startDate, endDate, false, adminShowAll);

/**
 * Retrieves the bucketed metrics for a prompt version within a specified date range.
 *
 * @param promptId - The ID of the prompt.
 * @param version - The version of the prompt.
 * @param startDate - The start date of the date range. Defaults to undefined.
 * @param endDate - The end date of the date range. Defaults to undefined.
 * @param adminShowAll - Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the bucketed metrics for prompts.
 */
export const getPromptVersionBucketedMetrics = async (
  promptId: string,
  version: number,
  startDate: Date | undefined = undefined,
  endDate: Date | undefined = undefined,
  adminShowAll: boolean = false
): Promise<PromptBucketedMetric[]> =>
  await getBucketedMetrics(`metrics/prompts/by_date/${promptId}/v/${version}`, startDate, endDate, false, adminShowAll);

/**
 * Retrieves bucketed model metrics from the specified endpoint.
 *
 * @param endpoint - The API endpoint to retrieve the metrics from.
 * @param start - The start date for the metrics (optional).
 * @param end - The end date for the metrics (optional).
 * @param includeArchived - Whether to include archived prompts. Defaults to false.
 * @param adminShowAll - Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the bucketed metrics.
 * @throws Throws an error if an error occurs while loading the metrics.
 */
const getBucketedModelMetrics = async (
  endpoint: string,
  start: Date | undefined = undefined,
  end: Date | undefined = undefined,
  includeArchived: boolean = false,
  adminShowAll: boolean = false
): Promise<Record<string, ModelBucketedMetric[]>> => {
  try {
    const { startDate, endDate } = handleDates(start, end);
    const params: any = { start_date: formatISO(startDate), end_date: formatISO(endDate), su: adminShowAll };
    if (includeArchived) {
      params.include_archived = includeArchived;
    }
    const response = await axiosInstance.get(endpoint, { params });

    return apiToPromptBucketedModelMetric(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while loading your prompt metrics.');
  }
};

/**
 * Retrieves the bucketed model metrics for prompts within a specified date range.
 *
 * @param startDate - The start date of the date range. Defaults to undefined.
 * @param endDate - The end date of the date range. Defaults to undefined.
 * @param includeArchived - Whether to include archived prompts. Defaults to false.
 * @param adminShowAll - Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the bucketed metrics for prompts.
 */
export const getPromptsBucketedModelMetrics = async (
  startDate: Date | undefined = undefined,
  endDate: Date | undefined = undefined,
  includeArchived: boolean = false,
  adminShowAll: boolean = false
): Promise<Record<string, ModelBucketedMetric[]>> =>
  await getBucketedModelMetrics('metrics/models/by_date', startDate, endDate, includeArchived, adminShowAll);

/**
 * Retrieves the bucketed model metrics for a prompt within a specified date range.
 *
 * @param promptId - The ID of the prompt.
 * @param startDate - The start date of the date range. Defaults to undefined.
 * @param endDate - The end date of the date range. Defaults to undefined.
 * @param adminShowAll - Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the bucketed metrics for prompts.
 */
export const getPromptBucketedModelMetrics = async (
  promptId: string,
  startDate: Date | undefined = undefined,
  endDate: Date | undefined = undefined,
  adminShowAll: boolean = false
): Promise<Record<string, ModelBucketedMetric[]>> =>
  await getBucketedModelMetrics(`metrics/models/by_date/p/${promptId}`, startDate, endDate, false, adminShowAll);

/**
 * Retrieves the bucketed model metrics for a prompt version within a specified date range.
 *
 * @param promptId - The ID of the prompt.
 * @param version - The version of the prompt.
 * @param startDate - The start date of the date range. Defaults to undefined.
 * @param endDate - The end date of the date range. Defaults to undefined.
 * @param adminShowAll - Whether to show all prompts. Defaults to false. Only available to admins.
 * @returns A Promise that resolves to the bucketed metrics for prompts.
 */
export const getPromptVersionBucketedModelMetrics = async (
  promptId: string,
  version: number,
  startDate: Date | undefined = undefined,
  endDate: Date | undefined = undefined,
  adminShowAll: boolean = false
): Promise<Record<string, ModelBucketedMetric[]>> =>
  await getBucketedModelMetrics(
    `metrics/models/by_date/p/${promptId}/v/${version}`,
    startDate,
    endDate,
    false,
    adminShowAll
  );

/**
 * Rates an event for an analyst.
 *
 * @param metric - The metric to rate.
 * @returns A promise that resolves to void.
 * @throws Throws an error if an error occurs while rating the event.
 */
export const rateAnalyst = async (metric: PromptMetric): Promise<void> => {
  try {
    return await axiosInstance.post(`metrics/${metric.id}/add-score`, {
      score: metric.analystScore,
      feedback: metric.analystFeedback,
      source: 'ANALYST'
    });
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while rating the event.');
  }
};

export const exportedForTesting = {
  handleDates,
  generateFilters,
  getOverviewMetrics,
  getBucketedMetrics,
  getBucketedModelMetrics
};
