import { axiosInstance } from '../api';
import { apiToAuthType, apiToUserType } from '../common/typeUtils';
import { apiExceptionHandler } from '../common/utils';
import { NewUser, User, UserBasicInfo } from '../types';
import { UserAuth, UserRole } from '../types/User';

export const localStorageKey = 'userInfo';
export const localOktaKey = 'okta-token-storage';

/**
 * Logs in a user and returns the authentication information.
 * @param user - The user object containing email and password.
 * @returns A promise that resolves to the user authentication information.
 * @throws An error if an error occurs while signing in.
 */
export const login = async (user: User): Promise<UserAuth> => {
  const data = new FormData();
  data.append('username', user.email);
  data.append('password', user.password);

  try {
    const response = await axiosInstance({
      method: 'POST',
      url: 'auth/token',
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    const auth = apiToAuthType(response.data);
    if (auth.active === true) {
      // store the auth info in local storage
      localStorage.setItem(localStorageKey, JSON.stringify(auth));
      // then use that to get the user info
      await getUser(true);
    }

    return auth;
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while signing in.');
  }
};

/**
 * Retrieves the basic information of the currently logged-in user from local storage.
 * @returns The basic information of the user, or undefined if not found.
 */
export const getUserLocal = (): UserBasicInfo | undefined => {
  try {
    return JSON.parse(localStorage.getItem(localStorageKey) as string) as UserBasicInfo;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Retrieves the basic information of the currently logged-in user from the server.
 * @param refresh - Whether to force a refresh of the user information from the server.
 * @returns A promise that resolves to the basic information of the user.
 * @throws An error if an error occurs while locating the user information.
 */
export const getUser = async (refresh: boolean = false): Promise<UserBasicInfo> => {
  try {
    if (!refresh && localStorageKey in localStorage) {
      return JSON.parse(localStorage.getItem(localStorageKey) as string) as UserBasicInfo;
    }
    const userData = (await axiosInstance.get('users/me')).data;
    // enrich auth info with user details
    const user = {
      ...apiToUserType(userData),
      ...(JSON.parse(localStorage.getItem(localStorageKey) as string) as UserAuth)
    };

    localStorage.setItem(localStorageKey, JSON.stringify(user));

    return userData;
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while locating your information.');
  }
};

/**
 * Retrieves the basic information of a user by their email.
 * @param email - The email of the user.
 * @returns A promise that resolves to the basic information of the user.
 * @throws An error if an error occurs while locating the user.
 */
export const getUserByEmail = async (email: string): Promise<UserBasicInfo> => {
  try {
    const response = await axiosInstance.get(`users/by_email?email=${encodeURIComponent(email)}`);
    return apiToUserType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while locating the user.');
  }
};

/**
 * Logs out the currently logged-in user by removing their information from local storage.
 */
export const logout = () => {
  console.log('Logging out');
  localStorage.removeItem(localStorageKey);
  localStorage.removeItem(localOktaKey);
};

/**
 * Registers a new user.
 * @param user - The user object containing the new user's information.
 * @returns A promise that resolves when the registration is successful.
 * @throws An error if an error occurs while registering.
 */
export const register = async (user: NewUser): Promise<void> => {
  try {
    return await axiosInstance.post('users/register/', user);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while registering.');
  }
};

/**
 * Registers a new user with Okta.
 *
 * @param {NewUser} user - The new user to register.
 * @returns {Promise<void>} A promise that resolves when the user is registered.
 * @throws Will throw an error if the registration fails.
 */
export const registerOkta = async (user: NewUser): Promise<void> => {
  try {
    return await axiosInstance.post('users/register?is_okta=true', user);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while registering.');
  }
};

/**
 * Sends a password reset email to the specified email address.
 * @param email - The email address to send the password reset email to.
 * @returns A Promise that resolves to void.
 * @throws If an error occurs while sending the password reset email.
 */
export const sendPasswordResetEmail = async (email: string): Promise<void> => {
  try {
    return await axiosInstance.post(`users/forgot-password?email=${email}`);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while sending the password reset email.');
  }
};

/**
 * Resets the user's password using the provided code and new password.
 * @param code - The reset code sent to the user's email.
 * @param password - The new password to set for the user.
 * @returns A Promise that resolves to void.
 * @throws Throws an error if an error occurs while resetting the password.
 */
export const resetPassword = async (code: string, password: string): Promise<void> => {
  try {
    return await axiosInstance.post('users/reset-password', { code, password });
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while resetting the password.');
  }
};

/**
 * Checks if a reset token is valid.
 * @param resetToken - The reset token to check.
 * @returns A Promise that resolves to a boolean indicating whether the reset token is valid.
 */
export const isResetTokenValid = async (resetToken: string): Promise<boolean> => {
  try {
    await axiosInstance.get(`users/check-reset/${resetToken}`);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Changes the user's password.
 *
 * @param currentPassword - The current password of the user.
 * @param newPassword - The new password to be set for the user.
 * @returns A Promise that resolves to void.
 * @throws Throws an error if an error occurs while changing the password.
 */
export const changePassword = async (currentPassword: string, newPassword: string): Promise<void> => {
  try {
    return await axiosInstance.post('users/change-password', { password: currentPassword, new_password: newPassword });
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while changing the password.');
  }
};

/**
 * Rotates the user's API key.
 *
 * @returns A Promise that resolves to void.
 * @throws Throws an error if an error occurs while rotating the API key.
 */
export const rotateApiKey = async (): Promise<void> => {
  try {
    return await axiosInstance.post('users/rotate-api-key', {});
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while changing the password.');
  }
};

/**
 * Retrieves the user's API key.
 *
 * @returns A Promise that resolves to void.
 * @throws Throws an error if an error occurs while retrieving the API key.
 */
export const getApiKey = async (): Promise<string> => {
  try {
    const { data } = await axiosInstance.get('users/api-key');
    return data['api_key'];
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching your API key.');
  }
};

/**
 * Checks if the current user is an admin.
 * @returns {boolean} Returns true if the user is an admin, otherwise false.
 */
export const isAdmin = () => getUserLocal()?.role === UserRole.ADMIN;

/**
 * Checks if a user exists by verifying the local user data and making an API call.
 *
 * @param {string} email The email of the user to check.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the user exists, otherwise `false`.
 */
export const userExists = async (email: string): Promise<boolean> => {
  try {
    await axiosInstance.get(`users/valid?email=${email}`);
    return true;
  } catch (error) {
    return false;
  }
};
