import { useEffect, useState } from 'react';
import { PromptVersion, Variant } from '../../types';
import { VariantType } from '../../types/Experiment';
import { getErrorMessage } from '../../common/utils';
import { StyledDialog } from '..';
import { faCirclePlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { createVariant, updateVariant } from '../../services/Experimentation';
import Selector, { SelectorValue } from '../common/Selector';
import { getVersions } from '../../services/PromptVersions';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Props for the AddVariantModal component.
 */
interface Props {
  experimentId: string;
  promptId: string;
  isOpen: boolean;
  variant?: Variant;
  onClose: () => void;
  onSave: (variant: Variant) => void;
}

const variantTypeSelectors: { [key: string]: SelectorValue } = {
  control: { value: 1, label: 'Control' },
  variant: { value: 2, label: 'Variant' }
};

/**
 * AddVariantModal component displays a modal for adding a new variant.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {string} props.experimentId - The ID of the experiment.
 * @param {string} props.promptId - The ID of the prompt.
 * @param {Variant} props.variant - The variant to manage. Optional.
 * @param {boolean} props.isOpen - Indicates whether the modal is open or not.
 * @param {Function} props.onClose - Callback function to handle the modal close event.
 * @param {Function} props.onSave - Callback function to handle the save event when a new variant is created.
 * @returns {JSX.Element} The rendered AddVariantModal component.
 */
const ManageVariantModal: React.FC<Props> = ({ experimentId, promptId, variant, isOpen, onClose, onSave }: Props) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [percentage, setPercentage] = useState<number>(100);
  const [isVersionsLoading, setIsVersionsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [versionSelectors, setVersionSelectors] = useState<SelectorValue[]>([]);
  const [versions, setVersions] = useState<PromptVersion[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<PromptVersion>();
  const [selectedVariantType, setSelectedVariantType] = useState<SelectorValue>(variantTypeSelectors.control);

  const resetForm = () => {
    setName('');
    setDescription('');
    setSelectedVersion(versions[0]);
    setPercentage(100);
  };

  const handleOnClose = () => {
    if (isSaving) return;
    resetForm();
    isOpen = false;
    onClose();
  };

  useEffect(() => {
    if (!isOpen) return;

    (async () => {
      try {
        let _versions: PromptVersion[] = [];
        if (!versions?.length) {
          setIsVersionsLoading(true);
          _versions = await getVersions(promptId);
          setVersions(_versions);
          setVersionSelectors(
            _versions.map((version) => ({ value: version.version || 0, label: `v${version.version}` }))
          );
        }

        setSelectedVersion(
          !variant ? _versions[0] : _versions.find((version) => version.version === variant.promptVersionNumber)
        );
      } catch (error) {
        return toast.error(getErrorMessage(error));
      } finally {
        setIsVersionsLoading(false);
      }
    })();
  }, [isOpen]);

  useEffect(() => {
    if (variant) {
      setName(variant.name);
      setDescription(variant.description);
      setPercentage(variant.percentage);
      setSelectedVariantType(
        variant.type === VariantType.CONTROL ? variantTypeSelectors.control : variantTypeSelectors.variant
      );
      setSelectedVersion(versions.find((version) => version.version === variant.promptVersionNumber));
    } else {
      resetForm();
    }
  }, [variant]);

  const handleOnVersionChange = (value: SelectorValue) => {
    const version = versions.find((version) => version.version === value.value);
    setSelectedVersion(version);
  };

  const handleOnCreate = async () => {
    if (selectedVersion === undefined) {
      toast.error('Please select a version.');
      return;
    } else if (!name.length || !description.length) {
      toast.error('Please provide a name and description.');
      return;
    } else if (percentage < 1 || percentage > 100) {
      toast.error('Please provide a percentage between 1 and 100.');
      return;
    }

    setIsSaving(true);

    const vType = selectedVariantType.value === 1 ? VariantType.CONTROL : VariantType.VARIANT;
    let _variant: Variant;

    try {
      if (!variant) {
        _variant = await createVariant(
          experimentId,
          promptId,
          selectedVersion.version,
          name,
          description,
          percentage,
          vType
        );
      } else {
        _variant = await updateVariant(
          experimentId,
          selectedVersion.version,
          variant.id!,
          name,
          description,
          percentage,
          vType
        );
      }
    } catch (error) {
      const err = getErrorMessage(error);
      if (err.includes('already exists')) {
        toast.error('This version already has a variant. Please select a different version.');
      } else {
        toast.error(err);
      }
      return;
    } finally {
      setIsSaving(false);
    }

    resetForm();
    onSave(_variant);
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title={`${variant ? 'Edit' : 'Add'} Variant`}
      closeText="Cancel"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : variant ? (
          'Update'
        ) : (
          'Create'
        )
      }
      onConfirm={handleOnCreate}
      onClose={handleOnClose}
      disabled={isVersionsLoading || isSaving}
      icon={faCirclePlus}>
      <div className="w-96">
        <div className="mt-4">
          <div className="text-sm text-gray-600">Version</div>
          {isVersionsLoading ? (
            <Skeleton count={1} className="!leading-10 !w-48" />
          ) : (
            <Selector
              onChange={handleOnVersionChange}
              values={versionSelectors}
              classNames="w-48"
              disabled={isVersionsLoading || isSaving}
              defaultValue={versionSelectors.find((v) => v.value === selectedVersion?.version)}
            />
          )}
        </div>
        <div className="mt-4">
          <div className="text-sm text-gray-600 ">Type</div>
          <Selector
            onChange={(action) => setSelectedVariantType(action)}
            values={Object.values(variantTypeSelectors)}
            classNames="w-48"
            defaultValue={selectedVariantType}
            disabled={isVersionsLoading || isSaving}
          />
        </div>
        <div className="mt-4">
          <div className="text-sm text-gray-600 ">Traffic Allocation</div>
          <input
            type="range"
            className="transparent h-[4px] w-1/2 flex-grow cursor-pointer appearance-none border-transparent accent-indigo-500 bg-neutral-200 dark:bg-neutral-600"
            min={1}
            max={100}
            step={1}
            value={percentage}
            disabled={isVersionsLoading || isSaving}
            onChange={(e) => setPercentage(Number(e.target.value))}
          />
          <input
            className="text-sm mt-2 text-gray-900 block disabled:text-gray-400 rounded-md border-0 px-1 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 w-9"
            disabled={isVersionsLoading || isSaving}
            onChange={(e) => setPercentage(Number(e.target.value))}
            value={new Intl.NumberFormat().format(Number(percentage))}
          />
        </div>
        <div className="mt-4">
          <input
            id="variantName"
            name="variantName"
            type="text"
            autoComplete="off"
            value={name}
            placeholder="Variant Name"
            onChange={(e) => setName(e.currentTarget.value)}
            required
            disabled={isVersionsLoading || isSaving}
            className="disabled:text-gray-400 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-4">
          <textarea
            id="variantDesc"
            name="variantDesc"
            placeholder="Variant description"
            rows={3}
            onChange={(e) => setDescription(e.currentTarget.value)}
            required
            disabled={isVersionsLoading || isSaving}
            className="disabled:text-gray-400 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={description}
          />
        </div>
      </div>
    </StyledDialog>
  );
};

export default ManageVariantModal;
