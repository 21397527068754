import {
  faAnchor,
  faArrowUpRightFromSquare,
  faCheckCircle,
  faFileCsv,
  faPersonRunning,
  faRocket,
  faSpinner,
  faTerminal,
  faTrophy,
  faWandMagicSparkles,
  faX
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router';
import { GroupBox, PipelineScoreCard, PromptOutputFormatter, SideBar, SidePanel, StyledDialog } from '../../components';
import { useInterval } from 'usehooks-ts';

import {
  downloadRunData,
  getEvaluators,
  getPipeline,
  getRun,
  getRunCompletions,
  startRun
} from '../../services/PipelineEvaluations';
import { PipelineEvaluator, PipelineRun as IPipelineRun, Pipeline } from '../../types';
import {
  EvaluatorType,
  PipelineCompletion,
  RunStatus,
  RunStatusLabels,
  RunType
} from '../../types/PipelineEvaluations';
import { Prompts } from '../prompts';
import { PromptTypes } from '../../types/Prompt';
import { DEFAULT_DF_FORMAT } from '../../constants';
import { format, formatDistanceStrict } from 'date-fns';
import { faFaceSadCry } from '@fortawesome/free-regular-svg-icons';
import { formatCurrency, getErrorMessage } from '../../common/utils';

const PipelineRunPage: React.FC<Props> = () => {
  const [selectedPage, setSelectedPage] = useState<number>(2);
  const navigate = useNavigate();
  const routerParams = useParams();

  useEffect(() => {
    if (selectedPage === 0) navigate('/pipelines');
    else if (selectedPage === 1) navigate(`/pipelines/${routerParams.pipelineId!}`);
  }, [selectedPage]);

  return (
    <div className="-m-5">
      <SideBar
        rows={['Pipelines', 'Overview', 'Run Results', 'Evaluator Prompts']}
        rowClasses={['', 'ml-4', 'ml-4', '']}
        selectedIndex={selectedPage}
        onClick={(index) => setSelectedPage(index)}>
        <div>
          {selectedPage === 2 && <PipelineRun />}
          {selectedPage === 3 && <Prompts type={PromptTypes.EVALUATOR} />}
        </div>
      </SideBar>
    </div>
  );
};

export default PipelineRunPage;

interface Props {}

/**
 * Represents the evaluation of a completion. Basically a completion with an evaluator's score and feedback.
 */
export interface SiblingEvaluation {
  id: string;
  name: string;
  score: number;
  feedback: string;
  bubbleColor?: string;
  type: EvaluatorType;
}

export interface CompletionEvaluated {
  completionId: number;
  piplineRunId: string;
  prompt: string;
  completion: string;
  evaluatorId: string;
  score: number;
  created: Date;
  requestTokens: number;
  responseTokens: number;
  requestCost: number;
  responseCost: number;
  failed: boolean;
  evaluatorName: string;
  evaluatorType: EvaluatorType;
  evaluatorFeedback: string;
  evaluatorRequestTokens: number;
  evaluatorResponseTokens: number;
  evaluatorRequestCost: number;
  evaluatorResponseCost: number;
  siblings?: SiblingEvaluation[];
}

const POLLING_DELAY = 5000;

/**
 * Renders the PipelineRun component.
 */
const PipelineRun: React.FC<Props> = ({}: Props) => {
  const routerParams = useParams();
  // FIXME: pipeline, run and evaluators come in from router state, but i cant seem to unset them on refresh. for now just load them from the api
  const [pipeline, setPipeline] = useState<Pipeline>(); // (location.state?.pipeline);
  const [pipelineRun, setPipelineRun] = useState<IPipelineRun>(); //(location.state?.run);
  const [evaluators, setEvaluators] = useState<PipelineEvaluator[]>(); //(location.state?.evaluators);
  const [evaluatorMap, setEvaluatorMap] = useState<{ [key: string]: PipelineEvaluator }>({});
  const [completions, setCompletions] = useState<PipelineCompletion[]>([]);
  const [tableData, setTableData] = useState<CompletionEvaluated[]>([]);
  const [status, setStatus] = useState<RunStatus>();
  const [selectedCompletion, setSelectedCompletion] = useState<CompletionEvaluated>();
  const [completionsCost, setCompletionsCost] = useState<number>(0);
  const [evaluatorsCost, setEvaluatorsCost] = useState<number>(0);
  const [isLoadingPipeline, setIsLoadingPipeline] = useState<boolean>(true);
  const [isLoadingPipelineRun, setIsLoadingPipelineRun] = useState<boolean>(true);
  const [isLoadingPipelineCompletions, setIsLoadingPipelineCompletions] = useState<boolean>(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState<boolean>(false);
  const [isShowDetails, setIsShowDetails] = useState<boolean>(false);
  const [isShowScoreCard, setIsShowScoreCard] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (pipeline) {
        setIsLoadingPipeline(false);
        return;
      }
      const [pipelineId, runId] = [routerParams.pipelineId!, routerParams.runId!];
      if (!pipelineId || !runId) return;

      try {
        const [_pipeline, _evaluators, _pipelineRun] = await Promise.all([
          getPipeline(pipelineId),
          getEvaluators(pipelineId),
          getRun(pipelineId, runId)
        ]);

        setPipeline(_pipeline);
        setEvaluators(_evaluators);
        setPipelineRun(_pipelineRun);
      } catch (error) {
        return toast.error(getErrorMessage(error));
      } finally {
        setIsLoadingPipeline(false);
        setIsLoadingPipelineRun(false);
      }
    })();

    // FIXME: this isnt working. basically when the page is refreshed, the state needs to be cleared
    return () => {
      setIsLoadingPipelineCompletions(false);
      // on a refresh clear out the state
      setPipeline(undefined);
      setPipelineRun(undefined);
      setEvaluators(undefined);
    };
  }, []);

  useEffect(() => {
    if (!pipelineRun) return;
    if (pipelineRun.status === RunStatus.COMPLETED) {
      (async () => {
        await loadCompletions();
      })();
    } else if (pipelineRun.status === RunStatus.RUNNING) {
      setIsLoadingPipelineCompletions(true);
    }

    setStatus(pipelineRun.status);
  }, [pipelineRun]);

  useEffect(() => {
    if (!completions || !evaluators || !pipelineRun) return;

    setEvaluatorMap(
      evaluators.reduce((acc: any, evaluator) => {
        if (!acc[evaluator.id]) {
          acc[evaluator.id] = evaluator;
        }
        return acc;
      }, {})
    );

    setCompletionsCost(completions.reduce((acc, c) => acc + (c.requestCost + c.responseCost), 0));

    let completionsCost = 0.0;
    let evaluatorsCost = 0.0;

    for (const completion of completions) {
      completionsCost += completion.requestCost + completion.responseCost;
      for (const evaluation of completion.evaluations) {
        evaluatorsCost += evaluation.requestCost + evaluation.responseCost;
      }
    }

    setCompletionsCost(completionsCost);
    setEvaluatorsCost(evaluatorsCost);

    setTableData(
      completions.flatMap((completion) =>
        completion.evaluations.flatMap((evaluation) => ({
          ...completion,
          score: evaluation.score,
          evaluatorFeedback: evaluation.feedback,
          evaluatorRequestTokens: evaluation.requestTokens,
          evaluatorResponseTokens: evaluation.responseTokens,
          evaluatorRequestCost: evaluation.requestCost,
          evaluatorResponseCost: evaluation.responseCost,
          evaluatorName: evaluatorMap[evaluation.evaluatorId].name,
          evaluatorType: evaluatorMap[evaluation.evaluatorId].type,
          evaluatorId: evaluation.evaluatorId,
          piplineRunId: pipelineRun.id
        }))
      )
    );
  }, [completions, evaluators]);

  useInterval(
    async () => {
      if (!pipelineRun || status !== RunStatus.RUNNING) {
        return;
      }
      await loadCompletions();
    },
    isLoadingPipelineCompletions ? POLLING_DELAY : null
  );

  const run = async () => {
    if (!pipeline || !pipelineRun) return;

    setStatus(RunStatus.RUNNING);
    try {
      await startRun(pipeline.id, pipelineRun.id);
    } catch (error) {
      console.error(error);
      setStatus(RunStatus.FAILED);
      return toast.error(getErrorMessage(error));
    }
    setIsLoadingPipelineCompletions(true);
  };

  const loadCompletions = async () => {
    if (!pipeline || !pipelineRun) return;

    try {
      const c = await getRunCompletions(pipeline.id, pipelineRun.id);
      if (!c || !c.length) return;
      else if (c[0].runStatus === RunStatus.COMPLETED) {
        setIsLoadingPipelineCompletions(false);
      }
      setStatus(c[0].runStatus);

      setCompletions(c);
    } catch (error) {
      console.error(error);
      setIsLoadingPipelineCompletions(false);
      setStatus(RunStatus.FAILED);
      return toast.error(getErrorMessage(error));
    }
  };

  const handleDownload = async () => {
    if (!pipeline || !pipelineRun) return;

    setIsLoadingCsv(true);
    try {
      await downloadRunData(pipeline.id, pipelineRun.id);
    } catch (error) {
      console.error(error);
      return toast.error(getErrorMessage(error));
    } finally {
      setIsLoadingCsv(false);
    }
  };

  const generateColumns = (): TableColumn<any>[] => {
    return [
      {
        name: 'ID',
        selector: (c: CompletionEvaluated) => c.completionId,
        sortable: true,
        width: '60px'
      },
      {
        name: 'Evaluator',
        selector: (c: CompletionEvaluated) => c.evaluatorName,
        sortable: true,
        width: '120px'
      },
      {
        name: 'Prompt',
        selector: (c: CompletionEvaluated) => c.prompt,
        width: '150px'
      },
      {
        name: 'Completion',
        selector: (c: CompletionEvaluated) => c.completion,
        width: '150px'
      },
      {
        name: 'Score',
        selector: (c: CompletionEvaluated) => c.score,
        sortable: true,
        width: '80px'
      },
      {
        name: 'Feedback',
        selector: (c: CompletionEvaluated) => c.evaluatorFeedback,
        width: '222px'
      },
      {
        name: 'Pass',
        selector: (c: CompletionEvaluated) => (c.failed ? '❌' : '✅'),
        sortable: true,
        width: '80px'
      },
      {
        name: 'Tokens',
        selector: (c: CompletionEvaluated) =>
          `${c.requestTokens.toLocaleString()} / ${c.responseTokens.toLocaleString()}`,
        sortable: false,
        width: '120px'
      },
      {
        name: 'Cost',
        selector: (c: CompletionEvaluated) =>
          `${formatCurrency(c.requestCost || 0, 5)} / ${formatCurrency(c.responseCost || 0, 5)}`,
        sortable: true,
        width: '160px'
      },
      {
        name: 'Created',
        selector: (c: CompletionEvaluated) => format(c.created, DEFAULT_DF_FORMAT),
        sortable: true,
        width: '160px'
      }
    ];
  };

  const handleCallRowClick = (c: CompletionEvaluated, e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    c.siblings = tableData
      .filter((s) => s.completionId === c.completionId)
      .map((s) => ({
        id: s.evaluatorId,
        name: s.evaluatorName,
        score: s.score,
        feedback: s.evaluatorFeedback,
        type: s.evaluatorType,
        bubbleColor: s.score < 3 ? 'bg-red-500' : s.score >= 3 && s.score < 6 ? 'bg-yellow-500' : 'bg-green-500'
      }));

    setSelectedCompletion(c);
    setIsShowDetails(true);
  };

  return (
    <div onClick={() => setIsShowDetails(false)}>
      <div className="flex justify-end">
        <div className="w-1/2">
          {isLoadingPipeline || isLoadingPipelineRun ? (
            <Skeleton count={3} containerClassName="w-52" />
          ) : (
            <div>
              <div className="text-gray-800 text-2xl">{pipeline?.name}</div>
              <div className="text-gray-700 ">{pipelineRun?.name}</div>
              <div className="text-gray-500 text-sm mt-2">
                {pipeline?.prompt.name} v{pipelineRun?.promptVersionNumber}
                <a
                  href={`/prompts/${pipeline?.promptId}/${pipelineRun?.promptVersion}`}
                  target="_blank"
                  rel="noreferrer"
                  className="!text-gray-500 leading-none mr-3">
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className="pl-2 w-4 h-4 hover:text-indigo-700 duration-200"
                  />
                </a>
              </div>
              <div className="mt-4 text-gray-600 text-sm">
                {status === RunStatus.COMPLETED ? (
                  <div className="flex items-center">
                    <div>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-green-400 mr-2 h-5 w-5" />
                      Run Complete
                    </div>
                  </div>
                ) : status === RunStatus.RUNNING ? (
                  <div>
                    <FontAwesomeIcon icon={faSpinner} className="text-indigo-600 mr-2 h-5 w-5 inline" spin /> Running
                  </div>
                ) : status === RunStatus.NOT_STARTED ? (
                  <button className="standard" onClick={run} disabled={isLoadingPipelineRun}>
                    <FontAwesomeIcon icon={faPersonRunning} className="mr-2" />
                    Start Run
                  </button>
                ) : (
                  <div>
                    <FontAwesomeIcon icon={faX} className="text-red-500 mr-2 h-5 w-5" />
                    Failed
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="w-1/2 flex justify-end ml-8">
          {isLoadingPipelineRun || !pipelineRun ? (
            <Skeleton count={6} containerClassName="w-full" />
          ) : (
            <GroupBox title="Info" className="" classNameContent="px-3 py-2">
              <div className="text-sm grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2">
                <div className="text-gray-700">
                  <span className="mr-1">Status:</span>
                  <span className="font-semibold">{RunStatusLabels[pipelineRun.status]}</span>
                </div>
                {pipelineRun.type === RunType.NORMAL && (
                  <div className="text-gray-700">
                    <span className="mr-1">File:</span>
                    <span className="font-semibold">{pipelineRun.metadata.fileName}</span>
                  </div>
                )}
                <div className="text-gray-700">
                  <span className="mr-1">Type:</span>
                  <span className="font-semibold whitespace-nowrap">{pipelineRun.metadata.fileType}</span>
                </div>
                <div className="text-gray-700">
                  <span className="mr-1">Data Rows:</span>
                  <span className="font-semibold">{pipelineRun.metadata.rows.toLocaleString()}</span>
                </div>
                <div className="text-gray-700">
                  <span className="mr-1">Completed:</span>
                  <span className="font-semibold text-green-500">{tableData.filter((c) => !c.failed).length}</span>
                </div>
                <div className="text-gray-700">
                  <span className="mr-1">Failed:</span>
                  <span className="font-semibold text-red-500">{tableData.filter((c) => c.failed).length}</span>
                </div>
                <div className="text-gray-700">
                  <span className="mr-1">Created:</span>
                  <span className="font-semibold">{format(pipelineRun.created, DEFAULT_DF_FORMAT)}</span>
                </div>
                <div className="text-gray-700">
                  <span className="mr-1">Run Time:</span>
                  <span className="font-semibold">
                    {status === RunStatus.COMPLETED
                      ? formatDistanceStrict(pipelineRun?.completedAt, pipelineRun?.startedAt)
                      : '—'}
                  </span>
                </div>
                <div className="text-gray-700">
                  <span className="mr-1">Completions:</span>
                  <span className="font-semibold">{formatCurrency(completionsCost, 3)}</span>
                </div>
                <div className="text-gray-700">
                  <span className="mr-1">Evaluators:</span>
                  <span className="font-semibold">{formatCurrency(evaluatorsCost, 3)}</span>
                </div>
                <div className="text-gray-700">
                  <span className="mr-1">Total:</span>
                  <span className="font-semibold">{formatCurrency(completionsCost + evaluatorsCost, 3)}</span>
                </div>
              </div>
            </GroupBox>
          )}
        </div>
      </div>
      {pipelineRun && status === RunStatus.COMPLETED && (
        <div className="w-full text-right mt-2">
          <button className="standard" onClick={() => setIsShowScoreCard(true)}>
            <FontAwesomeIcon icon={faTrophy} /> Scorecard
          </button>
          <button className="standard ml-4" onClick={() => handleDownload()}>
            <FontAwesomeIcon icon={isLoadingCsv ? faSpinner : faFileCsv} spin={isLoadingCsv} /> Download
          </button>
        </div>
      )}
      <div className="mt-4 max-h-lvh block overflow-y-auto overflow-x-scroll rounded-lg border-gray-300 border">
        <DataTable
          striped
          pointerOnHover
          fixedHeaderScrollHeight="calc(100vh - 100px)"
          noDataComponent={
            <div className="text-center p-8">
              {status === RunStatus.NOT_STARTED ? (
                <div>
                  <FontAwesomeIcon icon={faRocket} className="w-14 h-14 mb-4 text-indigo-600" />
                  <div>Ready to go!</div>
                </div>
              ) : status === RunStatus.RUNNING ? (
                <div>
                  <FontAwesomeIcon
                    icon={faWandMagicSparkles}
                    className="w-14 h-14 mb-4 text-indigo-600 animate-pulse"
                  />
                  <div>Run starting, hang tight!</div>
                </div>
              ) : status === RunStatus.FAILED ? (
                <div>
                  <FontAwesomeIcon icon={faFaceSadCry} className="w-14 h-14 mb-4 text-indigo-600" />
                  <div>Looks like something went wrong! {pipelineRun?.errorMessage}</div>
                </div>
              ) : (
                <Skeleton count={10} />
              )}
            </div>
          }
          data={tableData || []}
          columns={generateColumns()}
          className="datatable"
          onRowClicked={handleCallRowClick}
        />
      </div>

      <SidePanel title="Completion Details" visible={isShowDetails} onClose={() => setIsShowDetails(false)}>
        <div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4 gap-y-6">
            {selectedCompletion?.siblings &&
              selectedCompletion.siblings.map((evaluator, i) => (
                <div key={i}>
                  <div className="flex mb-1.5 align-bottom">
                    <div className="text-gray-500 text-md font-semibold flex-1">
                      <FontAwesomeIcon icon={evaluator.type === EvaluatorType.PROMPT ? faTerminal : faAnchor} />{' '}
                      {evaluator.name}
                    </div>
                    <div
                      className={`font-semibold ${evaluator?.bubbleColor} text-white rounded-full text-center text-md w-6 h-6`}>
                      {evaluator.score}
                    </div>
                  </div>
                  <blockquote className="p-4 my-2 border-s-4 border-gray-300 bg-gray-50 text-xs mt-0">
                    <p className="text-gray-700">{evaluator.feedback}</p>
                  </blockquote>
                </div>
              ))}
          </div>
          <div className="text-gray-500 font-semibold mb-2">Stats</div>
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-y-2 gap-x-2 text-xs mb-4">
            <div className="bg-gray-50 p-1">
              <label className="text-gray-500 font-semibold">Completion Request Tokens</label>
              <p className="text-gray-700">{selectedCompletion?.requestTokens}</p>
            </div>
            <div className="bg-gray-50 p-1">
              <label className="text-gray-500 font-semibold">Completion Response Tokens</label>
              <p className="text-gray-700">{selectedCompletion?.responseTokens}</p>
            </div>
            <div className="bg-gray-50 p-1">
              <label className="text-gray-500 font-semibold">Completion Request Cost</label>
              <p className="text-gray-700">{formatCurrency(selectedCompletion?.requestCost || 0, 4)}</p>
            </div>
            <div className="bg-gray-50 p-1">
              <label className="text-gray-500 font-semibold">Completion Response Cost</label>
              <p className="text-gray-700">{formatCurrency(selectedCompletion?.responseCost || 0, 4)}</p>
            </div>
            <div className="bg-gray-50 p-1">
              <label className="text-gray-500 font-semibold">Evaluator Request Tokens</label>
              <p className="text-gray-700">{selectedCompletion?.evaluatorRequestTokens}</p>
            </div>
            <div className="bg-gray-50 p-1">
              <label className="text-gray-500 font-semibold">Evaluator Response Tokens</label>
              <p className="text-gray-700">{selectedCompletion?.evaluatorResponseTokens}</p>
            </div>
            <div className="bg-gray-50 p-1">
              <label className="text-gray-500 font-semibold">Evaluator Request Cost</label>
              <p className="text-gray-700">{formatCurrency(selectedCompletion?.evaluatorRequestCost || 0, 4)}</p>
            </div>
            <div className="bg-gray-50 p-1">
              <label className="text-gray-500 font-semibold">Evaluator Response Cost</label>
              <p className="text-gray-700">{formatCurrency(selectedCompletion?.evaluatorResponseCost || 0, 4)}</p>
            </div>
          </div>
          <div>
            <label className="text-gray-500 font-semibold">Prompt</label>
            <blockquote className="p-4 my-2 border-s-4 border-gray-300 bg-gray-50">
              <div className="text-gray-700">
                <PromptOutputFormatter result={selectedCompletion?.prompt} />
              </div>
            </blockquote>
          </div>
          <div className="mt-4">
            <label className="text-gray-500 font-semibold">Completion</label>
            <blockquote className="p-4 my-2 border-s-4 border-gray-300 bg-gray-50">
              <div className="text-gray-700">
                <PromptOutputFormatter result={selectedCompletion?.completion} />
              </div>
            </blockquote>
          </div>
        </div>
        <div className="flex mt-10 text-xs">
          <div className=" text-xs flex-1">
            <div>
              <div>
                <label className="text-gray-500 font-semibold mr-2">Run ID:</label>
                <span className="text-gray-700">{selectedCompletion?.piplineRunId}</span>
              </div>
              <div>
                <label className="text-gray-500 font-semibold mr-2">Evaluator ID:</label>
                <span className="text-gray-700">{selectedCompletion?.evaluatorId}</span>
              </div>
              <div>
                <label className="text-gray-500 font-semibold mr-2">Completion ID:</label>
                <span className="text-gray-700">{selectedCompletion?.completionId}</span>
              </div>
            </div>
          </div>
        </div>
      </SidePanel>

      <StyledDialog
        isOpen={isShowScoreCard}
        title="Pipeline Score Card"
        message="Please wait while we fetch the completions."
        onClose={() => setIsShowScoreCard(false)}
        width="w-2/5"
        icon={faTrophy}>
        <PipelineScoreCard evaulations={tableData} />
      </StyledDialog>
    </div>
  );
};
