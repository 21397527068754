import Skeleton from 'react-loading-skeleton';
import { Model } from '../../types';
import { GroupBox } from '../common';

/**
 * Props for the ModelPicker component.
 */
interface Props {
  models: Model[];
  disabled: boolean;
  selectedModels: string[];
  onModelClick(modelId: string, checked: boolean): void;
}

/**
 * Renders a model picker component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.models - The array of models to display.
 * @param {boolean} props.disabled - Indicates whether the model picker is disabled.
 * @param {Function} props.onModelClick - The callback function to handle model click events.
 * @returns {JSX.Element} The rendered ModelPicker component.
 */
const ModelPicker: React.FC<Props> = ({ models, disabled, selectedModels, onModelClick }: Props) => {
  const handleModelClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    onModelClick(e.currentTarget.value, e.currentTarget.checked);
  };

  return (
    <GroupBox title="Models">
      <div className="p-4">
        {!models.length && <Skeleton count={5} height={20} />}
        {models.map((model) => (
          <div className="flex items-center mb-4" key={model.mid}>
            <input
              disabled={disabled}
              id={model.mid}
              type="checkbox"
              checked={selectedModels.includes(model.mid)}
              value={model.mid}
              onChange={handleModelClick}
              className="w-4 h-4 rounded accent-indigo-500"
            />
            <label htmlFor={model.mid} className="ms-2 text-sm font-medium text-gray-500 ">
              {model.name}
            </label>
          </div>
        ))}
      </div>
    </GroupBox>
  );
};

export default ModelPicker;
