import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Represents a tab group component.
 */
interface Props {
  tabNames: Array<React.ReactElement[] | React.ReactElement | string>;
  selectedTabIndex: number;
  className?: string;
  onTabChange: (index: number) => void;
  children: React.ReactElement[] | React.ReactElement | string;
  extraTabHeaderContent?: React.ReactElement[] | React.ReactElement | string;
  helpLink?: string;
}

const TabGroup: React.FC<Props> = ({
  tabNames,
  selectedTabIndex,
  className = '',
  onTabChange,
  children,
  extraTabHeaderContent,
  helpLink
}: Props) => {
  return (
    <>
      <div
        className={`text-sm font-medium text-center border-b border-gray-200 dark:text-gray-400 mb-6 flex ${className}`}>
        <ul className="flex flex-wrap -mb-px flex-1">
          {tabNames.map((tabName, index) => (
            <li key={index}>
              <a
                role="button"
                onClick={onTabChange.bind(null, index)}
                className={`inline-block px-4 pb-2  border-b-2 ${selectedTabIndex === index ? 'text-indigo-600 border-indigo-600 active' : '!text-gray-500 hover:text-gray-600 hover:border-gray-300 border-transparent'} rounded-t-lg`}>
                {tabName}
              </a>
            </li>
          ))}
          {extraTabHeaderContent}
        </ul>
        {helpLink && (
          <a href={helpLink} target="_blank" rel="noreferrer" className="-mt-2 pb-2 block">
            <button className="help">
              <FontAwesomeIcon icon={faCircleQuestion} className="inline-block" />
            </button>
          </a>
        )}
      </div>
      {children}
    </>
  );
};

export default TabGroup;
