import { useEffect, useState } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { PromptVersion } from '../../types';
import Skeleton from 'react-loading-skeleton';
import { PromptVersionTypes, PromptMessageRole, PromptTemplate, PromptMessage, Tool } from '../../types/Prompt';
import PromptEditorMessage from './PromptEditorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ToolList from './ToolList';
import '../../styles/editor.css';
import { getMessageContentString } from '../../common/prompts';

/**
 * Props for the PromptEditor component.
 */
interface Props {
  promptVersion?: PromptVersion;
  loading: boolean;
  disabled: boolean;
  readOnly: boolean;
  onChange(template: string | PromptTemplate, tools: Tool[]): void;
}

/**
 * PromptEditor component is a form for editing prompts.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {PromptVersion} props.promptVersion - The prompt version object.
 * @param {boolean} props.loading - Indicates if the component is in a loading state.
 * @param {boolean} props.disabled - Indicates if the component is disabled.
 * @param {boolean} props.readOnly - Indicates if the component is read-only.
 * @param {Function} props.onTemplateChange - The callback function for template change. Will be either a string or a PromptTemplate based on the prompt version type.
 * @returns {JSX.Element} The rendered PromptEditor component.
 */
const PromptEditor: React.FC<Props> = ({ promptVersion, loading, disabled, readOnly, onChange }: Props) => {
  const [template, setTemplate] = useState<string>();
  const [messagesTemplate, setMessagesTemplate] = useState<PromptTemplate>();
  const [tools, setTools] = useState<Tool[]>([]);

  useEffect(() => {
    // try to cleanly translate the template types
    if (promptVersion?.type === PromptVersionTypes.MESSAGING) {
      // messaging style, no existing messages - convert template to messages
      if (!promptVersion?.messageTemplate) {
        const newTemplate = {
          systemPrompt: '',
          messages: [
            {
              role: PromptMessageRole.USER,
              content: promptVersion?.template || ''
            }
          ]
        };

        onChange(newTemplate, tools);
      } else {
        // standard messaging style
        setMessagesTemplate(promptVersion.messageTemplate);
      }
      setTools(promptVersion.tools || []);
      setTemplate(undefined);
    }

    if (promptVersion?.type !== PromptVersionTypes.MESSAGING) {
      // completion/legacy style, no existing template - convert messages to template
      if (!promptVersion?.template) {
        if (!messagesTemplate?.messages[0].content) return;
        let newTemplate: string[] = [`${PromptMessageRole.USER}: `];
        newTemplate.push(`${messagesTemplate?.systemPrompt.trim()}. ` || '');

        messagesTemplate?.messages.forEach((m, i) => {
          if (i === 0) newTemplate.push(getMessageContentString(m));
          else newTemplate.push(`${m.role}: ${getMessageContentString(m)} `);
          newTemplate.push('\n\n');
        });

        setTemplate(newTemplate.join(''));
        onChange(newTemplate.join(''), tools);
      } else {
        // standard completion/legacy style
        setTemplate(promptVersion.template);
      }

      setMessagesTemplate(undefined);
    }
  }, [promptVersion]);

  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setTemplate(newValue);
    onChange(newValue, tools);
  };

  const handleSystemPromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessagesTemplate = { ...messagesTemplate!, systemPrompt: e.target.value };
    setMessagesTemplate(newMessagesTemplate);
    onChange(newMessagesTemplate, tools);
  };

  const handleMessageChange = (index: number, message: PromptMessage) => {
    const newMessages = [...messagesTemplate!.messages];
    newMessages[index] = message;
    setMessagesTemplate({ ...messagesTemplate!, messages: newMessages });
    onChange({ ...messagesTemplate!, messages: newMessages }, tools);
  };

  const handleMessageDelete = (index: number, message: PromptMessage) => {
    const newMessages = messagesTemplate!.messages.filter((m, i) => i !== index);
    setMessagesTemplate({ ...messagesTemplate!, messages: newMessages });
    onChange({ ...messagesTemplate!, messages: newMessages }, tools);
  };

  const handleAddMessage = () => {
    const lastMessage = messagesTemplate?.messages[messagesTemplate.messages.length - 1];
    const newMessage = {
      role: lastMessage?.role === PromptMessageRole.ASSISTANT ? PromptMessageRole.USER : PromptMessageRole.ASSISTANT,
      content: ''
    };
    setMessagesTemplate({ ...messagesTemplate!, messages: [...messagesTemplate!.messages, newMessage] });
  };

  const handleToolChanges = (newTools: Tool[]) => {
    setTools(newTools);
    onChange(messagesTemplate || template!, newTools);
  };

  return (
    <div className="w-full">
      {promptVersion?.type === PromptVersionTypes.MESSAGING && (
        <div className="w-full min-h-24 flex-grow px-1 border-b border-gray-300" data-color-mode="light">
          {!loading ? (
            <CodeEditor
              value={messagesTemplate?.systemPrompt}
              language="handlebars"
              placeholder="You are a helpful assistant.."
              onChange={handleSystemPromptChange}
              padding={10}
              disabled={disabled || loading || readOnly}
              className="w-full min-h-24-[10px] resize-none border-none p-3 text-md focus:ring-0 prompt-editor"
              style={{
                backgroundColor: '#fff'
              }}
            />
          ) : (
            <Skeleton containerClassName="h-full w-[calc(100%-10px)] block" className="ml-2 mt-4" count={2} />
          )}
        </div>
      )}

      <div className="w-full h-full flex-grow px-1" data-color-mode="light">
        {loading && <Skeleton containerClassName="h-full w-[calc(100%-10px)] block" className="ml-2 mt-4" count={8} />}

        {!loading &&
          (promptVersion?.type === PromptVersionTypes.COMPLETION ||
            promptVersion?.type === PromptVersionTypes.LEGACY) && (
            <CodeEditor
              value={template}
              language="handlebars"
              placeholder="You are a helpful assistant.."
              onChange={handlePromptChange}
              padding={10}
              disabled={disabled || loading || readOnly}
              className="h-full w-full min-h-[250px] resize-none border-none p-3 text-md focus:ring-0 prompt-editor"
              style={{
                backgroundColor: '#fff'
              }}
            />
          )}

        {!loading && promptVersion?.type === PromptVersionTypes.MESSAGING && (
          <div className="flex mt-4 pb-4 space-x-5 mx-2">
            <div className="flex-1">
              <div>
                {messagesTemplate?.messages.map((message, index) => (
                  <PromptEditorMessage
                    key={index}
                    message={message}
                    disabled={disabled}
                    readOnly={readOnly}
                    onMessageChange={(msg) => handleMessageChange(index, msg)}
                    onMessageDelete={(msg) => handleMessageDelete(index, msg)}
                  />
                ))}
              </div>
              <div className="w-full text-center mt-4">
                <button className="standard secondary mb-4" onClick={handleAddMessage}>
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add Message
                </button>
              </div>
            </div>
            <ToolList tools={tools} onChange={handleToolChanges} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PromptEditor;
