import { faCircleQuestion, faFlask, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { getErrorMessage } from '../../common/utils';
import { PipelineTile, AddPipelineModal } from '../../components/pipelines';
import { getPipelines } from '../../services/PipelineEvaluations';
import { Pipeline } from '../../types';

interface Props {}

/**
 * Pipeline List page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const PipelineList: React.FC<Props> = ({}: Props) => {
  const navigate = useNavigate();
  const [pipelines, setPipelines] = useState<Pipeline[]>([]);
  const [pipelinesFiltered, setPipelinesFiltered] = useState<Pipeline[]>([]);
  const [isPipelinesLoading, setIsPipelinesLoading] = useState<boolean>(true);
  const [isShowArchived, setIsShowArchived] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');
  const [isAddPipelineModalOpen, setIsAddPipelineModalOpen] = useState<boolean>(false);

  const handleOnPipelineEditClick = (pipeline: Pipeline) => {
    navigate(`/pipelines/${pipeline.id}/manage`, { state: { pipeline } });
  };

  useEffect(() => {
    (async () => {
      try {
        setPipelines(await getPipelines());
      } catch (error) {
        return toast.error(getErrorMessage(error));
      } finally {
        setIsPipelinesLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!pipelines) return;

    const filteredBytext = pipelines.filter(
      (pipeline) =>
        pipeline.name.toLowerCase().includes(filterText.toLowerCase()) ||
        pipeline.description.toLowerCase().includes(filterText.toLowerCase())
    );

    setPipelinesFiltered(!isShowArchived ? filteredBytext.filter((pipeline) => !pipeline.archived) : filteredBytext);
  }, [pipelines, isShowArchived, filterText]);

  const handleNewPipeline = (pipeline: Pipeline) => {
    setIsAddPipelineModalOpen(false);
    setPipelines([pipeline, ...pipelines]);
  };

  return (
    <>
      <div className="mx-auto">
        <div className="text-right">
          <div>
            <button className="standard" onClick={() => setIsAddPipelineModalOpen(true)}>
              <FontAwesomeIcon icon={faPlus} className="mr-1" />
              New Pipeline
            </button>
            <a href="/docs/#/pipelines" target="_blank" rel="noreferrer">
              <button className="help">
                <FontAwesomeIcon icon={faCircleQuestion} className="inline-block" />
              </button>
            </a>
          </div>
        </div>
        {!isPipelinesLoading && !pipelines.length && (
          <div className="mt-5 text-gray-700">
            <div className="mt-2 text-indigo-500 mx-auto text-center">
              <FontAwesomeIcon icon={faFlask} className="w-40 h-40" />
            </div>
            <div className="mt-5 text-gray-700 mx-auto text-center">
              It looks like you haven&lsquo;t created any pipelines yet.{' '}
              <a role="button" onClick={() => setIsAddPipelineModalOpen(true)} className="standard">
                Click here to get started!
              </a>
            </div>
          </div>
        )}
        {!isPipelinesLoading && pipelines.length > 0 && (
          <div className="flex items-center mt-2">
            <input
              id="filter"
              name="filter"
              type="search"
              autoComplete="off"
              value={filterText}
              placeholder="Filter"
              onChange={(e) => setFilterText(e.currentTarget.value)}
              required
              disabled={isPipelinesLoading}
              className="disabled:text-gray-400 block w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-4"
            />
            <div className="text-xs text-gray-500 mr-2 pt-0.5">Show Archived</div>
            <input
              type="checkbox"
              checked={isShowArchived}
              className="peer sr-only opacity-0"
              id="showArchived"
              disabled={isPipelinesLoading}
              onChange={() => setIsShowArchived(!isShowArchived)}
            />
            <label htmlFor="showArchived" className="selector mt-1" />
          </div>
        )}
        <ul className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 rounded-lg mt-6">
          {isPipelinesLoading && <PipelineTile />}
          {pipelinesFiltered.map((pipeline) => (
            <PipelineTile
              key={pipeline.id}
              pipeline={pipeline}
              onManageClick={handleOnPipelineEditClick}
              onClick={(pipeline: Pipeline) => navigate(`/pipelines/${pipeline.id}`, { state: { pipeline } })}
            />
          ))}
        </ul>
      </div>
      <AddPipelineModal
        isOpen={isAddPipelineModalOpen}
        onClose={() => setIsAddPipelineModalOpen(false)}
        onSave={handleNewPipeline}
      />
    </>
  );
};

export default PipelineList;
