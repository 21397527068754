import { axiosInstance } from '../api';
import { apiToExperimentType, apiToVariantType } from '../common/typeUtils';
import { apiExceptionHandler } from '../common/utils';
import { Experiment, Variant, VariantType } from '../types';

/**
 * Creates a new experiment.
 * @param promptId - The ID of the prompt.
 * @param name - The name of the experiment.
 * @param description - The description of the experiment.
 * @param begin - Optional. The start date of the experiment.
 * @param end - Optional. The end date of the experiment.
 * @returns A Promise that resolves to the created Experiment.
 */
export const createExperiment = async (
  promptId: string,
  name: string,
  description: string,
  begin?: Date | undefined,
  end?: Date | undefined
): Promise<Experiment> => {
  try {
    const response = await axiosInstance.post('experimentation/', {
      prompt_id: promptId,
      name,
      description,
      begin,
      end
    });

    return apiToExperimentType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while creating your experiment.');
  }
};

/**
 * Retrieves a list of experiments from the server.
 * @returns A Promise that resolves to an array of Experiment objects.
 * @throws Throws an error if an error occurs while loading the experiments.
 */
export const getExperiments = async (): Promise<Experiment[]> => {
  try {
    const response = await axiosInstance.get('experimentation/');
    return response.data
      .map((api: any) => apiToExperimentType(api))
      .sort((a: Experiment, b: Experiment) => b.updated.getTime() - a.updated.getTime());
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while loading your experiments.');
  }
};

/**
 * Retrieves an experiment by its ID.
 * @param id - The ID of the experiment to retrieve.
 * @returns A Promise that resolves to the retrieved Experiment.
 * @throws Throws an error if an error occurs while retrieving the experiment.
 */
export const getExperiment = async (id: string): Promise<Experiment> => {
  // FIXME: should also return variants?
  try {
    const response = await axiosInstance.get(`experimentation/${id}/`);
    return apiToExperimentType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while loading your experiment.');
  }
};

/**
 * Updates an experiment with the specified ID.
 * @param id - The ID of the experiment to update.
 * @param name - The new name for the experiment.
 * @param description - The new description for the experiment.
 * @param live - Indicates whether the experiment should be live or not.
 * @returns A Promise that resolves to the updated Experiment object.
 * @throws Throws an error if an error occurs while updating the experiment.
 */
export const updateExperiment = async (
  id: string,
  name: string,
  description: string,
  live: boolean
): Promise<Experiment> => {
  try {
    const response = await axiosInstance.put(`experimentation/${id}/`, {
      name,
      description,
      live
    });

    return apiToExperimentType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while updating your experiment.');
  }
};

/**
 * Creates a new variant for an experiment.
 *
 * @param experimentId - The ID of the experiment.
 * @param promptId - The ID of the prompt.
 * @param promptVersionNumber - The version number of the prompt.
 * @param name - The name of the variant.
 * @param description - The description of the variant.
 * @param percentage - The percentage of users assigned to the variant.
 * @param type - The type of the variant.
 * @returns A Promise that resolves to the created variant.
 * @throws Throws an error if an error occurs while creating the variant.
 */
export const createVariant = async (
  experimentId: string,
  promptId: string,
  promptVersionNumber: number,
  name: string,
  description: string,
  percentage: number,
  type: VariantType
): Promise<Variant> => {
  try {
    const response = await axiosInstance.post(`experimentation/${experimentId}/variants/`, {
      experiment_id: experimentId,
      prompt_id: promptId,
      prompt_version_number: promptVersionNumber,
      name,
      description,
      percentage,
      type
    });

    return apiToVariantType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while creating your variant.');
  }
};

/**
 * Updates a variant for a given experiment.
 *
 * @param experimentId - The ID of the experiment.
 * @param promptVersionNumber - The version number of the prompt.
 * @param variantId - The ID of the variant.
 * @param name - The name of the variant.
 * @param description - The description of the variant.
 * @param percentage - The percentage of users assigned to the variant.
 * @param type - The type of the variant.
 * @returns A Promise that resolves to the updated variant.
 * @throws If an error occurs while updating the variant.
 */
export const updateVariant = async (
  experimentId: string,
  promptVersionNumber: number,
  variantId: string,
  name: string,
  description: string,
  percentage: number,
  type: VariantType
): Promise<Variant> => {
  try {
    const response = await axiosInstance.put(`experimentation/${experimentId}/variants/${variantId}`, {
      prompt_version_number: promptVersionNumber,
      name,
      description,
      percentage,
      type
    });

    return apiToVariantType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while updating your variant.');
  }
};

/**
 * Deletes a variant by its ID.
 *
 * @param experimentId - The ID of the experiment.
 * @param variantId - The ID of the variant to delete.
 * @returns A Promise that resolves to void.
 * @throws Throws an error if an error occurs while deleting the variant.
 */
export const deleteVariant = async (experimentId: string, variantId: string): Promise<void> => {
  try {
    await axiosInstance.delete(`experimentation/${experimentId}/variants/${variantId}`);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while deleting your variant.');
  }
};

/**
 * Retrieves the variants for a given experiment.
 * @param experimentId - The ID of the experiment.
 * @returns A promise that resolves to an array of Variant objects.
 * @throws Throws an error if there was an issue retrieving the variants.
 */
export const getVariants = async (experimentId: string): Promise<Variant[]> => {
  try {
    const response = await axiosInstance.get(`experimentation/${experimentId}/variants/`);
    return response.data
      .map((api: any) => apiToVariantType(api))
      .sort((a: Variant, b: Variant) => b.percentage - a.percentage)
      .sort((a: Variant, b: Variant) => a.type.localeCompare(b.type));
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while loading your variants.');
  }
};
