import { axiosInstance } from '../api';
import {
  apiToPipelineCompletionType,
  apiToPipelineDefaultData,
  apiToPipelineEvaluatorType,
  apiToPipelineRunPageType,
  apiToPipelineRunType,
  apiToPipelineScores,
  apiToPipelineType,
  apiToPipelineVersionScore,
  apiToPipelinesData
} from '../common/typeUtils';
import { apiExceptionHandler, downloadFile, getErrorMessage } from '../common/utils';
import { HistoryFilters, MetricsFilters, Pipeline, PipelineEvaluator, PipelineRun } from '../types';
import {
  PipelineCompletion,
  PipelineMetadata,
  PipelineRunDataPage,
  PipelineRunPage,
  PipelineScores,
  PipelineVersionScore
} from '../types/PipelineEvaluations';

const BASE_PATH = 'evaluation-pipelines';
const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_START_PAGE = 1;

/**
 * Retrieves the list of pipelines from the server.
 * @returns A Promise that resolves to an array of Pipeline objects.
 * @throws Throws an error if there was an issue fetching the pipelines.
 */
export const getPipelines = async (): Promise<Pipeline[]> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/`);
    return response.data.map(apiToPipelineType);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching pipelines.');
  }
};

/**
 * Retrieves a pipeline by its ID.
 * @param pipelineId - The ID of the pipeline to retrieve.
 * @returns A Promise that resolves to the retrieved Pipeline object.
 * @throws Throws an error if an error occurs while fetching the pipeline.
 */
export const getPipeline = async (pipelineId: string): Promise<Pipeline> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${pipelineId}`);
    return apiToPipelineType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching pipeline.');
  }
};

/**
 * Adds a pipeline to the evaluation pipelines.
 * @param name - The name of the pipeline.
 * @param description - The description of the pipeline.
 * @param promptId - The ID of the prompt.
 * @param versionNumber - The version number of the prompt.
 * @returns A Promise that resolves to the added Pipeline.
 * @throws Throws an error if an error occurs while adding the pipeline.
 */
export const addPipeline = async (name: string, description: string, promptId: string): Promise<Pipeline> => {
  try {
    const response = await axiosInstance.post(`${BASE_PATH}`, {
      name,
      description,
      prompt_id: promptId
    });
    return apiToPipelineType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while adding pipeline.');
  }
};

/**
 * Updates a pipeline with the specified information.
 * @param pipelineId - The ID of the pipeline to update.
 * @param name - The new name for the pipeline.
 * @param description - The new description for the pipeline.
 * @param archived - Indicates whether the pipeline should be archived.
 * @throws Throws an error if an error occurs while updating the pipeline.
 */
export const updatePipeline = async (
  pipelineId: string,
  name: string,
  description: string,
  archived: boolean
): Promise<void> => {
  try {
    await axiosInstance.put(`${BASE_PATH}/${pipelineId}`, {
      name,
      description,
      archived
    });
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while adding pipeline.');
  }
};

/**
 * Retrieves the evaluations for a given pipeline.
 * @param pipelineId - The ID of the pipeline.
 * @returns A Promise that resolves to an array of PipelineEvaluation objects.
 * @throws Throws an error if an error occurs while fetching the evaluations.
 */
export const getEvaluators = async (pipelineId: string): Promise<PipelineEvaluator[]> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${pipelineId}/evaluators/`);
    return response.data.map(apiToPipelineEvaluatorType);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching evaluators.');
  }
};

/**
 * Adds a prompt-based evaluator to a pipeline.
 *
 * @param pipelineId - The ID of the pipeline.
 * @param name - The name of the evaluator.
 * @param promptId - The ID of the prompt.
 * @param versionNumber - The version number of the prompt.
 * @returns A Promise that resolves to a PipelineEvaluator object.
 * @throws Throws an error if an error occurs while adding the evaluator.
 */
export const addPromptEvaluator = async (
  pipelineId: string,
  name: string,
  promptId: string,
  versionNumber: number
): Promise<PipelineEvaluator> => {
  try {
    const response = await axiosInstance.post(`${BASE_PATH}/${pipelineId}/evaluators`, {
      name,
      prompt_id: promptId,
      prompt_version_number: versionNumber
    });
    return apiToPipelineEvaluatorType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while adding evaluator.');
  }
};

/**
 * Adds a webhook-based evaluator to a specified pipeline.
 *
 * @param pipelineId - The ID of the pipeline to which the evaluator will be added.
 * @param name - The name of the evaluator.
 * @param webhookId - The ID of the webhook associated with the evaluator.
 * @returns A promise that resolves to the added PipelineEvaluator.
 * @throws Will throw an error if the request fails.
 */
export const addWebhookEvaluator = async (
  pipelineId: string,
  name: string,
  webhookId: string
): Promise<PipelineEvaluator> => {
  try {
    const response = await axiosInstance.post(`${BASE_PATH}/${pipelineId}/evaluators`, {
      name,
      webhook_id: webhookId
    });
    return apiToPipelineEvaluatorType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while adding evaluator.');
  }
};

/**
 * Updates an evaluator for a pipeline.
 * @param pipelineId - The ID of the pipeline.
 * @param evaluatorId - The ID of the evaluator.
 * @param name - The name of the evaluator.
 * @param promptId - The ID of the prompt.
 * @param promptVersionNumber - The version number of the prompt.
 * @returns A Promise that resolves to void.
 */
export const updateEvaluator = async (
  pipelineId: string,
  evaluatorId: string,
  name: string,
  promptId: string,
  promptVersionNumber: number
): Promise<void> => {
  try {
    await axiosInstance.put(`${BASE_PATH}/${pipelineId}/evaluators/${evaluatorId}`, {
      name,
      prompt_id: promptId,
      prompt_version_number: promptVersionNumber
    });
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while updating evaluator.');
  }
};

/**
 * Deletes an evaluator from a pipeline.
 *
 * @param {string} pipelineId - The ID of the pipeline.
 * @param {string} evaluatorId - The ID of the evaluator to delete.
 * @returns {Promise<void>} - A promise that resolves when the evaluator is deleted.
 * @throws {Error} - If an error occurs while deleting the evaluator.
 */
export const deleteEvaluator = async (pipelineId: string, evaluatorId: string): Promise<void> => {
  try {
    await axiosInstance.delete(`${BASE_PATH}/${pipelineId}/evaluators/${evaluatorId}`);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while deleting evaluator.');
  }
};

/**
 * Retrieves a page of pipeline runs for a given pipeline ID.
 *
 * @param pipelineId - The ID of the pipeline.
 * @param page - The page number to retrieve (default: 1).
 * @param perPage - The number of runs per page (default: 10).
 * @returns A Promise that resolves to a PipelineRunPage object.
 * @throws Throws an error if an error occurs while fetching the runs.
 */
export const getRuns = async (
  pipelineId: string,
  page: number = DEFAULT_START_PAGE,
  perPage: number = DEFAULT_PAGE_SIZE
): Promise<PipelineRunPage> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${pipelineId}/runs/`, {
      params: {
        page,
        size: perPage
      }
    });

    return apiToPipelineRunPageType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching runs.');
  }
};

/**
 * Retrieves a specific pipeline run by its ID.
 * @param pipelineId - The ID of the pipeline.
 * @param runId - The ID of the run.
 * @returns A Promise that resolves to a PipelineRun object.
 * @throws Throws an error if an error occurs while fetching the run.
 */
export const getRun = async (pipelineId: string, runId: string): Promise<PipelineRun> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${pipelineId}/runs/${runId}`);
    return apiToPipelineRunType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching run.');
  }
};

/**
 * Retrieves the completions for a specific run of a pipeline.
 * @param pipelineId - The ID of the pipeline.
 * @param runId - The ID of the run.
 * @returns A Promise that resolves to an array of PipelineCompletion objects.
 * @throws Throws an error if there was an issue fetching the run completions.
 */
export const getRunCompletions = async (pipelineId: string, runId: string): Promise<PipelineCompletion[]> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${pipelineId}/runs/${runId}/completions`);
    return response.data.map(apiToPipelineCompletionType);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching run completions.');
  }
};

/**
 * Downloads the run data for a specific pipeline and run.
 *
 * @param pipelineId - The ID of the pipeline.
 * @param runId - The ID of the run.
 * @returns A Promise that resolves to void.
 * @throws Throws an error if an error occurs while downloading the run data.
 */
export const downloadRunData = async (pipelineId: string, runId: string): Promise<void> => {
  await downloadFile(`${BASE_PATH}/${pipelineId}/runs/${runId}/completions/csv`);
};

/**
 * Downloads the data file for a specific pipeline run.
 * @param pipelineId - The ID of the pipeline.
 * @param runId - The ID of the run.
 * @returns A Promise that resolves when the data file is downloaded.
 */
export const downloadDataset = async (pipelineId: string, runId: string): Promise<void> => {
  await downloadFile(`${BASE_PATH}/${pipelineId}/runs/${runId}/data`);
};

/**
 * Adds a new run to a pipeline using a file datasource.
 * @param pipelineId - The ID of the pipeline.
 * @param name - The name of the run.
 * @param file - The file to upload.
 * @returns A Promise that resolves to a PipelineRun object.
 * @throws Throws an error if an error occurs while adding the run.
 */
export const addRunFromFile = async (
  pipelineId: string,
  promptId: string,
  version: number,
  name: string,
  file: File
): Promise<PipelineRun> => {
  try {
    const formData = new FormData();
    formData.append('prompt_id', promptId);
    formData.append('version', version.toString());
    formData.append('name', name);
    formData.append('data', file!);

    const response = await axiosInstance.post(`${BASE_PATH}/${pipelineId}/runs/file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return apiToPipelineRunType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while adding run.');
  }
};

/**
 * Clones a run from a previous run.
 * @param pipelineId: string,
 * @param promptId: string,
 * @param version: number,
 * @param name: string,
 * @param previousPipelineId
 * @returns
 */
export const addRunClone = async (
  pipelineId: string,
  promptId: string,
  version: number,
  name: string,
  previousRunId: string
): Promise<PipelineRun> => {
  try {
    const response = await axiosInstance.post(`${BASE_PATH}/${pipelineId}/runs/clone`, {
      prompt_id: promptId,
      prompt_version_number: version,
      name,
      previous_run_id: previousRunId
    });
    return apiToPipelineRunType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while adding run.');
  }
};

/**
 * Adds a run from history to the specified pipeline.
 *
 * @param pipelineId - The ID of the pipeline to which the run will be added.
 * @param promptId - The ID of the prompt associated with the run.
 * @param version - The version number of the prompt.
 * @param name - The name of the run.
 * @param filters - The metrics filters to be applied to the run.
 * @returns A promise that resolves to the added PipelineRun.
 * @throws Will throw an error if the API request fails.
 */
export const addRunFromHistory = async (
  pipelineId: string,
  promptId: string,
  version: number,
  name: string,
  filters: MetricsFilters
): Promise<PipelineRun> => {
  let payload = Object.entries(filters).reduce(
    (acc, [key, value]) => {
      const api = HistoryFilters[key as keyof MetricsFilters]?.api;
      if (api) {
        acc[api] = value;
      }

      return acc;
    },
    {} as Record<string, string>
  );

  payload = { ...payload, run_prompt_id: promptId, run_prompt_version_number: version.toString(), name };

  try {
    const response = await axiosInstance.post(`${BASE_PATH}/${pipelineId}/runs/history`, payload);
    return apiToPipelineRunType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while adding run.');
  }
};

/**
 * Adds a new run to the specified pipeline using the provided prompt details.
 *
 * @param pipelineId - The ID of the pipeline to which the run will be added.
 * @param promptId - The ID of the prompt to be used for the run.
 * @param version - The version number of the prompt.
 * @param name - The name of the run.
 * @returns A promise that resolves to the created PipelineRun object.
 * @throws Will throw an error if the API request fails.
 */
export const addRunFromDefaultData = async (
  pipelineId: string,
  promptId: string,
  version: number,
  name: string
): Promise<PipelineRun> => {
  try {
    const response = await axiosInstance.post(`${BASE_PATH}/${pipelineId}/runs/default`, {
      prompt_id: promptId,
      prompt_version_number: version,
      name
    });
    return apiToPipelineRunType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while adding run.');
  }
};

/**
 * Starts a pipeline run.
 *
 * @param pipelineId - The ID of the pipeline.
 * @param runId - The ID of the run.
 * @returns A Promise that resolves to a PipelineRun object.
 * @throws Throws an error if an error occurs while starting the run.
 */
export const startRun = async (pipelineId: string, runId: string): Promise<void> => {
  try {
    await axiosInstance.post(`${BASE_PATH}/${pipelineId}/runs/${runId}/execute/`);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while starting run.');
  }
};

/**
 * Adds default data to a pipeline by uploading a file.
 *
 * @param {string} pipelineId - The ID of the pipeline to which the data will be added.
 * @param {File} file - The file containing the default data to be uploaded.
 * @returns {Promise<PipelineMetadata>} - A promise that resolves to the metadata of the pipeline.
 * @throws Will throw an error if the upload fails.
 */
export const addDefaultData = async (pipelineId: string, file: File): Promise<PipelineMetadata> => {
  try {
    const formData = new FormData();
    formData.append('data', file!);

    const response = await axiosInstance.post(`${BASE_PATH}/${pipelineId}/data`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return apiToPipelineDefaultData(response.data.metadatas);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while adding default data.');
  }
};

/**
 * Retrieves data already uploaded for all runs in a given pipeline ID.
 * @param pipelineId - The ID of the pipeline.
 * @returns A promise that resolves to an array of PipelineRunData objects.
 * @throws Throws an error if an error occurs while fetching the pipeline data.
 */
export const getPipelinesData = async (
  pipelineId: string,
  page: number = DEFAULT_START_PAGE,
  perPage: number = DEFAULT_PAGE_SIZE
): Promise<PipelineRunDataPage> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${pipelineId}/runs/data`, {
      params: {
        page,
        size: perPage
      }
    });
    return apiToPipelinesData(response.data);
  } catch (error) {
    if (getErrorMessage(error).includes('404')) return { total: 0, page: 1, size: 0, pages: 0, items: [] };
    throw apiExceptionHandler(error, 'An error occurred while fetching pipelines data.');
  }
};

/**
 * Retrieves the scores for a specific pipeline.
 * @param pipelineId - The ID of the pipeline.
 * @returns A Promise that resolves to the PipelineScores object.
 */
export const getPipelineScores = async (pipelineId: string): Promise<PipelineScores> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${pipelineId}/scores`);
    return apiToPipelineScores(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching pipeline scores.');
  }
};

/**
 * Fetches the version scores for a given pipeline.
 *
 * @param {string} pipelineId - The ID of the pipeline for which to fetch version scores.
 * @returns {Promise<PipelineVersionScore[]>} A promise that resolves to an array of pipeline version scores.
 */
export const getPipelineVersionScores = async (piplineId: string): Promise<PipelineVersionScore[]> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${piplineId}/scores/versions`);
    return response.data.map(apiToPipelineVersionScore);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching pipeline version scores.');
  }
};
