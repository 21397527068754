import { useEffect, useState } from 'react';
import { evaluatorTemplateParameters, getMessageContentString } from '../common/prompts';
import { toTitleCase } from '../common/utils';
import { SelectorValue } from '../components/common/Selector';
import { Prompt, PromptVersion } from '../types';
import { PromptTypes, PromptVersionTypes } from '../types/Prompt';

/**
 * Validates a new version of a prompt.
 *
 * @param {Prompt} props.prompt - The prompt object.
 * @param {PromptVersion} props.version - The version object.
 * @returns {string | null} - A string if there is an error, otherwise null.
 */
export const useValidateNewVersion = ({
  prompt,
  version
}: {
  prompt: Prompt;
  version: PromptVersion;
}): string | null => {
  if (!prompt?.name.length) {
    return 'Name is required.';
  }

  if (version?.type === PromptVersionTypes.MESSAGING) {
    if (
      (!version?.messageTemplate?.messages.length ||
        !getMessageContentString(version?.messageTemplate?.messages[0]).length) &&
      !version?.messageTemplate?.systemPrompt?.trim().length
    ) {
      return 'Please add a message or system message to the messaging template.';
    }
  } else if (!version.template.trim().length) {
    return 'Template is required.';
  }

  return null;
};

/**
 * Validates the template of an evaluator prompt version.
 *
 * @param {Prompt} props.prompt - The prompt object.
 * @param {PromptVersion} props.version - The version object.
 * @returns {string[] | null} - An array of missing parameters if there are any, otherwise null.
 */
export const useValidateEvaluator = ({
  prompt,
  version
}: {
  prompt: Prompt;
  version: PromptVersion;
}): string[] | null => {
  if (prompt.type === PromptTypes.EVALUATOR) {
    const template =
      version.type === PromptVersionTypes.MESSAGING ? JSON.stringify(version.messageTemplate) : version.template;
    const missingParameters = evaluatorTemplateParameters.filter((p) => !template.includes(`{{${p}}}`));

    if (missingParameters.length) {
      return missingParameters;
    }
  }

  return null;
};

/**
 * Custom hook that returns a list of version type selectors based on the provided prompt.
 *
 * @param {Prompt} [prompt] - The prompt object used to determine the version type selectors.
 * @returns {SelectorValue[]} An array of version type selectors.
 */
export const useVersionTypeSelectors = (prompt?: Prompt): SelectorValue[] => {
  const [versionTypeSelectors, setVersionTypeSelectors] = useState<SelectorValue[]>([]);

  useEffect(() => {
    if (!prompt) return;
    if (prompt.type === PromptTypes.PROMPT || prompt.type === PromptTypes.PROMPT_LIBRARY) {
      setVersionTypeSelectors(
        Object.values(PromptVersionTypes).map((t) => ({ value: t, label: `${toTitleCase(t)} Prompt` }))
      );
    } else {
      setVersionTypeSelectors([{ value: PromptVersionTypes.LEGACY, label: 'Evaluator Prompt' }]);
    }
  }, [prompt]);

  return versionTypeSelectors;
};
