import { faPenToSquare, faAnglesRight, faPlus, faToolbox, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Tool } from '../../types/Prompt';
import { StyledDialog } from '../common';
import ToolEditor from './ToolEditor';

interface Props {
  tools?: Tool[];
  onChange(tools: Tool[]): void;
}

/**
 * ToolList component renders a list of tools with options to add, edit, and delete tools.
 *
 * @component
 * @param {Props} props - The props for the ToolList component.
 * @param {Tool[]} [props.tools=[]] - The initial list of tools.
 * @param {function} props.onChange - Callback function to handle changes in the tool list.
 *
 * @returns {JSX.Element} The rendered ToolList component.
 *
 * @example
 * <ToolList tools={initialTools} onChange={handleToolsChange} />
 *
 * @remarks
 * - The component maintains internal state for selected tool, visibility of tools, tool editor, and delete confirmation dialog.
 * - Uses FontAwesome icons for UI elements.
 * - The `ToolEditor` component is used for adding and editing tools.
 * - The `StyledDialog` component is used for delete confirmation.
 */
const ToolList: React.FC<Props> = ({ tools = [], onChange }: Props) => {
  const [selectedTool, setSelectedTool] = useState<Tool>();
  const [isShowTools, setIsShowTools] = useState(false);
  const [isToolEditorOpen, setIsToolEditorOpen] = useState(false);
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] = useState(false);
  const [_tools, setTools] = useState<Tool[]>(tools);

  useEffect(() => setTools(tools), [tools]);

  const handleToolModified = (tool: Tool, originalName: string) => {
    console.log(tool, originalName, _tools);
    const newTools = _tools.filter((t) => t.name !== originalName);
    newTools.push(tool);

    setTools(newTools);
    setSelectedTool(undefined);
    onChange(newTools);
    setIsToolEditorOpen(false);
  };

  const handleDeleteConfirm = () => {
    setIsShowDeleteConfirm(false);
    const newTools = _tools.filter((t) => t.name !== selectedTool?.name);
    setTools(newTools);
    setSelectedTool(undefined);
    onChange(newTools);
  };

  return (
    <div className="">
      {!isShowTools ? (
        <div className="pt-1 cursor-pointer text-indigo-600" onClick={() => setIsShowTools(true)}>
          <FontAwesomeIcon icon={faToolbox} className="w-5 h-5" />
          {tools.length ? <span className="text-sm pl-1.5">{tools.length}</span> : null}
        </div>
      ) : (
        <div className="flex-intial w-52 pt-1 border border-gray-200 rounded-md">
          <div className="flex">
            <div className="flex-grow text-center text-md text-gray-600 pl-6">Tools</div>
            <div className="flex-none cursor-pointer ml-auto text-right" onClick={() => setIsShowTools(false)}>
              <FontAwesomeIcon icon={faAnglesRight} className="text-indigo-600 w-4 h-4 pr-2" />
            </div>
          </div>
          <div className="p-2">
            {_tools.map((tool) => (
              <div key={tool.name} className="flex items-center mb-1.5">
                <div className="flex-1 text-sm text-gray-800">{tool.name}</div>
                <div>
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    onClick={() => {
                      setSelectedTool(tool);
                      setIsToolEditorOpen(true);
                    }}
                    className="cursor-pointer hover:text-indigo-700 text-gray-700"
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => {
                      setSelectedTool(tool);
                      setIsShowDeleteConfirm(true);
                    }}
                    className="ml-2.5 text-red-400 cursor-pointer hover:text-red-500"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="w-full text-center mt-4">
            <button
              className="standard secondary mb-4"
              onClick={() => {
                setSelectedTool(undefined);
                setIsToolEditorOpen(true);
              }}>
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Tool
            </button>
          </div>
          <ToolEditor
            tool={selectedTool}
            existingToolNames={_tools.map((t) => t.name)}
            isOpen={isToolEditorOpen}
            onClose={() => {
              setSelectedTool(undefined);
              setIsToolEditorOpen(false);
            }}
            onSave={handleToolModified}
          />
          <StyledDialog
            isOpen={isShowDeleteConfirm}
            title="Delete Tool"
            closeText="No"
            confirmText="Yes"
            icon={faTrash}
            onClose={() => setIsShowDeleteConfirm(false)}
            onConfirm={() => handleDeleteConfirm()}>
            <div>
              Are you sure you want to delete the {'"'}
              {selectedTool?.name}
              {'"'} tool?
            </div>
          </StyledDialog>
        </div>
      )}
    </div>
  );
};

export default ToolList;
