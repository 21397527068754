import Handlebars from 'handlebars';
import { useEffect, useState } from 'react';
import { getErrorMessage } from '../../common/utils';

interface Props {
  template: string;
  payload: any;
  className?: string;
  onError?: (error: string) => void;
}

/**
 * Renders a formatted preview of a prompt using a Handlebars template and payload data.
 *
 * @param {Props} props - The component props.
 * @param {string} props.template - The Handlebars template string.
 * @param {object} props.payload - The payload data object.
 * @param {string} props.className - The CSS class name for the component.
 * @param {Function} props.onError - The error callback function.
 * @returns {JSX.Element} The rendered preview component.
 */
const PromptPreviewFormatter: React.FC<Props> = ({ template = '', payload = {}, className, onError }: Props) => {
  const [preview, setPreview] = useState<string>('');

  useEffect(() => {
    try {
      const hbsFormatter = Handlebars.compile(template, { noEscape: true });
      cleanupPreview(hbsFormatter(generateAnnotatedPayload()) || '');
    } catch (error) {
      setPreview(getErrorMessage(error));
      onError && onError(getErrorMessage(error));
    }
  }, [template, payload]);

  const cleanupPreview = (preview: string) => {
    let cleanPreview = preview
      .replaceAll('<', '&lt;')
      .replaceAll('>', '&gt;')
      .replaceAll('&lt;&lt;[', '<')
      .replaceAll(']&gt;&gt;', '>')
      .replaceAll('&lt;&lt;/[', '</');

    if (cleanPreview.startsWith('\n\n')) {
      cleanPreview = cleanPreview.slice(2);
    }

    setPreview(cleanPreview);
  };

  const generateAnnotatedPayload = (): any => {
    const annotatedPayload = JSON.parse(JSON.stringify(payload));

    const traverse = (obj: any) => {
      for (const key in obj) {
        if ((typeof obj[key] === 'string' && obj[key].trim().length) || typeof obj[key] === 'number') {
          obj[key] = `<<[span class="text-indigo-800 font-semibold"]>>${obj[key]}<</[span]>>`;
        } else if (typeof obj[key] === 'object') {
          traverse(obj[key]);
        }
      }
    };

    traverse(annotatedPayload);
    return annotatedPayload;
  };

  return <div className={`whitespace-pre-line ${className ?? ''}`} dangerouslySetInnerHTML={{ __html: preview }} />;
};

export default PromptPreviewFormatter;
