import { useEffect, useState } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import '../../styles/editor.css';
import { PromptMessageRole, PromptMessage } from '../../types/Prompt';
import Selector, { SelectorValue } from '../common/Selector';
import { PROMPT_MESSAGE_ROLES } from '../../constants';

import './PromptEditor.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getMessageContentString } from '../../common/prompts';

/**
 * Props for the PromptEditorMessage component.
 */
interface Props {
  message?: PromptMessage;
  disabled: boolean;
  readOnly: boolean;
  className?: string;
  language?: string;
  onMessageChange: (m: PromptMessage) => void;
  onMessageDelete: (m: PromptMessage) => void;
}

const roleSelectors: SelectorValue[] = PROMPT_MESSAGE_ROLES.map((r) => ({ value: r.id, label: r.label }));

/**
 * Represents a component for editing a prompt message.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {PromptMessage} props.message - The prompt message.
 * @param {boolean} props.disabled - Indicates if the component is disabled.
 * @param {boolean} props.readOnly - Indicates if the component is read-only.
 * @param {string} props.className - The component class name.
 * @param {string} props.language - The language of the message. Default handlebars.
 * @param {Function} props.onMessageChange - The callback function called when the message changes.
 * @param {Function} props.onMessageDelete - The callback function called when the message is deleted.
 * @returns {JSX.Element} The JSX element representing the prompt editor message component.
 */
const PromptEditorMessage: React.FC<Props> = ({
  message,
  disabled,
  readOnly,
  className,
  language = 'handlebars',
  onMessageChange,
  onMessageDelete
}: Props) => {
  const [_message, setMessage] = useState<PromptMessage>();
  const [selectedRole, setSelectedRole] = useState<SelectorValue>();

  useEffect(() => {
    setMessage(message || { role: PromptMessageRole.USER, content: '' });
    setSelectedRole(roleSelectors.find((r) => r.value === message?.role));
  }, [message]);

  useEffect(() => {
    if (!_message || !selectedRole || (_message.role === selectedRole.value && _message.content === message?.content))
      return;

    const newMessage = { ..._message, role: selectedRole.value as PromptMessageRole };

    setMessage(newMessage);
    onMessageChange(newMessage);
  }, [_message, selectedRole]);

  const handleDelete = () => {
    onMessageDelete(_message!);
  };

  return (
    <div className={`mb-4 border border-gray-200 rounded-md overflow-hidden promptMessage ${className}`}>
      <div className="border-b border-gray-300 flex">
        <Selector
          values={roleSelectors}
          defaultValue={selectedRole}
          onChange={(v) => setSelectedRole(v)}
          disabled={disabled || readOnly}
          isSearchable={false}
          classNames="w-32"
        />
        <div className="flex-1 text-right content-center mr-2">
          <FontAwesomeIcon
            icon={faTrash}
            className="text-red-400 cursor-pointer hover:text-red-500"
            onClick={handleDelete}
          />
        </div>
      </div>

      <div className="w-full flex-grow px-1" data-color-mode="light">
        <CodeEditor
          value={getMessageContentString(_message)}
          language={language}
          placeholder=""
          onChange={(e) => setMessage({ ..._message!, content: e.target.value })}
          padding={10}
          disabled={disabled || readOnly}
          className="h-full w-full min-h-[125px] resize-none border-none p-3 text-md focus:ring-0 prompt-editor"
          style={{
            backgroundColor: '#fff'
          }}
        />
      </div>
    </div>
  );
};

export default PromptEditorMessage;
