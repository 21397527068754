import { axiosInstance } from '../api';
import { apiToWebhookType } from '../common/typeUtils';
import { apiExceptionHandler } from '../common/utils';
import { Webhook, WebhookAuthMethods, WebhookVerbs } from '../types/Webhooks';

const BASE_PATH = 'webhooks';

/**
 * Fetches the list of webhooks from the server.
 *
 * @returns {Promise<Webhook[]>} A promise that resolves to an array of webhooks.
 * @throws Will throw an error if the request fails.
 */
export const getWebhooks = async (): Promise<Webhook[]> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/`);
    return response.data.map(apiToWebhookType);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching webhooks.');
  }
};

export const getWebhook = async (webhookId: string): Promise<Webhook> => {
  try {
    const response = await axiosInstance.get(`${BASE_PATH}/${webhookId}`);
    return apiToWebhookType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while fetching webhook.');
  }
};

/**
 * Adds a new webhook with the specified parameters.
 *
 * @param name - The name of the webhook.
 * @param description - A brief description of the webhook.
 * @param url - The URL to which the webhook will send requests.
 * @param httpVerb - The HTTP verb to be used by the webhook (e.g., GET, POST).
 * @param authMethod - The authentication method to be used by the webhook.
 * @param authToken - The authentication token, if required by the auth method.
 * @param authParam - Additional URL parameters, if any.
 * @returns A promise that resolves to the created Webhook object.
 * @throws Will throw an error if the webhook creation fails.
 */
export const addWebhook = async (
  name: string,
  description: string,
  url: string,
  httpVerb: WebhookVerbs,
  authMethod: WebhookAuthMethods,
  authToken: string | undefined,
  authParam: string | undefined
): Promise<Webhook> => {
  try {
    const response = await axiosInstance.post(`${BASE_PATH}`, {
      name,
      description,
      url,
      http_verb: httpVerb,
      auth_method: authMethod,
      auth_token: authToken,
      auth_param: authParam
    });
    return apiToWebhookType(response.data);
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while adding webhook.');
  }
};

/**
 * Updates an existing webhook with the provided details.
 *
 * @param webhookId - The unique identifier of the webhook to update.
 * @param name - The new name for the webhook.
 * @param description - The new description for the webhook.
 * @param url - The new URL for the webhook.
 * @param httpVerb - The HTTP verb to use for the webhook (e.g., GET, POST).
 * @param authMethod - The authentication method to use for the webhook.
 * @param authToken - The authentication token, if required by the auth method.
 * @param authParam - Additional URL parameters, if any.
 * @returns A promise that resolves when the webhook is successfully updated.
 * @throws Will throw an error if the update operation fails.
 */
export const updateWebhook = async (
  webhookId: string,
  name: string,
  description: string,
  url: string,
  httpVerb: WebhookVerbs,
  authMethod: WebhookAuthMethods,
  authToken: string | undefined,
  authParam: string | undefined
): Promise<void> => {
  try {
    await axiosInstance.put(`${BASE_PATH}/${webhookId}`, {
      name,
      description,
      url,
      http_verb: httpVerb,
      auth_method: authMethod,
      auth_token: authToken,
      auth_param: authParam
    });
  } catch (error) {
    console.error(error);
    throw apiExceptionHandler(error, 'An error occurred while updating webhook.');
  }
};
