import { faNoteSticky } from '@fortawesome/free-regular-svg-icons';
import { faCodeCompare, faFlask } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDistanceStrict } from 'date-fns';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { GroupBox } from '../';
import { formattedDelimDecimal, toTitleCase } from '../../common/utils';
import { getPipelineVersionScores } from '../../services/PipelineEvaluations';
import { Prompt, PromptVersion } from '../../types';
import { PipelineVersionScore } from '../../types/PipelineEvaluations';

/**
 * Props for the PromptVersions component.
 */
interface Props {
  prompt: Prompt | undefined;
  versions: PromptVersion[];
  selectedVersion?: number;
  onVersionChange?: (version: number) => void;
  onChangeLogClick?: (version: number) => void;
  onDiffClick?: (version: number) => void;
}

/**
 * Renders a list of prompt versions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array<PromptVersion>} props.versions - The array of prompt versions.
 * @param {number | undefined} props.selectedVersion - The selected version.
 * @param {Function} props.onVersionChange - The callback function triggered when the version is changed.
 * @param {Function} props.onChangeLogClick - The callback function triggered when the change log icon is clicked.
 * @param {Function} props.onDiffClick - The callback function triggered when the diff icon is clicked.
 * @returns {JSX.Element} The rendered PromptVersions component.
 */
const PromptVersions: React.FC<Props> = ({
  prompt,
  versions,
  selectedVersion,
  onVersionChange,
  onChangeLogClick,
  onDiffClick
}: Props) => {
  const [selected, setSelected] = useState<number | undefined>(selectedVersion);
  const [loadingScores, setLoadingScores] = useState<boolean>(false);
  const [scores, setScores] = useState<Record<number, PipelineVersionScore>>({});

  useEffect(() => {
    if (!prompt || !prompt.defaultPipeline?.id || loadingScores) return;

    setLoadingScores(true);
    (async () => {
      try {
        const scores = await getPipelineVersionScores(prompt.defaultPipeline?.id!);
        setScores(scores.reduce((acc, score) => ({ ...acc, [score.promptVersionNumber]: score }), {}));
      } catch (error) {
        console.error(error);
        toast.error('Failed to load version scores.');
      } finally {
        setLoadingScores(false);
      }
    })();
  }, [prompt]);

  useEffect(() => {
    setSelected(selectedVersion);
  }, [selectedVersion]);

  const handleOnVersionChange = (version: number) => {
    setSelected(version);
    onVersionChange && onVersionChange(version);
  };

  const handleOnDiffClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, version: number) => {
    e.stopPropagation();
    onDiffClick && onDiffClick(version);
  };

  const handleOnChangelogClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, version: number) => {
    e.stopPropagation();
    onChangeLogClick && onChangeLogClick(version);
  };

  const formatScore = (version?: number) => {
    if (!version || !scores[version]) return;
    const score = scores[version];
    const color =
      score.averageScore >= 8 ? 'text-green-600' : score.averageScore >= 6 ? 'text-yellow-500' : 'text-red-500';

    return version && scores[version] ? (
      <div>
        <FontAwesomeIcon icon={faFlask} className="font-normal inline pr-1" />
        <span className={color}>{formattedDelimDecimal(score.averageScore * 10, 0)}%</span>
      </div>
    ) : undefined;
  };

  const renderVersion = (version?: PromptVersion) => (
    <div
      key={version?.version}
      className={`hover:cursor-pointer py-2 border-b border-gray-200 w-full px-3 ${selected === (!!version && version.version) ? 'bg-indigo-100' : ''}`}
      onClick={() => handleOnVersionChange(version?.version!)}>
      <div className="flex justify-between">
        <div className="text-base text-gray-800 flex-1">{version ? `Version ${version.version}` : <Skeleton />}</div>
        <div>
          {version && (
            <>
              <FontAwesomeIcon
                icon={faNoteSticky}
                className="w-4 h-4 hover:text-indigo-800 mr-2"
                onClick={(e) => handleOnChangelogClick(e, version?.version!)}
              />
              <FontAwesomeIcon
                icon={faCodeCompare}
                className="w-4 h-4 hover:text-indigo-800"
                onClick={(e) => handleOnDiffClick(e, version?.version!)}
              />
            </>
          )}
        </div>
      </div>

      {version && version.environments.length > 0 && (
        <div className="float-right">
          <div className="text-xs bg-indigo-400 rounded-md text-white py-0.5 px-1">
            {version?.environments.map((e) => toTitleCase(e)).join(', ')}
          </div>
        </div>
      )}

      <div className="text-gray-600 max-w-44 whitespace-nowrap overflow-hidden overflow-ellipsis mb-1.5">
        {version ? version.changeLog : <Skeleton />}
      </div>
      <div className="flex justify-between align-middle gap-x-3 text-xs">
        <div className="text-gray-500">
          {version ? formatDistanceStrict(version.updated, new Date()) + ' ago' : <Skeleton />}
          {/* {' by '}/ */}
          {/* {version ? formatUserName(version.author?.name) : <Skeleton />} */}
        </div>
        <div className="text-right font-medium">{formatScore(version?.version)}</div>
      </div>
    </div>
  );

  return (
    <GroupBox title="Versions" collapsable={true}>
      <div className="max-h-72 overflow-y-scroll">
        {versions.length ? versions.map((version) => renderVersion(version)) : renderVersion(undefined)}
      </div>
    </GroupBox>
  );
};

export default PromptVersions;
