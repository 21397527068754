import { useState } from 'react';
import { Prompt, PromptUser } from '../../types';
import { StyledDialog } from '..';
import { Roles } from '../../types';
import Selector, { SelectorValue } from '../common/Selector';
import { managePromptUser } from '../../services/Prompts';
import { getUserByEmail } from '../../services/User';
import { getErrorMessage } from '../../common/utils';
import toast from 'react-hot-toast';
import { faCircleUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { manageUserSelectorValues } from '../../pages/prompts/ManagePrompt';

/**
 * Props for the AddUserModal component.
 */
interface Props {
  prompt: Prompt;
  isOpen: boolean;
  onClose: () => void;
  onSave: (user: PromptUser) => void;
}

/**
 * Modal component for adding a user to the prompt.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Prompt} props.prompt - The prompt object.
 * @param {boolean} props.isOpen - Flag indicating whether the modal is open.
 * @param {Function} props.onClose - Function to handle modal close event.
 * @param {Function} props.onSave - Function to handle save event.
 * @returns {JSX.Element} The rendered component.
 */
const AddUserModal: React.FC<Props> = ({ prompt, isOpen, onClose, onSave }: Props) => {
  const [email, setEmail] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<SelectorValue>(manageUserSelectorValues.READ_WRITE);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const resetForm = () => {
    setEmail('');
    setSelectedRole(manageUserSelectorValues.READ_WRITE);
  };

  const handleOnClose = () => {
    if (isSaving) return;

    isOpen = false;
    resetForm();
    onClose();
  };

  const handleOnCreate = async () => {
    // const role = selectedRole.value === 1 ? Roles.OWNER : selectedRole.value === 2 ? Roles.READ_ONLY : Roles.READ_WRITE;
    let role: Roles | undefined;
    Object.entries(manageUserSelectorValues).forEach(([key, value]) => {
      if (value === selectedRole) {
        role = key as Roles;
      }
    });

    if (!role) {
      toast.error('Invalid role');
      return;
    }

    setIsSaving(true);

    try {
      const user = await getUserByEmail(email);
      await managePromptUser(prompt.id!, user.id, 'ADD', role);
      toast.success('User added!');
      onSave({ ...user, promptRole: role });
    } catch (e) {
      const err = getErrorMessage(e);
      if (err.includes('already exists')) {
        toast.error('User already has access to this prompt.');
      } else {
        toast.error(err);
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title="Add User"
      closeText="Cancel"
      width="w-1/4"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : (
          'Add'
        )
      }
      onConfirm={handleOnCreate}
      onClose={handleOnClose}
      icon={faCircleUser}
      disabled={isSaving}>
      <div className="">
        <div className="mt-2">
          <input
            id="inviteemail"
            name="inviteEmail"
            type="email"
            autoComplete="off"
            value={email}
            placeholder="User Email"
            onChange={(e) => setEmail(e.currentTarget.value)}
            required
            disabled={isSaving}
            className="disabled:text-gray-400 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 my-5"
          />
        </div>
        <Selector
          classNames="w-40 my-2"
          values={Object.values(manageUserSelectorValues)}
          defaultValue={selectedRole}
          onChange={(action) => setSelectedRole(action)}
        />
      </div>
    </StyledDialog>
  );
};

export default AddUserModal;
