/**
 * Sorts an array of numbers in ascending order.
 *
 * @param {number[]} arr - The array of numbers to be sorted.
 * @returns {number[]} - The sorted array in ascending order.
 */
const asc = (arr: number[]): number[] => arr.sort((a, b) => a - b);

/**
 * Calculates the sum of all numbers in an array.
 *
 * @param arr - The array of numbers.
 * @returns The sum of all numbers in the array.
 */
const sum = (arr: number[]): number => arr.reduce((a, b) => a + b, 0);

/**
 * Calculates the mean of an array of numbers.
 *
 * @param arr - The array of numbers.
 * @returns The mean value of the array.
 */
export const mean = (arr: number[]): number => sum(arr) / arr.length;

/**
 * Calculates the quantile value of an array.
 *
 * @param arr - The array of values.
 * @param q - The quantile value (between 0 and 1).
 * @returns The quantile value of the array.
 */
export const quantile = (arr: number[], q: number) => {
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;

  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

/**
 * Calculates the median value of an array of numbers.
 *
 * @param arr - The array of numbers.
 * @returns The median value of the array.
 */
export const findMedian = (arr: number[]) => quantile(arr, 0.5);

/**
 * Calculates the quantile value of an array.
 * @param arr - The array of numbers.
 * @param q - The quantile value to calculate (1, 25, 50, 75, 90, 95, or 99).
 * @returns The quantile value of the array.
 */
export const getQuantile = (arr: number[], q: 1 | 5 | 25 | 50 | 75 | 90 | 95 | 99) => {
  return quantile(arr, q / 100);
};
