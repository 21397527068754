/**
 * Represents a sidebar component.
 */
interface Props {
  rows: React.ReactElement<any, string | React.JSXElementConstructor<any>>[] | string[];
  rowClasses?: string[];
  selectedIndex: number;
  onClick: (index: number) => void;
  children: React.ReactElement[] | React.ReactElement | string;
  className?: string;
}

// super quick and hacky sidebar solution

const SideBar: React.FC<Props> = ({ rows, rowClasses, selectedIndex, onClick, children, className }: Props) => {
  if (rowClasses && rowClasses.length !== rows.length) {
    throw new Error('rowClasses length must be equal to rows length');
  }

  return (
    <div className={`w-full flex ${className ?? ''}`}>
      <div className="xl:w-64 lg:w-64 md:32 sm:16 shrink-0 h-[calc(100vh-80px)]" aria-label="Sidebar">
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <ul className="space-y-2 font-medium">
            {rows.map((row, index) => (
              <li key={index} className={rowClasses ? rowClasses[index] : ''}>
                <a
                  role="button"
                  onClick={() => onClick(index)}
                  className={`flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-gray-700 rounded-lg hover:bg-gray-200 ${index === selectedIndex ? 'bg-gray-300' : ''}`}>
                  {row}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="ml-8  py-5 pr-5 flex-1 w-[calc(100%-300px)]">{children}</div>
    </div>
  );
};

export default SideBar;
