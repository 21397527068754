import { useEffect, useState } from 'react';
import { HistoryFilters, MetricsFilters, Pipeline, PipelineRun } from '../../types';
import { getErrorMessage } from '../../common/utils';
import StyledDialog from '../common/StyledDialog';
import { faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addRunFromHistory, getPipelines } from '../../services/PipelineEvaluations';
import Selector, { SelectorValue } from '../common/Selector';
import PromptVersionSelector, { PV } from '../common/PromptVersionSelector';

interface Props {
  isOpen: boolean;
  count: number;
  filters: MetricsFilters;
  onClose: () => void;
  onSave: (pipeline: PipelineRun) => void;
}

const DEFAULT_DATA_SELECTOR = { label: 'Select pipeline', value: -1 };

const AddPipelineRunFromHistory: React.FC<Props> = ({ filters, count, isOpen = false, onClose, onSave }: Props) => {
  const [name, setName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [pipelines, setPipelines] = useState<Pipeline[]>([]);
  const [selectedPipeline, setSelectedPipeline] = useState<SelectorValue>(DEFAULT_DATA_SELECTOR);
  const [pipelineSelectors, setPipelineSelectors] = useState<SelectorValue[]>([]);
  const [niceFilters, setNiceFilters] = useState<Record<string, string>>({});
  const [promptVersion, setPromptVersion] = useState<PV>();

  useEffect(() => {
    if (pipelines.length) return;

    setIsLoading(true);
    (async () => {
      try {
        const data: Pipeline[] = await getPipelines();
        setPipelines(data);
        setPipelineSelectors([DEFAULT_DATA_SELECTOR, ...data.map((d) => ({ label: d.name, value: d.id }))]);
      } catch (error) {
        const err = getErrorMessage(error);
        console.error(error);
        toast.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [isOpen]);

  useEffect(() => {
    setNiceFilters(
      Object.entries(filters).reduce(
        (acc, [key, value]) => {
          if (value instanceof Date) {
            acc[key] = value.toLocaleDateString();
          } else {
            acc[key] = value.toString();
          }
          return acc;
        },
        {} as Record<string, string>
      )
    );
  }, [filters]);

  const handlePipelineChange = (value: SelectorValue) => {
    setSelectedPipeline(value);
    setPromptVersion({ prompt: pipelines.find((p) => p.id === value.value)?.prompt!, version: undefined });
  };

  const reset = () => {
    setName('');
    setPromptVersion(undefined);
    setSelectedPipeline(DEFAULT_DATA_SELECTOR);
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const handleOnCreate = async () => {
    if (!promptVersion || !promptVersion?.version) {
      toast.error('Please select a version.');
      return;
    } else if (!name.length) {
      toast.error('Please enter a valid name.');
      return;
    } else if (!selectedPipeline || selectedPipeline.value === DEFAULT_DATA_SELECTOR.value) {
      toast.error('Please select a pipeline.');
      return;
    }

    setIsSaving(true);

    try {
      const run = await addRunFromHistory(
        selectedPipeline.value as string,
        promptVersion.prompt?.id!,
        promptVersion.version.version,
        name,
        filters
      );
      toast.success('Pipeline run created successfully.');
      onSave(run);
      reset();
    } catch (error) {
      const err = getErrorMessage(error);
      console.error(error);
      toast.error(err);
      return;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title="Pipeline Dataset from History"
      closeText="Cancel"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : (
          'Add'
        )
      }
      disabled={isSaving}
      onConfirm={handleOnCreate}
      onClose={handleOnClose}
      confirmDisabled={isLoading || isSaving}
      icon={faPlusCircle}>
      <div>
        {!isLoading && !pipelines.length ? (
          <div>No pipelines found.</div>
        ) : (
          <div>
            <div>
              <Selector
                values={pipelineSelectors}
                loading={isLoading}
                disabled={isSaving}
                classNames="w-80"
                onChange={handlePipelineChange}
              />
            </div>
            {promptVersion && (
              <div className="mt-2">
                <PromptVersionSelector
                  disabled={isSaving}
                  showPrompts={false}
                  selected={promptVersion}
                  showArchivedButton={false}
                  onChange={setPromptVersion}
                  showPreviewButton={true}
                />
              </div>
            )}
            <div className="mt-2">
              <input
                id="runName"
                name="runName"
                type="text"
                autoComplete="off"
                value={name}
                placeholder="Run Name"
                onChange={(e) => setName(e.currentTarget.value)}
                required
                disabled={isSaving}
                className="block w-2/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="mt-4 text-sm font-semibold  text-gray-600">{count.toLocaleString()} Records</div>
            <div className="mt-4 text-sm font-semibold text-gray-600">Applied Filters</div>
            <div className="grid grid-cols-3 text-xs ">
              {niceFilters.length
                ? Object.entries(niceFilters).map(([key, value], i) => (
                    <div key={i} className="mt-2">
                      <div className="font-semibold">{HistoryFilters[key as keyof MetricsFilters]?.label}</div>
                      <div>{value.toString()}</div>
                    </div>
                  ))
                : 'No filters applied'}
            </div>
          </div>
        )}
      </div>
    </StyledDialog>
  );
};

export default AddPipelineRunFromHistory;
