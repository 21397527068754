import { BaseApi, Pageable, Prompt, PromptVersion } from '.';

/**
 * Represents the possible run statuses for a pipeline.
 */
export enum RunStatus {
  NOT_STARTED = 'NOT_STARTED',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

/**
 * Represents the labels for the run statuses of a pipeline.
 */
export enum RunStatusLabels {
  NOT_STARTED = 'Not Started',
  RUNNING = 'Running',
  COMPLETED = 'Completed',
  FAILED = 'Failed'
}

/**
 * Represents the type of a run.
 */
export enum RunType {
  NORMAL = 'NORMAL',
  HISTORY = 'HISTORY'
}

/**
 * Enum representing the different data sources for a pipeline run.
 *
 * @enum {number}
 * @property {number} NEW - Represents a new data source.
 * @property {number} DEFAULT - Represents the default data source.
 * @property {number} PREVIOUS - Represents a previous data source.
 */
export enum PipelineRunDatasource {
  FILE = 0,
  DEFAULT = 1,
  PREVIOUS = 2
}

/**
 * Enum representing the types of evaluators available.
 *
 * @enum {string}
 * @property {string} WEBHOOK - Represents a webhook evaluator.
 * @property {string} PROMPT - Represents a prompt evaluator.
 */
export enum EvaluatorType {
  WEBHOOK = 'WEBHOOK',
  PROMPT = 'PROMPT'
}

/**
 * Represents a pipeline object.
 */
export interface Pipeline extends BaseApi {
  id: string;
  promptId: string;
  name: string;
  description: string;
  archived: boolean;
  prompt: Prompt;
  defaultDataS3Path: string;
  defaultDataMetadata: PipelineMetadata;
  evaluatorCount: number;
}

/**
 * Represents a pipeline evaluator object.
 */
export interface PipelineEvaluator extends BaseApi {
  id: string;
  pipelineId: string;
  name: string;
  promptId?: string;
  promptVersionNumber?: number;
  webhookId?: string;
  promptName?: string;
  type: EvaluatorType;
}

/**
 * Represents metadata for a pipeline run.
 */
export interface PipelineMetadata {
  contentType: string;
  fileType: string;
  fileName: string;
  rows: number;
}

/**
 * Represents a pipeline run object.
 */
export interface PipelineRun extends BaseApi {
  id: string;
  pipelineId: string;
  promptId: string;
  promptVersion: PromptVersion;
  promptVersionNumber: number;
  type: RunType;
  name: string;
  s3Path: string;
  status: RunStatus;
  metadata: PipelineMetadata;
  competions: any;
  errorMessage: string;
  startedAt: Date;
  completedAt: Date;
}

/**
 * Represents a pageable list of pipeline runs.
 */
export interface PipelineRunPage extends Pageable<PipelineRun> {}

/**
 * Represents data for all pipeline runs.
 */
export interface PipelineRunData {
  runId: string;
  runName: string;
  file: string;
  fileType: string;
}

/**
 * Represents a pageable collection of PipelineRunData objects.
 * This interface extends the Pageable interface, providing pagination
 * capabilities for PipelineRunData items.
 */
export interface PipelineRunDataPage extends Pageable<PipelineRunData> {}

/**
 * Represents an evaluation for a pipeline completion.
 */
export interface PipelineEvaluation extends BaseApi {
  completionId: number;
  evaluatorId: string;
  promptId: string;
  promptVersionNumber: number;
  score: number;
  feedback: string;
  requestTokens: number;
  responseTokens: number;
  requestCost: number;
  responseCost: number;
  failed: boolean;
}

/**
 * Represents a completion for a pipeline run.
 */
export interface PipelineCompletion extends BaseApi {
  completionId: number;
  piplineRunId: string;
  prompt: string;
  completion: string;
  evaluations: PipelineEvaluation[];
  runStatus: RunStatus;
  requestTokens: number;
  responseTokens: number;
  requestCost: number;
  responseCost: number;
  failed: boolean;
}

/**
 * Represents a pipeline score.
 */
export interface PipelineScore {
  pipelineId: string;
  pipelineRunId: string;
  pipelineRunName: string;
  evaluatorId: string;
  evaluatorName: string;
  score: number;
  totalRuns: number;
  created: Date;
}

/**
 * Represents all pipeline scores for a given pipeline.
 */
export interface PipelineScores {
  pipelineId: string;
  pipelineScores: { [key: string]: PipelineScore[] };
}

/**
 * Represents the score of a specific version of a pipeline.
 */
export interface PipelineVersionScore {
  promptVersionNumber: number;
  averageScore: number;
  totalRuns: number;
}
