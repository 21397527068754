interface Props {
  hbsHelpers: Record<string, string>;
}

const HbsHelpersTab: React.FC<Props> = ({ hbsHelpers }: Props) => {
  return (
    <div className="h-full overflow-auto pr-1">
      {Object.entries(hbsHelpers).length > 0 ? (
        <div>
          <div className="text-gray-800 font-semibold border-gray-200 border-b pb-1">Name</div>
          {Object.entries(hbsHelpers).map(([key, value], i) => (
            <div className="mr-1 text-gray-700 mt-2" key={i}>
              {key}
            </div>
          ))}
        </div>
      ) : (
        <div>No helpers available.</div>
      )}
    </div>
  );
};

export default HbsHelpersTab;
