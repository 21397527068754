import { BaseApi, ModelParameterValue, Pipeline } from '.';

/**
 * Represents the different roles that a user can have in a prompt.
 */
export enum PromptRoles {
  OWNER = 'OWNER',
  READ_WRITE = 'READ_WRITE',
  READ_ONLY = 'READ_ONLY',
  ADMIN = 'ADMIN'
}

/**
 * Represents the prompt type.
 */
export enum PromptTypes {
  PROMPT = 'PROMPT',
  EVALUATOR = 'EVALUATOR',
  PROMPT_LIBRARY = 'PROMPT_LIBRARY',
  EVALUATOR_LIBRARY = 'EVALUATOR_LIBRARY'
}

/**
 * Represents the prompt version type.
 */
export enum PromptVersionTypes {
  MESSAGING = 'MESSAGING',
  COMPLETION = 'COMPLETION',
  LEGACY = 'LEGACY'
}

/**
 * Represents the role of a message in a prompt.
 */
export enum PromptMessageRole {
  USER = 'user',
  ASSISTANT = 'assistant'
}

/**
 * Represents the different types of tool properties.
 */
export enum ToolPropertyTypes {
  STRING = 'string'
}

/**
 * Represents the different types of tools actions.
 */
export enum ToolActionTypes {
  TOOL_USE = 'tool_use',
  TOOL_RESULT = 'tool_result'
}

/**
 * Represents the different types of environments.
 */
export enum Environments {
  none = 'No Environment',
  LOCAL = 'Local',
  DEV = 'Development',
  NONPROD = 'Non-Prod',
  PREPROD = 'Pre-Prod',
  PROD = 'Production'
}

/**
 * Represents a user in a prompt.
 */
export interface PromptUser {
  id: string;
  name: string;
  email: string;
  promptRole: PromptRoles;
}

/**
 * Represents a prompt.
 */
export interface Prompt extends BaseApi {
  name: string;
  description: string;
  tags: string[];
  versions?: PromptVersion[];
  versionsCount: number;
  users: PromptUser[];
  userRole?: PromptRoles;
  archived: boolean;
  type: PromptTypes;
  userName?: string;
  defaultPipeline?: Pipeline;
}

/**
 * Represents a prompt and a specific version.
 */
export interface PromptAndVersion {
  prompt: Prompt;
  version: PromptVersion;
}

/**
 * Represents a prompt message.
 */
export interface PromptMessage {
  role: PromptMessageRole;
  content: string;
}

/**
 * Represents a message content.
 */
export interface MessageContent {
  role: PromptMessageRole;
  type?: ToolActionTypes;
  text?: string;
  content?: string;
  id?: string;
  name?: string;
  input?: Record<string, any>;
  toolUseId?: string;
}

/**
 * Represents a PlaygroundMessage, which is a combination of a PromptMessage and MessageContent.
 */
export interface PlaygroundMessage extends MessageContent {}

/**
 * Represents a message reply.
 */
export interface MessageReply {
  role: PromptMessageRole;
  content: MessageContent[];
  type: string;
  stopReason?: string;
}

/**
 * Represents a messaging prompt template.
 */
export interface PromptTemplate {
  systemPrompt: string;
  messages: PromptMessage[];
}

export type CustomPromptProperties = Record<string, string | boolean | number>;

/**
 * Represents a prompt version.
 */
export interface PromptVersion extends BaseApi {
  promptId: string;
  version: number;
  environments: string[];
  template: string;
  messageTemplate: PromptTemplate | undefined;
  samplePayload?: Record<string, any>;
  tools?: Tool[];
  model: string;
  type: PromptVersionTypes;
  parameters: ModelParameterValue[];
  customProps?: CustomPromptProperties;
  helpers?: Record<string, string>;
  changeLog: string;
  author?: PromptUser;
  tags: string[];
}

/**
 * Represents a tool property.
 */
export interface ToolProperty {
  type: ToolPropertyTypes;
  enum: string[];
  description: string;
}

/**
 * Represents the schema for a tool input.
 */
export interface ToolInputSchema {
  type: string;
  properties: Record<string, ToolProperty>;
  required: string[];
}

export interface Tool {
  name: string;
  description: string;
  inputSchema: ToolInputSchema;
}
