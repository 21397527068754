interface Props {}

const NotFound: React.FC<Props> = ({}: Props) => {
  return (
    <div className="text-center">
      <h1 className="font-semibold text-4xl text-indigo-600">404 - Not Found</h1>
      <div className="mt-2">Looks like someone took a wrong turn at Albuquerque!</div>
    </div>
  );
};

export default NotFound;
