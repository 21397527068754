import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { formatCurrency, getErrorMessage } from '../../common/utils';
import { DEFAULT_DF_FORMAT_NOTIME } from '../../constants';
import { compareMetrics, summarizeComparedMetrics } from '../../services/Analytics';
import { MetricsCompare, MetricsCompareSummary } from '../../types';
import PromptVersionSelector, { PV } from '../common/PromptVersionSelector';
import Selector, { SelectorValue } from '../common/Selector';

interface Props {}

const DEFAULT_LABELS = ['Select Prompt', 'Select Version'];
const DAY_SELECTOR_VALS: SelectorValue[] = [
  { value: 7, label: '7' },
  { value: 14, label: '14' },
  { value: 30, label: '30' },
  { value: 60, label: '60' },
  { value: 90, label: '90' }
];
const WINNER = <FontAwesomeIcon icon={faTrophy} className="ml-2 text-yellow-400" />;

/**
 * Prompt Analytics page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const CompareTab: React.FC<Props> = ({}: Props) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPV1, setSelectedPV1] = useState<PV>();
  const [selectedPV2, setSelectedPV2] = useState<PV>();
  const [selectedDaysCount, setSelectedDaysCount] = useState<SelectorValue>(DAY_SELECTOR_VALS[1]);
  const [ready, setReady] = useState<boolean>(false);
  const [compareData, setCompareData] = useState<MetricsCompare>();
  const [compareSummary, setCompareSummary] = useState<MetricsCompareSummary>();

  useEffect(() => {
    if (selectedPV1 && !selectedPV2) {
      setSelectedPV2({ ...selectedPV1 });
    }

    if (selectedPV1 && selectedPV1.version && selectedPV2 && selectedPV2.version) {
      setReady(true);
    }
  }, [selectedPV1, selectedPV2]);

  const handleLoadData = async () => {
    setIsDisabled(true);
    setIsLoading(true);

    try {
      const data = await compareMetrics(
        selectedPV1?.prompt?.id!,
        selectedPV1?.version?.version!,
        selectedPV2?.prompt?.id!,
        selectedPV2?.version?.version!,
        Number(selectedDaysCount.value)
      );

      // TODO: break into two try/catch blocks
      setCompareData(data);
      setCompareSummary(await summarizeComparedMetrics({ version1: data.version1, version2: data.version2 }));
    } catch (e) {
      console.error(e);
      return toast.error(getErrorMessage(e));
    } finally {
      setIsDisabled(false);
      setIsLoading(false);
    }
  };

  const formatLlmReview = (resp: string) => {
    const parts = resp.split('\n\n');
    const delimeter = '~';

    if (parts.length !== 2) {
      return (
        <div>
          <div className="font-semibold text-gray-700 text-md">Summary</div>
          <div className="space-y-1 text-gray-600 mt-2">{resp}</div>
        </div>
      );
    }

    // yeah yeah, I know this is a mess
    try {
      return (
        <div className="flex">
          <div className="mr-4 w-6/12">
            <div className="font-semibold text-gray-700 text-md">Summary</div>
            <div className="space-y-1 text-gray-600 mt-2 text-sm">{parts[0]}</div>
          </div>
          <div className="w-6/12">
            {parts[1].includes(delimeter) ? (
              <div>
                <div className="font-semibold text-gray-700 text-md">Suggestions</div>
                <ul className="space-y-1 text-gray-600 list-disc list-outside mt-2 ml-4">
                  {parts[1]
                    .split(delimeter)
                    .filter((item, index) => {
                      item = item.trim();
                      if (index === 0 && item.toLowerCase().includes('suggestion')) return false;
                      if (item !== '\n' && item.trim() !== '' && !item.startsWith('Suggestions')) return true;
                      return false;
                    })
                    .map((item, index2) => (
                      <li key={index2} className="text-sm ">
                        {item.replaceAll('- ', '').trim()}
                      </li>
                    ))}
                </ul>
              </div>
            ) : (
              <div>{parts[1]}</div>
            )}
          </div>
        </div>
      );
    } catch (e) {
      console.warn(`Error formatting LLM review: ${getErrorMessage(e)}`);
      return resp;
    }
  };

  return (
    <div>
      <div className="mx-auto mb-5 border-gray-300 border rounded py-2 px-1 bg-gray-50 text-sm text-gray-600 w-1/2 pl-2">
        <div className="flex items-center mt-2">
          <div className="mr-2">Prompt 1</div>
          <PromptVersionSelector
            onChange={setSelectedPV1}
            selected={selectedPV1}
            disabled={isDisabled}
            defaultLabels={DEFAULT_LABELS}
          />
        </div>
        <div className="flex items-center mt-4">
          <div className="mr-2">Prompt 2</div>
          <PromptVersionSelector
            onChange={setSelectedPV2}
            selected={selectedPV2}
            disabled={isDisabled}
            defaultLabels={DEFAULT_LABELS}
          />
        </div>
        <div className="flex items-center mt-4">
          <div className="mr-9">Days</div>
          <Selector
            classNames="w-40 my-2"
            values={DAY_SELECTOR_VALS}
            defaultValue={selectedDaysCount}
            disabled={isDisabled}
            onChange={(action) => setSelectedDaysCount(action)}
          />
          <div className="flex-1 text-right pr-2">
            <button className="standard" disabled={isDisabled || !ready} onClick={handleLoadData}>
              Compare
            </button>
          </div>
        </div>
      </div>

      {(compareData || isLoading) && (
        <div className="mt-8">
          <div className="mb-6 whitespace-pre-line text-gray-600">
            {compareSummary && !isLoading ? formatLlmReview(compareSummary.results) : <Skeleton count={3} />}
          </div>
          <div className="text-gray-700 border-gray-200 mt-8">
            <div className="container flex flex-wrap mx-auto">
              <div className=" mt-20 hidden lg:block">
                <div className="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
                  <p className="bg-gray-100 text-sm text-gray-900 h-12 px-4 flex items-center justify-start -mt-px">
                    Calls
                  </p>
                  <p className="text-sm text-gray-900 h-12 px-4 flex items-center justify-start">
                    Average Request Tokens
                  </p>
                  <p className="bg-gray-100 text-sm text-gray-900 h-12 px-4 flex items-center justify-start">
                    Average Response Tokens
                  </p>
                  <p className="text-sm text-gray-900 h-12 px-4 flex items-center justify-start">
                    Average Request Cost
                  </p>
                  <p className="bg-gray-100 text-sm text-gray-900 h-12 px-4 flex items-center justify-start">
                    Average Response Cost
                  </p>
                  <p className="text-sm text-gray-900 h-12 px-4 flex items-center justify-start">Average Latency</p>
                  <p className="bg-gray-100 text-sm text-gray-900 h-12 px-4 flex items-center justify-start">
                    Average TTFB
                  </p>
                  <p className="text-sm text-gray-900 h-12 px-4 flex items-center justify-start">Average User Score</p>
                  <p className="bg-gray-100 text-sm text-gray-900 h-12 px-4 flex items-center justify-start">
                    Average Analyst Score
                  </p>
                  <p className="text-sm text-gray-900 h-12 px-4 flex items-center justify-start">Model</p>
                  <p className="bg-gray-100 text-sm text-gray-900 h-12 px-4 flex items-center justify-start">
                    Parameters
                  </p>
                  <p className="text-sm text-gray-900 h-12 px-4 flex items-center justify-start">Date Range</p>
                </div>
              </div>
              <div className="flex lg:w-3/4 w-full flex-wrap lg:border border-gray-300 rounded-t-lg rounded-br-lg">
                <div className="lg:w-1/2 lg:mt-px w-full mb-10 lg:mb-0 border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
                  <div className="px-2 text-center pt-6 pb-3 flex flex-col items-center justify-center">
                    <span className="text-md text-indigo-600 font-semibold">{selectedPV1?.prompt?.name}</span>
                    <span className="text-sm text-gray-700">v{selectedPV1?.version?.version}</span>
                  </div>
                  <p className="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version1.totalInvocations}
                        {compareData?.version1.totalInvocations > compareData?.version2.totalInvocations && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading || !compareData ? (
                      <Skeleton width={200} />
                    ) : (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version1.avgRequestTokens.toFixed()}
                      </span>
                    )}
                  </p>
                  <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading || !compareData ? (
                      <Skeleton width={200} />
                    ) : (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version1.avgResponseTokens.toFixed()}
                      </span>
                    )}
                  </p>
                  <p className="h-12 text-gray-600 px-6 text-center leading-relaxed flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version1 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {formatCurrency(compareData.version1.avgRequestCost, 6)}
                        {compareData.version1.avgRequestCost < compareData.version2.avgRequestCost && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version1 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {formatCurrency(compareData.version1.avgResponseCost, 6)}
                        {compareData.version1.avgResponseCost < compareData.version2.avgResponseCost && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version1 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData.version1.avgLatency.toFixed(4)} sec
                        {compareData.version1.avgLatency < compareData.version2.avgLatency && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version1 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version1.avgTtfb.toFixed(2)} ms
                        {compareData.version1.avgTtfb < compareData.version2.avgTtfb && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version1 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version1.avgScore.toFixed(2)}
                        {compareData.version1.avgScore > compareData.version2.avgScore && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version1 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version1.avgAnalystScore.toFixed(2)}
                        {compareData.version1.avgAnalystScore > compareData.version2.avgAnalystScore && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version1 && (
                      <span className="">{compareData?.version1.version.model}</span>
                    )}
                  </p>
                  <p className="bg-gray-100 text-gray-600 text-center text-xs h-12 overflow-auto flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version1 && (
                      <span className="">
                        {compareData?.version1.version.parameters
                          .map((param) => `${param.name}: ${param.value}`)
                          .join(', ')}
                      </span>
                    )}
                  </p>
                  <p className="text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version2 && (
                      <span className="ml-2">
                        {format(compareData?.version1.firstInvocation, DEFAULT_DF_FORMAT_NOTIME)} -{' '}
                        {format(compareData?.version1.lastInvocation, DEFAULT_DF_FORMAT_NOTIME)}
                      </span>
                    )}
                  </p>
                </div>
                <div className="lg:w-1/2 lg:-mt-px w-full mb-10 lg:mb-0 border-l border-gray-300 rounded-r-lg relative">
                  <div className="px-2 text-center pt-6 pb-3 flex flex-col items-center justify-center">
                    <span className="text-md text-indigo-600 font-semibold">{selectedPV2?.prompt?.name}</span>
                    <span className="pb-0.5 text-sm text-gray-700">v{selectedPV2?.version?.version}</span>
                  </div>
                  <p className="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version2.totalInvocations}
                        {compareData?.version1.totalInvocations < compareData?.version2.totalInvocations && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading ? (
                      <Skeleton width={200} />
                    ) : (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version2.avgRequestTokens.toFixed()}
                      </span>
                    )}
                  </p>
                  <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading ? (
                      <Skeleton width={200} />
                    ) : (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version2.avgResponseTokens.toFixed()}
                      </span>
                    )}
                  </p>
                  <p className="h-12 text-gray-600 px-6 text-center leading-relaxed flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version2 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {formatCurrency(compareData.version2.avgRequestCost, 6)}
                        {compareData.version1.avgRequestCost > compareData.version2.avgRequestCost && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version2 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {formatCurrency(compareData.version2.avgResponseCost, 6)}
                        {compareData.version1.avgResponseCost > compareData.version2.avgResponseCost && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version2 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData.version2.avgLatency.toFixed(4)} sec
                        {compareData.version1.avgLatency > compareData.version2.avgLatency && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version2 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version2.avgTtfb.toFixed(2)} ms
                        {compareData.version1.avgTtfb > compareData.version2.avgTtfb && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version2 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version2.avgScore.toFixed(2)}
                        {compareData.version1.avgScore < compareData.version2.avgScore && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version2 && (
                      <span className="w-[7.8rem] pl-4 text-left">
                        {compareData?.version2.avgAnalystScore.toFixed(2)}
                        {compareData.version1.avgAnalystScore < compareData.version2.avgAnalystScore && WINNER}
                      </span>
                    )}
                  </p>
                  <p className="text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version2 && (
                      <span className="">{compareData?.version2.version.model}</span>
                    )}
                  </p>
                  <p className="bg-gray-100 text-gray-600 text-center text-xs h-12 overflow-auto flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version2 && (
                      <span className="">
                        {compareData?.version2.version.parameters
                          .map((param) => `${param.name}: ${param.value}`)
                          .join(', ')}
                      </span>
                    )}
                  </p>
                  <p className="text-gray-600 text-center h-12 flex items-center justify-center">
                    {isLoading && <Skeleton width={200} />}
                    {!isLoading && compareData?.version2 && (
                      <span className="ml-2">
                        {format(compareData?.version2.firstInvocation, DEFAULT_DF_FORMAT_NOTIME)} -{' '}
                        {format(compareData?.version2.lastInvocation, DEFAULT_DF_FORMAT_NOTIME)}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompareTab;
