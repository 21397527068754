import { memo } from 'react';

/**
 * Represents a column in the table data.
 */
export interface TableDataCol {
  onClick?: (col: TableDataCol) => void;
  colSpan?: number;
  value: string | number | React.ReactElement | React.ReactHTMLElement<any> | undefined;
  className?: string;
}

/**
 * Represents a row in the table data.
 */
export interface TableDataRow {
  cols: TableDataCol[];
  onClick?: (row: TableDataRow) => void;
  className?: string;
}

/**
 * Represents the table data.
 */
export interface TableData {
  headers: string[];
  rows: TableDataRow[];
}

/**
 * Props for the DataTable component.
 */
interface Props {
  data?: TableData;
  noDataMessage?: string;
  className?: string;
  divideY?: boolean;
}

/**
 * A reusable data table component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Array<Object>} props.data - The data to be displayed in the table.
 * @param {string} [props.className=''] - Additional CSS class for the table container.
 * @param {string} [props.noDataMessage='No results.'] - The message to display when there is no data.
 * @param {boolean} [props.divideY=true] - Whether to divide the rows in the table.
 * @returns {JSX.Element} The rendered DataTable component.
 */
const DataTable: React.FC<Props> = memo(
  ({ data, className = '', noDataMessage = 'No results.', divideY = true }: Props) => {
    const colClazz = `w-1/${data?.headers.length}`;
    return (
      <div className={`py-2 align-middle inline-block min-w-full ${className}`}>
        <div className="shadow border-b border-gray-200 sm:rounded-lg">
          <table className="w-full divide-gray-200 ">
            <thead className="bg-gray-50 sticky">
              <tr>
                {data?.headers.map((header, i) => (
                  <th
                    scope="col"
                    key={i}
                    className={`${colClazz} px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase`}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            {/* fixme: cant get this tbody to overflow scroll */}
            <tbody className={`bg-white ${divideY ? 'divide-y divide-gray-200' : ''}`}>
              {!data?.rows.length && (
                <tr className="text-center w-full">
                  <td colSpan={data?.headers.length} className="pt-4 text-lg">
                    {noDataMessage}
                  </td>
                </tr>
              )}
              {data?.rows.map((row, ridx) => (
                <tr
                  key={ridx}
                  onClick={() => row.onClick && row.onClick(row)}
                  className={`hover:bg-gray-100 cursor-pointer ${row.className || ''}`}>
                  {row.cols.map((col, cidx) => (
                    <td
                      key={cidx}
                      colSpan={col.colSpan || 1}
                      className={`${colClazz} ${col.className || ''} px-6 py-4 whitespace-nowrap text-sm text-gray-500`}>
                      {col.value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
);

DataTable.displayName = 'DataTable';

export default DataTable;
