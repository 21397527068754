import Skeleton from 'react-loading-skeleton';
import { formatCurrency } from '../../common/utils';
import { ModelRunResult } from '../../types/Models';

interface Props {
  run?: ModelRunResult;
}

const ModelRunStats: React.FC<Props> = ({ run }: Props) => {
  return (
    <div className="grid grid-cols-6 gap-2 mt-2 pb-4 border-b border-gray-300">
      <div className="flex flex-col">
        <div className="text-xs text-gray-600">TTFB</div>
        <div className="text-sm">{run ? run?.ttfb + 'ms' : <Skeleton />}</div>
      </div>
      <div className="flex flex-col">
        <div className="text-xs text-gray-600">Latency</div>
        <div className="text-sm">{run ? run?.latency.toFixed(1) + 's' : <Skeleton />}</div>
      </div>
      <div className="flex flex-col">
        <div className="text-xs text-gray-600">Tokens In</div>
        <div className="text-sm">{run ? run?.tokens.requestTokens : <Skeleton />}</div>
      </div>
      <div className="flex flex-col">
        <div className="text-xs text-gray-600">Tokens Out</div>
        <div className="text-sm">{run ? run?.tokens.responseTokens : <Skeleton />}</div>
      </div>
      <div className="flex flex-col">
        <div className="text-xs text-gray-600">Tokens</div>
        <div className="text-sm">{run ? run?.tokens.requestTokens + run?.tokens.responseTokens : <Skeleton />}</div>
      </div>
      <div className="flex flex-col">
        <div className="text-xs text-gray-600">Cost</div>
        <div className="text-sm">
          {run ? formatCurrency(run?.tokens.requestCost + run?.tokens.responseCost, 5) : <Skeleton />}
        </div>
      </div>
    </div>
  );
};

export default ModelRunStats;
