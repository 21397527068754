import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Props for the Dialog component.
 */
export interface DialogProps {
  title: string;
  icon?: IconProp;
  children?: React.ReactElement[] | React.ReactElement | string;
  message?: string;
  isOpen: boolean;
  closeText?: React.ReactElement[] | React.ReactElement | string;
  confirmText?: React.ReactElement[] | React.ReactElement | string;
  confirmDisabled?: boolean;
  confirmClassName?: string;
  tertiaryText?: React.ReactElement[] | React.ReactElement | string;
  tertiaryDisabled?: boolean;
  tertiaryClassName?: string;
  disabled?: boolean;
  width?: string;
  onClose: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onTertiary?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledDialog: React.FC<DialogProps> = ({
  title,
  icon,
  confirmText,
  confirmClassName,
  closeText,
  tertiaryText,
  tertiaryDisabled,
  tertiaryClassName,
  children,
  message,
  confirmDisabled = false,
  isOpen = false,
  disabled = false,
  width = 'w-1/3',
  onClose,
  onConfirm,
  onTertiary
}: DialogProps) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [children, message]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 backdrop-blur-sm bg-white/30" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
          <div className="flex min-h-full justify-center p-4 text-center items-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4">
              <Dialog.Panel
                className={`${width} relative transform overflow-hidden rounded-lg bg-white text-left border-gray-200 shadow-xl border transition-all mt-2`}>
                <div className="bg-gray-50 px-4 pt-3  ">
                  <Dialog.Title as="h2" className="text-lg font-semibold leading-6 text-gray-900 flex ">
                    {icon && <FontAwesomeIcon icon={icon} className="h-6 w-6 text-indigo-600 mr-2" />}
                    <div className="flex-1">{title}</div>
                  </Dialog.Title>
                </div>
                <div className="p-4 pb-6 bg-gray-50 whitespace-pre-line">{children || message}</div>
                <div className="flex bg-gray-100 py-2 pr-2 ">
                  <div className="flex-1">
                    {onTertiary && (
                      <button
                        type="button"
                        className={`disabled:cursor-not-allowed standard secondary px-3 py-2 sm:ml-3 sm:w-auto ${tertiaryClassName}`}
                        onClick={onTertiary}
                        disabled={disabled || tertiaryDisabled}>
                        {tertiaryText || 'Tertiary'}
                      </button>
                    )}
                  </div>
                  <div className="flex flex-row-reverse">
                    {onConfirm && (
                      <button
                        type="button"
                        className={`disabled:cursor-not-allowed standard px-3 py-2 sm:ml-3 sm:w-auto ${confirmClassName}`}
                        onClick={onConfirm}
                        disabled={disabled || confirmDisabled}>
                        {confirmText || 'Confirm'}
                      </button>
                    )}
                    <button
                      type="button"
                      className="disabled:cursor-not-allowed mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={onClose}
                      disabled={disabled}>
                      {closeText || 'Close'}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default StyledDialog;
