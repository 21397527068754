import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Model, ModelParameterValue } from '../../types';
import { deepCopy, getErrorMessage } from '../../common/utils';
import { Prompt, PromptVersion } from '../../types';
import { ModelParameterGroup, PromptEditor, Selector, StyledDialog } from '../../components';
import toast from 'react-hot-toast';
import { createPrompt } from '../../services/Prompts';
import { createVersion } from '../../services/PromptVersions';
import { getModels } from '../../services/Models';
import { faSave, faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import { PromptTemplate, PromptTypes, PromptVersionTypes, Tool } from '../../types/Prompt';
import { newEmptyPrompt, newEmptyVersion } from '../../common/prompts';
import { SelectorValue } from '../../components/common/Selector';
import { TagsInput } from 'react-tag-input-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useValidateEvaluator, useValidateNewVersion, useVersionTypeSelectors } from '../../hooks/versionHooks';

interface Props {}

/**
 * New prompt page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const NewPrompt: React.FC<Props> = ({}: Props) => {
  const navigate = useNavigate();
  const routerParams = useParams();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [versionTypeVal, setPromptVersionTypeSelector] = useState<SelectorValue>();
  const [isShowEvaluatorError, setIsShowEvaluatorError] = useState<boolean>(false);
  const [model, setModel] = useState<Model>();
  const [models, setModels] = useState<Model[]>([]);
  const [prompt, setPrompt] = useState<Prompt>(newEmptyPrompt());
  const [version, setVersion] = useState<PromptVersion>(newEmptyVersion());
  const versionTypeSelectors = useVersionTypeSelectors(prompt);
  const [missingEvaluatorParams, setMissingEvaluatorParams] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const models = await getModels();
      const defaultModel = models[0];
      const defaultParameters =
        version.type === PromptVersionTypes.COMPLETION
          ? defaultModel.parametersCompletion
          : defaultModel.parametersMessages;

      setModels(models);
      setModel(defaultModel);
      setVersion({
        ...deepCopy(version),
        model: defaultModel.mid,
        type: PromptVersionTypes.MESSAGING,
        parameters: defaultParameters.map((parameter) => ({
          name: parameter.parameterModel,
          value: parameter.default
        }))
      });
    })();

    let typeStr: string = routerParams.type as string;
    let pType: PromptTypes = PromptTypes.PROMPT;
    if (typeStr) {
      pType = PromptTypes[typeStr.toUpperCase() as keyof typeof PromptTypes];
    }

    setPromptField('type', pType);
  }, []);

  const setVersionField = (field: keyof PromptVersion, value: any) =>
    setVersion({ ...deepCopy(version!), [field]: value });

  const setPromptField = (field: keyof Prompt, value: any) => setPrompt({ ...deepCopy(prompt!), [field]: value });

  const handleModelChange = (model: Model, parameters: ModelParameterValue[]) => {
    setModel(model);
    setVersion({ ...deepCopy(version), model: model.mid, parameters });
  };

  const handleVersionTypeChange = (type: SelectorValue) => {
    setPromptVersionTypeSelector(type);
    setVersionField('type', type.value);
  };

  const handleTemplateChange = (template: string | PromptTemplate, tools: Tool[]) => {
    if (typeof template === 'string') {
      setVersionField('template', template);
    } else {
      setVersion({ ...deepCopy(version), messageTemplate: template, tools });
    }
  };

  const handleOnSave = async () => {
    const id = 'toasty-id';
    const validatorProps = { prompt, version };
    const validationErrors = useValidateNewVersion(validatorProps);
    const validationEvaluatorErrors = useValidateEvaluator(validatorProps);

    if (validationErrors) {
      return toast.error(validationErrors, { id });
    } else if (validationEvaluatorErrors) {
      setMissingEvaluatorParams(validationEvaluatorErrors);
      setIsShowEvaluatorError(true);
      return;
    }

    toast.loading(`Creating prompt`, { id });
    setIsSaving(true);

    try {
      const newPrompt = await createPrompt(prompt);

      toast.loading('Creating version', { id });
      const newVersion = await createVersion({ ...deepCopy(version), promptId: newPrompt.id! });

      toast.success('Prompt Created', { id });
      navigate(`/prompts/${newPrompt.id}`, { state: { newPrompt, newVersion } });
    } catch (error) {
      return toast.error(getErrorMessage(error), { id });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <div className="mx-auto">
        <div className="flex">
          <div className="flex-1">
            <h1 className="text-2xl text-gray-800">New Prompt</h1>
          </div>
        </div>
        <div className="flex mt-4 ">
          <div className="w-full mr-4 rounded-lg border-gray-300 border flex flex-col">
            <div className="border-b border-gray-300 px-1 flex justify-between">
              <div className="flex-1">
                <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="off"
                  value={prompt?.name}
                  placeholder="Prompt Name"
                  onChange={(e) => setPromptField('name', e.target.value)}
                  onBlur={(e) => setPromptField('name', e.target.value.trim())}
                  disabled={isSaving}
                  required
                  className="block w-full border-0 pt-2.5 text-lg font-medium placeholder-gray-500 focus:ring-0"
                />
              </div>
            </div>

            <PromptEditor
              promptVersion={version}
              disabled={isSaving}
              loading={false}
              readOnly={false}
              onChange={handleTemplateChange}
            />
            <div className="w-full py-1 px-1 border-t border-gray-300">
              <div className="text-sm px-3 pt-1 text-gray-600">Notes</div>
              <textarea
                id="notes"
                name="notes"
                placeholder="Add any notes you'd like to keep about this prompt."
                rows={2}
                onChange={(e) => setPromptField('description', e.target.value)}
                onBlur={(e) => setPromptField('description', e.target.value.trim())}
                disabled={isSaving}
                value={prompt?.description}
                required
                className="w-full resize-none border-none  focus:ring-0"
              />
            </div>
            <div className="w-full items-center flex justify-end px-4 py-1 border-t border-gray-300">
              <div className="flex-1 text-gray-600">
                <div className="flex items-center">
                  <div className="text-sm inline-block">Tags</div>
                  <TagsInput
                    value={prompt?.tags}
                    onChange={(tags: string[]) => setPromptField('tags', tags)}
                    name="tags"
                    placeHolder="Add Tag"
                    disabled={isSaving}
                    classNames={{
                      input: 'focus:ring-0 !w-auto !text-xs !pl-0 placeholder-indigo-700 text-indigo-700',
                      tag: 'mr-2 !pl-2 !py-1 text-xs !bg-gray-200'
                    }}
                  />
                </div>
              </div>
              <div>
                <button
                  className={'standard ' + (isSaving ? 'disabled:opacity-25' : '')}
                  onClick={handleOnSave}
                  disabled={isSaving}>
                  {isSaving ? (
                    <div>
                      <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
                    </div>
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faSave} className="mr-1.5" /> Save
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="flex-intial w-64">
            <Selector
              values={versionTypeSelectors}
              defaultValue={versionTypeVal}
              onChange={handleVersionTypeChange}
              disabled={isSaving}
              isSearchable={false}
              classNames="w-full mb-4"
            />
            <ModelParameterGroup
              models={models}
              model={model}
              disabled={isSaving}
              type={version.type}
              parameters={version.parameters}
              onChange={handleModelChange}
            />
          </div>
        </div>
        <StyledDialog
          title="Missing Parameters"
          isOpen={isShowEvaluatorError}
          onClose={() => setIsShowEvaluatorError(false)}
          icon={faWarning}>
          <div>Your template must include the following parameters: {missingEvaluatorParams.join(', ')}.</div>
        </StyledDialog>
      </div>
    </>
  );
};

export default NewPrompt;
