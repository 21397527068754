import Selector, { SelectorValue } from '../../components/common/Selector';
import { faPlus, faSliders, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { StyledDialog } from '../common';
import { CustomPromptProperties } from '../../types/Prompt';

interface Props {
  isOpen: boolean;
  properties?: CustomPromptProperties;
  disabled?: boolean;
  onSave: (properties: CustomPromptProperties) => void;
  onClose: () => void;
}

const defaultPropertyName = 'property';

const propertyTypeSelectors = [
  { value: 'string', label: 'String' },
  { value: 'number', label: 'Number' },
  { value: 'boolean', label: 'Boolean' }
];

type PromptPropertyType = 'string' | 'number' | 'boolean';

interface CustomPromptProperty {
  value: string | number | boolean;
  type: PromptPropertyType;
}

const CustomProperties: React.FC<Props> = ({ properties, isOpen, disabled, onClose, onSave }: Props) => {
  const [_properties, setProperties] = useState<Record<string, CustomPromptProperty>>({});

  useEffect(() => {
    if (!properties) return;

    setProperties(
      Object.entries(properties).reduce((acc, [name, value]) => {
        return { ...acc, [name]: { value, type: typeof value } };
      }, {})
    );
  }, [properties]);

  const handlePropertyNameChange = (oldKey: string, newKey: string) => {
    if (Object.keys(_properties).includes(newKey)) {
      return toast.error(`Property named "${newKey}" already exists`);
    }

    const newProperties = { ..._properties, [newKey]: _properties[oldKey] };
    delete newProperties[oldKey];

    setProperties(newProperties);
  };

  const handlePropertyValueChange = (key: string, value: string): void => {
    const newProperty = _properties[key];
    newProperty.value =
      newProperty.type === 'number' ? Number(value) : newProperty.type === 'boolean' ? Boolean(value) : value;

    setProperties({ ..._properties, [key]: newProperty });
  };

  const handlePropertyTypeChange = (key: string, selectorValue: SelectorValue) => {
    const newProperty = _properties[key];
    newProperty.type = selectorValue.value as PromptPropertyType;
    try {
      newProperty.value =
        newProperty.type === 'number'
          ? Number(newProperty.value)
          : newProperty.type === 'boolean'
            ? newProperty.value === 'true'
            : newProperty.value;
    } catch (e) {
      return toast.error(`Invalid property type for value ${selectorValue.value}`);
    }

    setProperties({ ..._properties, [key]: newProperty });
  };

  const handleAddProperty = () => {
    if (Object.keys(_properties).includes(defaultPropertyName)) {
      return toast.error(`Property named "${defaultPropertyName}" already exists`);
    }

    setProperties({ ..._properties, [defaultPropertyName]: { value: '', type: 'string' } });
  };

  const handleDeleteProperty = (key: string): void => {
    const newProperties = { ..._properties };
    delete newProperties[key];

    setProperties(newProperties);
  };

  const handleOnSave = () => {
    onSave(
      Object.entries(_properties).reduce((acc, [name, value]) => {
        return {
          ...acc,
          [name]: value.type === 'string' ? value.value : value.type === 'number' ? Number(value.value) : value.value
        };
      }, {})
    );
  };

  return (
    <StyledDialog
      title="Custom Properties"
      isOpen={isOpen}
      closeText="Close"
      icon={faSliders}
      width="w-2/3"
      confirmText="Update"
      onConfirm={() => handleOnSave()}
      onClose={() => onClose()}>
      <div className="h-full overflow-auto pr-1">
        {Object.entries(_properties).map(([key, property], i) => (
          <div key={i} className="grid grid-cols-4 space-x-4 content-center mb-2">
            <input
              type="text"
              value={key}
              onChange={(e) => handlePropertyNameChange(key, e.currentTarget.value)}
              disabled={disabled}
              className=" block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <Selector
              classNames=" inline-block mr-4"
              values={propertyTypeSelectors}
              disabled={disabled}
              defaultValue={propertyTypeSelectors.find((v) => v.value === property.type)}
              onChange={(action) => handlePropertyTypeChange(key, action)}
            />

            {property.type !== 'boolean' ? (
              <input
                type={property.type === 'number' ? 'number' : 'text'}
                value={property.value as string}
                onChange={(e) => handlePropertyValueChange(key, e.currentTarget.value)}
                disabled={disabled}
                className="w-64 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            ) : (
              <div>
                <input
                  type="checkbox"
                  checked={(property.value as boolean) === true}
                  className="peer sr-only opacity-0"
                  id={`toggle-${key}`}
                  onChange={(e) => handlePropertyValueChange(key, e.target.checked.toString())}
                  disabled={disabled}
                />
                <label htmlFor={`toggle-${key}`} className="selector mt-1" />
              </div>
            )}
            <div className="text-right content-center">
              <FontAwesomeIcon
                icon={faTrash}
                className="text-red-400 cursor-pointer hover:text-red-500"
                onClick={() => handleDeleteProperty(key)}
              />
            </div>
          </div>
        ))}
        <div className="text-center mt-2">
          <button className="standard secondary" onClick={(e) => handleAddProperty()}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Property
          </button>
        </div>
      </div>
    </StyledDialog>
  );
};

export default CustomProperties;
