import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useParams } from 'react-router-dom';
import { userCanEdit } from '../../common/prompts';
import { getErrorMessage } from '../../common/utils';
import { TabGroup } from '../../components';
import ManageExperimentTab from '../../components/experiments/ManageExperimentTab';
import VariantTab from '../../components/experiments/VariantTab';
import { getPromptUsers } from '../../services/Prompts';
import { getExperiment } from '../../services/Experimentation';
import { getPrompt } from '../../services/Prompts';
import { getUserLocal } from '../../services/User';
import { Experiment as IExperiment, Prompt } from '../../types';

interface Props {}

/**
 * Represents the Experiment page component.
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const Experiment: React.FC<Props> = ({}: Props) => {
  const location = useLocation();
  const experimentId = useParams().experimentId;
  const [experiment, setExperiment] = useState<IExperiment | undefined>(location.state?.experiment);
  const [prompt, setPrompt] = useState<Prompt>();
  // const [promptUsers, setPromptUsers] = useState<PromptUser[]>([]);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const onExperimentChanges = (updatedExperiment: IExperiment) => {
    setExperiment(updatedExperiment);
  };

  useEffect(() => {
    (async () => {
      let _experiment = experiment;
      try {
        if (!_experiment) {
          _experiment = await getExperiment(experimentId!);
          setExperiment(_experiment);
        }
        const _promptUsers = await getPromptUsers(_experiment.promptId);
        setPrompt(await getPrompt(_experiment.promptId));
        // setPromptUsers(_promptUsers);
        setCanEdit(userCanEdit(_promptUsers, getUserLocal()!));
      } catch (error) {
        return toast.error(getErrorMessage(error));
      }
    })();
  }, [experiment]);

  return (
    <div className="mx-auto">
      <div className="flex mt-2 space-x-4">
        <div className="flex-intial w-64">
          <h1 className="text-xl font-semibold text-gray-800 mb-1 w-full">
            {experiment ? experiment.name : <Skeleton containerClassName="inline" />}
          </h1>
          <h2 className="text-base mb-6">
            {prompt ? (
              <a
                href={`/prompts/${experiment?.promptId}/prompts/${prompt.id}`}
                target="_blank"
                rel="noreferrer"
                className="text-gray-400 standard">
                {prompt.name}
                <FontAwesomeIcon icon={faUpRightFromSquare} className="mx-2 h-3 w-3 pb-1" />
              </a>
            ) : (
              <Skeleton containerClassName="inline" />
            )}
          </h2>
          {experiment && (
            <div className={`text-md font-semibold ${experiment.live ? 'text-green-500' : 'text-red-400'} `}>
              {experiment.live ? 'Live' : 'Paused'}
            </div>
          )}
        </div>
        <div className="w-full">
          <TabGroup
            tabNames={['Variations', 'Results', canEdit ? 'Settings' : '']}
            selectedTabIndex={selectedTab}
            onTabChange={setSelectedTab}>
            <>{selectedTab === 0 && <VariantTab experiment={experiment} canEdit={canEdit} />}</>
            <>
              {selectedTab === 2 && experiment && prompt && canEdit && (
                <ManageExperimentTab prompt={prompt} experiment={experiment} onChangesSaved={onExperimentChanges} />
              )}
            </>
          </TabGroup>
        </div>
      </div>
    </div>
  );
};

export default Experiment;
