import {
  faPlus,
  faFileCsv,
  faFilter,
  faSpinner,
  faCircleQuestion,
  faTableColumns
} from '@fortawesome/free-solid-svg-icons';
import { faFaceSadCry } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { formatCurrency, getErrorMessage } from '../common/utils';
import { DEFAULT_DF_FORMAT_SHORT } from '../constants';
import { getMetrics, getMetricsCsv, rateAnalyst, SearchDefaults } from '../services/Analytics';
import { MetricsFilters, MetricsSort, MetricsSortPair, Model, Prompt, PromptMetric, PromptMetricPage } from '../types';
import {
  AddPipelineRunFromHistory,
  CallHistoryDetailsPanel,
  CallHistoryFilterModal,
  CustomColumnModal
} from '../components';
import { getPrompts } from '../services/Prompts';
import { getModels } from '../services/Models';

enum Columns {
  TIME = 'time',
  PROMPT_NAME = 'promptName',
  VERSION = 'version',
  MODEL = 'model',
  COST = 'cost',
  LATENCY = 'latency',
  INFERENCE = 'inference',
  RUN_TIME = 'runTime',
  TTFB = 'ttfb',
  USER_SCORE = 'userScore',
  USER_FEEDBACK = 'userFeedback',
  SPECIALIST_SCORE = 'specialistScore',
  SPECIALIST_FEEDBACK = 'specialistFeedback',
  PROMPT = 'prompt',
  COMPLETION = 'completion'
}

const metricsSortMap: Partial<Record<Columns, MetricsSort>> = {
  [Columns.TIME]: MetricsSort.CREATED,
  [Columns.MODEL]: MetricsSort.MODEL,
  [Columns.VERSION]: MetricsSort.VERSION,
  [Columns.LATENCY]: MetricsSort.LATENCY,
  [Columns.INFERENCE]: MetricsSort.INFERENCE_TIME,
  [Columns.TTFB]: MetricsSort.TTFB,
  [Columns.USER_SCORE]: MetricsSort.SCORE,
  [Columns.SPECIALIST_SCORE]: MetricsSort.ANALYST_SCORE
};

export interface PromptMetricExtended extends PromptMetric {
  modelObject: Model;
  promptObject: Prompt;
}

interface Props {}

/**
 * Call History page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const CallHistory: React.FC<Props> = ({}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingCsv, setIsLoadingCsv] = useState<boolean>(false);
  const [isShowDetails, setIsShowDetails] = useState<boolean>(false);
  const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
  const [isShowNewRunModal, setIsShowNewRunModal] = useState<boolean>(false);
  const [isShowCustomColumns, setIsShowCustomColumns] = useState<boolean>(false);
  const [selectedMetric, setSelectedMetric] = useState<PromptMetricExtended>();
  const [models, setModels] = useState<Record<string, Model>>();
  const [prompts, setPrompts] = useState<Record<string, Prompt>>();
  const [pageData, setPageData] = useState<PromptMetricPage>();
  const [history, setHistory] = useState<PromptMetricExtended[]>([]);
  const [perPage, setPerPage] = useState<number>(25);
  const [sortBy, setSortBy] = useState<MetricsSortPair>({ sortBy: MetricsSort.CREATED, sortDesc: true });
  const [filters, setFilters] = useState<MetricsFilters>({});
  const [columnsShown, setColumnsShown] = useState<Record<string, boolean>>({
    time: true,
    promptName: true,
    version: true,
    model: true,
    cost: true,
    latency: false,
    inference: false,
    runTime: true,
    ttfb: false,
    userScore: true,
    userFeedback: false,
    specialistScore: true,
    specialistFeedback: false,
    prompt: false,
    completion: true
  });

  useEffect(() => {
    let pollInterval: any;

    (async () => {
      try {
        const [_prompts, _models, _data] = await Promise.all([
          getPrompts(),
          getModels(),
          getMetrics(filters, sortBy, 1, perPage)
        ]);
        setPrompts(_prompts.reduce((acc, prompt) => ({ ...acc, [prompt.id!]: prompt }), {}));
        setModels(_models.reduce((acc, model) => ({ ...acc, [model.mid]: model }), {}));
        setPageData(_data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        return toast.error(getErrorMessage(error));
      }
    })();

    return () => clearInterval(pollInterval);
  }, []);

  useEffect(() => {
    if (isLoading) return;

    (async () => {
      await getData();
    })();
  }, [perPage, sortBy, filters]);

  useEffect(() => {
    if (!pageData) return;

    setHistory(
      pageData.items.map((metric) => ({
        ...metric,
        modelObject: models![metric.model],
        promptObject: prompts![metric.promptId!]
      }))
    );
  }, [pageData]);

  const getData = async (page: number = 1) => {
    setIsLoading(true);

    let response: PromptMetricPage;

    try {
      response = await getMetrics(filters, sortBy, page, perPage);

      setPageData(response);
    } catch (error) {
      console.error(error);
      return toast.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const getDataCsv = async () => {
    setIsLoadingCsv(true);

    try {
      await getMetricsCsv(filters, sortBy);
    } catch (error) {
      console.error(error);
      return toast.error(getErrorMessage(error));
    } finally {
      setIsLoadingCsv(false);
    }
  };

  const handleFiltersChange = (filters: MetricsFilters) => {
    setFilters(filters);
    setIsShowFilters(false);
  };

  const handlePageChange = async (page: number) => await getData(page);

  const handleSort = (row: TableColumn<any>, sortDir: string): void => {
    const sortBy = metricsSortMap[row.id as Columns];
    const sortDesc = sortDir.toLowerCase() === 'desc';
    if (!sortBy) return;

    setSortBy({ sortBy, sortDesc });
  };

  const handleDownload = async () => {
    try {
      await getDataCsv();
    } catch (error) {
      console.error(error);
      return toast.error('An error occurred while downloading the run data.');
    }
  };

  const generateColumns = (): TableColumn<PromptMetricExtended>[] => [
    {
      id: Columns.TIME,
      name: 'Time',
      selector: (metric: PromptMetricExtended) => format(metric.requestAt, DEFAULT_DF_FORMAT_SHORT),
      sortable: true,
      reorder: true,
      omit: !columnsShown.time,
      width: '130px'
    },
    {
      id: Columns.PROMPT_NAME,
      name: 'Prompt',
      selector: (metric: PromptMetricExtended) => (prompts && prompts[metric.promptId]?.name) || '—',
      sortable: false,
      reorder: true,
      omit: !columnsShown.promptName,
      width: '150px'
    },
    {
      id: Columns.VERSION,
      name: 'Ver',
      selector: (metric: PromptMetricExtended) => metric.promptVersion,
      sortable: false,
      reorder: true,
      omit: !columnsShown.version,
      width: '75px'
    },
    {
      id: Columns.MODEL,
      name: 'Model',
      selector: (metric: PromptMetricExtended) => metric.modelObject?.name,
      sortable: true,
      reorder: true,
      omit: !columnsShown.model,
      width: '150px'
    },
    {
      id: Columns.COST,
      name: 'Cost',
      selector: (metric: PromptMetricExtended) => formatCurrency(metric.totalCost, 4),
      sortable: false,
      reorder: true,
      omit: !columnsShown.cost,
      width: '100px'
    },
    {
      id: Columns.LATENCY,
      name: 'Latency',
      selector: (metric: PromptMetricExtended) => `${metric.latency.toFixed(1)} sec`,
      sortable: true,
      reorder: true,
      omit: !columnsShown.latency,
      width: '100px'
    },
    {
      id: Columns.INFERENCE,
      name: 'Inference',
      selector: (metric: PromptMetricExtended) => `${(metric.inferenceTime / 1000).toFixed(1)} sec`,
      sortable: true,
      reorder: true,
      omit: !columnsShown.inference,
      width: '100px'
    },
    {
      id: Columns.RUN_TIME,
      name: 'Time',
      selector: (metric: PromptMetricExtended) => `${(metric.calculatedTotalTime / 1000).toFixed(1)} sec`,
      sortable: true,
      reorder: true,
      omit: !columnsShown.runTime,
      width: '100px'
    },
    {
      id: Columns.TTFB,
      name: 'TTFB',
      selector: (metric: PromptMetricExtended) => (metric.streaming ? `${metric.ttfb.toFixed(0)} ms` : '—'),
      sortable: true,
      reorder: true,
      omit: !columnsShown.ttfb,
      width: '100px'
    },
    {
      id: Columns.USER_SCORE,
      name: 'U Score',
      selector: (metric: PromptMetricExtended) => `${metric.score || '—'}`,
      sortable: true,
      reorder: true,
      omit: !columnsShown.userScore,
      width: '100px'
    },
    {
      id: Columns.USER_FEEDBACK,
      name: 'Feedback',
      selector: (metric: PromptMetricExtended) => `${metric.feedback || '—'}`,
      sortable: true,
      reorder: true,
      omit: !columnsShown.userFeedback,
      width: '400px'
    },
    {
      id: Columns.SPECIALIST_SCORE,
      name: 'S Score',
      selector: (metric: PromptMetricExtended) => `${metric.analystScore || '—'}`,
      sortable: true,
      reorder: true,
      omit: !columnsShown.specialistScore,
      width: '100px'
    },
    {
      id: Columns.SPECIALIST_FEEDBACK,
      name: 'S Feedback',
      selector: (metric: PromptMetricExtended) => `${metric.analystFeedback || '—'}`,
      sortable: true,
      reorder: true,
      omit: !columnsShown.specialistFeedback,
      width: '400px'
    },
    {
      id: Columns.PROMPT,
      name: 'Prompt',
      selector: (metric: PromptMetricExtended) => metric.prompt,
      sortable: false,
      reorder: true,
      omit: !columnsShown.prompt,
      width: '400px'
    },
    {
      id: Columns.COMPLETION,
      name: 'Completion',
      selector: (metric: PromptMetricExtended) => metric.completion,
      sortable: false,
      reorder: true,
      omit: !columnsShown.completion,
      width: '400px'
      // grow: 4
    }
  ];

  const showMetricDetails = (metric: PromptMetricExtended, e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    setSelectedMetric(metric);
    setIsShowDetails(true);
  };

  const onRatingChange = async (metric: PromptMetricExtended, rating: number) => {
    if (!selectedMetric) return;

    await updateAnalystData({ ...selectedMetric, analystScore: rating });
  };

  const onFeedbackChange = async (metric: PromptMetricExtended, feedback: string) => {
    if (!selectedMetric) return;

    await updateAnalystData({ ...selectedMetric, analystFeedback: feedback });
  };

  const updateAnalystData = async (updatedMetric: PromptMetricExtended) => {
    setSelectedMetric(updatedMetric);
    setHistory(history.map((m) => (m.id === updatedMetric.id ? updatedMetric : m)));

    try {
      await rateAnalyst(updatedMetric);
    } catch (error) {
      console.error(error);
      return toast.error(getErrorMessage(error));
    }
  };

  const onMetricPage = (fwd: boolean) => {
    const idx = history.findIndex((m) => m.id === selectedMetric?.id);
    if (idx === -1) return;

    const nextMetric = fwd ? history[idx - 1] : history[idx + 1];
    if (!nextMetric) return;

    setSelectedMetric(nextMetric);
  };

  return (
    <>
      <div className="mx-auto" onClick={() => setIsShowDetails(false)}>
        <div className="flex">
          <div className="flex-1">
            <button onClick={() => setIsShowFilters(true)} className="standard" disabled={isLoading || isLoadingCsv}>
              <FontAwesomeIcon icon={faFilter} /> Filters ({pageData?.total.toLocaleString() || 0})
            </button>
            <button
              onClick={() => setIsShowCustomColumns(true)}
              className="standard ml-2"
              disabled={isLoading || isLoadingCsv}>
              <FontAwesomeIcon icon={faTableColumns} /> Columns
            </button>
          </div>
          <div>
            <button
              onClick={() => setIsShowNewRunModal(true)}
              className="standard mr-2"
              disabled={isLoading || history.length === 0}>
              <FontAwesomeIcon icon={faPlus} /> Pipeline Dataset
            </button>
            <button
              onClick={handleDownload}
              className="standard"
              disabled={isLoading || isLoadingCsv || history.length === 0}>
              <FontAwesomeIcon icon={isLoadingCsv ? faSpinner : faFileCsv} spin={isLoadingCsv} /> Export
            </button>
            <a href="/docs/#/history" target="_blank" rel="noreferrer">
              <button className="help">
                <FontAwesomeIcon icon={faCircleQuestion} className="inline-block" />
              </button>
            </a>
          </div>
        </div>
        <div className="mt-6 max-h-lvh block overflow-y-auto rounded-lg border-gray-300 border">
          <DataTable
            // fixedHeader // FIXME: if set then elements are relative and clip the dropdowns
            pagination
            paginationServer
            sortServer
            responsive
            striped
            dense
            pointerOnHover
            highlightOnHover
            persistTableHead
            fixedHeaderScrollHeight="calc(100vh - 350px)"
            progressPending={isLoading}
            progressComponent={
              <FontAwesomeIcon icon={faSpinner} className="animate-spin w-10 h-10 p-8 text-indigo-600" />
            }
            noDataComponent={
              <div className="text-center p-8">
                <FontAwesomeIcon icon={faFaceSadCry} className="w-14 h-14 mb-4 text-indigo-600" />
                <div>No history available. Try adjusting your filters.</div>
              </div>
            }
            // paginationComponentOptions={{ selectAllRowsItem: true, rowsPerPageText: 'Rows per page' }}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationTotalRows={pageData?.total || 0}
            columns={generateColumns()}
            data={history}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => setPerPage(currentRowsPerPage)}
            onSort={handleSort}
            onRowClicked={showMetricDetails}
            className="datatable"
            conditionalRowStyles={[
              {
                when: (row) => row.id === selectedMetric?.id,
                style: {
                  backgroundColor: '#E0E7FF'
                }
              }
            ]}
          />
        </div>
      </div>

      <CallHistoryDetailsPanel
        metric={selectedMetric}
        isOpen={isShowDetails}
        onClose={() => setIsShowDetails(false)}
        onPage={onMetricPage}
        onAnalystScoreChange={onRatingChange}
        onAnalystFeedbackChange={onFeedbackChange}
      />

      <CallHistoryFilterModal
        days={SearchDefaults.days}
        models={Object.values(models || [])}
        isOpen={isShowFilters}
        onApply={handleFiltersChange}
        onClose={() => setIsShowFilters(false)}
      />

      <AddPipelineRunFromHistory
        filters={filters}
        count={pageData?.total || 0}
        isOpen={isShowNewRunModal}
        onClose={() => setIsShowNewRunModal(false)}
        onSave={() => {
          setIsShowNewRunModal(false);
        }}
      />

      <CustomColumnModal
        isOpen={isShowCustomColumns}
        columns={columnsShown}
        onClose={() => setIsShowCustomColumns(false)}
        onSave={(c: Record<string, boolean>) => {
          setColumnsShown(c);
          setIsShowCustomColumns(false);
        }}
      />
    </>
  );
};

export default CallHistory;
