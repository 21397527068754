import { faRocket, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { PromptComposerMessages } from '.';
import { generatePromptPayloadFromVersion } from '../../common/prompts';
import { deepMergeExcludeMissing, formatCurrency, getErrorMessage, toTitleCase } from '../../common/utils';
import { runModelMessages } from '../../services/Models';
import { PromptVersion } from '../../types';
import { PromptMetricSource } from '../../types/Metrics';
import { FullMessageContent, ModelRunResultMessages } from '../../types/Models';
import { PlaygroundMessage } from '../../types/Prompt';
import { StyledDialog } from '../common';

interface Props {
  open: boolean;
  version: PromptVersion | undefined;
  onClose: (payload: any) => void;
}

/**
 * PlaygroundMessages component renders a dialog for running and evaluating prompt messages.
 *
 * @component
 * @param {Props} props - The properties object.
 * @param {boolean} props.open - Indicates if the dialog is open.
 * @param {PromptVersion} props.version - The version of the prompt.
 * @param {function} props.onClose - Callback function to handle closing the dialog.
 *
 * @returns {JSX.Element} The rendered PlaygroundMessages component.
 *
 * @example
 * <PlaygroundMessages open={true} version={version} onClose={handleClose} />
 */
const PlaygroundMessages: React.FC<Props> = ({ open, version, onClose }: Props) => {
  const [payload, setPayload] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [fullMessageContent, setFullMessageContent] = useState<FullMessageContent>();
  const [fullMessageContentEvaluated, setFullMessageContentEvaluated] = useState<FullMessageContent>();
  const [_version, setVersion] = useState<PromptVersion>();
  const [results, setResults] = useState<ModelRunResultMessages>();

  useEffect(() => {
    if (!version || !version.messageTemplate) return;

    if (!version.samplePayload) {
      const emptyPayload = generatePromptPayloadFromVersion(version);
      const newPayload = deepMergeExcludeMissing(emptyPayload, payload);

      setPayload(newPayload);
    } else {
      setPayload(version.samplePayload);
    }

    setVersion(version);
    reset(version);
  }, [version]);

  const reset = (version: PromptVersion) => {
    if (!version || !version.messageTemplate) return;

    setFullMessageContent({
      systemPrompt: version.messageTemplate.systemPrompt,
      messages: version.messageTemplate.messages,
      tools: version.tools
    });
  };

  const handleClose = () => {
    if (disabled) return;
    onClose(payload);
  };

  const handleRun = async () => {
    if (!version || !fullMessageContentEvaluated || !fullMessageContent) return;

    setDisabled(true);

    try {
      const resp = await runModelMessages(
        version.model!,
        fullMessageContentEvaluated.systemPrompt,
        fullMessageContentEvaluated.messages,
        version.parameters,
        fullMessageContentEvaluated.tools,
        version.promptId,
        version.version,
        PromptMetricSource.PLAYGROUND
      );

      if (resp.failed) {
        return toast.error(`Failed to run model: ${resp.failureReason}`);
      }

      setResults(resp);

      setFullMessageContent({
        ...fullMessageContent,
        messages: fullMessageContent.messages.concat(resp.response.content as PlaygroundMessage[])
      });
    } catch (e) {
      console.error(e);
      return toast.error(`Failed to run model: ${getErrorMessage(e)}`);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <StyledDialog
      title="Playground"
      icon={faRocket}
      isOpen={open}
      closeText="Close"
      confirmText={disabled ? <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> : 'Run'}
      tertiaryText="Reset"
      tertiaryClassName="!bg-red-500 !hover:bg-red-600"
      disabled={disabled}
      width="w-[calc(100%-30px)]"
      onClose={handleClose}
      onConfirm={handleRun}
      onTertiary={() => reset(_version!)}>
      <div className="mt-4 h-full playground-messages">
        {version && version.messageTemplate && (
          <PromptComposerMessages
            fullMessageContent={fullMessageContent}
            payload={payload}
            hbsHelpers={version.helpers || {}}
            disabled={disabled}
            onPayloadChange={(p) => setPayload(p)}
            onMessagesUpdate={(messages, messagesEvald) => {
              setFullMessageContent(messages);
              setFullMessageContentEvaluated(messagesEvald);
            }}
          />
        )}

        <div className="mt-10 border-t border-gray-200 pt-4">
          {/* <div className="text-baseline font-semibold leading-6 text-gray-800 mt-4">Details</div> */}
          <div className="grid grid-flow-col gap-2">
            <div className="flex flex-col">
              <div className="text-xs text-gray-600">Model</div>
              <div className="text-sm">{version?.model}</div>
            </div>
            {version?.parameters.map((p, i) => (
              <div key={i} className="flex flex-col">
                <div className="text-xs text-gray-600">{toTitleCase(p.name.split('_').join(' '))}</div>
                <div className="text-sm">{p.value}</div>
              </div>
            ))}
            {results && <div className="border-l border-gray-200 pl-2 max-w-0" />}
            {results && (
              <div className="flex flex-col">
                <div className="text-xs text-gray-600">Latency</div>
                <div className="text-sm">{results?.latency.toFixed(1) + 's'}</div>
              </div>
            )}
            {results && (
              <div className="flex flex-col">
                <div className="text-xs text-gray-600">Tokens In</div>
                <div className="text-sm">{results?.tokens.requestTokens}</div>
              </div>
            )}
            {results && (
              <div className="flex flex-col">
                <div className="text-xs text-gray-600">Tokens Out</div>
                <div className="text-sm">{results?.tokens.responseTokens}</div>
              </div>
            )}
            {results && (
              <div className="flex flex-col">
                <div className="text-xs text-gray-600">Cost</div>
                <div className="text-sm">
                  {results ? formatCurrency(results?.tokens.requestCost + results?.tokens.responseCost, 5) : ''}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledDialog>
  );
};

export default PlaygroundMessages;
