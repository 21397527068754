import { axiosInstance } from '../api';
import { apiToAdminStats, apiToHealth, apiToUserType } from '../common/typeUtils';
import { apiExceptionHandler } from '../common/utils';
import { UserBasicInfo } from '../types';
import { AdminStats } from '../types/Admin';
import { UserRole } from '../types/User';

/**
 * Retrieves a list of users.
 * @returns A promise that resolves to an array of UserBasicInfo objects.
 * @throws Throws an error if an error occurs while loading users.
 */
export const getUsers = async (): Promise<UserBasicInfo[]> => {
  try {
    const response = await axiosInstance.get('admin/users');
    return response.data.map(apiToUserType);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while loading users.');
  }
};

/**
 * Retrieves user information by their ID.
 * @param userId - The ID of the user to retrieve.
 * @returns A Promise that resolves to the basic information of the user.
 * @throws Throws an error if there was an issue retrieving the user information.
 */
export const getUserById = async (userId: string): Promise<UserBasicInfo> => {
  try {
    const response = await axiosInstance.get(`admin/users/${userId}`);
    return apiToUserType(response.data, true);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while loading user.');
  }
};

/**
 * Changes the role of a user.
 * @param userId - The ID of the user.
 * @param role - The new role
 * @returns A promise that resolves when the role is successfully changed.
 * @throws Throws an error if an error occurs while changing the role.
 */
export const changeRole = async (userId: string, role: UserRole): Promise<void> => {
  try {
    await axiosInstance.put(`admin/users/${userId}/role/${role.toUpperCase()}`);
  } catch (error) {
    throw apiExceptionHandler(error, "An error occurred while changing user's role.");
  }
};

/**
 * Changes the active status of a user.
 * @param userId - The ID of the user.
 * @param isActive - The new active status.
 * @returns A promise that resolves when the active status is successfully changed.
 * @throws Throws an error if an error occurs while changing the active status.
 */
export const changeActiveStatus = async (userId: string, isActive: boolean): Promise<void> => {
  try {
    await axiosInstance.put(`admin/users/${userId}/active?is_active=${isActive}`);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while changing active status.');
  }
};

/**
 * Retrieves site statistics from the server.
 * @returns A Promise that resolves to an instance of AdminStats.
 * @throws Throws an error if an error occurs while loading users.
 */
export const getSiteStats = async (): Promise<AdminStats> => {
  try {
    const response = await axiosInstance.get('admin/stats');
    return apiToAdminStats(response.data);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while loading users.');
  }
};

/**
 * Retrieves the health details from the server.
 * @returns {Promise<Health>} A promise that resolves to the health details.
 * @throws {ApiException} If an error occurs while loading health.
 */
export const getHealth = async () => {
  try {
    const response = await axiosInstance.get('health/details');
    return apiToHealth(response.data);
  } catch (error) {
    throw apiExceptionHandler(error, 'An error occurred while loading health.');
  }
};
