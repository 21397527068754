export enum MetricsSort {
  CREATED = 'CREATED',
  VERSION = 'VERSION',
  MODEL = 'MODEL',
  LATENCY = 'LATENCY',
  INFERENCE_TIME = 'INFERENCE_TIME',
  TTFB = 'TTFB',
  SCORE = 'SCORE',
  ANALYST_SCORE = 'ANALYST_SCORE'
}

export interface MetricsSortPair {
  sortBy: MetricsSort;
  sortDesc: boolean;
}

export interface MetricsFilters {
  startDate?: Date;
  endDate?: Date;
  promptId?: string;
  promptVersion?: number;
  models?: string[];
  latencyMin?: number;
  latencyMax?: number;
  requestPriceMin?: number;
  requestPriceMax?: number;
  responsePriceMin?: number;
  responsePriceMax?: number;
  ttfbMin?: number;
  ttfbMax?: number;
  scoreMin?: number;
  scoreMax?: number;
  analystScoreMin?: number;
  analystScoreMax?: number;
  streaming?: boolean;
  search?: string;
  archived?: boolean;
  metafilterKey?: string;
  metafilterValue?: string;
  source?: string;
  promptLike?: string;
  completionLike?: string;
  hasFeedback?: boolean;
  hasSpecialistFeedback?: boolean;
}

export interface FilterApiMappings {
  prompt_id?: string;
  prompt_version_number?: number;
  created__gte?: Date;
  created__lte?: Date;
  model__in?: string;
  latency__gte?: number;
  latency__lte?: number;
  request_price__gte?: number;
  request_price__lte?: number;
  response_price__gte?: number;
  response_price__lte?: number;
  ttfb__gte?: number;
  ttfb__lte?: number;
  score__gte?: number;
  score__lte?: number;
  analyst_score__gte?: number;
  analyst_score__lte?: number;
  streaming?: boolean;
  prompt__archived?: boolean;
  search?: string;
  meta_filter_key?: string;
  meta_filter_value?: string;
  source?: string;
  prompt__like?: string;
  completion__like?: string;
  analyst_feedback__neq?: string;
  feedback__neq?: string;
}

interface FilterInput {
  type: 'slider' | 'input' | 'toggle' | 'date' | 'select' | 'text';
  label: string;
  secondaryLabel: string;
  default: string | boolean | number | Date;
  value: string | boolean | number | Date;
  min?: number;
  max?: number;
  step?: number;
  api: string; // this is the actual column, used for the pipelines create
}

export type Filters = {
  [key in keyof MetricsFilters]: FilterInput;
};

// some of these filters are here only to support mapping in the call history to pipeline modal
export const HistoryFilters: Filters = {
  // unused
  startDate: {
    type: 'date',
    label: 'Start Date',
    secondaryLabel: '',
    default: '',
    value: '',
    api: 'request_at_min'
  },
  endDate: {
    type: 'date',
    label: 'Start Date',
    secondaryLabel: '',
    default: '',
    value: '',
    api: 'request_at_max'
  },
  models: {
    type: 'select',
    label: 'Models',
    secondaryLabel: '',
    default: '',
    value: '',
    api: 'model'
  },
  promptId: {
    type: 'select',
    label: 'Prompt',
    secondaryLabel: '',
    default: '',
    value: '',
    api: 'prompt_id'
  },
  promptVersion: {
    type: 'select',
    label: 'Version',
    secondaryLabel: '',
    default: '',
    value: '',
    api: 'prompt_version_number'
  },
  // used
  latencyMin: {
    type: 'slider',
    label: 'Min Latency (s)',
    secondaryLabel: 'From',
    default: 0,
    value: 0,
    min: 0,
    max: 30,
    step: 1,
    api: 'latency_min'
  },
  latencyMax: {
    type: 'slider',
    label: 'Max Latency (s)',
    secondaryLabel: 'To',
    default: 30,
    value: 30,
    min: 1,
    max: 30,
    step: 1,
    api: 'latency_max'
  },
  ttfbMin: {
    type: 'slider',
    label: 'Min TTFB (ms)',
    secondaryLabel: 'From',
    default: 0,
    value: 0,
    min: 0,
    max: 2000,
    step: 10,
    api: 'ttfb_min'
  },
  ttfbMax: {
    type: 'slider',
    label: 'Max TTFB (ms)',
    secondaryLabel: 'To',
    default: 2000,
    value: 2000,
    min: 0,
    max: 2000,
    step: 0.2,
    api: 'ttfb_max'
  },
  requestPriceMin: {
    type: 'slider',
    label: 'Request Cost Min',
    secondaryLabel: 'From',
    default: 0,
    value: 0.0,
    min: 0.0,
    max: 2,
    step: 0.001,
    api: 'request_price_min'
  },
  requestPriceMax: {
    type: 'slider',
    label: 'Request Cost Max',
    secondaryLabel: 'To',
    default: 2,
    value: 2,
    min: 0,
    max: 2,
    step: 0.001,
    api: 'request_price_max'
  },
  responsePriceMin: {
    type: 'slider',
    label: 'Response Cost Min',
    secondaryLabel: 'From',
    default: 0,
    value: 0.0,
    min: 0.0,
    max: 2,
    step: 0.001,
    api: 'response_price_min'
  },
  responsePriceMax: {
    type: 'slider',
    label: 'Response Cost Max',
    secondaryLabel: 'To',
    default: 2,
    value: 2,
    min: 0,
    max: 2,
    step: 0.001,
    api: 'response_price_max'
  },
  scoreMin: {
    type: 'slider',
    label: 'Min Score',
    secondaryLabel: 'From',
    default: 0,
    value: 0,
    min: 0,
    max: 5,
    step: 1,
    api: 'score_min'
  },
  scoreMax: {
    type: 'slider',
    label: 'Max Score',
    secondaryLabel: 'To',
    default: 5,
    value: 5,
    min: 0,
    max: 5,
    step: 1,
    api: 'score_max'
  },
  analystScoreMin: {
    type: 'slider',
    label: 'Min Analyst Score',
    secondaryLabel: 'From',
    default: 0,
    value: 0,
    min: 0,
    max: 5,
    step: 1,
    api: 'analyst_score_min'
  },
  analystScoreMax: {
    type: 'slider',
    label: 'Max Analyst Score',
    secondaryLabel: 'To',
    default: 5,
    value: 5,
    min: 0,
    max: 5,
    step: 1,
    api: 'analyst_score_max'
  },
  // streaming: {
  //   type: 'toggle',
  //   label: 'Streaming',
  //   secondaryLabel: '',
  //   default: false,
  //   value: false
  // },
  archived: {
    type: 'toggle',
    label: 'Include Archived',
    secondaryLabel: '',
    default: false,
    value: false,
    api: 'archived'
  },
  hasFeedback: {
    type: 'toggle',
    label: 'User Feedback',
    secondaryLabel: '',
    default: false,
    value: false,
    api: 'feedback'
  },
  hasSpecialistFeedback: {
    type: 'toggle',
    label: 'Specialist Feedback',
    secondaryLabel: '',
    default: false,
    value: false,
    api: 'feedback'
  },
  metafilterKey: {
    type: 'text',
    label: 'Metadata Prop Name',
    secondaryLabel: '',
    default: '',
    value: '',
    api: 'meta_filter_key'
  },
  metafilterValue: {
    type: 'text',
    label: 'Metadata Prop Value',
    secondaryLabel: '',
    default: '',
    value: '',
    api: 'meta_filter_value'
  },
  promptLike: {
    type: 'text',
    label: 'Prompt Filter',
    secondaryLabel: '',
    default: '',
    value: '',
    api: 'prompt'
  },
  completionLike: {
    type: 'text',
    label: 'Completion Filter',
    secondaryLabel: '',
    default: '',
    value: '',
    api: 'completion'
  },
  source: {
    type: 'select',
    label: 'Source',
    secondaryLabel: '',
    default: '',
    value: '',
    api: 'source'
  }
};
