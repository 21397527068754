import { faCopy, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { updateExperiment } from '../../services/Experimentation';
import { Experiment, Prompt } from '../../types';
import { StyledDialog } from '../common';

interface Props {
  experiment: Experiment;
  prompt: Prompt;
  onChangesSaved?: (experiment: Experiment) => void;
}

const ManageExperimentTab: React.FC<Props> = ({ experiment, prompt, onChangesSaved }: Props) => {
  const [_experiment, setExperiment] = useState<Experiment>(experiment);
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);
  const [requestedStatus, setRequestedStatus] = useState<boolean>(experiment.live);

  const setName = (name: string) => setExperiment({ ..._experiment!, name });
  const setDesc = (description: string) => setExperiment({ ..._experiment!, description });

  const onStatusChange = async () => {
    const id = toast.loading('Updating experiment status');

    const updated = await updateExperiment(experiment.id!, experiment.name, experiment.description, requestedStatus);
    setExperiment(updated);
    setIsShowConfirm(false);
    onChangesSaved && onChangesSaved(updated);
    toast.success(`Experiment ${requestedStatus ? 'enabled' : 'disabled'}`, { id });
  };

  const handleOnSave = async () => {
    const id = toast.loading('Updating experiment');
    try {
      const updated = await updateExperiment(
        _experiment.id!,
        _experiment.name,
        _experiment.description,
        _experiment.live
      );
      setExperiment(updated);
      onChangesSaved && onChangesSaved(updated);
      toast.success('Experiment updated', { id });
    } catch (error) {
      toast.error('An error occurred while saving changes', { id });
    }
  };

  useEffect(() => {
    setExperiment(experiment);
    setName(experiment.name);
    setDesc(experiment.description);
    setRequestedStatus(experiment.live);
  }, [experiment]);

  return (
    <>
      <div className="w-full">
        <div className="mt-6 grid grid-cols-2 justify-start gap-x-16 gap-y-8">
          <div className="col-span-1 w-5/6">
            <label htmlFor="appname" className="block text-sm font-medium leading-6 text-gray-900">
              Experiment Name
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="text"
                  name="expname"
                  id="expname"
                  autoComplete="expname"
                  value={_experiment?.name}
                  onChange={(e) => setName(e.currentTarget.value)}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="Experiment name"
                />
              </div>
            </div>
          </div>

          <div className="col-span-1">
            <label className="block text-sm font-medium leading-6 text-gray-900">Prompt</label>
            <div className="mt-2">
              <div className="text-gray-900 sm:text-sm sm:leading-6">{prompt.name}</div>
            </div>
          </div>
          <div className="col-span-1 w-5/6">
            <label htmlFor="desc" className="block text-sm font-medium leading-6 text-gray-900">
              Description
            </label>
            <div className="mt-2">
              <textarea
                id="desc"
                name="desc"
                rows={3}
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full"
                defaultValue={_experiment?.description}
                onChange={(e) => setDesc(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="col-span-1">
            <div>
              <label className="text-sm font-medium leading-6 text-gray-900">Experiment Key</label>{' '}
              <CopyToClipboard
                text={experiment.key}
                onCopy={() => {
                  toast.success('Key copied');
                }}>
                <FontAwesomeIcon
                  icon={faCopy}
                  className="h-4 w-4 text-indigo-500 hover:text-indigo-400 cursor-pointer"
                />
              </CopyToClipboard>
            </div>
            <div className="mt-2 text-gray-900 sm:text-sm sm:leading-6">{experiment.key}</div>
          </div>
          <div className="col-span-21">
            <button className="standard" onClick={handleOnSave}>
              Save Changes
            </button>
            <button
              className="standard secondary ml-2"
              onClick={() => {
                setIsShowConfirm(true);
                setRequestedStatus(!_experiment.live);
              }}>
              {_experiment.live ? 'Pause Experiment' : 'Activate Experiment'}
            </button>
          </div>
        </div>
      </div>
      <StyledDialog
        title="Confirm"
        isOpen={isShowConfirm}
        closeText="Close"
        confirmText="Confirm"
        icon={faQuestionCircle}
        onClose={() => {
          setIsShowConfirm(false);
        }}
        onConfirm={onStatusChange}>
        <div>Are you sure you want to {_experiment.live ? 'pause' : 'activate'} this experiment?</div>
      </StyledDialog>
    </>
  );
};

export default ManageExperimentTab;
