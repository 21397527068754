import { useEffect, useState } from 'react';
import { Webhook } from '../../types/Webhooks';
import { getErrorMessage, validateUrl } from '../../common/utils';
import StyledDialog from '../common/StyledDialog';
import { faAnchorCircleCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WebhookAuthMethods, WebhookVerbs } from '../../types/Webhooks';
import Selector, { SelectorValue } from '../common/Selector';
import { addWebhook, updateWebhook } from '../../services/Webhooks';

interface Props {
  webhook?: Webhook;
  isOpen: boolean;
  onClose: () => void;
  onSave: (webhook: Webhook, isNew: boolean) => void;
}

const authSelectorValues: SelectorValue[] = [
  { value: WebhookAuthMethods.NONE, label: 'None' },
  { value: WebhookAuthMethods.BEARER, label: 'Bearer Token' },
  { value: WebhookAuthMethods.HEADER, label: 'Header' },
  { value: WebhookAuthMethods.URL, label: 'URL Parameter' }
];

const verbSelectorValues: SelectorValue[] = [
  { value: WebhookVerbs.POST, label: 'Post' },
  { value: WebhookVerbs.GET, label: 'Get' }
];

const ManageWebhookModal: React.FC<Props> = ({ webhook, isOpen, onClose, onSave }: Props) => {
  const [_webhook, setWebhook] = useState<Webhook>();
  const [selectedAuthMethod, setSelectedAuthMethod] = useState<SelectorValue>(authSelectorValues[0]);
  const [selectedVerb, setSelectedVerb] = useState<SelectorValue>(verbSelectorValues[0]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!webhook) {
      reset();
    } else {
      setWebhook(webhook);
    }

    setSelectedAuthMethod(
      webhook
        ? authSelectorValues.find((v) => v.value === webhook.authMethod) || authSelectorValues[0]
        : authSelectorValues[0]
    );
    setSelectedVerb(
      webhook
        ? verbSelectorValues.find((v) => v.value === webhook.httpVerb) || verbSelectorValues[0]
        : verbSelectorValues[0]
    );
  }, [webhook]);

  const reset = () => {
    setWebhook({
      name: '',
      description: '',
      url: '',
      httpVerb: WebhookVerbs.POST,
      authMethod: WebhookAuthMethods.HEADER,
      authToken: '',
      authParam: '',
      users: [],
      created: new Date(),
      updated: new Date()
    });
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const handleOnCreate = async () => {
    if (!_webhook) return;

    if (_webhook.name.length < 2) {
      return toast.error('Please enter a valid name.');
    } else if (!validateUrl(_webhook.url)) {
      return toast.error('Please enter a valid URL.');
    } else if (selectedAuthMethod.value === WebhookAuthMethods.BEARER && !_webhook.authToken.length) {
      return toast.error('Please enter a valid auth token.');
    } else if (
      selectedAuthMethod.value === WebhookAuthMethods.HEADER &&
      (!_webhook.authParam.length || !_webhook.authToken.length)
    ) {
      return toast.error('Please enter a valid header name and API Key.');
    } else if (
      selectedAuthMethod.value === WebhookAuthMethods.URL &&
      (!_webhook.authParam.length || !_webhook.authToken.length)
    ) {
      return toast.error('Please enter a valid URL parameter and API Key.');
    }

    if (_webhook.url.startsWith('http://')) {
      toast.error('Please consider using HTTPS for webhook URLs.');
    }

    setIsSaving(true);

    try {
      if (_webhook.id) {
        await updateWebhook(
          _webhook.id,
          _webhook.name,
          _webhook.description,
          _webhook.url,
          selectedVerb.value as WebhookVerbs,
          selectedAuthMethod.value as WebhookAuthMethods,
          _webhook.authToken,
          _webhook.authParam
        );

        toast.success('Webhook Updated');
        onSave(_webhook, false);
      } else {
        const webhook = await addWebhook(
          _webhook.name,
          _webhook.description,
          _webhook.url,
          selectedVerb.value as WebhookVerbs,
          selectedAuthMethod.value as WebhookAuthMethods,
          _webhook.authToken,
          _webhook.authParam
        );

        toast.success('Webhook Added');
        onSave(webhook, true);
      }
      reset();
    } catch (error) {
      const err = getErrorMessage(error);
      console.error(error);
      toast.error(err);
      return;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title={`${_webhook?.id ? 'Update' : 'Add'} Webhook`}
      closeText="Cancel"
      confirmText={
        isSaving ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
          </div>
        ) : _webhook?.id ? (
          'Save'
        ) : (
          'Add'
        )
      }
      disabled={isSaving}
      onConfirm={handleOnCreate}
      onClose={handleOnClose}
      icon={faAnchorCircleCheck}>
      <div>
        <div className="mt-3">
          <input
            type="text"
            value={_webhook?.name}
            placeholder="Webhook Name"
            onChange={(e) => setWebhook({ ..._webhook!, name: e.currentTarget.value })}
            required
            disabled={isSaving}
            className="block w-52 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-3">
          <input
            type="text"
            value={_webhook?.description}
            placeholder="Webhook Description"
            onChange={(e) => setWebhook({ ..._webhook!, description: e.currentTarget.value })}
            required
            disabled={isSaving}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-3">
          <input
            type="url"
            value={_webhook?.url}
            placeholder="URL"
            onChange={(e) => setWebhook({ ..._webhook!, url: e.currentTarget.value })}
            required
            disabled={isSaving}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-3">
          <label htmlFor="httpVerb" className="text-sm text-gray-500 block mt-3">
            Method Type
          </label>
          <Selector
            classNames="w-40 !hover:z-0"
            id="httpVerb"
            values={verbSelectorValues}
            defaultValue={selectedVerb}
            isSearchable={false}
            onChange={(selectorValue) => setSelectedVerb(selectorValue)}
          />
        </div>
        <div className="mt-3">
          <label htmlFor="authMethod" className="text-sm text-gray-500 block mt-3">
            Authentiation
          </label>
          <Selector
            classNames="w-40 !hover:z-0"
            id="authMethod"
            values={authSelectorValues}
            defaultValue={selectedAuthMethod}
            isSearchable={false}
            onChange={(selectorValue) => setSelectedAuthMethod(selectorValue)}
          />
        </div>
        {selectedAuthMethod.value !== WebhookAuthMethods.NONE && (
          <div>
            {(selectedAuthMethod.value === WebhookAuthMethods.URL ||
              selectedAuthMethod.value === WebhookAuthMethods.HEADER) && (
              <div className="mt-3">
                <input
                  type="text"
                  value={_webhook?.authParam}
                  placeholder={selectedAuthMethod.value === WebhookAuthMethods.URL ? 'Parameter' : 'Header'}
                  onChange={(e) => setWebhook({ ..._webhook!, authParam: e.currentTarget.value })}
                  required
                  disabled={isSaving}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            )}
            <div className="mt-3">
              <input
                type="text"
                value={_webhook?.authToken}
                placeholder={selectedAuthMethod.value === WebhookAuthMethods.BEARER ? 'Token' : 'API Key'}
                onChange={(e) => setWebhook({ ..._webhook!, authToken: e.currentTarget.value })}
                required
                disabled={isSaving}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        )}
      </div>
    </StyledDialog>
  );
};

export default ManageWebhookModal;
