import { useState } from 'react';
import { PromptVersion } from '../../types';
import { StyledDialog } from '../common';
import React from 'react';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { PromptDiffModal } from '.';

interface Props {
  version?: PromptVersion;
  previousVersion?: PromptVersion;
  hasPipelines: boolean;
  isOpen: boolean;
  isDisabled: boolean;
  onSave: (changeLog: string, runPipeline: boolean) => void;
  onClose: () => void;
}

const PromptVersionSaveModal: React.FC<Props> = ({
  version,
  previousVersion,
  hasPipelines,
  isOpen,
  isDisabled,
  onSave,
  onClose
}: Props) => {
  const [runPipelineOnSave, setRunPipelineOnSave] = useState<boolean>(false);
  const [changeLog, setChangeLog] = useState<string>('');

  const handleOnClose = () => {
    onClose();
    setChangeLog('');
  };

  const handleOnSave = () => {
    onSave(changeLog, runPipelineOnSave);
    setChangeLog('');
  };

  return (
    <StyledDialog
      title="Add Version"
      isOpen={isOpen}
      onClose={handleOnClose}
      onConfirm={handleOnSave}
      icon={faCloudArrowUp}
      width="w-3/4">
      <div className="flex mb-6">
        <div className="flex-1">
          <input
            value={changeLog}
            placeholder="Changelog"
            onChange={(e) => setChangeLog(e.target.value)}
            onBlur={(e) => setChangeLog(e.target.value)}
            disabled={isDisabled}
            className="disabled:text-gray-400 block xl:w-1/3 lg:w-1/2 w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        {hasPipelines && (
          <div className="flex items-center justify-end">
            <label htmlFor="runPipelineOnSave" className="text-sm text-gray-500 font-semibold mr-2">
              Run Pipeline
            </label>
            <input
              type="checkbox"
              checked={runPipelineOnSave}
              className="peer sr-only opacity-0"
              id="runPipelineOnSave"
              onChange={(e) => setRunPipelineOnSave(e.target.checked)}
            />
            <label htmlFor="runPipelineOnSave" className="selector" />
          </div>
        )}
      </div>
      <PromptDiffModal
        current={version}
        previous={previousVersion}
        isOpen={true}
        noModal={true}
        className="mt-4"
        onClose={() => {}}
      />
    </StyledDialog>
  );
};

export default PromptVersionSaveModal;
