import { useEffect, useState } from 'react';
import { formatCurrency, getErrorMessage } from '../../common/utils';
import toast from 'react-hot-toast';
import {
  getPromptsOverviewMetrics,
  getPromptOverviewMetrics,
  getVersionOverviewMetrics
} from '../../services/Analytics';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from '../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { PromptMetricOverview } from '../../types';

interface MetricStruct {
  text: string;
  tip?: string;
}

/**
 * Props for the MetricOverviewGroup component.
 */
interface Props {
  promptId?: string;
  version?: number;
  startDate?: Date;
  endDate?: Date;
  expectedCount?: number;
  includeArchived?: boolean;
  adminShowAll?: boolean;
  onData?: (data: PromptMetricOverview) => void;
}

/**
 * Renders a group of metric overviews.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {string} props.promptId - The ID of the prompt.
 * @param {string} props.version - The version.
 * @param {date} props.startDate - The start date.
 * @param {date} props.endDate - The end date.
 * @param {number} [props.expectedCount=5] - The expected count of metrics to render.
 * @param {boolean} [props.includeArchived=false] - Whether to include archived prompts. This is only used when promptId or version is not provided.
 * @param {boolean} [props.adminShowAll=false] - Whether to show all prompts for admin.
 * @param {(data: PromptMetricOverview) => void} [props.onData] - Callback function to call when data is loaded.
 * @returns {JSX.Element} The rendered component.
 */
const MetricOverviewGroup: React.FC<Props> = ({
  promptId,
  version,
  startDate,
  endDate,
  expectedCount = 6,
  includeArchived = true,
  adminShowAll = false,
  onData
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [overviewMetrics, setOverviewMetrics] = useState<{ [key: string]: MetricStruct }>();

  useEffect(() => {
    (async () => await loadData())();
  }, [version, promptId, startDate, endDate, includeArchived, adminShowAll]);

  const loadData = async () => {
    setIsLoading(true);
    try {
      let data: PromptMetricOverview;
      try {
        if (promptId && version) {
          data = await getVersionOverviewMetrics(promptId, version, startDate, endDate, adminShowAll);
        } else if (promptId) {
          data = await getPromptOverviewMetrics(promptId, startDate, endDate, adminShowAll);
        } else {
          data = await getPromptsOverviewMetrics(startDate, endDate, includeArchived, adminShowAll);
        }
      } catch (e) {
        console.error(e);
        toast.error(`Unable to load analytics overview. ${getErrorMessage(e)}`);
        return;
      }

      if (!data) {
        setIsLoading(false);
        return toast.error('No data found');
      }

      const nFormat = new Intl.NumberFormat();

      setOverviewMetrics({
        Usage: { text: nFormat.format(data.totalInvocations) },
        'Average Latency': { text: `${data.avgLatency.toFixed(2)} sec` },
        'Average TTFB': {
          text: `${data.avgTtfb.toFixed(0)} ms`,
          tip: 'Only available for streaming requests.'
        },
        'Average Request Tokens': {
          text: nFormat.format(data.avgRequestTokens)
        },
        'Average Response Tokens': { text: nFormat.format(data.avgResponseTokens) },
        'Average User Score': { text: data.avgScore.toFixed(2) },
        'Average Analyst Score': { text: data.avgAnalystScore.toFixed(2) },
        'Total Cost': { text: `${formatCurrency(data.totalCost)}` }
      });

      onData && onData(data);
    } catch (error) {
      setOverviewMetrics(undefined);
      return toast.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const renderMetric = (metric?: string, data?: MetricStruct) => {
    return (
      <li key={Math.random()} className="rounded-lg border-gray-300 border p-2">
        <div className="flex items-center">
          <h3 className="text-gray-800 text-base overflow-hidden w-full">{metric ? metric : <Skeleton />}</h3>
          {data && (
            <>
              {data.tip && (
                <Tooltip className="inline-flex" message={data.tip}>
                  <FontAwesomeIcon icon={faQuestionCircle} className="text-sm pl-1" />
                </Tooltip>
              )}
            </>
          )}
        </div>
        <h4 className="text-2xl font-semibold text-gray-600 mt-2 w-full text-center">{data?.text ?? <Skeleton />}</h4>
      </li>
    );
  };

  return (
    <ul className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 rounded-lg w-full">
      {isLoading
        ? [...Array(expectedCount)].map(() => renderMetric())
        : overviewMetrics &&
          Object.entries(overviewMetrics).map(([metric, metricStruct]) => renderMetric(metric, metricStruct))}
    </ul>
  );
};

export default MetricOverviewGroup;
