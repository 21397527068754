import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable, { TableColumn } from 'react-data-table-component';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { DEFAULT_DF_FORMAT } from '../../constants';
import { getUsers } from '../../services/Admin';
import { UserBasicInfo } from '../../types';
import { getErrorMessage, toTitleCase } from '../../common/utils';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';

interface Props {}

const Users: React.FC<Props> = ({}: Props) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserBasicInfo[]>();
  const [usersFiltered, setUsersFiltered] = useState<UserBasicInfo[]>();
  const [filterText, setFilterText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowActive, setIsShowActive] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let users;

      try {
        users = await getUsers();
      } catch (e) {
        console.error(e);
        return toast.error(getErrorMessage(e));
      } finally {
        setIsLoading(false);
      }

      setUsers(users);
      setUsersFiltered(users);
    })();
  }, []);

  useEffect(() => {
    console.log(`Filtering users by: ${filterText}`);
    if (!users) return;

    let filtered = users.filter(
      (u) =>
        u.name.toLowerCase().includes(filterText.toLowerCase()) ||
        u.email.toLowerCase().includes(filterText.toLowerCase())
    );
    if (!isShowActive) {
      filtered = filtered.filter((u) => !u.active);
    }

    setUsersFiltered(filtered);
  }, [filterText, isShowActive]);

  const generateColumns = (): TableColumn<any>[] => {
    return [
      {
        name: 'Name',
        selector: (u: UserBasicInfo) => u.name,
        sortable: true,
        width: '150px'
      },
      {
        name: 'Email',
        selector: (u: UserBasicInfo) => u.email,
        sortable: true
      },
      {
        name: 'Status',
        selector: (u: UserBasicInfo) => (u.active ? 'Active' : 'Inactive'),
        sortable: true,
        width: '100px'
      },
      {
        name: 'Role',
        selector: (u: UserBasicInfo) => toTitleCase(u.role),
        sortable: true,
        width: '100px'
      },
      {
        name: 'ID',
        selector: (u: UserBasicInfo) => u.id,
        sortable: false
        // width: '60px'
      },
      {
        name: 'Updated',
        selector: (u: UserBasicInfo) => format(u.updated, DEFAULT_DF_FORMAT),
        sortable: true
      }
    ];
  };

  const handleOnRowClicked = (user: UserBasicInfo) => {
    navigate(`/admin/users/${user.id}`);
  };

  return (
    <div className="mx-auto">
      <div>
        <div>
          <h1 className="text-2xl mb-4 text-gray-800">Users</h1>
          <div className="flex items-center mt-2">
            <input
              id="filter"
              name="filter"
              type="search"
              autoComplete="off"
              value={filterText}
              placeholder="Filter"
              onChange={(e) => setFilterText(e.currentTarget.value)}
              required
              disabled={isLoading}
              className="disabled:text-gray-400 block w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-4"
            />
            <div className="text-xs text-gray-500 mr-2 pt-0.5">Show Active</div>
            <input
              type="checkbox"
              checked={isShowActive}
              className="peer sr-only opacity-0"
              id="showActive"
              disabled={isLoading}
              onChange={() => setIsShowActive(!isShowActive)}
            />
            <label htmlFor="showActive" className="selector mt-1" />
          </div>
          <DataTable
            striped
            pointerOnHover
            fixedHeaderScrollHeight="calc(100vh - 100px)"
            progressPending={!usersFiltered}
            progressComponent={
              <FontAwesomeIcon icon={faSpinner} className="animate-spin w-10 h-10 p-8 text-indigo-600" />
            }
            data={usersFiltered || []}
            columns={generateColumns()}
            className="datatable"
            onRowClicked={handleOnRowClicked}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
