import { faCopy, faEye, faEyeSlash, faSave } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router';
import { getErrorMessage, toTitleCase } from '../../common/utils';
import Selector, { SelectorValue } from '../../components/common/Selector';
import { DEFAULT_DF_FORMAT } from '../../constants';
import { changeActiveStatus, changeRole, getUserById } from '../../services/Admin';
import { getUserLocal } from '../../services/User';
import { UserBasicInfo } from '../../types';
import { UserRole } from '../../types/User';

interface Props {}

export const roleSelectorValues: { [key in UserRole]: SelectorValue } = {
  [UserRole.USER]: { value: 0, label: 'User' },
  [UserRole.MAINTAINER]: { value: 1, label: 'Maintainer' },
  [UserRole.ADMIN]: { value: 2, label: 'Admin' }
};

const AdminUser: React.FC<Props> = ({}: Props) => {
  const routerParams = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isKeyVisable, setIsKeyVisable] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [user, setUser] = useState<UserBasicInfo>();
  const [selectedRole, setSelectedRole] = useState<UserRole>(UserRole.USER);
  const [selectedStatus, setSelectedStatus] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!routerParams.userId) {
        toast.error('User ID not provided.');
        return;
      }

      setIsLoading(true);
      try {
        const _user = await getUserById(routerParams.userId);
        setUser(_user);
        setSelectedRole(_user.role);
        setSelectedStatus(_user.active);
      } catch (error) {
        console.error(error);
        toast.error(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleRoleSelected = (action: SelectorValue) => {
    Object.values(roleSelectorValues).forEach((value) => {
      if (value.value === action.value) {
        setSelectedRole(value.label.toUpperCase() as UserRole);
      }
    });
  };

  const handleUpdateRole = async () => {
    if (!user) return;

    const id = toast.loading('Updating role..');
    setIsSaving(true);

    try {
      await changeRole(user.id, selectedRole);
      setUser({ ...user, role: selectedRole });
      toast.success('Role updated successfully.', { id });
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error), { id });
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateStatus = async () => {
    if (!user) return;

    const me = getUserLocal();
    if (me?.id === user.id) {
      toast.error('Cannot deactivate your own account.');
      return;
    }

    const id = toast.loading('Updating status..');
    setIsSaving(true);

    try {
      await changeActiveStatus(user.id, selectedStatus);
      setUser({ ...user, active: selectedStatus });
      toast.success(`User ${selectedStatus ? 'activated' : 'deactivated'}.`, { id });
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error), { id });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <h3 className="mt-2 mb-4 text-lg text-gray-700 font-semibold w-60">
        {isLoading ? <Skeleton width={150} /> : user?.name}
      </h3>
      <div className="border-b border-gray-300 pb-6 mb-8 text-sm">
        <div className="grid grid-cols-2 gap-4 w-2/3">
          <div className="text-gray-500 font-semibold">ID</div>
          <div>{isLoading ? <Skeleton width={150} /> : user?.id}</div>
          <div className="text-gray-500 font-semibold">Status</div>
          <div>{isLoading ? <Skeleton width={150} /> : user?.active ? 'Active' : 'Inactive'}</div>
          <div className="text-gray-500 font-semibold">Role</div>
          <div>{isLoading || !user ? <Skeleton width={150} /> : toTitleCase(user.role)}</div>
          <div className="text-gray-500 font-semibold">Email</div>
          <div>{isLoading ? <Skeleton width={150} /> : user?.email}</div>
          <div className="text-gray-500 font-semibold">Created</div>
          <div>{isLoading || !user ? <Skeleton width={150} /> : format(user.created, DEFAULT_DF_FORMAT)}</div>
          <div className="text-gray-500 font-semibold">Updated</div>
          <div>{isLoading || !user ? <Skeleton width={150} /> : format(user.updated, DEFAULT_DF_FORMAT)}</div>
          <div className="text-gray-500 font-semibold">Prompts</div>
          <div>{isLoading ? <Skeleton width={150} /> : user?.prompts.length.toLocaleString()}</div>
          <div className="text-gray-500 font-semibold">API Key</div>
          <div>
            {isLoading ? <Skeleton width={150} /> : !isKeyVisable ? `*****${user?.apiKey.slice(-8)}` : user?.apiKey}
            {isLoading ? null : (
              <>
                <CopyToClipboard text={user?.apiKey || 'NA'} onCopy={() => toast.success('API Key Copied!')}>
                  <FontAwesomeIcon icon={faCopy} className="ml-2 w-4 h-4 cursor-pointer hover:text-indigo-500" />
                </CopyToClipboard>

                <FontAwesomeIcon
                  icon={isKeyVisable ? faEyeSlash : faEye}
                  onClick={() => setIsKeyVisable(!isKeyVisable)}
                  className="ml-2 cursor:pointer w-4 h-4 cursor-pointer hover:text-indigo-500"
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="border-b border-gray-300 pb-6 mb-8 text-sm">
        <div className="grid grid-cols-2 gap-4 w-2/3">
          <div className="mr-8">
            <h3 className="mt-2 mb-4 text-lg text-gray-700 font-semibold w-60">Role</h3>
            <Selector
              classNames="w-40 inline-block mr-4"
              values={Object.values(roleSelectorValues)}
              disabled={isLoading || isSaving}
              isSearchable={false}
              loading={isLoading}
              defaultValue={roleSelectorValues[selectedRole]}
              onChange={(action) => {
                handleRoleSelected(action);
              }}
            />

            <button className="standard" onClick={handleUpdateRole} disabled={isSaving}>
              {isSaving ? (
                <div>
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon icon={faSave} className="mr-1.5" /> Save
                </div>
              )}
            </button>
          </div>
          <div>
            <h3 className="mt-2 mb-4 text-lg text-gray-700 font-semibold w-60">Status</h3>
            <Selector
              classNames="w-40 inline-block mr-4"
              values={[
                { value: 0, label: 'Inactive' },
                { value: 1, label: 'Active' }
              ]}
              disabled={isLoading || isSaving}
              isSearchable={false}
              loading={isLoading}
              defaultValue={{ value: selectedStatus ? 1 : 0, label: selectedStatus ? 'Active' : 'Inactive' }}
              onChange={(action) => {
                setSelectedStatus(action.value === 1);
              }}
            />

            <button className="standard" onClick={handleUpdateStatus} disabled={isSaving}>
              {isSaving ? (
                <div>
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Saving
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon icon={faSave} className="mr-1.5" /> Save
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUser;
