import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router';
import { formatCurrency, getErrorMessage } from '../../common/utils';
import { getHealth, getSiteStats } from '../../services/Admin';
import { AdminStats, Health } from '../../types/Admin';

interface Props {}

const Admin: React.FC<Props> = ({}: Props) => {
  const navigate = useNavigate();
  const [siteStats, setSiteStats] = useState<AdminStats>();
  const [health, setHealth] = useState<Health>();

  const ok = <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 w-4 h-4" />;
  const bad = <FontAwesomeIcon icon={faX} className="text-red-500 w-4 h-4" />;

  useEffect(() => {
    (async () => {
      try {
        const [_stats, _health] = await Promise.all([getSiteStats(), getHealth()]);
        setSiteStats(_stats);
        setHealth(_health);
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
    })();
  }, []);

  return (
    <div className="mx-auto">
      <div>
        <div className="flex gap-4 flex-wrap">
          <div className="border-gray-300 border rounded-lg p-2">
            <h2 className="text-lg text-gray-700 font-semibold mb-1">System Stats</h2>
            <div className="grid grid-cols-2 w-72 text-gray-500">
              {!siteStats ? (
                <Skeleton count={8} />
              ) : (
                <>
                  <div>Prompts</div>
                  <div className="font-semibold text-gray-600">{siteStats.totalPrompts.toLocaleString()}</div>
                  <div>Versions</div>
                  <div className="font-semibold text-gray-600">{siteStats.totalVersions.toLocaleString()}</div>
                  <div>Calls Processed</div>
                  <div className="font-semibold text-gray-600">{siteStats.totalCalls.toLocaleString()}</div>
                  <div>Pipelines</div>
                  <div className="font-semibold text-gray-600">{siteStats.totalPipelines.toLocaleString()}</div>
                  <div>Pipeline Runs</div>
                  <div className="font-semibold text-gray-600">{siteStats.totalPipelineRuns.toLocaleString()}</div>
                  <div>Active Users</div>
                  <div className="font-semibold text-gray-600">{siteStats.activeUsers.toLocaleString()}</div>
                  <div>Pending Users</div>
                  <div className="font-semibold text-gray-600">{siteStats.pendingUsers.toLocaleString()}</div>
                  <div>Bedrock $ (30d)</div>
                  <div className="font-semibold text-gray-600">{formatCurrency(siteStats.bedrockCost)}</div>
                </>
              )}
            </div>
          </div>
          <div className="border-gray-300 border rounded-lg p-2">
            <h2 className="text-lg text-gray-700 font-semibold mb-1">API Health</h2>
            <div className="grid grid-cols-2 w-72 text-gray-500">
              {!health ? (
                <Skeleton count={6} />
              ) : (
                <>
                  <div>Healthy</div>
                  <div className="font-semibold text-gray-600">{health.api.healthy ? ok : bad}</div>
                  <div>Version</div>
                  <div className="font-semibold text-gray-600">{health.api.version}</div>
                  <div>Active Threads</div>
                  <div className="font-semibold text-gray-600">{health.api.activeThreads}</div>
                  <div>CPUs</div>
                  <div className="font-semibold text-gray-600">{health.api.cpus}</div>
                  <div>CPU Usage</div>
                  <div className="font-semibold text-gray-600">{health.api.cpuUsage}%</div>
                  <div>Memory Usage</div>
                  <div className="font-semibold text-gray-600">{health.api.memoryUsage}%</div>
                </>
              )}
            </div>
          </div>
          <div className="border-gray-300 border rounded-lg p-2">
            <h2 className="text-lg text-gray-700 font-semibold mb-1">Postgres Health</h2>
            <div className="grid grid-cols-2 w-72 text-gray-500">
              {!health ? (
                <Skeleton count={5} />
              ) : (
                <>
                  <div>Healthy</div>
                  <div className="font-semibold text-gray-600">{health.postgres.healthy ? ok : bad}</div>
                  <div>Pool Size</div>
                  <div className="font-semibold text-gray-600">{health.postgres.poolSize}</div>
                  <div>Checked In</div>
                  <div className="font-semibold text-gray-600">{health.postgres.checkedIn}</div>
                  <div>Checked Out</div>
                  <div className="font-semibold text-gray-600">{health.postgres.checkedOut}</div>
                  <div>Overflow</div>
                  <div className="font-semibold text-gray-600">{health.postgres.overflow}</div>
                </>
              )}
            </div>
          </div>
          <div className="border-gray-300 border rounded-lg p-2">
            <h2 className="text-lg text-gray-700 font-semibold mb-1">Redis Health</h2>
            <div className="grid grid-cols-2 w-72 text-gray-500">
              {!health ? (
                <Skeleton count={4} />
              ) : (
                <>
                  <div>Healthy</div>
                  <div className="font-semibold text-gray-600">{health.redis.healthy ? ok : bad}</div>
                  <div>Used Memory</div>
                  <div className="font-semibold text-gray-600">{health.redis.usedMemory.toLocaleString()}</div>
                  <div>Keyspace Hits</div>
                  <div className="font-semibold text-gray-600">{health.redis.keyspaceHits.toLocaleString()}</div>
                  <div>Keyspace Misses</div>
                  <div className="font-semibold text-gray-600">{health.redis.keyspaceMisses.toLocaleString()}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <h1 className="text-2xl text-gray-800 mb-4">Tools</h1>
        <button className="standard" onClick={() => navigate('users/')}>
          Manage Users
        </button>
      </div>
    </div>
  );
};

export default Admin;
