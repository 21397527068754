import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components';
import { getUserLocal, logout } from '../services/User';
import { history } from '../lib/history';

const ProtectedLayout = () => {
  const basicUserInfo = getUserLocal();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if ((!authState || !authState?.isAuthenticated) && !basicUserInfo?.active) {
      console.log('Redirecting to login');
      logout();
      history.replace(toRelativeUrl('/login', window.location.origin));
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated, basicUserInfo]);

  return (
    <>
      <div className="p-2 h-screen overflow-hidden relative">
        <div className="bg-gray-100 flex flex-col pl-0">
          <Header />
          <div className="bg-white p-5 border-gray-300 border rounded-b-sm mb-5 h-[calc(100vh-80px)] overflow-auto ">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProtectedLayout;
