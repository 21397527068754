import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';

interface Props {
  title: string;
  children: React.ReactElement[] | React.ReactElement | string;
  topNav?: React.ReactElement[] | React.ReactElement | string;
  visible: boolean;
  showCloseButton?: boolean;

  onClose?: () => void;
}

export const SidePanel: React.FC<Props> = ({
  title,
  topNav,
  children,
  visible,
  showCloseButton = true,
  onClose
}: Props) => {
  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false);

    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, []);

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose && onClose();
    }
  };

  return (
    <div className={`relative z-10 ${!visible ? 'hidden' : ''}`} role="dialog" onClick={onClose}>
      <div className="fixed inset-0 ">
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-6">
              <div className="pointer-events-auto relative w-screen max-w-2xl">
                {showCloseButton && (
                  <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                    <FontAwesomeIcon
                      icon={faClose}
                      className="h-6 w-6 text-indigo-700 hover:text-indigo-400 cursor-pointer"
                    />
                  </div>
                )}
                <div
                  className="flex h-full flex-col overflow-y-scroll bg-gray-200 py-3 shadow-slate-700 shadow-xl"
                  onClick={(e) => e.stopPropagation()}>
                  <div className="px-4 flex border-b border-gray-300 pb-2">
                    <h1 className="flex-1 text-xl font-semibold leading-6 text-gray-600 ">{title}</h1>
                    <div>{topNav}</div>
                  </div>
                  <div className="relative mt-2 flex-1 px-4">{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidePanel;
