import { history } from '../../lib/history';
import { useEffect, useState } from 'react';
import { Pipeline, PipelineRun, Prompt, PromptVersion } from '../../types';
import { Selector, StyledDialog } from '../common';
import { faFlask, faPersonRunning, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SelectorValue } from '../common/Selector';
import { getErrorMessage } from '../../common/utils';
import toast from 'react-hot-toast';
import { addRunFromDefaultData, startRun } from '../../services/PipelineEvaluations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { DEFAULT_DF_FORMAT_SHORT } from '../../constants';

/**
 * Props for the PromptCodeBlock component.
 */
interface Props {
  prompt?: Prompt;
  version?: PromptVersion;
  pipelines?: Pipeline[];
  isOpen: boolean;
  onClose: () => void;
}

/**
 * Renders a code block for a prompt.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Version} props.version - The version of the prompt.
 * @param {boolean} props.isOpen - Whether the code block is open or not.
 * @param {() => void} props.onClose - The callback function to handle the close event.
 * @returns {JSX.Element} The rendered code block.
 */
const PromptPipelineRunner: React.FC<Props> = ({ prompt, version, pipelines, isOpen, onClose }: Props) => {
  const [isSubmittingJob, setIsSubmittingJob] = useState<boolean>(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false);
  const [pipelineSelectors, setPipelineSelectors] = useState<SelectorValue[]>([]);
  const [selectedPipeline, setSelectedPipeline] = useState<Pipeline>();
  const [run, setRun] = useState<PipelineRun>();
  const [name, setName] = useState<string>('');
  const [pipelineIssue, setPipelineIssue] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!prompt || !pipelines) return;

    setPipelineSelectors(pipelines.map((pipeline) => ({ value: pipeline.id, label: pipeline.name })));
    setSelectedPipeline(pipelines.find((pipeline) => prompt.defaultPipeline?.id === pipeline.id) || pipelines[0]);
  }, [pipelines]);

  useEffect(() => {
    if (!version || isOpen === false) return;

    setName(`CI: v${version?.version} - ${format(new Date(), DEFAULT_DF_FORMAT_SHORT)}`);
  }, [isOpen]);

  useEffect(() => {
    setDisabled(true);

    if (!selectedPipeline) return;
    else if (!selectedPipeline.defaultDataS3Path) {
      return setPipelineIssue(
        'The selected pipeline does not have a default dataset. Please create one from the pipelines page.'
      );
    } else if (selectedPipeline.evaluatorCount === 0) {
      return setPipelineIssue(
        'The selected pipeline does not have any evaluators. Please create one from the pipeline page.'
      );
    }

    setDisabled(false);
  }, [selectedPipeline]);

  const handlePipelineChange = (value: SelectorValue) => {
    const pipeline = pipelines?.find((pipeline) => pipeline.id === value.value);
    setSelectedPipeline(pipeline);
  };

  const submitJob = async () => {
    if (!version || !selectedPipeline) return;
    if (!name.length) return toast.error('Please enter a name for the run.');

    setIsSubmittingJob(true);
    const id = 'toasty-id';
    toast.loading('Creating pipeline run', { id });

    let run: PipelineRun;
    try {
      run = await addRunFromDefaultData(selectedPipeline.id, version?.promptId, version.version, name);
    } catch (error) {
      setIsSubmittingJob(false);
      return toast.error(getErrorMessage(error), { id });
    }

    toast.loading('Starting pipeline run', { id });
    try {
      await startRun(selectedPipeline.id, run.id);
    } catch (error) {
      return toast.error(
        `There was an error starting the run. Please run manually from pipeline page. ${getErrorMessage(error)}`,
        { id }
      );
    } finally {
      setIsSubmittingJob(false);
    }

    toast.success('Pipeline run started', { id });

    setRun(run);
    setIsSuccessOpen(true);
  };

  const handleSuccessConfirm = () => {
    if (!selectedPipeline || !run) return;
    history.replace({ pathname: `/pipelines/${selectedPipeline.id}/runs/${run.id}` });
  };

  return (
    <div>
      <StyledDialog
        title="Pipeline Runner"
        isOpen={isOpen}
        closeText="Close"
        icon={faFlask}
        width="w-96"
        disabled={isSubmittingJob}
        confirmText={
          isSubmittingJob ? (
            <div>
              <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Creating Run
            </div>
          ) : (
            'Create Run'
          )
        }
        confirmDisabled={pipelineIssue.length > 0 || !selectedPipeline}
        onConfirm={() => submitJob()}
        onClose={() => onClose()}>
        <div>
          {pipelines?.length === 0 ? (
            <div className="text-sm text-gray-500 mb-4">
              No pipelines available. You can add a pipeline from the pipelines page.
            </div>
          ) : (
            <div>
              <div className="text-sm text-gray-500 mb-4">Select a pipeline to run the current version against.</div>
              <Selector
                values={pipelineSelectors}
                onChange={handlePipelineChange}
                defaultValue={{ value: selectedPipeline?.id!, label: selectedPipeline?.name! }}
                disabled={disabled || isSubmittingJob}
                isSearchable={true}
                classNames="w-80 mb-4"
              />
              {pipelineIssue.length > 0 && <div className="text-sm text-red-500 mt-4">{pipelineIssue}</div>}
              <div className="mt-2 flex">
                <input
                  id="runName"
                  name="runName"
                  type="text"
                  autoComplete="off"
                  value={name}
                  placeholder="Run Name"
                  onChange={(e) => setName(e.currentTarget.value)}
                  required
                  disabled={disabled || isSubmittingJob}
                  className="block w-80 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          )}
        </div>
      </StyledDialog>
      <StyledDialog
        title="Pipeline Run Started"
        isOpen={isSuccessOpen}
        closeText="Close"
        icon={faPersonRunning}
        width="w-80"
        confirmText="View Run"
        onConfirm={() => handleSuccessConfirm()}
        onClose={() => setIsSuccessOpen(false)}>
        <div>A new pipeline run has been started. Would you like to view the run?</div>
      </StyledDialog>
    </div>
  );
};

export default PromptPipelineRunner;
