import { useEffect, useState } from 'react';
import StyledDialog from '../common/StyledDialog';
import { faArrowRotateRight, faCircleExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PromptMetricExtended } from '../../pages/CallHistory';
import { replay } from '../../services/History';
import { getErrorMessage } from '../../common/utils';
import { ModelRunResult } from '../../types/Models';
import { ModelRunStats } from '../common';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { DiffMethod } from 'react-diff-viewer-continued';
import Skeleton from 'react-loading-skeleton';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import '../../styles/editor.css';
import '../../styles/replay.css';

interface Props {
  metric?: PromptMetricExtended;
  isOpen: boolean;
  onClose: () => void;
}

const MODEL_PARAMS = ['top_p', 'top_k', 'temperature', 'max_tokens', 'max_tokens_to_sample', 'max_gen_len'];

const ReplayModal: React.FC<Props> = ({ metric, isOpen, onClose }: Props) => {
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [result, setResult] = useState<ModelRunResult>();
  const [_metric, setMetric] = useState<PromptMetricExtended>();
  const [ranOnce, setRanOnce] = useState<boolean>(false);
  const [oldRunResult, setOldRunResult] = useState<ModelRunResult>();

  useEffect(() => {
    if (!metric) return;

    setMetric(metric);
    setRanOnce(false);

    // this is only used for metric comparison
    const { completion, ttfb, latency, tokensByModel } = metric;
    setOldRunResult({
      completion,
      failed: false,
      failureReason: '',
      modelId: '',
      modelName: '',
      ttfb,
      latency,
      tokens: tokensByModel
    });
  }, [metric]);

  const handleOnClose = () => {
    onClose();
    setRanOnce(false);
  };

  const handleOnRun = async () => {
    if (!_metric) return;

    setRanOnce(true);
    setIsRunning(true);

    try {
      setResult(await replay(_metric.id));
    } catch (error) {
      console.log(error);
      return toast.error(getErrorMessage(error));
    } finally {
      setIsRunning(false);
    }
  };

  const getFormattedPrompt = (prompt: string): string => {
    try {
      return JSON.stringify(JSON.parse(_metric?.prompt || '{}'), null, 2);
    } catch (e) {
      return prompt;
    }
  };

  return (
    <StyledDialog
      isOpen={isOpen}
      title="History Replay"
      closeText="Close"
      confirmText={
        isRunning ? (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> Running
          </div>
        ) : (
          'Run'
        )
      }
      width="w-2/3"
      disabled={isRunning}
      onConfirm={handleOnRun}
      onClose={handleOnClose}
      icon={faArrowRotateRight}>
      <div>
        <div className="!max-h-[30vh] overflow-y-scroll composer-highlighter !not-italic">
          <SyntaxHighlighter
            language="json"
            style={docco}
            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
            wrapLines>
            {getFormattedPrompt(metric?.prompt || '')}
          </SyntaxHighlighter>
        </div>

        {ranOnce ? (
          !isRunning ? (
            !result?.failed ? (
              <div className="mt-4 diffViewer">
                <div className="text-baseline font-semibold leading-6 text-gray-800 mt-4 pt-4 border-t border-gray-300">
                  Response
                </div>
                <ReactDiffViewer
                  oldValue={metric?.completion}
                  newValue={result?.completion}
                  splitView={true}
                  hideLineNumbers
                  compareMethod={DiffMethod.WORDS_WITH_SPACE}
                  leftTitle={'Original'}
                  rightTitle={'Replay'}
                />
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={faCircleExclamation} className="text-red-600 w-4 h-4 mr-2" /> Failed:{' '}
                {result.failureReason}
              </div>
            )
          ) : (
            <Skeleton count={3} className="mt-4" />
          )
        ) : null}

        {ranOnce
          ? !isRunning && (
              <div className="mt-4">
                <div>
                  <div className="text-baseline font-semibold leading-6 text-gray-800 mt-4 pt-4  ">Replay Metrics</div>
                  <ModelRunStats run={result} />
                </div>
                <div className="mt-2">
                  <div className="text-baseline font-semibold leading-6 text-gray-800 mt-4 pt-4  ">
                    Original Metrics
                  </div>
                  <ModelRunStats run={oldRunResult} />
                </div>
                <div className="text-baseline font-semibold leading-6 text-gray-800 mt-4">Configuration</div>
                <div className="grid grid-flow-col gap-2 mt-2">
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-600">Model</div>
                    <div className="text-sm">{metric?.model}</div>
                  </div>
                  {Object.entries(metric?.payload)
                    .filter(([p, v], i) => MODEL_PARAMS.includes(p))
                    .map(([p, v], i) => {
                      return (
                        <div key={i} className="flex flex-col">
                          <div className="text-xs text-gray-600">{p}</div>
                          <div className="text-sm">{v as string}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )
          : null}
      </div>
    </StyledDialog>
  );
};

export default ReplayModal;
