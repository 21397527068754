import { formatCurrency } from '../../common/utils';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CompletionMetric } from '../../types/Performance';
import { useEffect, useState } from 'react';
import { RatedEvaluation, Sentiment } from '../../types/Evaluations';
import Skeleton from 'react-loading-skeleton';
import { CHART_COLORS } from '../../constants';

/**
 * Props for the PerformanceGraph component.
 */
interface Props {
  metrics: CompletionMetric[];
  sentiments: Sentiment[];
  coherence: RatedEvaluation[];
  fluency: RatedEvaluation[];
}

const labelSizeSmall = { fontSize: '0.85em' };
const barRadius: [number, number, number, number] = [5, 5, 0, 0];
const chartContainerHeight = 300;
const chartSkeletons = 11;

interface SentimentGraphData {
  model: string;
  NEUTRAL: number;
  POSITIVE: number;
  NEGATIVE: number;
  UNKNOWN: number;
}
/**
 * Renders a performance graph component.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {Array<Metric>} props.metrics - The metrics data for the graph.
 * @param {Array<Sentiment>} props.sentiments - The sentiment data for the graph.
 * @param {Array<RatedEvaluation>} props.coherence - The coherence data for the graph.
 * @param {Array<RatedEvaluation>} props.fluency - The fluency data for the graph.
 * @returns {JSX.Element} The rendered component.
 */
const PerformanceGraph: React.FC<Props> = ({ metrics, sentiments, coherence, fluency }: Props) => {
  const [sentimentData, setSentimentData] = useState<SentimentGraphData[]>([]);
  const [coherenceData, setCoherenceData] = useState<Record<string, number | string>[]>([]);
  const [fluencyData, setFluencyData] = useState<Record<string, number | string>[]>([]);

  useEffect(() => {
    const result = sentiments.map((item) => {
      const sentimentCounts: { [key: string]: number } = {
        NEUTRAL: 0,
        POSITIVE: 0,
        NEGATIVE: 0,
        UNKNOWN: 0
      };

      item.results.forEach((sentiment) => {
        sentimentCounts[sentiment]++;
      });

      const totalCount = item.results.length;
      return {
        model: item.model,
        NEUTRAL: (sentimentCounts.NEUTRAL / totalCount) * 100,
        POSITIVE: (sentimentCounts.POSITIVE / totalCount) * 100,
        NEGATIVE: (sentimentCounts.NEGATIVE / totalCount) * 100,
        UNKNOWN: (sentimentCounts.UNKNOWN / totalCount) * 100
      };
    });

    setSentimentData(result);
  }, [sentiments]);

  useEffect(() => {
    const result = coherence.map((item) => {
      const ratings = item.ratings;
      const avgRating = ratings.reduce((acc, val) => acc + val, 0) / ratings.length;
      return {
        model: item.model,
        avgRating
      };
    });

    setCoherenceData(result);
  }, [coherence]);

  useEffect(() => {
    const result = fluency.map((item) => {
      const ratings = item.ratings;
      const avgRating = ratings.reduce((acc, val) => acc + val, 0) / ratings.length;
      return {
        model: item.model,
        avgRating
      };
    });

    setFluencyData(result);
  }, [fluency]);

  const xTickFormatter = (data: any[], value: string): string => {
    if (data.length > 4) {
      const parts = value.split(' ');
      if (parts.length > 1) {
        return `${parts[0].substring(0, 1)}. ${parts.pop()}`;
      }
    }
    return value;
  };

  return (
    <>
      <div className="mt-8 grid grid-cols-2 gap-x-16 gap-y-8 ">
        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Latency</h2>
          <div className="text-xs text-gray-500 mb-4">
            Measures the average latency for the model&lsquo;s predicted answer.
          </div>
          {metrics.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={metrics} margin={{ left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(metrics, v)} />
                <YAxis tick={labelSizeSmall} tickFormatter={(v) => `${v}s`} />
                <Tooltip formatter={(value) => `${value.toLocaleString()}`} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="minLatency" fill={CHART_COLORS[0]} name="Minimum" radius={barRadius} />
                <Bar dataKey="avgLatency" fill={CHART_COLORS[1]} name="Average" radius={barRadius} />
                <Bar dataKey="maxLatency" fill={CHART_COLORS[2]} name="Maximum" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Time to First Byte</h2>
          <div className="text-xs text-gray-500 mb-4">
            Measures the average time to first byte for the model&lsquo;s predicted answer.
          </div>
          {metrics.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={metrics} margin={{ left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(metrics, v)} />
                <YAxis tick={labelSizeSmall} tickFormatter={(v) => `${v}s`} />
                <Tooltip formatter={(value) => `${value.toLocaleString()}`} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="minTtfb" fill={CHART_COLORS[0]} name="Minimum" radius={barRadius} />
                <Bar dataKey="avgTtfb" fill={CHART_COLORS[1]} name="Average" radius={barRadius} />
                <Bar dataKey="maxTtfb" fill={CHART_COLORS[2]} name="Maximum" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Cost</h2>
          <div className="text-xs text-gray-500 mb-4">
            Measures the cost of model&lsquo;s predicted answer. Includes request and response costs.
          </div>
          {metrics.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={metrics} margin={{ left: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(metrics, v)} />
                <YAxis tick={labelSizeSmall} tickFormatter={(tick) => formatCurrency(tick, 4)} />
                <Tooltip formatter={(value) => formatCurrency(value.toString(), 5)} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="avgRequestCost" stackId="a" fill={CHART_COLORS[0]} name="Request" />
                <Bar dataKey="avgResponseCost" stackId="a" fill={CHART_COLORS[2]} name="Response" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Request Tokens</h2>
          <div className="text-xs text-gray-500 mb-4">Measures the total token count in the model&lsquo;s prompt.</div>
          {metrics.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={metrics} margin={{ left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(metrics, v)} />
                <YAxis tick={labelSizeSmall} />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="avgRequestTokens" fill={CHART_COLORS[0]} name="Average" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Response Tokens</h2>
          <div className="text-xs text-gray-500 mb-4">
            Measures the total token count in the model&lsquo;s predicted answer.
          </div>
          {metrics.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={metrics} margin={{ left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(metrics, v)} />
                <YAxis tick={labelSizeSmall} />
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="avgResponseTokens" fill={CHART_COLORS[0]} name="Average" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Coherence</h2>
          <div className="text-xs text-gray-500 mb-4">
            Measures that the model&lsquo;s output that flows smoothly, reads naturally, and resembles human-like
            language.
          </div>
          {coherenceData.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={coherenceData} margin={{ left: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(coherenceData, v)} />
                <YAxis tick={labelSizeSmall} ticks={[1, 2, 3, 4, 5]} tickMargin={0} />
                <Tooltip />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="avgRating" fill={CHART_COLORS[0]} name="Rating" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Fluency</h2>
          <div className="text-xs text-gray-500 mb-4">
            Measures the grammatical proficiency of a model&lsquo;s predicted answer.
          </div>
          {fluencyData.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={fluencyData} margin={{ left: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(fluencyData, v)} />
                <YAxis tick={labelSizeSmall} ticks={[1, 2, 3, 4, 5]} tickMargin={0} />
                <Tooltip />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="avgRating" fill={CHART_COLORS[0]} name="Rating" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-700 mb-0">Sentiment</h2>
          <div className="text-xs text-gray-500 mb-4">
            Measures the emotional tone of the model&lsquo;s predicted answer.
          </div>
          {sentimentData.length === 0 ? (
            <Skeleton count={chartSkeletons} />
          ) : (
            <ResponsiveContainer height={chartContainerHeight}>
              <BarChart data={sentimentData} margin={{ left: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={labelSizeSmall} dataKey="model" tickFormatter={(v) => xTickFormatter(sentimentData, v)} />
                <YAxis tick={labelSizeSmall} tickFormatter={(v) => `${v}%`} />
                <Tooltip formatter={(v) => `${v}%`} />
                <Legend wrapperStyle={labelSizeSmall} />
                <Bar dataKey="NEUTRAL" fill={CHART_COLORS[0]} name="Neutral" radius={barRadius} />
                <Bar dataKey="POSITIVE" fill={CHART_COLORS[1]} name="Positive" radius={barRadius} />
                <Bar dataKey="NEGATIVE" fill={CHART_COLORS[2]} name="Negative" radius={barRadius} />
                <Bar dataKey="UNKNOWN" fill={CHART_COLORS[3]} name="Unknown" radius={barRadius} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default PerformanceGraph;
