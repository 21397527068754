import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage, validateUserPassword } from '../../common/utils';
import { isResetTokenValid, resetPassword } from '../../services/User';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface Props {}

/**
 * ResetPassword user page component.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const ResetPassword: React.FC<Props> = ({}: Props) => {
  const navigate = useNavigate();
  const resetToken = useParams().resetToken;
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (!resetToken) {
      navigate('/');
      toast.error('Invalid Reset Token');
    }

    (async () => {
      try {
        const isValid = await isResetTokenValid(resetToken!);
        setIsLoading(false);

        if (!isValid) {
          throw new Error('Invalid Reset Token');
        }
      } catch (e) {
        toast.error(getErrorMessage(e));
        navigate('/');
      }
    })();
  }, []);

  const handleResetPassword = async () => {
    if (!password || !passwordConfirm) {
      toast.error('Please fill in all fields');
      return;
    } else if (passwordConfirm !== password) {
      toast.error('Passwords do not match');
      return;
    }

    const passwordErrorMessage = validateUserPassword(password);
    if (passwordErrorMessage) {
      toast.error(passwordErrorMessage);
      return;
    }

    setIsSubmitting(true);

    try {
      await resetPassword(resetToken!, password);
      toast.success('Password reset successfully');
      navigate('/login');
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mx-auto max-w-lg my-5 bg-gray-50 border-gray-30000 border-2 p-5 rounded-lg">
      <div className="">
        <h1 className="mx-auto text-center text-4xl font-bold text-gray-800">
          {'{ '}
          <span className="text-indigo-500">Promptly</span>
          {' }'}
        </h1>
        <h2 className="mt-8 text-center text-2xl font-semibold tracking-tight text-gray-900">Reset Password</h2>
      </div>

      <div className="mt-10 ">
        <div className="space-y-6">
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                required
                disabled={isSubmitting || isLoading}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="passwordConfirm" className="block text-sm font-medium leading-6 text-gray-900">
                Confirm Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="passwordConfirm"
                name="passwordConfirm"
                type="password"
                autoComplete="off"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.currentTarget.value)}
                required
                disabled={isSubmitting || isLoading}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              onClick={handleResetPassword}
              disabled={isSubmitting || isLoading}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              {isSubmitting && <FontAwesomeIcon icon={faSpinner} className="animate-spin" />} Reset Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
