import { FallbackProps } from 'react-error-boundary';
import { getErrorMessage } from '../../common/utils';

export const ErrorFallback: React.FC<FallbackProps> = ({ error }: FallbackProps) => {
  return (
    <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
      <div className="relative">
        <h1 className="my-2 text-gray-800 font-bold text-2xl">Looks like someone tripped over a wire!</h1>
        <p className="my-2 text-red-600">{getErrorMessage(error)}</p>
        <p className="my-2 text-gray-800 mt-10">
          Sorry about that! Let us know on <span className="font-bold">#promptly</span>.
        </p>
        <a href="https://coxauto.slack.com/archives/C06V80TMKED">
          <button className="standard mt-4">Chat With Us!</button>
        </a>
      </div>
      <div>
        <img src="/error.png" />
      </div>
    </div>
  );
};

export default ErrorFallback;
