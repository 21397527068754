import { faCircleExclamation, faRocket, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { PromptComposer } from '.';
import { generatePromptPayloadFromVersion } from '../../common/prompts';
import { deepMergeExcludeMissing, getErrorMessage } from '../../common/utils';
import { runModel } from '../../services/Models';
import { PromptVersion } from '../../types';
import { PromptMetricSource } from '../../types/Metrics';
import { ModelRunResult } from '../../types/Models';
import { ModelRunStats, PromptOutputFormatter, StyledDialog } from '../common';

interface Props {
  open: boolean;
  version: PromptVersion | undefined;
  onClose: (payload: any) => void;
}

/**
 * Playground component provides an interface for running and testing different versions of a model.
 * It allows users to input a prompt, run the model, and view the results and metrics.
 *
 * @component
 * @param {Props} props - The properties object.
 * @param {boolean} props.open - Indicates whether the dialog is open.
 * @param {Version} props.version - The version of the model to be tested.
 * @param {function} props.onClose - Callback function to handle closing the dialog.
 *
 * @returns {JSX.Element} The rendered Playground component.
 *
 * @example
 * <Playground open={true} version={modelVersion} onClose={handleClose} />
 */
const Playground: React.FC<Props> = ({ open, version, onClose }: Props) => {
  const [payload, setPayload] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string | undefined>('');
  const [result, setResult] = useState<ModelRunResult>();

  useEffect(() => {
    if (!version) return;

    if (!version.samplePayload) {
      const emptyPayload = generatePromptPayloadFromVersion(version);
      const newPayload = deepMergeExcludeMissing(emptyPayload, payload);
      setPayload(newPayload);
    } else {
      setPayload(version.samplePayload);
    }

    setPrompt('');
    setResult(undefined);
  }, [version]);

  const handleClose = () => {
    if (disabled) return;
    onClose(payload);
  };

  const handleRun = async () => {
    if (!version) return;

    setDisabled(true);
    setResult(undefined);

    try {
      const resp = await runModel(
        version.model!,
        prompt?.replace('"systemPrompt":', '"system_prompt":')!,
        version.parameters!,
        version.type,
        version.tools,
        version.promptId,
        version.version,
        PromptMetricSource.PLAYGROUND
      );
      setResult(resp);
    } catch (e) {
      console.error(e);
      return toast.error(`Failed to run model: ${getErrorMessage(e)}`);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <StyledDialog
      title="Playground"
      isOpen={open}
      closeText="Close"
      icon={faRocket}
      confirmText={disabled ? <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> : 'Run'}
      width="w-5/6"
      onClose={handleClose}
      onConfirm={handleRun}>
      <div className="mt-4 h-[75vh]">
        <PromptComposer
          template={version?.template}
          payload={payload}
          disabled={disabled}
          showPreview={true}
          className={result || disabled ? '!max-h-[30vh]' : ''}
          onUnload={setPayload}
          onPromptGenerate={setPrompt}
        />

        {(result || disabled) && (
          <div>
            <div className="text-baseline font-semibold leading-6 text-gray-800 mt-4">Results</div>
            <div className="mt-2 !max-h-[30vh] overflow-y-scroll">
              {result ? (
                !result.failed ? (
                  <blockquote className="p-4 my-2 border-s-4 border-gray-300 bg-gray-50">
                    <PromptOutputFormatter result={result?.completion} />
                  </blockquote>
                ) : (
                  <div>
                    <FontAwesomeIcon icon={faCircleExclamation} className="text-red-600 w-4 h-4 mr-2" /> Failed:{' '}
                    {result.failureReason}
                  </div>
                )
              ) : (
                <Skeleton count={3} />
              )}
            </div>

            <div className="text-baseline font-semibold leading-6 text-gray-800 mt-4 pt-4 border-t border-gray-300">
              Metrics
            </div>
            <ModelRunStats run={result} />
            <div className="text-baseline font-semibold leading-6 text-gray-800 mt-4">Configuration</div>
            <div className="grid grid-flow-col gap-2 mt-2">
              <div className="flex flex-col">
                <div className="text-xs text-gray-600">Model</div>
                <div className="text-sm">{version?.model}</div>
              </div>
              {version?.parameters.map((p, i) => (
                <div key={i} className="flex flex-col">
                  <div className="text-xs text-gray-600">{p.name}</div>
                  <div className="text-sm">{p.value}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </StyledDialog>
  );
};

export default Playground;
